import clsx from 'clsx';
import Box from 'components/Box';
import { useClickOutside } from 'hooks';
import { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { isFunction } from 'utils';

const Dropdown = ({ options, className, classNameBox, optionsPopover, children }) => {
    const [open, setOpen] = useState(false);

    const ref = useRef();

    useClickOutside(ref, () => setOpen(false));
    const close = () => {
        setOpen(false);
    };

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, optionsPopover);

    return (
        <div ref={ref} className={clsx('relative h-full', classNameBox)}>
            <div className="w-full h-full" ref={setReferenceElement} onClick={() => setOpen(!open)}>
                {children}
            </div>
            {open && options.length !== 0 && (
                <Box
                    ref={setPopperElement}
                    style={{
                        ...styles.popper,
                    }}
                    {...attributes.popper}
                    className={clsx(
                        `bg-white absolute min-w-max  w-full max-w-[220px] z-[100] origin-top-left`,
                        className,
                    )}
                >
                    {/* Check options is a function */}
                    {isFunction(options) ? options({ close }) : options}
                </Box>
            )}
        </div>
    );
};
export default Dropdown;
