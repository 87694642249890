import { IMAGE } from 'assets/imagePath';

export const introduceData = {
    agencies: {
        title: "Elevate your agency's digital ad management to a professional level",
        icon: 'agency',
        html: /*html*/ `Elevate your agency's digital ad management to a professional level </br>Are you searching for ways to  elevate your agency's digital advertising capabilities? </br> Look no further than AdNucleus, the leading ad tool tailored specifically for agencies and digital advertising professionals.`,
    },
    finance: {
        title: 'Manage your finance digital ads like professionals',
        icon: 'finance',
        html: 'Maximize your financial institution’s digital advertising potential with AdNucleus, the ultimate financial tool for professionals in the finance industry.',
    },
    government: {
        title: "Transform your government institution's digital advertising like a pro",
        icon: 'government',
        html: 'Are you seeking to elevate your government institution’s digital advertising to new heights? Look no further than AdNucleus, the leading ad tool designed specifically for the government industry.',
    },
    retail: {
        title: 'Take your retail digital advertising to the next level with AdNucleus',
        icon: 'retail',
        html: 'Are you looking to enhance your retail digital advertising strategies? Look no further than AdNucleus, the ultimate ad tool for retail professionals.',
    },
    blockchain: {
        title: 'Master the art of handling your blockchain digital ads',
        icon: 'blockchain',
        html: 'Streamline your digital advertising with AdNucleus, the ultimate tool for managing your campaigns with efficiency and precision.',
    },
    travel: {
        title: 'Elevate Your Travel Advertising with AdNucleus',
        icon: 'travel',
        html: 'Are you ready to revolutionize your digital advertising strategy in the travel industry? Look no further than AdNucleus, the ultimate tool for travel marketers.',
    },
    healthcare: {
        title: "Enhance your healthcare institution's digital advertising capabilities with AdNucleus",
        icon: 'healthcare',
        html: "Are you seeking to elevate your healthcare institution's digital advertising efforts? Look no further than AdNucleus, the premier ad tool designed specifically for healthcare professionals.",
    },
};

export const tailoredData = {
    agencies: {
        title: 'Tailored for agencies',
        image: IMAGE.tailored_agencies,
        html: 'Bid farewell to the cumbersome task of switching between various platforms and tools. AdNucleus streamlines the entire process, saving you valuable time and enhancing your overall efficiency.',
        benefits: [
            {
                title: 'User-centric interface',
                desc: 'An interface that is both user-friendly and intuitive can significantly boost productivity while minimizing errors. Navigate through AdNucleus seamlessly and get things done with ease.',
                className: 'bg-warning',
                icon: 'market',
            },
            {
                title: 'Personalized dashboards',
                desc: "Create dashboards that align with your users' specific roles and objectives, ensuring relevance and meaningful insights for each individual.",
                className: 'bg-success',
                icon: 'plusSquare',
            },
            {
                title: 'Automated reporting',
                desc: 'Eliminate manual effort and minimize inaccuracies by automating the generation of reports that succinctly summarize campaign performance.',
                className: 'bg-error',
                icon: 'report',
            },
            {
                title: 'Enhanced collaboration',
                desc: 'Foster teamwork and collaboration on a centralized hub within AdNucleus. Team members can effortlessly access and share information, eliminating the need for lengthy email exchanges and communication gaps.',
                className: 'bg-error',
                icon: 'users',
            },
            {
                title: 'Campaign optimization features',
                desc: 'Empower yourself with real-time campaign optimization capabilities based on data-driven insights. Refine audience targeting, ad placements, and bidding strategies to enhance campaign performance and maximize ROI.',
                className: 'bg-primary',
                icon: 'brand',
            },
            {
                title: 'Data visualization',
                desc: 'Make well-informed decisions with precise estimates and comprehensive analytics. AdNucleus provides robust data-driven insights to facilitate informed decision-making for your campaigns.',
                className: 'bg-warning',
                icon: 'chartLine',
            },
        ],
    },
    blockchain: {
        title: 'Tailored for agencies',
        image: IMAGE.tailored_blockchain,
        html: 'Bid farewell to the complexities of navigating multiple platforms and tools. AdNucleus seamlessly integrates all aspects of your digital advertising, saving you valuable time and boosting your overall productivity.',
        benefits: [
            {
                title: 'User-centric interface',
                desc: 'An interface that is both user-friendly and intuitive can significantly boost productivity while minimizing errors. Navigate through AdNucleus seamlessly and get things done with ease.',
                className: 'bg-warning',
                icon: 'market',
            },
            {
                title: 'Personalized dashboards',
                desc: "Create dashboards that align with your users' specific roles and objectives, ensuring relevance and meaningful insights for each individual.",
                className: 'bg-success',
                icon: 'plusSquare',
            },
            {
                title: 'Automated reporting',
                desc: 'Eliminate manual effort and minimize inaccuracies by automating the generation of reports that succinctly summarize campaign performance.',
                className: 'bg-error',
                icon: 'report',
            },
            {
                title: 'Enhanced collaboration',
                desc: 'Foster teamwork and collaboration on a centralized hub within AdNucleus. Team members can effortlessly access and share information, eliminating the need for lengthy email exchanges and communication gaps.',
                className: 'bg-error',
                icon: 'users',
            },
            {
                title: 'Campaign optimization features',
                desc: 'Empower yourself with real-time campaign optimization capabilities based on data-driven insights. Refine audience targeting, ad placements, and bidding strategies to enhance campaign performance and maximize ROI.',
                className: 'bg-primary',
                icon: 'brand',
            },
            {
                title: 'Data visualization',
                desc: 'Make well-informed decisions with precise estimates and comprehensive analytics. AdNucleus provides robust data-driven insights to facilitate informed decision-making for your campaigns.',
                className: 'bg-warning',
                icon: 'chartLine',
            },
        ],
    },
    finance: {
        title: 'Tailored for financial institutions',
        image: IMAGE.tailored_finance,
        html: 'Streamline your operations and save valuable time with AdNucleus comprehensive suite of features, eliminating the need to juggle multiple platforms and tools.',
        benefits: [
            {
                title: 'Intuitive interface for financial experts',
                desc: 'Navigate with ease through AdNucleus user-friendly interface, specifically designed for finance professionals. Increase productivity and minimize errors with its simple and intuitive layout.',
                className: 'bg-warning',
                icon: 'market',
            },
            {
                title: 'Personalized dashboards',
                desc: 'AdNucleus allows you to customize your dashboards to suit your specific roles and objectives. Stay focused on what matters most by accessing relevant and meaningful data in real-time.',
                className: 'bg-success',
                icon: 'plusSquare',
            },
            {
                title: 'Automated reporting for financial insights',
                desc: 'Effortlessly generate reports that consolidate campaign performance data, saving time and ensuring accuracy. Gain valuable financial insights to optimize your strategies and maximize returns.',
                className: 'bg-error',
                icon: 'report',
            },
            {
                title: 'Enhanced collaboration for financial teams',
                desc: 'Drive efficiency and foster collaboration with AdNucleus centralized hub. Your finance team can effortlessly access and share information, eliminating communication gaps and reducing reliance on back-and-forth emails.',
                className: 'bg-error',
                icon: 'users',
            },
            {
                title: 'Real-time campaign optimization',
                desc: 'Utilize AdNucleus cutting-edge features to optimize campaigns in real-time based on data-driven insights. Refine audience targeting, ad placements, and bidding strategies to enhance campaign performance and maximize return on investment (ROI).',
                className: 'bg-primary',
                icon: 'brand',
            },
            {
                title: 'Precise data visualization',
                desc: 'Make well-informed financial decisions using AdNucleus powerful data visualization tools. Accurate estimates and analytics empower you to make strategic choices that align with your campaign objectives.',
                className: 'bg-warning',
                icon: 'chartLine',
            },
        ],
    },
    government: {
        title: 'Tailored for government institutions',
        image: IMAGE.tailored_government,
        html: 'Bid farewell to the complexities of juggling multiple platforms and tools. AdNucleus simplifies the process, saving you time and enhancing your operational efficiency.',
        benefits: [
            {
                title: 'User-friendly interface',
                desc: 'Enjoy a streamlined and intuitive interface that facilitates seamless navigation, boosting productivity and minimizing errors in your government advertising endeavors.',
                className: 'bg-warning',
                icon: 'market',
            },
            {
                title: 'Customizable dashboards',
                desc: 'Personalize dashboards to cater to the unique roles and objectives of government agency users, delivering relevant and meaningful insights.',
                className: 'bg-success',
                icon: 'plusSquare',
            },
            {
                title: 'Automated reporting',
                desc: 'Slash time-consuming tasks and mitigate errors by automatically generating comprehensive reports that succinctly summarize campaign performance, empowering evidence-based decision-making.',
                className: 'bg-error',
                icon: 'report',
            },
            {
                title: 'Enhanced Collaboration',
                desc: 'Foster teamwork within your government agency through a centralized hub that enables easy access and seamless information sharing. Bid adieu to unnecessary email exchanges and communication gaps.',
                className: 'bg-error',
                icon: 'users',
            },
            {
                title: 'Campaign optimization features',
                desc: 'Harness the power of real-time data insights to optimize campaigns swiftly and effectively. Refine audience targeting, ad placements, and bidding strategies to enhance campaign performance and maximize return on investment (ROI).',
                className: 'bg-primary',
                icon: 'brand',
            },
            {
                title: 'Data visualization',
                desc: "Make well-informed decisions with accurate estimates and robust analytics. AdNucleus provides potent data-driven insights to empower data-based decision-making for your government agency's campaigns.",
                className: 'bg-warning',
                icon: 'chartLine',
            },
        ],
    },
    healthcare: {
        title: 'Tailored for healthcare institutions',
        image: IMAGE.tailored_healthcare,
        html: 'Bid farewell to the complexities of navigating multiple platforms and tools. AdNucleus streamlines the process, saving you valuable time and boosting overall efficiency.',
        benefits: [
            {
                title: 'User-friendly interface',
                desc: 'Enjoy a streamlined and intuitive interface that facilitates seamless navigation, boosting productivity and minimizing errors in your government advertising endeavors.',
                className: 'bg-warning',
                icon: 'market',
            },
            {
                title: 'Customizable dashboards',
                desc: 'Personalize dashboards to cater to the unique roles and objectives of government agency users, delivering relevant and meaningful insights.',
                className: 'bg-success',
                icon: 'plusSquare',
            },
            {
                title: 'Automated reporting',
                desc: 'Slash time-consuming tasks and mitigate errors by automatically generating comprehensive reports that succinctly summarize campaign performance, empowering evidence-based decision-making.',
                className: 'bg-error',
                icon: 'report',
            },
            {
                title: 'Streamlined collaboration',
                desc: 'Collaborate seamlessly within a centralized hub where team members can access and share information effortlessly. Eliminate the need for cumbersome email chains and bridge communication gaps.',
                className: 'bg-error',
                icon: 'users',
            },
            {
                title: 'Campaign optimization features',
                desc: 'Harness the power of real-time data insights to optimize campaigns swiftly and effectively. Refine audience targeting, ad placements, and bidding strategies to enhance campaign performance and maximize return on investment (ROI).',
                className: 'bg-primary',
                icon: 'brand',
            },
            {
                title: 'Data visualization',
                desc: "Make well-informed decisions with accurate estimates and robust analytics. AdNucleus provides potent data-driven insights to empower data-based decision-making for your government agency's campaigns.",
                className: 'bg-warning',
                icon: 'chartLine',
            },
        ],
    },
    retail: {
        title: 'Tailored for retailers',
        image: IMAGE.tailored_retail,
        html: 'Say goodbye to the complexity of managing multiple platforms and tools. AdNucleus streamlines the process, saving you time and boosting your efficiency.',
        benefits: [
            {
                title: 'User-friendly interface',
                desc: 'Enjoy a streamlined and intuitive interface that facilitates seamless navigation, boosting productivity and minimizing errors in your government advertising endeavors.',
                className: 'bg-warning',
                icon: 'market',
            },
            {
                title: 'Customizable dashboards',
                desc: 'Personalize dashboards to cater to the unique roles and objectives of government agency users, delivering relevant and meaningful insights.',
                className: 'bg-success',
                icon: 'plusSquare',
            },
            {
                title: 'Automated reporting',
                desc: 'Slash time-consuming tasks and mitigate errors by automatically generating comprehensive reports that succinctly summarize campaign performance, empowering evidence-based decision-making.',
                className: 'bg-error',
                icon: 'report',
            },
            {
                title: 'Enhanced Collaboration',
                desc: 'Foster teamwork within your government agency through a centralized hub that enables easy access and seamless information sharing. Bid adieu to unnecessary email exchanges and communication gaps.',
                className: 'bg-error',
                icon: 'users',
            },
            {
                title: 'Campaign optimization features',
                desc: 'Harness the power of real-time data insights to optimize campaigns swiftly and effectively. Refine audience targeting, ad placements, and bidding strategies to enhance campaign performance and maximize return on investment (ROI).',
                className: 'bg-primary',
                icon: 'brand',
            },
            {
                title: 'Data visualization',
                desc: "Make well-informed decisions with accurate estimates and robust analytics. AdNucleus provides potent data-driven insights to empower data-based decision-making for your government agency's campaigns.",
                className: 'bg-warning',
                icon: 'chartLine',
            },
        ],
    },
    travel: {
        title: 'Tailored for Travel Businesses',
        image: IMAGE.tailored_travel,
        html: 'Say goodbye to the complexity of managing multiple platforms and tools. AdNucleus simplifies the process, saving you time and boosting your efficiency.',
        benefits: [
            {
                title: 'User-friendly interface',
                desc: 'Enjoy a streamlined and intuitive interface that facilitates seamless navigation, boosting productivity and minimizing errors in your government advertising endeavors.',
                className: 'bg-warning',
                icon: 'market',
            },
            {
                title: 'Customizable dashboards',
                desc: 'Personalize dashboards to cater to the unique roles and objectives of government agency users, delivering relevant and meaningful insights.',
                className: 'bg-success',
                icon: 'plusSquare',
            },
            {
                title: 'Automated reporting',
                desc: 'Slash time-consuming tasks and mitigate errors by automatically generating comprehensive reports that succinctly summarize campaign performance, empowering evidence-based decision-making.',
                className: 'bg-error',
                icon: 'report',
            },
            {
                title: 'Streamlined collaboration',
                desc: 'Collaborate seamlessly within a centralized hub where team members can access and share information effortlessly. Eliminate the need for cumbersome email chains and bridge communication gaps.',
                className: 'bg-error',
                icon: 'users',
            },
            {
                title: 'Campaign optimization features',
                desc: 'Harness the power of real-time data insights to optimize campaigns swiftly and effectively. Refine audience targeting, ad placements, and bidding strategies to enhance campaign performance and maximize return on investment (ROI).',
                className: 'bg-primary',
                icon: 'brand',
            },
            {
                title: 'Data visualization',
                desc: "Make well-informed decisions with accurate estimates and robust analytics. AdNucleus provides potent data-driven insights to empower data-based decision-making for your government agency's campaigns.",
                className: 'bg-warning',
                icon: 'chartLine',
            },
        ],
    },
};
