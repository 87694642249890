import { SVG } from 'assets/imagePath';
import { BoxContent } from 'components/BoxContent';
import { Button } from 'components/Button';
import { ChatBox, ChatInput } from 'components/ChatBox';
import Dropdown from 'components/Dropdown';
import { HeaderNavigate } from 'components/HeaderNavigate';
import Image from 'components/Image';
import { useParams } from 'react-router-dom';

const chatContents = [
    {
        avatar: '',
        message:
            'Senectus non lobortis nisl facilisis faucibus vitae ullamcorper. Feugiat id faucibus mi elit molestie nulla quisque. Feugiat nunc sit risus gravida feugiat ut aliquet molestie.',
        timestamp: '',
        isYou: false,
        time: '10:00 AM',
    },
    {
        avatar: '',
        message:
            'Senectus non lobortis nisl facilisis faucibus vitae ullamcorper. Feugiat id faucibus mi elit molestie nulla quisque. Feugiat nunc sit risus gravida feugiat ut aliquet molestie.',
        timestamp: '',
        isYou: true,
        time: '3:00 PM',
    },
];

function TicketComment() {
    const { ticketId } = useParams();
    return (
        <div className="p-2.5  md:p-[30px] w-full">
            <HeaderNavigate
                detailInfo={{ detailName: 'Ticket', detailId: ticketId, chipLabel: 'Resolved' }}
                chipProps={{
                    color: 'success',
                }}
                ActionArea={
                    <Dropdown
                        options={
                            <ul className="flex flex-col z-50 divide-y-[1px] divide-blue-cfd">
                                <li className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer">
                                    <div className="flex flex-row items-center gap-[5px] flex-1">
                                        <span className="text-sm text-blue-dark">Agency Account</span>
                                    </div>
                                </li>
                            </ul>
                        }
                    >
                        <Button className={'px-[15px] '} color="secondary">
                            <div className=" flex flex-row gap-[5px] items-center">
                                <div>Status</div>
                                <Image src={SVG.chevron_down} />
                            </div>
                        </Button>
                    </Dropdown>
                }
            />

            <BoxContent title={'Comments'} className="mt-[15px] md:mt-[30px] max-w-[820px]">
                <div className="flex flex-col gap-5">
                    <ChatBox chatContents={chatContents} rating />
                    <ChatInput />
                </div>
            </BoxContent>
        </div>
    );
}

export default TicketComment;
