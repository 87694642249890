import { Button } from 'components/Button';
import { useNavigateSearch } from 'hooks';
import { useLocation } from 'react-router-dom';

function AcceptInvitation({ name }) {
    const navigateSearch = useNavigateSearch();

    const { pathname } = useLocation();

    const onSubmit = () => {
        navigateSearch(pathname, { step: '2' });
    };

    return (
        <>
            <div className="text-center font-bold text-[24px] leading-[29px] text-dark-nav mb-[30px]">
                Accept Invitation
            </div>
            <div className="text-center  text-[14px] leading-[21px] text-dark-nav mb-[20px]">
                Corporate Legal Name has invited <br /> you to join company account on {name}.
            </div>

            <form className="w-full mx-auto max-w-[360px]  mt-[20px]  mb-[30px]">
                <div className=" py-[20px] h-[205px] px-[15px] shadow   bg-white-light rounded-[15px]">
                    <div className="flex justify-between text-[12px] leading-[15px] mb-[10px]">
                        <span>Corporate Legal Name</span>
                        <span>Value</span>
                    </div>
                    <div className="flex justify-between text-[12px] leading-[15px] mb-[10px]">
                        <span>System User Name</span>
                        <span>Value</span>
                    </div>
                    <div className="flex justify-between text-[12px] leading-[15px] mb-[10px]">
                        <span>Corporate Title</span>
                        <span>Value</span>
                    </div>
                    <div className="flex justify-between text-[12px] leading-[15px] mb-[10px]">
                        <span>System Role</span>
                        <span>Value</span>
                    </div>
                    <div className="flex justify-between text-[12px] leading-[15px] mb-[10px]">
                        <span>Account Status</span>
                        <span>Value</span>
                    </div>
                    <div className="flex justify-between text-[12px] leading-[15px] mb-[10px]">
                        <span>Invitation Date</span>
                        <span>Value</span>
                    </div>
                    <div className="flex justify-between text-[12px] leading-[15px] mb-[10px]">
                        <span>Primary Account ID</span>
                        <span>Value</span>
                    </div>
                </div>
                <div className="flex gap-[10px] mt-[30px] mb-[30px]">
                    <Button className=" w-[260px] py-[15px] px-5  shadow-outer " color="secondary">
                        Decline
                    </Button>
                    <Button className=" w-[260px] py-[15px] px-5  shadow-outer" onClick={onSubmit}>
                        Accept
                    </Button>
                </div>
            </form>
        </>
    );
}

export default AcceptInvitation;
