import { IMAGE } from 'assets/imagePath';
import Image from 'components/Image';

const Strategy = () => {
    return (
        <section className="flex flex-col sm:text-start text-center xl:flex-row items-center justify-center gap-5 xl:gap-[78px] xl:h-[380px] w-full mx-auto px-[20px] py-[30px]  sm:p-[50px] bg-dark-nav">
            <Image src={IMAGE.strategy} className="w-[715px] h-auto object-contain" />
            <div>
                <div className="font-bold text-white text-lg xs:text-xl leading-8 mb-[10px] font-sen">
                    Proactive Strategy and Expertise
                </div>
                <div className="max-w-[715px] xl:max-w-[407px] text-xs xs:text-sm leading-[21px] text-blue-cfd">
                    Dig really deep into your existing clients, new brands, industry trends and market data. AdNucleus's
                    team of analytic experts and internal research tools will help you deliver unique insights, develop
                    inspiring pitches and deliver world-class campaigns.
                </div>
            </div>
        </section>
    );
};

export default Strategy;
