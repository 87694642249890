import Chip from 'components/Chip/Chip';
import ActionsButton from 'components/GridTable/ActionsButton';
import { checkStatusCampaign } from 'utils';

export const campaignColumn = [
    {
        field: 'id',

        headerStyle: { cursor: 'pointer', minWidth: '65px' },
        headerName: 'ID',
        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id</li>
        //             </ul>
        //         );
        //     },
        // },
    },
    {
        field: 'name',

        headerStyle: { cursor: 'pointer', minWidth: '160px' },
        headerName: 'Name',
        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id?</li>
        //             </ul>
        //         );
        //     },
        // },
    },
    {
        field: 'state',
        headerStyle: { cursor: 'pointer', minWidth: '80px' },

        headerName: 'State',
        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id?</li>
        //             </ul>
        //         );
        //     },
        // },
        renderCell: (row) => {
            const { color, label } = checkStatusCampaign(row?.state);
            return (
                <div className="">
                    <Chip className={'mx-0 md:mx-auto'} color={color}>
                        {label}
                    </Chip>
                </div>
            );
        },
    },
    {
        field: 'review',
        headerName: 'Review',
        headerStyle: { cursor: 'pointer', minWidth: '80px' },

        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id?</li>
        //             </ul>
        //         );
        //     },
        // },
        renderCell: (row) => {
            return (
                <div className="">
                    <Chip className={'mx-0 md:mx-auto'} color={'white'}>
                        {row.review}
                    </Chip>
                </div>
            );
        },
    },
    {
        field: 'audience',
        headerStyle: { cursor: 'pointer', minWidth: '160px' },

        headerName: 'Audience',
        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id?</li>
        //             </ul>
        //         );
        //     },
        // },
    },
    {
        field: 'creative',

        headerStyle: { cursor: 'pointer', minWidth: '160px' },
        headerName: 'Creative',
        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id?</li>
        //             </ul>
        //         );
        //     },
        // },
    },
    {
        field: 'start_date',
        headerStyle: { cursor: 'pointer' },
        rowStyle: { maxWidth: '117px' },
        headerName: 'Start Date',
        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id?</li>
        //             </ul>
        //         );
        //     },
        // },
    },
    {
        field: 'end_date',
        headerStyle: { cursor: 'pointer' },
        rowStyle: { maxWidth: '117px' },

        headerName: 'End Date',
        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id?</li>
        //             </ul>
        //         );
        //     },
        // },
    },
    {
        field: 'budget',
        headerStyle: { cursor: 'pointer' },

        headerName: 'Budget',
        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id?</li>
        //             </ul>
        //         );
        //     },
        // },
    },
    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row, handle) => {
            return (
                <ActionsButton
                    onChange={(actionVal) => {
                        // console.log(actionVal);
                        handle(row, actionVal);
                    }}
                />
            );
        },
    },
];

export const campaignRow = [
    {
        id: 324,
        name: 'Campaign 53',
        state: 'draft',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 349,
    },
    {
        id: 325,
        name: 'Campaign 57',
        state: 'draft',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 769,
    },
    {
        id: 326,
        name: 'Campaign 82',
        state: 'embed',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 800,
    },
    {
        id: 327,
        name: 'Campaign 64',
        state: 'draft',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 119,
    },
    {
        id: 328,
        name: 'Campaing 8',
        state: 'paused',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 674,
    },
    {
        id: 329,
        name: 'Campaign 44',
        state: 'draft',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 735,
    },
    {
        id: 330,
        name: 'Campaign 60',
        state: 'active',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 978,
    },
    {
        id: 331,
        name: 'Campaign 12',
        state: 'draft',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 443,
    },
    {
        id: 335,
        name: 'Campaign 65',
        state: 'draft',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 644,
    },
    {
        id: 336,
        name: 'Campaign 71',
        state: 'archived',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 95,
    },
    {
        id: 337,
        name: 'Campaign 45',
        state: 'draft',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 426,
    },

    {
        id: 324,
        name: 'Campaign 78',
        state: 'draft',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 866,
    },
    {
        id: 324,
        name: 'Campaign 70',
        state: 'draft',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 4000,
    },
    {
        id: 324,
        name: 'Campaign 61',
        state: 'draft',
        review: 'Not started',
        audience: 'Audience',
        creative: 'Creative',
        start_date: 'Jan 24, 2023',
        end_date: 'Jan 24, 2023',
        budget: 519,
    },
];
