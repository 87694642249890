import { Button } from 'components/Button';
import { TextArea } from 'components/Input';
import { Modal } from 'components/Modal';
import { Portal } from 'components/Portal';
import Tabs from 'components/Tabs/Tabs';
import { useState } from 'react';
import { checkValidCoordinates } from 'utils/maps';
import { Countries } from './Segment';
import Coordinates from './Segment/Coordinates';
import SegmentLocationLayout from './Segment/layout/SegmentLocationLayout';

const locationTabs = [
    {
        value: 'countries',
        label: 'Countries',
    },
    {
        value: 'cities',
        label: 'Cities',
    },
    {
        value: 'zip',
        label: 'ZIP',
    },
    {
        value: 'coordinates',
        label: 'Coordinates',
    },
    {
        value: 'favourites',
        label: 'Favourites',
    },
];

const selectedArr = [
    {
        title: 'Coordinates',
        subTitle: 'Avails: 32K, 25.7784039, -80.3545026, 1 mile radius',
        isWarning: false,
    },
    {
        title: 'Coordinates',
        subTitle: 'Avails: 12K, 25.7784039, -80.3542026, 0.2 mile radius',
        isWarning: true,
    },
];

const selectedCountries = [
    {
        id: '1',
        title: 'USA',
        subTitle: '331M Avails',
        lat: 25.7784039,
        lng: -80.3545026,
        rad: 1,
        isWarning: false,
    },
];

function LocationModule({ defaultTab }) {
    const defaultT = locationTabs.find((item) => item.value === defaultTab);
    const [tab, setTab] = useState(defaultT || locationTabs[0]);
    const [isOpen, setIsOpen] = useState(false);
    const [coordinatesJson, setCoordinatesJson] = useState('');
    const [markers, setMarkers] = useState([]);

    const handleOnClickMapToMark = (position) => {
        const { id, ...rest } = position;
        const latLng = {
            id: id ?? Date.now(), //fake id

            name: 'Coordinates',
            rad: 25, //default 5000 meterts
            ...rest,
        };

        setMarkers((prev) => {
            return [...prev, latLng];
        });
    };

    const handleChangeMarker = (markerId, value) => {
        const marker = markers.find((item) => item.id === markerId);
        if (!marker || !value) return;

        if (marker.rad === value || (marker.lat === value.lat && marker.lng === value.lng)) {
            return;
        }

        const markerUpdate = markers.map((mark) => {
            if (mark.id === markerId) {
                return {
                    ...mark,
                    ...value,
                };
            } else {
                return mark;
            }
        });

        setMarkers(markerUpdate);
    };

    const onSubmit = () => {
        const markersUpdate = [...markers];
        const data = markersUpdate.map((item) => {
            // delete item.id;
            return {
                lat: item.lat,
                lng: item.lng,
                name: item.name,
                rad: item.rad, //default 25 meterts
            };
        });

        console.log(data);
    };

    const handleRemoveSelect = (removeId) => {
        if (!removeId) {
            setMarkers([]);
        } else {
            const markerUpdate = markers.filter((marker) => {
                return marker.id !== removeId;
            });
            console.log(markerUpdate);

            setMarkers(markerUpdate);
        }
    };

    const hanldeChangeNameGeoLocation = (e, markerId) => {
        const markerUpdate = markers.map((mark) => {
            if (mark.id === markerId) {
                return {
                    ...mark,
                    name: e.target.value,
                };
            } else {
                return mark;
            }
        });

        setMarkers(markerUpdate);
    };

    const [isHovering, setIsHovering] = useState(null);

    const [errorCoordinates, setErrorCoordinates] = useState({
        error: false,
        textError: '',
    });

    const handleSaveImport = () => {
        let parseCoordinates;

        try {
            parseCoordinates = JSON.parse(coordinatesJson);
        } catch (error) {
            setErrorCoordinates({ error: true, textError: 'Invalid Json' });
            return;
        }

        if (!Array.isArray(parseCoordinates)) {
            return;
        }

        const err = checkValidCoordinates(parseCoordinates);

        if (err.error) {
            setErrorCoordinates({ ...err });
            return;
        } else {
            //Add default rad or name if JSON array not have
            parseCoordinates = parseCoordinates.map((item) => {
                return {
                    ...item,
                    id: item?.id ?? Date.now(), //fake id
                    name: item?.name ?? 'Coordinates',
                    rad: item?.rad ?? 5000, //default 5000 meterts
                };
            });
        }

        setMarkers((prev) => {
            const updateCoordinates = [...prev, ...parseCoordinates];

            const uniqueItems = {};
            //Check duplicate position
            const filteredData = updateCoordinates.filter((item) => {
                const key = `${item.lat}-${item.lng}`;
                // const key2 = item.id;
                if (!uniqueItems[key]) {
                    uniqueItems[key] = true;
                    return true;
                }
                return false;
            });
            return filteredData;
        });

        setCoordinatesJson('');

        setErrorCoordinates({ error: false, textError: '' });

        setIsOpen(false);
    };

    return (
        <>
            <div className="flex flex-col h-full gap-5 mt-5">
                <Tabs
                    onChange={(tab) => {
                        setTab(tab);
                    }}
                    className={'w-full'}
                    tabList={locationTabs}
                    value={tab.value}
                />

                {tab.value === 'countries' && (
                    <SegmentLocationLayout
                        type="LOCATION"
                        importBtn={{
                            label: 'Import Coordinates',
                        }}
                        selectedArr={selectedCountries}
                        title={'Selected Location'}
                    >
                        <Countries />
                    </SegmentLocationLayout>
                )}
                {tab.value === 'cities' && <></>}
                {tab.value === 'zip' && <></>}
                {tab.value === 'coordinates' && (
                    <SegmentLocationLayout
                        importBtn={{
                            label: 'Import Coordinates',
                            onClick: () => setIsOpen(true),
                        }}
                        selectedArr={markers}
                        title={'Selected Location'}
                        onSave={onSubmit}
                        onRemoveSelect={handleRemoveSelect}
                        onChangeGeoLocation={hanldeChangeNameGeoLocation}
                        onHoverRemove={(markerHoveringId) => {
                            setIsHovering(markerHoveringId);
                        }}
                    >
                        <Coordinates
                            markers={markers}
                            hoveringId={isHovering}
                            onAddMarker={handleOnClickMapToMark}
                            markerProps={{
                                onChangeMarker: handleChangeMarker,
                            }}
                        />
                    </SegmentLocationLayout>
                )}
                {tab.value === 'favourites' && <></>}
            </div>

            <Portal>
                <Modal
                    bodyClass={'max-w-[800px] w-full pb-[30px] z-[100] '}
                    className="z-[100]"
                    title="Import Coordinate"
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                >
                    <div>
                        <TextArea
                            rows={5}
                            onChange={(e) => setCoordinatesJson(e.target.value)}
                            value={coordinatesJson}
                            error={errorCoordinates.error}
                            helper={errorCoordinates.textError}
                        />

                        <div className="flex flex-row justify-end gap-5 mt-2.5">
                            <Button color="secondary">Cancel</Button>

                            <Button onClick={handleSaveImport}>Save</Button>
                        </div>
                    </div>
                </Modal>
            </Portal>
        </>
    );
}

export default LocationModule;
