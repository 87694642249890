import PostTable from 'components/AccountConfig/Education/PostTable';
import { HeaderNavigate } from 'components/HeaderNavigate';
import React from 'react';

function PostManagement() {
    return (
        <div className="p-2.5 md:p-[30px] flex flex-col gap-[30px]">
            <HeaderNavigate detailInfo={{ detailName: 'Posts' }} />
            <PostTable />
        </div>
    );
}

export default PostManagement;
