import Box from 'components/Box';
import { StarRating } from 'components/StarRating';
import Text from 'components/Text';
import React from 'react';
import ChatMessage from './ChatMessage';

function ChatBox({ chatContents = [], rating = false }) {
    return (
        <Box border="none" className="p-[15px] bg-white-light" shadow="default">
            <div className="flex flex-col gap-5 text-xs">
                <div className="self-center rounded-full bg-white p-[5px_10px]">Yesterday</div>
                {chatContents.map((chatContent, idx) => {
                    return (
                        <ChatMessage
                            isYou={chatContent.isYou}
                            messageInfo={{ message: chatContent.message, time: chatContent?.time }}
                            key={idx}
                        />
                    );
                })}
                {rating && (
                    <div className="self-center flex flex-col gap-2.5">
                        <div className="rounded-full bg-white p-[5px_10px]">
                            <Text className="text-center" size="12">
                                Looks like the ticket has been resolved. Please rate support you received
                            </Text>
                        </div>
                        <StarRating maxStars={5} />
                    </div>
                )}
            </div>
        </Box>
    );
}

export default ChatBox;
