import Tabs from 'components/Tabs/Tabs';
import { useState } from 'react';
import AudienceSegment from './Segment/AudienceSegment';
import SegmentLocationLayout from './Segment/layout/SegmentLocationLayout';
import { useViewport } from 'context/ViewportContext';
import { Select } from 'components/Input';
import Dropdown, { DropdownMenu } from 'components/Dropdown';
import Text from 'components/Text';
import Image from 'components/Image';
import { SVG } from 'assets/imagePath';

const segmentTabs = [
    {
        value: 'audience',
        label: 'Audience Segments',
    },
    {
        value: 'semantic',
        label: 'Semantic Segments',
    },
    {
        value: 'curated',
        label: 'Curated Segments',
    },
    {
        value: 'favourites',
        label: 'Favourites',
    },
];

const selectedArr = [
    {
        id: '1',
        title: 'NavEgg: Age 15-18 (NID)',
        subTitle: 'Avails: 62M, #234525, Underage',
        isWarning: false,
    },
    {
        id: '2',
        title: 'NavEgg: Age 15-18 (NID)',
        subTitle: 'Avails: 62M, #234525, Underage',
        isWarning: true,
    },
];

function SegmentModule({ defaultTab }) {
    const defaultT = segmentTabs.find((item) => item.value === defaultTab);
    const [tab, setTab] = useState(defaultT || segmentTabs[0]);
    const { width: widthScreen } = useViewport();

    return (
        <div className="flex flex-col gap-5 mt-5">
            {(widthScreen || 0) >= 768 ? (
                <Tabs
                    onChange={(tab) => {
                        setTab(tab);
                    }}
                    className={'w-full'}
                    tabList={segmentTabs}
                    value={tab.value}
                />
            ) : (
                <Dropdown
                    options={({ close }) => (
                        <DropdownMenu
                            menu={segmentTabs}
                            onChange={(menuValue) => {
                                setTab(menuValue);
                                close();
                            }}
                        />
                    )}
                    classNameBox={'w-full'}
                    className={'max-w-full'}
                >
                    <div className="rounded-[10px] shadow-raise border-2 border-blue-ebe bg-blue-ebe flex flex-row justify-between w-full items-center gap-2.5 h-[50px] px-[15px]">
                        <Text>{tab.label}</Text>
                        <Image src={SVG.chevron_down} />
                    </div>
                </Dropdown>
            )}

            {tab.value === 'audience' && (
                <SegmentLocationLayout
                    type="SEGMENT"
                    importBtn={{
                        label: 'Import Segments',
                    }}
                    selectedArr={selectedArr}
                    title={'Selected Segment'}
                >
                    <AudienceSegment />
                </SegmentLocationLayout>
            )}
            {tab.value === 'semantic' && <></>}
            {tab.value === 'curated' && <></>}
            {tab.value === 'favourites' && <></>}
        </div>
    );
}

export default SegmentModule;
