import clsx from 'clsx';
import Image from 'components/Image';
import React from 'react';
import { colorStyle, sizeStyle, variantStyle } from './styles';

//variant = contained | outlined
//color = primary  | secondary
//disabled = true | false
//size = small | medium | large
function Button({
    type = 'button',
    disabled = false,
    variant = 'contained',
    color = 'primary',
    size = 'medium',
    className,
    styleIcon,
    startIcon,
    endIcon,
    children,
    classNameText,
    ...props
}) {
    return (
        <button
            type={type}
            className={clsx(
                'inline-block rounded-[10px] text-sm px-5',
                disabled ? 'opacity-25' : '',
                colorStyle(color),
                sizeStyle(size),
                variantStyle(variant),
                className,
            )}
            disabled={disabled}
            {...props}
        >
            <div className={clsx('text-center flex items-center justify-center', classNameText)}>
                {startIcon && <Image className={clsx('object-cover', styleIcon)} src={startIcon} />}
                <div className="whitespace-nowrap ">{children}</div>
                {endIcon && <Image className={clsx('object-cover', styleIcon)} src={endIcon} />}
            </div>
        </button>
    );
}

export { Button };
