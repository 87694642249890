import { SVG } from 'assets/imagePath';
import Image from 'components/Image';
import Text from 'components/Text';
import { useEffect, useState } from 'react';
import { offerItems } from './data';
import clsx from 'clsx';

const OfferItem = ({ item, active, className, ...rest }) => {
    return (
        <div
            className={clsx(
                'w-[120px] h-[120px] cursor-pointer flex items-center flex-col justify-center text-center hover:bg-white/5 hover:rounded-[10px]',
                active ? 'bg-white/5 rounded-[10px]' : '',
                className,
            )}
            {...rest}
        >
            <Image src={item?.icon || SVG.agency} className="w-12 h-12 mx-auto" />
            <Text className="text-sm leading-[21px] text-white mt-2.5">{item?.label}</Text>
        </div>
    );
};

const Offer = ({
    onChange,
    auto = {
        isAuto: false,
        delay: 2000,
    },
    defaultActive,
    isTitle = true,
}) => {
    const [active, setActive] = useState(defaultActive || offerItems[0].label);

    const handleOnChange = (it) => {
        setActive(it?.label);
        onChange && onChange(it?.label);
    };

    const autoChangeActive = (arr = []) => {
        const lengthOfArray = arr.length;
        const currentIdx = arr.findIndex((item) => item.label === active);

        const timeOut = setTimeout(() => {
            if (currentIdx === lengthOfArray - 1) {
                //Set active index = item[0]
                handleOnChange(arr[0]);
            } else {
                //set next active
                handleOnChange(arr[currentIdx + 1]);
            }
        }, auto.delay);

        return timeOut;
    };

    useEffect(() => {
        if (!auto.isAuto) return;
        const timeOut = autoChangeActive(offerItems);
        return () => {
            clearTimeout(timeOut);
        };
    }, [active]);

    return (
        <section className="w-full mx-auto py-[30px] xs:py-[50px] bg-dark-nav overflow-hidden">
            <div
                className={clsx(
                    'px-[30px] xs:p-0 mx-auto flex flex-col 2xl:flex-row items-center xs:gap-[30px] gap-2.5 ',
                    isTitle ? 'container justify-center' : '',
                )}
            >
                {isTitle && (
                    <div className="2xl:w-[312px] text-white font-sen text-lg xs:text-xl font-bold leading-[30px] text-center 2xl:text-left">
                        We offer top programmatic ad solutions for your industry.
                    </div>
                )}

                <div
                    className={clsx(
                        'md:flex flex-wrap  grid grid-cols-2 xs:grid-cols-3 md:grid-cols-2 lg:grid-flow-col gap-x-[10px] gap-y-[20px]',
                        isTitle ? 'justify-center' : 'w-full justify-evenly',
                    )}
                >
                    {offerItems.map((item) => (
                        <OfferItem
                            item={item}
                            key={item.label}
                            active={defaultActive === item.label && onChange}
                            onClick={() => handleOnChange(item)}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Offer;
