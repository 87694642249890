import FormInputText from 'components/modules/form/FormInputText';
import FormSelect from 'components/modules/form/FormSelect';
import React from 'react';

const countries = [{ label: 'Miami', value: 'miami' }];
function LeadProfileForm() {
    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-row  gap-5">
                <FormInputText name="first_name" label="First Name" placeholder="First Name" />
                <FormInputText name="last_name" label="Last Name" placeholder="Last Name" />
                <FormInputText name="company" label="Company" placeholder="Company" />
            </div>
            <div className="flex flex-row  gap-5">
                <FormSelect
                    placeholder="Country"
                    propsDropdown={{ classNameBox: 'flex-1' }}
                    placeholderTop
                    name="country"
                    options={countries}
                />
                <FormInputText classNameBox="flex-1" name="email" label="Email" placeholder="Email" />
                <FormInputText classNameBox="flex-1" name="phone" label="Phone" placeholder="Phone" />
            </div>

            <div className="flex flex-row  gap-5">
                <FormInputText name="description" label="Description" placeholder="Description" />
            </div>
        </div>
    );
}

export default LeadProfileForm;
