import { useAdCanva } from 'context/AdCanvaContext';
import { Form, Formik } from 'formik';
import SelectTemplate from '../../SelectTemplate';

function MyCanvas() {
    const { step } = useAdCanva();

    return (
        <Formik
            initialValues={{
                callAction: {
                    text: 'Learn more',
                    size: '12',
                    font: 'font-inter',
                },
                subTitle: {
                    text: 'For every big goal you have in life, the best way to tackle it is to create smaller goals that you can get started on right away',
                    size: '12',
                    font: 'font-inter',
                },
                title: {
                    text: 'You are one click away from success!',
                    size: '16',
                    font: 'font-inter',
                },
                base: '#0E65F1',
                accent: '#E39115',
            }}
            onSubmit={(data) => {
                console.log(data);
            }}
        >
            <Form>
                {step === 1 && <SelectTemplate title="My Canvas" subTitle="Placeholder" disableNextStep />}

                {/* {step === 2 && (
                    <CanvaLayout title={'Update Images'} subTitle={'Placeholder'}>
                        <CanvaChooseSize />
                    </CanvaLayout>
                )}

                {step === 3 && (
                    <CanvaLayout title={'Update Images'} subTitle={'Placeholder'}>
                        <CanvaUpdateBg />
                    </CanvaLayout>
                )}
                {step === 4 && (
                    <CanvaLayout title={'Update Text'} subTitle={'Placeholder'}>
                        <CanvaUpdateContent />
                    </CanvaLayout>
                )}
                {step === 5 && (
                    <CanvaLayout title={'Update Text'} subTitle={'Placeholder'}>
                        <CanvaUpdateColors />
                    </CanvaLayout>
                )}
                {step === 6 && (
                    <CanvaLayout title={'Save Creatives Set'} subTitle={'Placeholder'}>
                        <CanvaSaved />
                    </CanvaLayout>
                )} */}
            </Form>
        </Formik>
    );
}

export default MyCanvas;
