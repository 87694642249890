import { earningColumn, earningRow } from 'components/AccountConfig/Earnings/data';
import { DetailBox } from 'components/DetailBox';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function EarningDetail() {
    const { earningId } = useParams();

    const rowDetail = earningRow.find((item) => item?.id === earningId);

    const [row, setRow] = useState(rowDetail || earningRow[0]);

    useEffect(() => {
        //Fetch Row Earning Detail
        // setRow(rowDetail)
    }, []);

    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <div className="flex flex-col gap-[30px]">
                <HeaderNavigate detailInfo={{ detailName: 'Credit Memo', detailId: earningId }} />

                <div className="max-w-[400px]">
                    <DetailBox
                        title="Credit"
                        column={earningColumn.filter((item) => item?.field !== 'actions')}
                        row={row}
                    />
                </div>
            </div>
        </div>
    );
}

export default EarningDetail;
