import clsx from 'clsx';
import TreeNode from './TreeNode';

const data = [
    {
        title: 'parent 1',
        key: '0-0',

        children: [
            {
                title: 'parent 1-0',
                key: '0-0-0',

                children: [
                    { title: 'parent 1-0-0', key: '0-0-0-0' },
                    {
                        title: (
                            <>
                                <div>multiple line title</div>
                                <div>multiple line title</div>
                            </>
                        ),
                        key: '0-0-0-1',
                    },
                    {
                        title: 'leaf',
                        key: '0-0-0-2',
                        children: [
                            { title: 'leaf', key: '0-0-2-0' },
                            {
                                title: 'leaf',
                                key: '0-0-2-1',
                            },
                        ],
                    },
                ],
            },
            {
                title: 'parent 1-1',
                key: '0-0-1',

                children: [{ title: 'leaf', key: '0-0-1-0' }],
            },
            {
                title: 'parent 1-2',
                key: '0-0-2',

                children: [
                    { title: 'leaf', key: '0-0-2-0' },
                    {
                        title: 'leaf',
                        key: '0-0-2-1',
                    },
                ],
            },
        ],
    },
];
function Tree({ treeData = data, collapseProps, className }) {
    return (
        <div className={clsx(className)}>
            {treeData.map((tree) => (
                <TreeNode key={tree.key} node={tree} collapseProps={collapseProps} />
            ))}
        </div>
    );
}

export default Tree;
