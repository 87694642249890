import { Button } from 'components/Button';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { Form, Formik } from 'formik';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';

function MediaLayout({ MediaForm }) {
    const { mediaId } = useParams();
    const formikRef = useRef(null);

    return (
        <div className="flex-1 p-5 md:p-[30px]">
            <HeaderNavigate
                detailInfo={{ detailName: 'Media', detailId: mediaId, chipLabel: 'Draft' }}
                ActionArea={
                    <div className="flex flex-row gap-2.5">
                        <Button
                            color="secondary"
                            type="button"
                            onClick={() => {
                                alert('remove');
                            }}
                        >
                            Remove
                        </Button>
                        <Button
                            onClick={() => {
                                formikRef.current?.submitForm();
                            }}
                        >
                            Save
                        </Button>
                    </div>
                }
            />
            <Formik
                innerRef={formikRef}
                initialValues={{
                    type: 'display',
                }}
                onSubmit={(values) => {
                    console.log(values);
                    alert(JSON.stringify(values));
                }}
            >
                {({ values }) => (
                    <Form>
                        <MediaForm formValues={values} />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default MediaLayout;
