import { SVG } from 'assets/imagePath';
import GridTable from 'components/GridTable';
import TableHead from 'components/GridTable/TableHead';
import Image from 'components/Image';
import { useNavigate } from 'react-router-dom';
import { leadCol, leadRow } from '../Leads/data';
import { routesPath } from 'routes/routes';

function QaQcOrg() {
    const navigate = useNavigate();
    const handleActionTable = (row, action) => {
        switch (action.value) {
            case 'view':
                navigate(routesPath.qaqc_review(row.id));
                break;

            default:
                break;
        }
    };
    return (
        <div>
            <TableHead title={'Campaigns to review'} actionButton={{ label: 'Add campaign for review' }} />
            <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                <GridTable handle={handleActionTable} className={'w-full table-fixed'} column={leadCol} row={leadRow} />

                <div className="bg-white-light  flex  justify-end p-[15px]  gap-[5px]">
                    <p className="text-sm font-normal text-grey-light">2 of 2 records</p>
                    <Image src={SVG.chevron_left_light} />
                    <Image src={SVG.chevron_right_light} />
                </div>
            </div>
        </div>
    );
}

export default QaQcOrg;
