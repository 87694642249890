import { InputPassword, InputText } from 'components/Input';
import { useField, useFormik, useFormikContext } from 'formik';
import React from 'react';

function FormInputPassword({ ...props }) {
    const [field, meta] = useField(props);

    return <InputPassword rounded error={meta.error} helper={meta.error} {...field} {...props} />;
}

export default FormInputPassword;
