import { IMAGE } from 'assets/imagePath';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { lazyLoadImageOnError } from 'utils';

const Avatar = ({ className, size = 'default', src, ...props }) => {
    let variantClassName = 'inline-block';
    if (size === 'small') {
        variantClassName = variantClassName + ' h-6 w-6';
    } else if (size === 'default') {
        variantClassName = variantClassName + ' h-9 w-9';
    } else if (size === 'large') {
        variantClassName = variantClassName + ' h-14 w-14';
    }

    return (
        <LazyLoadImage
            className={clsx(variantClassName, className)}
            src={src || IMAGE.avatar_default}
            onError={lazyLoadImageOnError}
            {...props}
        />
    );
};

export default Avatar;
