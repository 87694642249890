import { IMAGE } from 'assets/imagePath';
import { CardRadio } from 'components/Card';
import { SubTitle } from 'components/Title';
import FormInputText from 'components/modules/form/FormInputText';
import FormRadio from 'components/modules/form/FormRadio';
import { useFormikContext } from 'formik';
import DeviceTarget from './DeviceTarget';
import LocationTargeting from './LocationTargeting';
import SegmentTable from './SegmentTable';
import LocationTable from './LocationTable';

function AudienceForm({ targetMethodVal }) {
    const { values } = useFormikContext();
    return (
        <div className="flex flex-col gap-[30px]">
            <div className="flex flex-col gap-5">
                <SubTitle className={'mt-7'}>Details</SubTitle>
                <FormInputText name="name" placeholder="Name" />

                <div className="flex flex-col gap-5 sm:flex-row lg:flex-col xl:flex-row">
                    <FormRadio
                        name="type"
                        defaultChecked={values.type === 'location'}
                        // className="peer-checked:shadow-both peer-checked:bg-white-light peer-checked:border-2 peer-checked:border-primary"
                        className="!shadow-none peer-checked:!bg-white peer-checked:shadow-none h-full bg-white-light border-2 !border-blue-cfd"
                        value="location"
                        renderComponent={
                            <CardRadio
                                title="Location"
                                img={IMAGE.location}
                                content={
                                    'Ad targeting that focuses on delivering ads to users based on their geographic location.'
                                }
                            />
                        }
                    />

                    <FormRadio
                        name="type"
                        defaultChecked={values.type === 'segment'}
                        className="!shadow-none peer-checked:!bg-white peer-checked:shadow-none h-full bg-white-light border-2 !border-blue-cfd"
                        value="segment"
                        renderComponent={
                            <CardRadio
                                title="Segment"
                                img={IMAGE.segment}
                                content={
                                    'Ad targeting that is based on various criteria such as demographics, interests, behaviors, or purchase history.'
                                }
                            />
                        }
                    />
                </div>
            </div>
            {values.type === 'location' ? <LocationTable /> : <SegmentTable />}

            <DeviceTarget />
        </div>
    );
}

export default AudienceForm;
