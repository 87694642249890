import { useReducer, createContext } from 'react';

const initialState = {};

export const GlobalContext = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        default:
            return {
                state,
            };
    }
};

export const GlobalContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    
    return <GlobalContext.Provider value={{ state, dispatch }}>{props.children}</GlobalContext.Provider>;
};
