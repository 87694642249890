import clsx from 'clsx';
import React from 'react';

//color : white | error | success |warning | primary
function Chip({ color = 'primary', size = 'medium', className, children, ...props }) {
    let colorClass = 'bg-primary text-white'; //primary

    switch (color) {
        case 'white':
            colorClass = 'bg-white text-blue-dark';
            break;
        case 'error':
            colorClass = 'bg-error text-white';
            break;
        case 'success':
            colorClass = 'bg-success text-white';
            break;
        case 'warning':
            colorClass = 'bg-warning text-white';
            break;
        default:
            colorClass = 'bg-primary text-white';
            break;
    }

    const sizeClass = () => {
        switch (size) {
            case 'small':
                return 'px-1 py-2 text-xxs';
            case 'medium':
                return 'md:py-[5px]  md:px-2.5 py-1 px-2 text-xxs  md:text-xs';
            default:
                break;
        }
    };

    return (
        <div
            className={clsx(
                'flex justify-center items-center  rounded-full shadow-chip  w-fit xxs:max-md:ml-auto',
                sizeClass(),
                colorClass,

                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
}

export default Chip;
