import { SVG } from 'assets/imagePath';
import Image from 'components/Image';

const Footer = () => {
    return (
        <section className="w-full py-[30px] px-[20px] lg:py-[60px]  lg:px-[80px] bg-dark-nav">
            <div className="container mx-auto w-full flex flex-col lg:flex-row lg:gap-[100px] 2xl:gap-[285px]">
                <div className="left">
                    <Image src={SVG.logo_light} className="mb-[20px] mx-auto lg:mx-[unset]" />
                    <div className="sm:text-sm text-xs text-center lg:text-start font-[400] text-blue-cfd lg:max-w-[407px] mb-[20px] lg:mb-[80px]">
                        Whether you bring your own first-party data or use our third-party data, we will help you
                        identify, reach, and engage your audiences across all supply channels
                    </div>
                </div>
                <div className="right grid grid-cols-2 sm:grid-cols-3 gap-y-[30px]">
                    <div className="">
                        <div className="sm:text-sm text-xs text-white font-bold mb-[5px]">Menu</div>
                        <div className="sm:text-sm text-xs text-blue-cfd mb-[10px]">Home</div>
                        <div className="sm:text-sm text-xs text-blue-cfd mb-[10px]">Industries</div>
                        <div className="sm:text-sm text-xs text-blue-cfd mb-[10px]">Product</div>
                        <div className="sm:text-sm text-xs text-blue-cfd mb-[10px]">Channels</div>
                        <div className="sm:text-sm text-xs text-blue-cfd mb-[10px]">Why Us?</div>
                    </div>
                    <div className="">
                        <div className="sm:text-sm text-xs text-white font-bold mb-[5px]">Legal</div>
                        <div className="sm:text-sm text-xs text-blue-cfd mb-[10px]">Terms of Use</div>
                        <div className="sm:text-sm text-xs text-blue-cfd mb-[10px]">Privacy Policy</div>
                        <div className="sm:text-sm text-xs text-blue-cfd mb-[10px]">User Opt Out Info</div>
                    </div>
                    <div className="">
                        <div className="sm:text-sm text-xs text-white font-bold mb-[11px]">Contact Us</div>
                        <div className="flex gap-[12px] sm:text-sm text-xs text-blue-cfd mb-[10px]">
                            <Image src={SVG.phone_icon} />
                            +1 123 456 77890
                        </div>
                        <div className="flex gap-[12px] text-[14px] leading-[21px] text-blue-cfd mb-[10px]">
                            <Image src={SVG.uil_at_icon} />
                            info@adnucleus.com
                        </div>

                        <div className="flex gap-[23px] mt-[34px] ml-3">
                            <Image src={SVG.twitter_icon} />
                            <Image src={SVG.linkedin_icon} />
                            <Image src={SVG.facebook_icon} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto flex xs:flex-row flex-col  justify-between lg:mt-[30px] mt-5">
                <div className="text-[14px] leading-[21px] font-[400] text-blue-cfd">© 2023 AdNucleus</div>
                <div className="text-[14px] leading-[21px] font-[400] text-blue-cfd">
                    Powered by ECOM INDUSTRIES, LLC
                </div>
            </div>
        </section>
    );
};

export default Footer;
