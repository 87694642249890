import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Dropdown from 'components/Dropdown';
import Image from 'components/Image';
import { memo, useLayoutEffect, useRef, useState } from 'react';
import { isPermissionView } from 'utils';
import TableMobile from './TableMobile';
import './Table.css';
function GridTable({ column, row, handle, className, isRadiusTable, tableId }) {
    const refTable = useRef(null);

    const [clientTable, setClientTable] = useState({});

    const handleChange = () => {
        setClientTable({ width: refTable.current?.offsetWidth, height: refTable.current?.offsetHeight });
    };

    useLayoutEffect(() => {
        handleChange();
        window.addEventListener('resize', handleChange);
        return () => window.removeEventListener('resize', handleChange);
    }, []);
    return (
        <>
            <div className="rounded-[10px] border border-blue-cfd">
                <div id={tableId} className="md:block hidden min-h-max w-full rounded-[10px] " ref={refTable}>
                    <table
                        className={clsx(
                            'max-w-full w-full  rounded-[10px] ',
                            isRadiusTable ? 'table__grid' : '',
                            className,
                        )}
                    >
                        <thead>
                            <tr className="border-b border-blue-cfd">
                                {column.map((col, idx) => {
                                    let isHide = undefined;
                                    if (Array.isArray(col?.hideCol)) {
                                        isHide = !isPermissionView(col?.hideCol);
                                    } else {
                                        isHide = col?.hideCol;
                                    }

                                    return (
                                        !isHide && (
                                            <th
                                                scope="col"
                                                key={col.field}
                                                className={clsx(
                                                    'text-blue-dark mx-auto p-[15px]  text-left font-normal text-sm whitespace-nowrap  min-w-[80px] overflow-hidden',
                                                )}
                                                style={col?.headerStyle}
                                            >
                                                {col?.selectHead ? (
                                                    <Dropdown options={col?.selectHead?.renderContentSelect()}>
                                                        <div className="flex items-center  gap-[5px]">
                                                            <p>{col?.headerName}</p>
                                                            <Image src={SVG.chevron_down_light} alt="chevron_down" />
                                                        </div>
                                                    </Dropdown>
                                                ) : (
                                                    col.headerName
                                                )}
                                            </th>
                                        )
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody className="bg-white-light">
                            {row.map((rw, idx) => {
                                return (
                                    <tr
                                        className={clsx(
                                            'border-b border-blue-cfd ',
                                            idx + 1 === row?.length && isRadiusTable ? 'border-b-0' : '',
                                        )}
                                        key={idx}
                                    >
                                        {column.map((column) => {
                                            const colSpan = column?.colSpan || 1;
                                            let isHide = undefined;
                                            if (Array.isArray(column?.hideCol)) {
                                                isHide = !isPermissionView(column?.hideCol);
                                            } else {
                                                isHide = column?.hideCol;
                                            }

                                            return (
                                                !isHide && (
                                                    <td
                                                        colSpan={colSpan}
                                                        key={column.field}
                                                        className="h-full text-sm text-left text-ellipsis whitespace-nowrap "
                                                        style={column?.rowStyle}
                                                    >
                                                        {column?.renderCell ? (
                                                            column?.renderCell(rw, handle, clientTable)
                                                        ) : (
                                                            <div className="p-[15px]  text-ellipsis whitespace-nowrap">
                                                                <p className="overflow-hidden">{rw[column?.field]}</p>
                                                            </div>
                                                        )}
                                                    </td>
                                                )
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <TableMobile rows={row} col={column} handle={handle} />
        </>
    );
}

export default memo(GridTable);
