import { SVG } from 'assets/imagePath';
import { Button } from 'components/Button';
import Image from 'components/Image';
import { InputText, Select } from 'components/Input';
import { Title } from 'components/Title';
import { useFormik } from 'formik';
import { useNavigateSearch } from 'hooks';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

const countryOptions = [
    {
        value: 'personal',
        label: 'Personal',
    },
    {
        value: 'business',
        label: 'Business',
    },
];
function CreateCompanyForm() {
    // ---- *** This page protect by state "isPermission" from URL, please props state "isPermission" *** ----- //

    const location = useLocation();

    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();

    const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            console.log(values);
            navigateSearch(
                routesPath.account_subCo,
                {
                    config: 'profile',
                },
                { state: { createSuccess: true } },
            );
        },
        // validationSchema: createAccountSchema,
    });
    useEffect(() => {
        if (!location?.state?.isPermission) {
            navigate(-1, { replace: true });
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !location?.state?.isPermission ? (
        <></>
    ) : (
        <div className="flex flex-col justify-between items-center gap-2.5 xxs:gap-5">
            <Image className={'max-w-[200px] w-full'} src={SVG.logo_agency} />
            <Title className="py-[10.5px] leading-[29px] text-center">
                {location?.state?.suggestName || '4312 Company Form'}
            </Title>
            <form onSubmit={handleSubmit} className="w-full">
                <div className="flex w-full flex-col gap-2.5 xxs:gap-5 ">
                    <div className="flex flex-start items-center gap-2.5 xxs:gap-5 ">
                        <InputText
                            onChange={handleChange}
                            value={values.company_name}
                            error={errors.company_name}
                            classNameBox={'flex-1'}
                            name="company_name"
                            placeholder={'Company Name'}
                            rounded
                        />
                    </div>

                    <div className="flex flex-col xxs:flex-row flex-start items-center gap-2.5 xxs:gap-5">
                        <Select
                            placeholder="Country"
                            options={countryOptions}
                            setValue={setFieldValue}
                            value={values.country}
                            classNameText="flex-1"
                            name="country"
                            propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                        />
                        <Select
                            placeholder="City"
                            options={countryOptions}
                            setValue={setFieldValue}
                            value={values.city}
                            classNameText="flex-1"
                            propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                            name="city"
                        />
                        <InputText
                            onChange={handleChange}
                            value={values.zip}
                            error={errors.zip}
                            classNameBox="flex-1"
                            name="zip"
                            placeholder={'ZIP'}
                            rounded
                        />
                    </div>
                    <div>
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.company_address}
                            error={errors.company_address}
                            name="company_address"
                            placeholder={'Company Address'}
                            rounded
                        />
                    </div>

                    <div className="flex flex-col xxs:flex-row flex-start items-center gap-2.5 xxs:gap-5">
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.company_email_address}
                            error={errors.company_email_address}
                            name="company_email_address"
                            placeholder={'Company Email Address'}
                            rounded
                        />
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.company_phone_number}
                            error={errors.company_phone_number}
                            name="company_phone_number"
                            placeholder={'Company Phone Number'}
                            rounded
                        />
                    </div>

                    <div className="flex flex-col xxs:flex-row flex-start items-center gap-2.5 xxs:gap-5">
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.company_website}
                            error={errors.company_website}
                            name="company_website"
                            placeholder={'Company Website'}
                            rounded
                        />

                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.company_registration_number}
                            error={errors.company_registration_number}
                            name="company_registration_number"
                            placeholder={'Company Registration Number '}
                            rounded
                        />
                    </div>
                    <Button type="submit" className={'max-w-[260px] w-full mx-auto'} color="primary">
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default CreateCompanyForm;
