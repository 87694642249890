import { SVG } from 'assets/imagePath';
import Chip from 'components/Chip/Chip';
import ActionsButton from 'components/GridTable/ActionsButton';
import Text from 'components/Text/Text';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

export const resourceColumn = [
    {
        field: 'id',

        headerName: 'ID',
        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id</li>
        //             </ul>
        //         );
        //     },
        // },
    },
    {
        field: 'title',

        headerName: 'Title',
    },
    {
        field: 'author',

        headerName: 'Author',
    },

    {
        field: 'status',
        headerName: 'Status',

        renderCell: (row) => {
            return (
                <Chip className={'ml-[15px]'} color="success">
                    {row?.status}
                </Chip>
            );
        },
    },

    {
        field: 'type',

        headerName: 'Type',
    },
    {
        field: 'category',

        headerName: 'Category',
        renderCell: (row) => {
            return (
                <Text ellipsis className="sm:p-[15px] text-right">
                    {row?.category.join(', ')}
                </Text>
            );
        },
    },
    {
        field: 'audience',

        headerName: 'Audience',
    },
    {
        field: 'published',

        headerName: 'Published',
    },
    {
        field: 'updated',

        headerName: 'Updated',
    },

    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row, handle) => {
            return (
                <ActionsButton
                    onChange={(actionVal) => {
                        // console.log(actionVal);
                        handle(row, actionVal);
                    }}
                />
            );
        },
    },
];

export const resourceRow = [
    {
        id: 324,
        title: 'Title',
        author: 'Author',
        status: 'Published',
        type: 'Internal',
        category: ['Category 1', 'Category 2'],
        audience: 'VIP Members',
        published: 'Published',
        updated: '03/14/2023',
    },
    {
        id: 324,
        title: 'Title',
        author: 'Author',
        status: 'Published',
        type: 'Internal',
        category: ['Category 1', 'Category 2'],

        audience: 'VIP Members',
        published: 'Published',
        updated: '03/14/2023',
    },
    {
        id: 324,
        title: 'Title',
        author: 'Author',
        status: 'Published',
        type: 'Internal',
        category: ['Category 1', 'Category 2'],

        audience: 'VIP Members',
        published: 'Published',
        updated: '03/14/2023',
    },
    {
        id: 324,
        title: 'Title',
        author: 'Author',
        status: 'Published',
        type: 'Internal',
        category: ['Category 1', 'Category 2'],

        audience: 'VIP Members',
        published: 'Published',
        updated: '03/14/2023',
    },
    {
        id: 324,
        title: 'Title',
        author: 'Author',
        status: 'Published',
        type: 'Internal',
        category: ['Category 1', 'Category 2'],

        audience: 'VIP Members',
        published: 'Published',
        updated: '03/14/2023',
    },
    {
        id: 324,
        title: 'Title',
        author: 'Author',
        status: 'Published',
        type: 'Internal',
        category: ['Category 1', 'Category 2'],

        audience: 'VIP Members',
        published: 'Published',
        updated: '03/14/2023',
    },
    {
        id: 324,
        title: 'Title',
        author: 'Author',
        status: 'Published',
        type: 'Internal',
        category: ['Category 1', 'Category 2'],

        audience: 'VIP Members',
        published: 'Published',
        updated: '03/14/2023',
    },
];

export const categoryCol = [
    {
        field: 'id',

        headerName: 'ID',
    },
    {
        field: 'name',

        headerName: 'Name',
    },
    {
        field: 'assigned_posts',

        headerName: 'Assigned Posts',
    },
    {
        field: 'author',

        headerName: 'Author',
    },

    {
        field: 'created',

        headerName: 'Created',
    },

    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row, handle) => {
            return (
                <ActionsButton
                    onChange={(actionVal) => {
                        // console.log(actionVal);
                        handle(row, actionVal);
                    }}
                />
            );
        },
    },
];

export const categoryRow = [
    {
        id: 324,
        name: 'Category',
        author: 'Author',
        assigned_posts: '23',

        created: 'March 14, 2023',
    },
    {
        id: 324,
        name: 'Category',
        author: 'Author',
        assigned_posts: '23',

        created: 'March 14, 2023',
    },
    {
        id: 324,
        name: 'Category',
        author: 'Author',
        assigned_posts: '23',

        created: 'March 14, 2023',
    },
];
