import { SVG } from 'assets/imagePath';
import Image from 'components/Image';
import Step from 'components/Step';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CreateAccount, CreatePassword, VerifyAccount } from './components';

function RegisterPrimaryUser() {
    const [searchParams] = useSearchParams();

    const step = searchParams.get('step') || '1';
    const navigate = useNavigate();
    return (
        <>
            <Image
                onClick={() => navigate('/')}
                src={SVG.logo}
                className="max-w-[200px] mx-auto mb-[40px] cursor-pointer  w-full h-auto"
            />

            {step === '1' && <CreateAccount />}

            {step === '2' && <CreatePassword />}

            {step === '3' && <VerifyAccount />}

            <Step maxStep={3} activeStep={step} />
        </>
    );
}

export default RegisterPrimaryUser;
