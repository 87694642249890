import Box from 'components/Box/Box';
import React from 'react';
import UploadImage from './UploadImage';
import { Svg } from 'components/Svg';
import clsx from 'clsx';
import Text from 'components/Text/Text';
import Image from 'components/Image/Image';
import { IMAGE, SVG } from 'assets/imagePath';

function InputUpload({ label, className, helper, error, renderWrapper, ...props }) {
    return (
        <Box
            Component="label"
            htmlFor={props.name}
            shadow="default"
            className={clsx('bg-white-light p-5  relative', className)}
        >
            {label && (
                <Text
                    className={clsx(
                        'z-1 px-[10px]  absolute bg-inherit z-0  rounded-[30px] shadow-default-top bg-white-light left-[15px] -top-[5px]  text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                    )}
                    size="10"
                >
                    {label}
                </Text>
            )}
            <UploadImage
                renderRemoveButton={({ removeImage }) => (
                    <Svg
                        className="absolute cursor-pointer -bottom-[10px] -right-[10px]"
                        name="trash"
                        onClick={removeImage}
                    />
                )}
                {...props}
                className={'w-full h-full flex justify-between items-center'}
            >
                {renderWrapper ? renderWrapper() : <Image className={'max-w-[48px] mx-auto h-full'} src={SVG.upload} />}
            </UploadImage>
            {helper && (
                <Text
                    className={clsx(
                        'px-[10px] absolute bg-inherit z-0  rounded-[30px] shadow-default-bottom  bg-white-light left-[15px] -bottom-[5px] text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                        error ? 'text-error' : '',
                    )}
                    size="10"
                >
                    {helper}
                </Text>
            )}
        </Box>
    );
}

export default InputUpload;
