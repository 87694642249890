import { CircleF, MarkerF } from '@react-google-maps/api';
import { memo, useEffect, useId, useRef, useState } from 'react';

function MarkerCustom({
    google,
    status,
    radius = 25,
    editable = false,
    defaultPosition,
    onChangeRadius,
    onChangePosition,
}) {
    const options = {
        remove: {
            strokeColor: '#ff0000',
            fillColor: '#ff0000',
        },
        add: {
            strokeColor: 'green',
            fillColor: 'green',
        },
    };

    const [position, setPosition] = useState(defaultPosition);

    const keyMarker = useId();
    const keyCircle = useId();

    const circleRef = useRef(null);
    const boxRef = useRef(null);

    const markerRef = useRef(null);

    const handleOnLoad = (markerInstance) => {
        markerRef.current = markerInstance;
    };

    const markerIconFactory = (status) => ({
        path: google.maps.SymbolPath.CIRCLE,
        fillOpacity: 1,
        strokeWeight: 0,
        scale: 4,
        zIndex: 1000000,
        fillColor: status === 'add' ? 'green' : 'red',
    });

    useEffect(() => {
        if (markerRef.current) {
            markerRef.current.setIcon(markerIconFactory(status));
        }
    }, [status]);

    return (
        <div ref={boxRef}>
            <MarkerF key={keyMarker} icon={markerIconFactory(status)} onLoad={handleOnLoad} position={position} />
            <CircleF
                center={defaultPosition}
                radius={radius}
                editable={false}
                options={{
                    fillOpacity: 0.2,
                    strokeWeight: 1,

                    ...options[status],
                }}
                onLoad={(circle) => {
                    circleRef.current = circle;
                }}
                onUnmount={(circle) => {
                    circleRef.current = null;
                }}
                onClick={() => editable && circleRef.current && circleRef.current.set('editable', true)}
                onMouseOut={() => editable && circleRef.current && circleRef.current.set('editable', false)}
                key={keyCircle}
                onCenterChanged={() => {
                    if (circleRef?.current) {
                        if (!circleRef.current.getEditable()) return;

                        const latLng = {
                            lat: circleRef.current['center'].lat(),
                            lng: circleRef.current['center'].lng(),
                        };

                        setPosition(latLng);

                        onChangePosition?.({ ...latLng });
                    }
                }}
                onRadiusChanged={() => {
                    if (circleRef.current) {
                        if (!circleRef.current.getEditable()) return;

                        onChangeRadius?.(parseInt(circleRef.current['radius']));
                    }
                }}
            />
        </div>
    );
}

export default memo(MarkerCustom);
