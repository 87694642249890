import { AUTH_KEY } from 'constants/localStorageKey';
import { useAuth } from 'hooks';
import useLocalStorage from 'hooks/useLocalStorage';
import { Navigate, Outlet } from 'react-router-dom';
import { routesPath } from 'routes/routes';

export const ProtectedLayout = () => {
    const [auth, setAuth] = useLocalStorage(AUTH_KEY);
    if (!auth?.is_login) {
        return <Navigate to={routesPath.home} />;
    }

    return <Outlet />;
};
