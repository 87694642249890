import { Button } from 'components/Button';
import { InputPassword } from 'components/Input';
import Text from 'components/Text';
import { useFormik } from 'formik';

function RefundForm() {
    const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            alert(JSON.stringify(values));
        },
        // validationSchema: createAccountSchema,
    });

    return (
        <form onSubmit={handleSubmit} className="flex flex-col items-center gap-5">
            <Text className="text-center" size="16">
                Please confirm refund with password.
            </Text>

            <InputPassword
                placeholder="Password"
                rounded
                name="password"
                onChange={handleChange}
                value={values.password}
                error={errors.password}
            />

            <Button className={'w-full'} type="submit">
                Refund
            </Button>
        </form>
    );
}

export default RefundForm;
