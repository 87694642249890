import { InputText } from 'components/Input';
import { useField } from 'formik';

function FormInputMarkup({ markupSymbol = '%', type, max, min, ...props }) {
    const [field, meta, helper] = useField(props);

    const { setValue } = helper;
    const { onChange, ...restField } = field;

    const handleOnInput = (e) => {
        let int = e.target.value.slice(0, e.target.value.length - 1);
        // console.log(int);
        const numRegex = /^\d+$/;

        // if (type === 'number' && !numRegex.test(int)) {
        //     // e.target.value = int + `${markupSymbol}`;
        //     e.target.setSelectionRange(e.target.value.length - 1, e.target.value.length - 1);
        //     return;
        // }

        if (!e.target.value) {
            //Set value
            e.target.value = '';
        } else if (int.includes(markupSymbol)) {
            //Can't onChange if you change after "symbol"
            e.target.value = markupSymbol;
            e.target.setSelectionRange(e.target.value.length - 1, e.target.value.length - 1);
        } else if (e.target.value.includes(markupSymbol) && !int) {
            e.target.value = '';
        } else if (!int.includes(markupSymbol) && !int) {
            e.target.value = e.target.value + `${markupSymbol}`;
            e.target.setSelectionRange(e.target.value.length - 1, e.target.value.length - 1);
        } else {
            e.target.value = int + `${markupSymbol}`;
            e.target.setSelectionRange(e.target.value.length - 1, e.target.value.length - 1);
        }

        if (type === 'number') {
            if (max && +int > max) {
                e.target.value = max + `${markupSymbol}`;
                e.target.setSelectionRange(e.target.value.length - 1, e.target.value.length - 1);
            }
            if (min && +int < min) {
                e.target.value = min + `${markupSymbol}`;
                e.target.setSelectionRange(e.target.value.length - 1, e.target.value.length - 1);
            }
        }
        // console.log('For robots: ' + getInt(e.target.value));

        setValue(e.target.value);
    };

    return (
        <InputText rounded error={meta.error} helper={meta.error} onInput={handleOnInput} {...restField} {...props} />
    );
}

export default FormInputMarkup;
