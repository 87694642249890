import Box from 'components/Box/Box';
import { FilterButton } from 'components/FilterTools';
import GgMap from 'components/Map/GgMap';
import MarkerCustom from 'components/Map/MarkerCustom';
import PlacesAutocomplete from 'components/Map/PlacesAutocomplete';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import { useRef } from 'react';
import { getDetails } from 'use-places-autocomplete';

const DEFAULT_RADIUS = 25;

function Coordinates({ onAddMarker, hoveringId, markers, markerProps }) {
    // const [google, setGoogle] = useState(null);

    const mapRef = useRef(null);

    const handleSetSelected = (loc) => {
        onAddMarker(loc);
        mapRef.current.panTo(loc);
    };

    return (
        <div className="flex flex-col h-full gap-[10px]">
            <div className="flex flex-col md:flex-row justify-between gap-[30px] w-full">
                <div>
                    <SubTitle>Countries</SubTitle>
                    <Text>Placeholder</Text>
                </div>

                <div className="flex flex-col gap-5 xs:flex-row">
                    <FilterButton />
                    <PlacesAutocomplete setSelected={handleSetSelected} />
                </div>
            </div>

            <Box className="w-full flex-1 bg-white-light overflow-auto  gap-2.5 p-5" variant="contained">
                <GgMap
                    onClick={async (e) => {
                        const placeId = e?.placeId;
                        let info;
                        if (placeId) {
                            info = await getDetails({ placeId });
                        }
                        const position = {
                            lat: parseFloat(e.latLng.lat()),
                            lng: parseFloat(e.latLng.lng()),
                            id: e?.placeId,
                            name: info?.name,
                        };
                        onAddMarker(position);
                    }}
                    className="max-md:h-[400px]"
                    ref={mapRef}
                >
                    {(google) =>
                        markers.map((marker, idx) => (
                            <MarkerCustom
                                editable
                                key={marker.id}
                                google={google}
                                defaultPosition={{ lat: marker.lat, lng: marker.lng }}
                                radius={marker?.rad}
                                status={hoveringId === marker.id ? 'remove' : 'add'}
                                {...markerProps}
                                onChangePosition={(position) =>
                                    markerProps.onChangeMarker(marker.id, {
                                        lat: position.lat,
                                        lng: position.lng,
                                        id: Date.now(),
                                    })
                                }
                                onChangeRadius={(radius) =>
                                    markerProps.onChangeMarker(marker.id, {
                                        rad: radius,
                                    })
                                }
                            />
                        ))
                    }
                </GgMap>
            </Box>
        </div>
    );
}

export default Coordinates;
