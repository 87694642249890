//Button

export const colorStyle = (color) => {
    switch (color) {
        case 'primary':
            return 'bg-blue-dark  text-white ';
        case 'secondary':
            return 'bg-blue-ebe text-blue-dark';

        default:
            break;
    }
};

export const sizeStyle = (size) => {
    switch (size) {
        case 'none':
            return 'w-fit';
        case 'small':
            return 'h-10';
        case 'medium':
            return 'h-[50px]';
        case 'large':
            return 'h-15';
        default:
            break;
    }
};

export const variantStyle = (variant) => {
    switch (variant) {
        case 'contained':
            return 'shadow-outer';
        case 'outlined':
            return '!bg-transparent ring-1 ring-blue-cfd !text-blue-dark';

        default:
            break;
    }
};
