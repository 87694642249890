import { SVG } from 'assets/imagePath';
import GridTable from 'components/GridTable';
import TableHead from 'components/GridTable/TableHead';
import Image from 'components/Image';
import { agencyColumn, agencyRow } from 'pages/Dashboard/data';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function AgenciesOrg() {
    const navigate = useNavigate();
    const handleActionTable = (row, action) => {
        switch (action.value) {
            case 'view':
                navigate(routesPath.agency_detail(row.id));
                break;

            default:
                break;
        }
    };
    return (
        <div>
            <TableHead title={'Leads'} actionButton={{ label: 'Add Agency' }} />
            <div className="flex flex-col rounded-[10px] shadow-default">
                <GridTable
                    handle={(row, action) => {
                        handleActionTable(row, action);
                    }}
                    className={'w-full table-fixed'}
                    column={agencyColumn}
                    row={agencyRow}
                />

                <div className="bg-white-light  flex  justify-end p-[15px]  gap-[5px]">
                    <p className="text-sm font-normal text-grey-light">2 of 2 records</p>
                    <Image src={SVG.chevron_left_light} />
                    <Image src={SVG.chevron_right_light} />
                </div>
            </div>
        </div>
    );
}

export default AgenciesOrg;
