import { EducationTopic } from 'components/Education';
import { POSTS } from 'components/Education/data';
import { APP_ROLE } from 'constants/auth';
import { isPermissionView } from 'utils';
// import { campaignColumn, campaignRow } from './data';

const Education = () => {
    return (
        <div className="p-2.5 md:p-[30px] flex flex-col gap-[30px]">
            <div className="font-extrabold text-2xl text-blue-dark  py-[10.5px]">
                <h2>Education</h2>
            </div>

            <EducationTopic topic={'External'} posts={POSTS} />
            {isPermissionView([APP_ROLE.Org]) && <EducationTopic topic={'Internal'} posts={POSTS} />}
            {isPermissionView([APP_ROLE.Co, APP_ROLE.SubCo, APP_ROLE.Org, APP_ROLE.Ag]) && (
                <EducationTopic topic={'Company'} posts={POSTS} />
            )}
            {isPermissionView([APP_ROLE.Org, APP_ROLE.Ag]) && <EducationTopic topic={'Agency'} posts={POSTS} />}
        </div>
    );
};

export default Education;
