import { IMAGE } from 'assets/imagePath';
import { AnalyticReview } from 'components/Analytics';
import { doughnutStyle } from 'components/Charts/DoughnutChart/config';
import DoughnutChart from 'components/Charts/DoughnutChart/Doughnut';
import DoughnutLegend from 'components/Charts/DoughnutChart/DoughnutLegend';
import Image from 'components/Image';
import { SubTitle } from 'components/Title';
import { fakeColumnCity, fakeColumnLabel, fakeRowPerformance } from './data';

function PerformanceLocation() {
    return (
        <div className="flex flex-col gap-5">
            <SubTitle className={'pt-7'}>Preformance by Location</SubTitle>

            <div className="flex">
                <AnalyticReview
                    className={'w-full max-h-full'}
                    title={'Map'}
                    CustomReview={<Image src={IMAGE.map} />}
                />
            </div>
            <div className="grid   md:grid-cols-2 grid-cols-1 gap-5">
                <AnalyticReview
                    className={'flex-1'}
                    title={'Cities'}
                    Chart={<DoughnutChart />}
                    LegendComponent={
                        <DoughnutLegend
                            headerLegend={fakeColumnCity}
                            dataLegend={fakeRowPerformance}
                            configData={doughnutStyle}
                        />
                    }
                />

                <AnalyticReview
                    className={'flex-1'}
                    title={'Regions'}
                    Chart={<DoughnutChart />}
                    LegendComponent={
                        <DoughnutLegend
                            headerLegend={fakeColumnLabel}
                            dataLegend={fakeRowPerformance}
                            configData={doughnutStyle}
                        />
                    }
                />

                <AnalyticReview
                    className={'flex-1'}
                    title={'Cities'}
                    Chart={<DoughnutChart />}
                    LegendComponent={
                        <DoughnutLegend
                            headerLegend={fakeColumnCity}
                            dataLegend={fakeRowPerformance}
                            configData={doughnutStyle}
                        />
                    }
                />
                <AnalyticReview
                    className={'flex-1'}
                    title={'Coordinates'}
                    Chart={<DoughnutChart />}
                    LegendComponent={
                        <DoughnutLegend
                            headerLegend={fakeColumnLabel}
                            dataLegend={fakeRowPerformance}
                            configData={doughnutStyle}
                        />
                    }
                />
            </div>
        </div>
    );
}

export default PerformanceLocation;
