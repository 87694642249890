import { BoxContent } from 'components/BoxContent';
import { Button } from 'components/Button';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { TextArea } from 'components/Input';
import { SubTitle } from 'components/Title';

function Embeds() {
    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <div className="flex flex-col gap-[30px]">
                <HeaderNavigate detailInfo={{ detailName: 'Embeds' }} />

                <div className="flex items-center justify-between">
                    <SubTitle>White-label Registration Form</SubTitle>

                    <Button variant="outlined">Preview</Button>
                </div>
                <BoxContent>
                    <TextArea
                        className={'h-[140px]'}
                        iconInfo
                        label={'White-label Registration Form'}
                        value={`<script> !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '{your-pixel-id-goes-here}'); fbq('track', 'PageView'); </script> <noscript> <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id={your-pixel-id-goes-here}&ev=PageView&noscript=1"/> </noscript>`}
                        copy
                    />
                </BoxContent>

                <div className="flex items-center justify-between">
                    <SubTitle>White-label Campaign Form</SubTitle>

                    <Button variant="outlined">Preview</Button>
                </div>
                <BoxContent>
                    <TextArea
                        className={'h-[140px]'}
                        iconInfo
                        label={'White-label Campaign Form'}
                        value={`<script> !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '{your-pixel-id-goes-here}'); fbq('track', 'PageView'); </script> <noscript> <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id={your-pixel-id-goes-here}&ev=PageView&noscript=1"/> </noscript>`}
                        copy
                    />
                </BoxContent>
            </div>
        </div>
    );
}

export default Embeds;
