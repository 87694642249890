import clsx from 'clsx';
import Box from 'components/Box/Box';
import { FilterButton, SearchBox } from 'components/FilterTools';
import { Checkbox } from 'components/Input';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import { Tree } from 'components/Tree';

const data = [
    {
        title: (
            <div className="px-[30px] flex justify-between items-center py-[15px] ">
                <Checkbox value="US" Label={<Text>USA</Text>} />
                <Svg className="h-[15px] w-[15px] child:fill-warning" name="star" />
            </div>
        ),
        key: '0-0',
    },
    {
        title: (
            <div className="px-[30px] py-[15px] flex justify-between items-center">
                <Checkbox value="CAN" Label={<Text>Canada</Text>} />
                <Svg className="h-[15px] w-[15px]" name="star" />
            </div>
        ),
        key: '0-1',
    },
];
function Countries() {
    return (
        <div className="flex flex-col gap-[10px]">
            <div className="flex flex-row justify-between gap-[30px] w-full">
                <div>
                    <SubTitle>Countries</SubTitle>
                    <Text>Placeholder</Text>
                </div>

                <div className="flex flex-row gap-5">
                    <FilterButton />
                    <SearchBox />
                </div>
            </div>

            <Box className="h-[570px] w-full bg-white-light overflow-auto  gap-2.5 p-5" variant="contained">
                <Tree
                    className={
                        'grid w-full grid-cols-1 ring-1 ring-blue-cfd divide-y divide-blue-cfd h-auto rounded-[10px] bg-white'
                    }
                    treeData={data}
                    collapseProps={{
                        renderButton: ({ title, toggle, isOpen }) => {
                            return (
                                <button
                                    className={clsx(
                                        'w-full  flex flex-row gap-2.5 justify-between border-b border-blue-cfd  h-[50px] items-center px-5 py-4',
                                    )}
                                    type="button"
                                    onClick={toggle}
                                >
                                    <Text>{title}</Text>

                                    <Svg
                                        name="chevronDown"
                                        className={clsx(
                                            'transition-all w-full max-w-[20px] h-[20px] child:fill-black',
                                            isOpen ? '' : 'rotate-180',
                                        )}
                                    />
                                </button>
                            );
                        },
                    }}
                />
            </Box>
        </div>
    );
}

export default Countries;
