import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { lazyLoadImageOnError } from 'utils';

const Image = ({ className, src, ...props }) => {
    return (
        <LazyLoadImage
            style={{
                display: 'block',
            }}
            src={src}
            onError={lazyLoadImageOnError}
            effect="blur"
            className={className}
            // onLoad={(e) => (e.currentTarget.src = IMAGE.no_image)}
            {...props}
        />
    );
};

export default Image;
