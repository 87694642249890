import { CardContent, CardRangeView } from 'components/Card';
import { SubTitle } from 'components/Title';

function ServiceBlock() {
    return (
        <div className="flex flex-col gap-5">
            <SubTitle>Service</SubTitle>

            <div className="grid grid-cols-1 xxs:grid-cols-2 grid-flow-row gap-5 lg:grid-cols-4">
                <CardContent className={'w-full min-w-max'} title={'Audience'} content="#2323" actionText={'View'} />
                <CardContent className={'w-full min-w-max'} title={'Creative'} content="#2323" actionText={'View'} />

                <CardContent className={'w-full min-w-max'} title={'Created'} content="22 Feb" actionText={'View'} />

                <CardRangeView
                    className={'w-full min-w-max'}
                    title="Campaign duration"
                    actionText={'View'}
                    propsRange={{ from: '01/20/2023', to: '01/26/2023', value: 50 }}
                    propsTextReview={{ label: '6 days left' }}
                />
            </div>
        </div>
    );
}

export default ServiceBlock;
