import BoxLabel from 'components/BoxLabel/BoxLabel';
import { TextArea } from 'components/Input';
import UploadImage from 'components/Input/UploadImage';
import FormInputText from 'components/modules/form/FormInputText';
import FormSelect from 'components/modules/form/FormSelect';
import Text from 'components/Text';
const optionsMedia = [
    { label: 'Image', value: 'image' },
    { label: 'Media', value: 'media' },
];

const categories = [{ label: 'Category', value: 'category' }];
const type = [
    { label: 'Internal', value: 'internal' },
    { label: 'Media', value: 'media' },
];
const audiences = [{ label: 'All User', value: 'all' }];
function PostsForm({ image, setImage }) {
    const formItemClass = 'flex flex-col gap-5';

    return (
        <div className="post_form flex flex-col gap-[30px]">
            <div className={formItemClass}>
                <FormInputText label="Title" name="title" />
            </div>
            <div className={formItemClass}>
                <Text weight="bold" size="18">
                    Featured
                </Text>
                <FormSelect
                    placeholder="Featured Media Type"
                    propsDropdown={{ classNameBox: 'flex-1' }}
                    placeholderTop
                    name="media_type"
                    options={optionsMedia}
                />

                <BoxLabel
                    className={'h-[300px] p-5'}
                    label={'Featured Media'}
                    helper="Recommended size: 1920x1080 px, JPG format"
                >
                    <UploadImage />
                </BoxLabel>
            </div>
            <div className={formItemClass}>
                <Text weight="bold" size="18">
                    Audience
                </Text>
                <FormSelect
                    placeholder="Post Category"
                    propsDropdown={{ classNameBox: 'flex-1' }}
                    placeholderTop
                    name="post_category"
                    options={categories}
                />
                <FormSelect
                    placeholder="Type"
                    propsDropdown={{ classNameBox: 'flex-1' }}
                    placeholderTop
                    name="type"
                    options={type}
                />
                <FormSelect
                    placeholder="Audience"
                    propsDropdown={{ classNameBox: 'flex-1' }}
                    placeholderTop
                    name="audience"
                    options={audiences}
                />
            </div>
            <div className={formItemClass}>
                <Text weight="bold" size="18">
                    Content
                </Text>

                <TextArea editor className={'h-[150px]'} placeholder="Content example" />
            </div>
        </div>
    );
}

export default PostsForm;
