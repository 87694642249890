import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Image from 'components/Image';
import { RangeReview } from 'components/RangeReview';
import Text from 'components/Text';
import React from 'react';
import Card from './Card';

function CardRangeView({ title, actionText, propsRange, propsTextReview, className }) {
    return (
        <Card className={clsx('p-5  h-[140px]  overflow-hidden', className)}>
            <div className="flex flex-col items-center justify-between w-full h-full">
                <div className="flex flex-row justify-center gap-[5px]">
                    <Text size="12" component="p">
                        {title}
                    </Text>

                    <Image src={SVG.info_outlined} />
                </div>

                <div className="flex w-full rounded-full  gap-[5px] items-center flex-start">
                    <RangeReview {...propsRange} />
                </div>
                <div className="flex items-center justify-between w-full h-auto" onClick={() => {}}>
                    <Text size="14" weight="bold" {...propsTextReview}>
                        {propsTextReview?.label}
                    </Text>
                    <div className="flex items-center cursor-pointer">
                        <Text size="10">{actionText}</Text>
                        <Image src={SVG.chevron_right} className="w-3 h-3" />
                    </div>
                </div>
            </div>
        </Card>
    );
}

export default CardRangeView;
