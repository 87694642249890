import clsx from 'clsx';
import Box from 'components/Box';
import { RowDetail } from 'components/GridTable';
import RowDetail2 from 'components/GridTable/RowDetail2';
import Text from 'components/Text';
import React, { useId } from 'react';

function DetailBox2({ column, row, title, isText, className, ...props }) {
    const boxId = useId();
    return (
        <Box id={boxId} shadow="default" className={clsx('bg-white-light relative z-10 p-[15px] w-full', className)}>
            <Text className="mt-[15px] mb-[5px] !text-xs md:!text-xs" size="12" weight="bold">
                {title}
            </Text>
            <RowDetail2 boxId={boxId} column={column} row={row} isText={isText} {...props} />
        </Box>
    );
}

export default DetailBox2;
