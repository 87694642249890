import { IMAGE } from 'assets/imagePath';
import Image from 'components/Image';

const OurAdvertising = () => {
    return (
        <section className="w-full mx-auto px-[20px] md:pt-[60px] py-[30px] md:pb-[80px] bg-white ">
            <div className="text-center mx-auto  text-2xl sm:text-[32px]  font-bold mb-[10px]  sm:mb-[22px] max-w-[745px] text-dark-nav font-sen">
                Our advertising platform excels in targeting audiences, data, and identity.
            </div>

            <div className="text-center  text-sm sm:text-base  font-medium  text-gray max-w-[960px]  mb-[10px]  sm:mb-[22px] sm:mx-auto">
                Our advertising platform offers a superior level of performance when it comes to targeting audiences,
                data, and identity. By leveraging advanced data analytics and machine learning algorithms, we can help
                you optimize your campaigns and reach your target audience with precision. Our platform's robust
                targeting capabilities allow you to identify and engage your ideal customers across multiple channels,
                including desktop and mobile devices, social media, and more. With our powerful identity resolution
                tools, we can help you connect the dots between your customers' online and offline behaviors, giving you
                valuable insights into their preferences and interests. With our advertising platform, you can expect
                nothing less than exceptional performance and results.
            </div>
            <div className="mx-auto w-fit">
                <Image src={IMAGE.home_rating} className="mx-auto h-auto md:w-[200px] w-[160px]" />
            </div>
        </section>
    );
};

export default OurAdvertising;
