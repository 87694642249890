import { useId, useState } from 'react';

function RadioSquare({ label, onSelect, setValueFormik, Label, type = 'radio', ...props }) {
    const radioId = useId();
    const [value, setValue] = useState();
    const handleOnChange = (e) => {
        const value = e.target.value;
        setValue(value);
        setValueFormik && setValueFormik(value);

        onSelect && onSelect();
    };

    return (
        <label className="flex flex-row gap-2.5" htmlFor={radioId}>
            <div className="aspect-square w-5  flex justify-center items-center shadow-default bg-white-light rounded-[5px]">
                <input
                    value={value}
                    className="hidden peer"
                    type={type}
                    id={radioId}
                    onChange={handleOnChange}
                    {...props}
                />
                <div className="shadow-check-box aspect-square w-2.5 rounded-[3px]  peer-checked:bg-primary  bg-blue-cfd" />
            </div>
            {Label}
        </label>
    );
}

export default RadioSquare;
