import { Title } from 'components/Title';

function EducationPostDetail({ post }) {
    return (
        <div className="flex flex-col w-full h-auto gap-5">
            <div className="h-[50px] flex items-center">
                <Title>{post?.title}</Title>
            </div>

            <div className="text-sm post__detail">
                {/* { post.content} */}
                Lorem ipsum dolor sit amet consectetur. Augue pulvinar blandit in ut. Sollicitudin tempor leo phasellus
                nulla rhoncus. Elit id phasellus adipiscing nunc pulvinar aliquam tortor. Turpis amet ullamcorper orci
                aliquam nulla aliquam. Amet est magna eget tristique lectus dignissim tellus phasellus mi. Ipsum
                ullamcorper orci venenatis egestas turpis eget nisi nec platea. Id odio et hac bibendum facilisis duis
                mi tincidunt lorem. Integer erat fames aliquet dui arcu vulputate eros. Sed fermentum ultrices pretium
                turpis eu. Risus blandit egestas vel sed dui. Dictum dolor orci commodo in imperdiet dignissim elit
                convallis volutpat. Neque iaculis mauris justo purus feugiat sit sed ut. Non egestas senectus convallis
                eget sed.
                <br />
                <br />
                Lacus congue consectetur adipiscing facilisi. Viverra venenatis egestas leo varius sit ac imperdiet.
                Amet sed vehicula senectus neque et senectus nunc aliquam scelerisque. Id vitae commodo odio egestas
                nisl dui rhoncus. Ut justo tellus ut imperdiet morbi sed adipiscing. Felis tempus eu at etiam nec
                venenatis posuere etiam. Nulla neque semper nisl libero scelerisque. Orci leo tempus sociis scelerisque
                sagittis adipiscing donec netus. Fermentum eget diam vivamus ipsum mauris massa donec aenean leo. Amet
                imperdiet non diam eget. Erat leo tortor nulla elit turpis. Vestibulum posuere et id neque dui. Aliquet
                tortor massa amet id nisl urna natoque eget. Mi nullam enim dui ultrices vulputate velit dis eu
                adipiscing.
                <br />
                <br />
                Tristique quis lobortis vitae id imperdiet tincidunt malesuada adipiscing elit. Scelerisque nisl massa
                tristique suspendisse id euismod. Est ac interdum risus est vitae non. Luctus sem tincidunt vitae a.
                Quam tortor cursus scelerisque et ac lectus ipsum nunc. Egestas facilisi mi sed amet id hac egestas.
                <br />
                <br />
                Et tellus nunc libero auctor pellentesque ultricies ut tristique. Aliquam ante pharetra aenean lacinia.
                Faucibus id sagittis non erat. Fusce interdum suspendisse vitae aliquam. Tellus arcu elementum libero
                tristique feugiat mi. Mattis hac aliquet parturient convallis cum. In mus est amet amet consequat in
                integer justo in. Dictumst imperdiet ultricies elementum sociis ullamcorper pellentesque est a.
                <br />
                <br />
                Diam amet amet placerat leo tortor massa nulla mauris. Pretium eget rhoncus id ultricies arcu integer.
                Sit vestibulum nibh quam amet. Feugiat ut aliquet amet proin. Massa pellentesque in ultrices sed risus
                fames. Cursus ipsum at eros eu in tellus habitant pulvinar. Elementum in pharetra mauris senectus
                tincidunt iaculis ipsum. Sed augue pretium porttitor sit volutpat pulvinar.
            </div>
        </div>
    );
}

export default EducationPostDetail;
