import { InputText, Select } from 'components/Input';
import Text from 'components/Text';
import { useFormik } from 'formik';
import { useState } from 'react';
import UploadAvatar from './UploadAvatar';
const countryOptions = [
    {
        value: 'personal',
        label: 'Personal',
    },
    {
        value: 'business',
        label: 'Business',
    },
];
function ProfileOrganization({ organizationSuggest = 'Magic Media (234242)' }) {
    const [image, setImage] = useState('');
    const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            console.log(values);
            // navigateSearch(pathname, { step: '2' });
        },
        // validationSchema: createAccountSchema,
    });

    return (
        <div className="max-w-[820px]">
            <Text className="mb-5 pt-7" size="18" weight="bold">
                Profile
            </Text>

            <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-y-[20px]">
                    <div className="flex flex-start items-center gap-[20px]">
                        <InputText
                            onChange={handleChange}
                            value={values.organization_name}
                            error={errors.organization_name}
                            classNameBox={'flex-1'}
                            name="organization_name"
                            placeholder={'Organization Name'}
                            rounded
                        />

                        <UploadAvatar image={image} setImage={setImage} label={'Change Profile Image'} />
                    </div>

                    <div className="flex sm:flex-row flex-col flex-start items-center gap-[20px]">
                        <Select
                            placeholder="Country"
                            options={countryOptions}
                            setValue={setFieldValue}
                            value={values.country}
                            classNameText="flex-1"
                            name="country"
                            propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                        />
                        <Select
                            placeholder="City"
                            options={countryOptions}
                            setValue={setFieldValue}
                            value={values.city}
                            classNameText="flex-1"
                            propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                            name="city"
                        />
                        <InputText
                            onChange={handleChange}
                            value={values.zip}
                            error={errors.zip}
                            classNameBox="flex-1"
                            name="zip"
                            placeholder={'ZIP'}
                            rounded
                        />
                    </div>
                    <div>
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.organization_address}
                            error={errors.organization_address}
                            name="organization_address"
                            placeholder={'Organization Address'}
                            rounded
                        />
                    </div>

                    <div className="flex sm:flex-row flex-col flex-start items-center gap-[20px]">
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.organization_email_address}
                            error={errors.organization_email_address}
                            name="organization_email_address"
                            placeholder={'Organization Email Address'}
                            rounded
                        />
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.organization_phone_number}
                            error={errors.organization_phone_number}
                            name="organization_phone_number"
                            placeholder={'Organization Phone Number'}
                            rounded
                        />
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.organization_website}
                            error={errors.organization_website}
                            name="organization_website"
                            placeholder={'Organization Website'}
                            rounded
                        />
                    </div>

                    <InputText
                        classNameBox="flex-1"
                        onChange={handleChange}
                        value={values.organization_registration_number}
                        error={errors.organization_registration_number}
                        name="organization_registration_number"
                        placeholder={'Organization Registration Number '}
                        rounded
                    />

                    <Select
                        placeholder="Primary contact"
                        options={countryOptions}
                        setValue={setFieldValue}
                        value={values.primary_contact}
                        classNameText="flex-1"
                        propsDropdown={{ classNameBox: 'flex-1' }}
                        name="primary_contact"
                    />

                    <InputText
                        onChange={handleChange}
                        value={values.bio}
                        error={errors.bio}
                        classNameBox="flex-1"
                        name="bio"
                        placeholder={'Bio'}
                        rounded
                    />
                </div>
            </form>
        </div>
    );
}

export default ProfileOrganization;
