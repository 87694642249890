import { BoxLabelOutline } from 'components/BoxLabel';
import { CardSelect } from 'components/Card';
import { SubTitle } from 'components/Title';

function BalanceMethod() {
    return (
        <div className="balance_method py-2.5">
            <SubTitle className="mb-5 mt-7">Balance</SubTitle>

            <BoxLabelOutline label="Balances">
                <CardSelect title={'3245.00'} subTitle="FIAT (USD)" />
                <CardSelect title={'3245.00'} subTitle="FIAT (EUR)" />
            </BoxLabelOutline>
        </div>
    );
}

export default BalanceMethod;
