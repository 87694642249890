import { SelectMulti } from 'components/Input';
import { useField } from 'formik';

function FormSelectMulti(props) {
    const [field, meta, helpers] = useField(props);

    const { setValue: setValueFormik } = helpers;
    return <SelectMulti helper={meta.error} error={meta.error} setValueFormik={setValueFormik} {...field} {...props} />;
}

export default FormSelectMulti;
