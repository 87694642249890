import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import { IconButton } from 'components/Button';
import Chip from 'components/Chip/Chip';
import Text from 'components/Text';
import { useNavigate } from 'react-router-dom';

// detailInfo = {
//     detailName
//     detailId
//     chipLabel

// }
function HeaderDetail({ detailInfo, chipProps, ActionArea, navigateTo = '', className }) {
    const navigate = useNavigate();
    return (
        <div className={clsx('flex flex-wrap justify-between gap-[30px]', className)}>
            <div className="flex flex-row gap-5 md:gap-[30px]">
                <IconButton
                    classNameImage={'w-5 h-5'}
                    className="w-[50px] h-50px"
                    source={SVG.chevron_left}
                    onClick={() => navigate(navigateTo || -1, { replace: true })}
                />
                <div className="flex flex-wrap items-center justify-start flex-1 gap-5 lg:flex-nowrap">
                    <Text weight="bold" size="24" component={'p'}>
                        {detailInfo?.detailName}
                    </Text>
                    {detailInfo?.detailId && (
                        <Text weight="semibold" size="24" color="secondary" component="h3">
                            #{detailInfo?.detailId}
                        </Text>
                    )}
                    {detailInfo?.detailId && (
                        <Chip color="white" {...chipProps}>
                            {detailInfo?.chipLabel}
                        </Chip>
                    )}
                </div>
            </div>
            {ActionArea}
        </div>
    );
}

export default HeaderDetail;
