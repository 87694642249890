import { BoxContent } from 'components/BoxContent';
import React from 'react';
import Analytics from './CompaniesAnalytics';
import CompaniesTable from './CompaniesTable';

function CompaniesAgency() {
    return (
        <div className="company">
            <BoxContent title={'Analytics'}>
                <Analytics />
            </BoxContent>

            <div className="mt-[30px]">
                <CompaniesTable />
            </div>
        </div>
    );
}

export default CompaniesAgency;
