import React, { useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { SVG } from 'assets/imagePath';
import { Button, IconButton } from 'components/Button';
import { Collapse } from 'components/Collapse';
import { Drawer } from 'components/drawer';
import { offerItems } from 'components/Public/Home/data';
import { MENU_HOME } from 'constants/menu';
import Image from 'components/Image';
import clsx from 'clsx';

const MenuIndustriesMobile = ({ onClose }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState('');

    const handleNavigateIndustyOffer = (label) => {
        onClose();
        setActive(label);
        navigate(routesPath.industries, { state: { industryLabel: label } });
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        if (location?.state && location?.state?.industryLabel) setActive(location?.state?.industryLabel);
    }, [location]);

    return (
        <div>
            {offerItems.map((item, index) => (
                <div
                    key={index}
                    className={clsx(
                        'flex items-center gap-[10px] bg-dark-nav px-[20px] py-[12.5px] border-b border-[#2C394F80]',
                        active === item?.label ? 'bg-[#242f42]' : 'bg-dark-nav',
                    )}
                    onClick={() => handleNavigateIndustyOffer(item?.label)}
                >
                    <Image src={item?.icon || SVG.agency} className="w-[24px] h-[24px] mx-auto" />
                    <p className="text-sm leading-[17px] text-white">{item?.label}</p>
                </div>
            ))}
        </div>
    );
};

const MobileDrawer = ({ scrollToView, onClose }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const collapseRef = useRef();

    return (
        <div className="flex flex-col divide-y divide-blue-cfd">
            {MENU_HOME.map((nav) => {
                return nav.id === 'industries' ? (
                    <Collapse
                        key={nav.id}
                        title={nav.label}
                        defaultToggle={false}
                        renderButton={({ toggle, isOpen, title }) => {
                            collapseRef.current = { toggle };
                            return (
                                <div
                                    onClick={toggle}
                                    className={clsx(
                                        'h-full py-[15px] px-5 flex items-center text-black text-sm  font-inter hover:bg-nav',
                                        location.pathname === routesPath.industries
                                            ? 'bg-white-light'
                                            : 'bg-transparent',
                                    )}
                                >
                                    {title}
                                </div>
                            );
                        }}
                    >
                        <MenuIndustriesMobile onClose={onClose} />
                    </Collapse>
                ) : (
                    <NavLink
                        key={nav.id}
                        to={nav.to}
                        className={({ isActive }) =>
                            `h-full py-[15px] px-5 flex items-center text-black text-sm  font-inter hover:bg-nav ${
                                isActive ? 'bg-white-light' : 'bg-transparent'
                            }`
                        }
                        onClick={() => {
                            collapseRef.current.toggle(false);
                            onClose();
                        }}
                    >
                        {nav.label}
                    </NavLink>
                );
            })}

            <div className="p-5 w-full flex gap-2.5">
                <Button
                    className={'flex-[5] h-[50px] px-[15px]'}
                    color="secondary"
                    onClick={() => {
                        scrollToView('request_demo');
                        onClose();
                    }}
                >
                    Request a Live Demo
                </Button>
                <Button className={'flex-[2] h-[50px]'} onClick={() => navigate(routesPath.login)}>
                    Sign up
                </Button>
            </div>
        </div>
    );
};

const HeaderDrawer = ({ onClose }) => {
    return (
        <div className="px-5 py-[15px] flex justify-between border-b border-blue-cfd">
            <Image className={'max-w-[160px] h-auto object-contain'} src={SVG.logo} />
            <Image className={'cursor-pointer h-auto object-contain'} src={SVG.close_icon} onClick={onClose} />
        </div>
    );
};

function NavBarMobile({ scrollToView }) {
    const [openDrawer, setOpenDrawer] = useState(false);
    const navigate = useNavigate();
    return (
        <>
            <div className="px-5 py-[15px] gap-[5px] flex justify-between">
                <IconButton source={SVG.bar} onClick={() => setOpenDrawer(true)} />
                <div onClick={() => navigate('/')} className="child:!inline-block flex  items-center cursor-pointer">
                    <Image className={'!flex'} src={SVG.logo} />
                </div>
                <IconButton onClick={() => navigate(routesPath.login)} color="primary" source={SVG.navigate_icon} />
            </div>
            <Drawer
                classNameBox="w-full h-full  p-0"
                position={'top'}
                handleClose={() => setOpenDrawer(false)}
                open={openDrawer}
                Header={<HeaderDrawer onClose={() => setOpenDrawer(false)} />}
                DrawerContent={
                    <MobileDrawer
                        onClose={() => setOpenDrawer(false)}
                        scrollToView={() => scrollToView('request_demo')}
                    />
                }
            />
        </>
    );
}

export default NavBarMobile;
