import Radio from 'components/Input/Radio';
import { SubTitle } from 'components/Title';
import React, { memo } from 'react';

function SelectMethod({ onGetMethod }) {
    return (
        <div className="select__method">
            <SubTitle className={'mt-7 mb-5'}>Select Method</SubTitle>
            <div className="grid grid-flow-row grid-cols-1 gap-5 xxs:grid-cols-2 md:grid-cols-3">
                <Radio
                    label="FIAT"
                    value="fiat"
                    name="payment_method"
                    onChange={(e) => onGetMethod(e.target.value)}
                    infoIcon
                />
                <Radio
                    label="Crypto"
                    value="crypto"
                    name="payment_method"
                    onChange={(e) => onGetMethod(e.target.value)}
                    infoIcon
                />
                <Radio
                    label="Payment History"
                    value="history"
                    name="payment_method"
                    onChange={(e) => onGetMethod(e.target.value)}
                    infoIcon
                />
            </div>
        </div>
    );
}

export default memo(SelectMethod);
