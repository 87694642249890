import { Circle } from 'components/Circle';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import { useClickOutside } from 'hooks';
import { useRef, useState } from 'react';

const SegmentItem = ({ isWarning, onRemove = () => {}, onHoverRemove, item, renderIcon, inputProps }) => {
    const [editName, setEditName] = useState(false);

    const nameRef = useRef(null);

    useClickOutside(nameRef, () => setEditName(false));

    const [isHoveringRemove, setIsHoveringRemove] = useState(false);

    return (
        <div className="flex flex-row border border-blue-cfd items-center gap-2.5 w-full bg-white-light rounded-[10px] p-2.5">
            <div className="flex flex-row w-full gap-2.5 items-center">
                {renderIcon ? (
                    renderIcon()
                ) : (
                    <Circle className={isHoveringRemove ? 'bg-error' : 'bg-success'}>
                        <Svg name={isHoveringRemove ? 'minus' : 'plus'} className="child:fill-white" />
                    </Circle>
                )}
                <div className="flex flex-col flex-1">
                    <Text weight="bold" size="14" onClick={() => setEditName(true)}>
                        {item?.title}
                    </Text>
                    <Text color="secondary">{item?.subTitle}</Text>
                </div>
                <Svg
                    name="close"
                    className="child:fill-black max-w-[17px] cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        onRemove();
                    }}
                    onMouseOver={() => {
                        onHoverRemove(item?.id);
                        setIsHoveringRemove(true);
                    }}
                    onMouseLeave={() => {
                        onHoverRemove(null);
                        setIsHoveringRemove(false);
                    }}
                />
            </div>
        </div>
    );
};

export default SegmentItem;
