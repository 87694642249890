import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Box from 'components/Box';
import Image from 'components/Image';
import Text from 'components/Text';
import { useState } from 'react';

function AnalyticReview({
    title,
    propsBox,
    className,
    Chart,
    LegendComponent,
    CustomReview,
    defaultReverse = false,
    classNameBox,
    ...props
}) {
    const [reverseCard, setReverseCard] = useState(defaultReverse);
    return (
        <div
            className={clsx(
                'shadow-outer rounded-[15px] bg-blue-eae p-5 flex flex-col gap-[5px] h-full max-h-[347px]',
                className,
            )}
        >
            {!reverseCard ? (
                <>
                    <div className="flex gap-[5px] items-center">
                        <Text weight="bold" size={'12'}>
                            {title}
                        </Text>
                        <div onClick={() => setReverseCard((prev) => !prev)}>
                            <Image src={SVG.info_outlined} />
                        </div>
                    </div>

                    <Box
                        className={clsx(
                            'rounded-[15px] h-full overflow-auto    bg-white-light max-h-full w-full p-2.5',
                            classNameBox,
                        )}
                        shadow="default"
                        {...propsBox}
                    >
                        {CustomReview ? (
                            CustomReview
                        ) : (
                            <div className="flex h-full w-full flex-col gap-5 px-[5px]">
                                <div className="w-full h-full mx-auto">{Chart}</div>
                                <div className="h-full overflow-auto">{LegendComponent}</div>
                            </div>
                        )}
                    </Box>
                </>
            ) : (
                <div className="flex flex-col h-full">
                    <Text size="12" className="text-center">
                        Total Ad Spend
                    </Text>
                    <Text weight="bold" size="20" className="flex items-center justify-center flex-1">
                        $12,000
                    </Text>
                    <div className="flex items-center justify-center">
                        <div
                            className="flex items-center cursor-pointer"
                            onClick={() => setReverseCard((prev) => !prev)}
                        >
                            <Text className="!text-[10px]">View</Text>
                            <Image src={SVG.chevron_right} className="w-3 h-3" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AnalyticReview;
