import { SVG } from 'assets/imagePath';
import Box from 'components/Box';
import { Button } from 'components/Button';
import Image from 'components/Image';
import { Svg } from 'components/Svg';
import Text from 'components/Text';
import React, { useState } from 'react';
import SavedSignatureItem from './SavedSignatureItem';

function SavedSignature() {
    const [activeIdx, setActiveIdx] = useState(0);
    return (
        <div className="flex flex-col gap-5 items-center ">
            <Box
                className="bg-white-light flex flex-col gap-2.5 max-h-[170px] max-w-[347px] w-full overflow-auto p-[14px]"
                shadow="default"
            >
                <div className="grid grid-cols-1 xxs:grid-cols-2  gap-2.5 flex-1 h-full w-full  p-[1px] overflow-auto ">
                    {Array(4)
                        .fill(0)
                        .map((_, index) => (
                            <div
                                className=" w-full cursor-pointer"
                                onClick={() => {
                                    setActiveIdx(index);
                                }}
                            >
                                <SavedSignatureItem active={+activeIdx === index} />
                            </div>
                        ))}
                </div>

                <div className="flex justify-center">
                    <div className="flex w-fit  gap-[5px] cursor-pointer">
                        {/* <Image className={'max-w-[10px] w-full object-contain cursor-pointer'} src={SVG.trash_light} /> */}
                        <Svg name="trash" className="w-[10px] h-[10px] svg-path:fill-blue-cfd" />
                        <Text size="10">Delete All</Text>
                    </div>
                </div>
            </Box>
            <Button className="max-w-[260px] w-full">Sign</Button>
        </div>
    );
}

export default SavedSignature;
