import { Button } from 'components/Button';
import { SubTitle } from 'components/Title';
import { BREAKPOINT } from 'constants/breakpoints';
import { useViewport } from 'context/ViewportContext';
import React from 'react';

function HeaderTableSummary({ title, viewAll, add }) {
    const { width } = useViewport();
    return (
        <div className="flex justify-start  md:justify-between md:flex-row flex-col  gap-2.5  md:items-center">
            <SubTitle>{title}</SubTitle>
            <div className="flex gap-2.5 md:gap-5">
                <Button size={width <= BREAKPOINT.md ? 'small' : 'medium'} variant="outlined" onClick={viewAll}>
                    View All
                </Button>
                {add && (
                    <Button size={width <= BREAKPOINT.md ? 'small' : 'medium'} variant="outlined" onClick={add.onAdd}>
                        {add.label}
                    </Button>
                )}
            </div>
        </div>
    );
}

export default HeaderTableSummary;
