import React, { memo } from 'react';

const AnalyzeSummary = memo(() => {
    return (
        <div className="">
            <div className="py-[15px]">
                <h3 className="text-blue-dark font-bold text-base text-center">Analyze Summary</h3>
            </div>
            <div className="p-[15px] bg-white-light rounded-[15px] shadow-default">
                <div className="flex flex-col">
                    <div className="flex flex-col ">
                        <h2 className="font-semibold text-xs pt-[15px]">Overall Preformance</h2>
                        <div className="flex justify-between py-[5px]">
                            <p className="font-normal text-xs">Impressions</p>
                            <p className="font-normal text-xs">145,176</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="font-normal text-xs">Clicks</p>
                            <p className="font-normal text-xs">780</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="font-normal text-xs">CTR</p>
                            <p className="font-normal text-xs">0.54%</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="font-normal text-xs">Cost</p>
                            <p className="font-normal text-xs">$871.07</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default AnalyzeSummary;
