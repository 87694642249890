import { CardContent } from 'components/Card';
import Text from 'components/Text';
import { APP_ROLE } from 'constants/auth';
import { getCurrentRole, isPermissionView } from 'utils';

function StatusOrgazination() {
    const CURRENT_ROLE = getCurrentRole();

    return (
        <div className="flex flex-col gap-[30px]">
            <div className={'box__content'}>
                <Text className="mb-5" size="18" weight="bold">
                    Statistics
                </Text>

                {CURRENT_ROLE !== APP_ROLE.Si && (
                    <div className="mb-5 grid sm:grid-cols-2 grid-flow-row justify-items-start md:flex  md:flex-row gap-[20px]">
                        {isPermissionView([APP_ROLE.Org]) && (
                            <CardContent
                                className={'w-full md:w-[210px]'}
                                title="Total agency clients"
                                content="8"
                                actionText={'Refresh'}
                            />
                        )}
                        {isPermissionView([APP_ROLE.Org]) && (
                            <CardContent
                                className={'w-full md:w-[210px]'}
                                title="Total sub-company clients"
                                content="12"
                                actionText={'Refresh'}
                            />
                        )}

                        {isPermissionView([APP_ROLE.Org, APP_ROLE.Ag]) && (
                            <CardContent
                                className={'w-full md:w-[210px]'}
                                title="Total company clients"
                                content="7"
                                actionText={'Refresh'}
                            />
                        )}
                        {isPermissionView([APP_ROLE.Org, APP_ROLE.SubCo, APP_ROLE.Co, APP_ROLE.Ag]) && (
                            <CardContent
                                className={'w-full md:w-[210px]'}
                                title="Total Users"
                                content="114"
                                actionText={'Refresh'}
                            />
                        )}
                    </div>
                )}

                <div className="grid sm:grid-cols-2 grid-flow-row justify-items-start md:flex  md:flex-row gap-[20px]">
                    <CardContent
                        className={'w-full md:w-[210px]'}
                        title="Member Since"
                        content="Feb 23, 2023"
                        actionText={'Refresh'}
                    />
                    <CardContent
                        className={'w-full md:w-[210px]'}
                        title="Last Active"
                        content="Mar 12, 2023"
                        actionText={'Refresh'}
                    />
                    <CardContent
                        className={'w-full md:w-[210px]'}
                        title="Total Active"
                        content="12h 43ms"
                        actionText={'Refresh'}
                    />
                </div>
            </div>
        </div>
    );
}

export default StatusOrgazination;
