import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Image from 'components/Image';
import Text from 'components/Text';
import React from 'react';
import Card from './Card';

function CardContent({ title, content, icon, actionText, onAction, className }) {
    return (
        <Card className={clsx('p-5  h-[140px]  overflow-hidden', className)}>
            <div className="flex flex-col items-center justify-between w-full h-full">
                <Text size="12" component="span">
                    {title}
                </Text>
                <div className="flex  gap-[5px] items-center flex-start">
                    {icon && <Image className={'w-8 h-8'} alt="icon" src={icon} />}
                    <Text size={icon ? '20' : '24'} weight="bold">
                        {content}
                    </Text>
                </div>
                <div className="flex items-center cursor-pointer" onClick={onAction}>
                    <Text className="!text-[10px]">{actionText}</Text>
                    <Image src={SVG.chevron_right} className="w-3 h-3" />
                </div>
            </div>
        </Card>
    );
}

export default CardContent;
