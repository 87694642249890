import clsx from 'clsx';
import Text from 'components/Text';
import { useRef, useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export const InputPassword = ({
    icon,
    rounded = true,
    className,
    placeholder,
    PlaceHolder,
    value,
    onChange,
    error,
    classNameBox,
    label,
    helper,
    ...props
}) => {
    const inputRef = useRef(null);

    const [isFocus, setIsFocus] = useState(false);

    const [showPass, setShowPass] = useState(false);
    return (
        <label className={clsx('relative w-full h-fit  block', classNameBox)}>
            <span className="sr-only">Search</span>

            <div
                onClick={() => setShowPass(!showPass)}
                className="absolute inset-y-0 right-0 flex items-center pr-[15px]"
            >
                {showPass ? <AiOutlineEyeInvisible size={'20'} /> : <AiOutlineEye size={'20'} />}
            </div>

            {label && (
                <Text
                    className={clsx(
                        'z-1 px-[10px]  absolute bg-inherit z-0  rounded-[30px] shadow-default-top bg-white-light left-[15px] -top-[10px]  text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                    )}
                    size="10"
                >
                    {label}
                </Text>
            )}
            <input
                ref={inputRef}
                className={clsx(
                    'placeholder:text-grey-light placeholder:text-sm block bg-white-light w-full py-[15px] px-[20px] shadow-default focus:outline-none text-sm pr-9',

                    rounded ? 'rounded-[10px]' : '',
                    className,
                )}
                type={showPass ? 'text' : 'password'}
                placeholder={PlaceHolder ? undefined : placeholder}
                value={value}
                onChange={onChange}
                // onFocus={() => setIsFocus(true)}
                // onBlur={() => setIsFocus(false)}
                {...props}
            />

            {helper && (
                <Text
                    className={clsx(
                        'px-[10px] absolute bg-inherit z-0  rounded-[30px] shadow-default-bottom  bg-white-light left-[15px] -bottom-[10px] text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                        error ? 'text-error' : '',
                    )}
                    size="10"
                >
                    {helper}
                </Text>
            )}

            {isFocus ||
                (!value && (
                    <span
                        onClick={() => {
                            inputRef.current.focus();
                        }}
                        className="cursor-text absolute inset-y-0 left-0 flex items-center pl-[20px] select-none"
                    >
                        {PlaceHolder}
                    </span>
                ))}
        </label>
    );
};
