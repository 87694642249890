import { IMAGE, SVG } from 'assets/imagePath';
import Image from 'components/Image/Image';
import Text from 'components/Text/Text';
import React from 'react';

function Advertising() {
    return (
        <section className="w-full mx-auto px-5 py-[60px] bg-white">
            <div className="max-w-[960px] w-full mx-auto ">
                <div className="flex flex-col gap-[60px] w-full">
                    <div className="flex flex-col md:flex-row gap-[30px] items-center md:px-[50px]">
                        <Image className={'max-w-[318px] w-full h-auto'} src={IMAGE.industry_app} alt="alt-ad" />

                        <div className="flex flex-col">
                            <Image className={'max-w-[200px] w-full h-auto'} src={SVG.logo} alt="alt-ad" />
                            <Text family="sens" weight="bold" size="20" className="mt-2.5 mb-5 tracking-tighter">
                                Your ultimate Ad management tool
                            </Text>
                            <Text weight="medium" size="16">
                                At AdNucleus, we understand the importance of advertising, but we also recognize the
                                challenges of managing ads. That's why we've developed a tool that simplifies the
                                process, allowing you to monitor and optimize campaigns in real-time while maximizing
                                your budget. Our tool is meticulously designed to assist businesses of all sizes in
                                achieving their marketing goals.
                            </Text>
                        </div>
                    </div>
                    <div className="w-full relative p-[30px] xs:p-[60px] bg-blue-dark xs:px-[90px] rounded-[30px]">
                        <Text size="20" className="text-white !font-extralight mb-2.5">
                            “Advertising people who ignore research are as dangerous <br /> as generals who ignore
                            decodes of enemy signals.”
                        </Text>

                        <Text weight="bold" className="text-white text-[15px]">
                            David Ogilvy
                        </Text>
                        <Text className="text-white text-[15px]">Founder of Ogilvy & Mather</Text>
                        <div className="absolute top-[20px] right-[20px] aspect-square w-full max-w-[180px]">
                            <Image className={'w-full h-auto'} src={IMAGE.industry_ad} alt="alt-ad" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Advertising;
