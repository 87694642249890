import { Button } from 'components/Button';
import { InputText, Select } from 'components/Input';
import { useFormik } from 'formik';
import { useNavigateSearch } from 'hooks';
import { useLocation } from 'react-router-dom';
import { confirmDetailsSchema } from '../registerSchema';

const useCaseOptions = [
    {
        value: 'personal',
        label: 'Personal',
    },
    {
        value: 'business',
        label: 'Business',
    },
];

function ConfirmDetails() {
    const navigateSearch = useNavigateSearch();

    const { pathname } = useLocation();

    const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            mobile_phone: '',
            country: '',
            city: '',
            street_address: '',
            suite_or_apartment: '',
            zip_code: '',
        },
        onSubmit: (values) => {
            console.log(values);
            navigateSearch(pathname, { step: '3' });
        },
        validationSchema: confirmDetailsSchema,
    });

    return (
        <>
            <div className="text-center font-bold text-[24px] leading-[29px] text-dark-nav mb-[30px]">
                Confirm Details
            </div>
            <div className="text-center  text-[14px] leading-[21px] text-dark-nav mb-[20px]">
                Please fill the form to <span className="font-bold">confirm details</span>.
            </div>
            <form onSubmit={handleSubmit}>
                <div className="flex gap-[20px] mb-[20px]">
                    <InputText
                        placeholder="First Name"
                        rounded
                        onChange={handleChange}
                        value={values.first_name}
                        errors={errors.first_name}
                        name="first_name"
                    />
                    <InputText
                        placeholder="Last Name"
                        rounded
                        onChange={handleChange}
                        value={values.last_name}
                        errors={errors.last_name}
                        name="last_name"
                    />
                </div>

                <div className="mb-[20px]">
                    <InputText
                        placeholder="User Email"
                        rounded
                        onChange={handleChange}
                        value={values.email}
                        errors={errors.email}
                        name="email"
                    />
                </div>

                <div className="mb-[20px]">
                    <InputText
                        className=""
                        placeholder="Mobile Phone"
                        rounded
                        onChange={handleChange}
                        value={values.mobile_phone}
                        errors={errors.mobile_phone}
                        name="mobile_phone"
                    />
                </div>

                <div className="flex gap-[20px] mb-[20px] ">
                    <Select
                        placeholder="Country"
                        options={useCaseOptions}
                        className="justify-between"
                        setValue={setFieldValue}
                        value={values.country}
                        errors={errors.country}
                        name="country"
                        propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                    />
                    <Select
                        placeholder="City"
                        options={useCaseOptions}
                        className="justify-between "
                        setValue={setFieldValue}
                        value={values.city}
                        errors={errors.city}
                        name="city"
                        propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                    />
                </div>

                <div className="mb-[20px]">
                    <InputText
                        className="mb-[20px]"
                        placeholder="Street Address"
                        rounded
                        onChange={handleChange}
                        value={values.street_address}
                        errors={errors.street_address}
                        name="street_address"
                    />
                </div>

                <div className="flex gap-[20px] mb-[30px]">
                    <InputText
                        placeholder="Suite or Apartment"
                        rounded
                        onChange={handleChange}
                        value={values.suite_or_apartment}
                        errors={errors.suite_or_apartment}
                        name="suite_or_apartment"
                    />
                    <InputText
                        placeholder="ZIP Code"
                        rounded
                        onChange={handleChange}
                        value={values.zip_code}
                        errors={errors.zip_code}
                        name="zip_code"
                    />
                </div>

                <div
                    className="flex items-center justify-center gap-[10px] mb-[30px]"
                    onClick={() => setFieldValue('accept_term', !values.accept_term)}
                >
                    <div className="w-[20px] h-[20px] shadow-default bg-white-light flex items-center justify-center rounded-[5px] cursor-pointer">
                        <div
                            className={`w-[10px] h-[10px] rounded-[3px] shadow-check-box ${
                                values.accept_term ? 'bg-blue-0e6' : 'bg-blue-cfd'
                            }`}
                        ></div>
                    </div>
                    <span className="select-none cursor-pointer text-[14px] leading-[15px] font-[400px]">
                        Accept <u>Terms & Conditions</u>
                    </span>
                </div>

                <div className="flex gap-[20px] ">
                    <Button className="w-[260px] py-[15px] px-5 shadow-outer" color="secondary">
                        Decline
                    </Button>
                    <Button className="w-[260px] py-[15px] px-5 shadow-outer" type="submit">
                        Accept
                    </Button>
                </div>
            </form>
        </>
    );
}

export default ConfirmDetails;
