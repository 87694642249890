import { BoxLabelOutline } from 'components/BoxLabel';
import { Button } from 'components/Button';
import PhoneNumberInput from 'components/Input/PhoneNumberInput';
import FormInputText from 'components/modules/form/FormInputText';
import FormSelect from 'components/modules/form/FormSelect';
import { SubTitle } from 'components/Title';
import { Form, Formik } from 'formik';
import React from 'react';

function CreditCardPayment() {
    return (
        <div className="credit__payment flex flex-col gap-5">
            <SubTitle className={'mt-7'}>Credit Card Payment</SubTitle>
            <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                    }, 0);
                }}
            >
                <Form className="flex flex-col gap-5">
                    <BoxLabelOutline label="Billing Details">
                        <div className="flex flex-col gap-5 w-full">
                            <div className="flex flex-col sm:flex-row gap-5">
                                <FormSelect
                                    placeholder="Country"
                                    propsDropdown={{ classNameBox: 'flex-1' }}
                                    placeholderTop
                                    name="country"
                                    options={[]}
                                />

                                <FormInputText classNameBox="flex-1" placeholder="City" name="city" />
                                <FormInputText classNameBox="flex-1" placeholder="Sate / Region" name="sate" />
                            </div>
                            <div className="flex flex-col sm:flex-row gap-5">
                                <FormInputText placeholder="Address" classNameBox="flex-[1.5]" name="address" />
                                <FormInputText placeholder="ZIP Code" classNameBox="flex-1" name="zip" />
                            </div>
                        </div>
                    </BoxLabelOutline>
                    <BoxLabelOutline label="Contact Details">
                        <div className="flex flex-col gap-5 w-full">
                            <div className="flex flex-col sm:flex-row gap-5">
                                <div className="flex-1">
                                    <PhoneNumberInput />
                                </div>

                                <FormInputText classNameBox="flex-1" placeholder="Email" name="email" />
                            </div>
                        </div>
                    </BoxLabelOutline>
                    <BoxLabelOutline label="Card Details">
                        <div className="flex flex-col gap-5 w-full">
                            <div className="flex-1 flex-col sm:flex-row flex gap-5">
                                <FormInputText classNameBox="flex-1" placeholder="Name" name="name" />
                                <FormInputText classNameBox="flex-1" placeholder="Card Number" name="card_number" />
                                <FormInputText classNameBox="sm:max-w-[90px]" placeholder="MM/YY" name="mmyy" />
                                <FormInputText classNameBox="sm:max-w-[60px]" placeholder="CVV" name="cvv" />
                                <Button className={'sm:max-w-[150px] w-full'} type="submit">
                                    Pay
                                </Button>
                            </div>
                        </div>
                    </BoxLabelOutline>
                </Form>
            </Formik>
        </div>
    );
}

export default CreditCardPayment;
