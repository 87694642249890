import { SubTitle } from 'components/Title';
import FormInputText from 'components/modules/form/FormInputText';
import FormSelectMulti from 'components/modules/form/FormSelectMulti';
import FormUpload from 'components/modules/form/FormUpload';
import { formatBytes, getTypeFile } from 'utils';

const additions = [{ label: 'Expandable (User initiated - Click)', value: 'expandable' }];
function MediaNativeDetail({ formValues, data }) {
    const { type, width, height, size } = formValues?.attachment_image || {};

    return (
        <>
            <div className="flex flex-col gap-[30px]">
                <div className="flex flex-col w-full h-full gap-5">
                    <SubTitle className={'mt-7'}>Attributes</SubTitle>

                    {/* <div className="flex flex-col gap-5 xs:flex-row">
                        <FormSelect
                            propsDropdown={{ classNameBox: 'flex-1' }}
                            options={attachment_methods}
                            name="attachment_type"
                            placeholder="Type"
                            placeholderTop
                        />
                        <Button type="button" color="secondary" onClick={() => setIsOpen(true)}>
                            Create Using AdCanva
                        </Button>
                    </div> */}

                    <FormUpload
                        className="max-h-[500px] h-[200px]"
                        label="Attachment"
                        name="attachment_image"
                        helper={
                            formValues?.attachment_image &&
                            `Size: ${width}x${height}, Format:  ${getTypeFile(type)}, Size: ${formatBytes(size)}`
                        }
                    />
                </div>
                <div className="flex flex-col gap-5">
                    <FormInputText name="call_action" placeholder="Call to action" label="Call to action" />

                    <FormInputText name="url" placeholder="Display URL" label="Display URL" />
                    <FormInputText
                        name="additional_des"
                        placeholder="Additional Description"
                        label="Additional Description"
                    />
                    <FormInputText name="sale_price" placeholder="Sale Price" label="Sale Price" />

                    <FormInputText name="price" placeholder="Price" label="Price" />
                    <FormInputText name="amount_download" placeholder="No. of Downloads" label="No. of Downloads" />

                    <FormInputText name="amount_like" placeholder="No. of Likes" label="No. of Likes" />
                    <FormInputText name="rating" placeholder="Rating" label="Rating" />
                    <FormInputText name="description" placeholder="Description" label="Description" />
                    <FormInputText name="sponsored_by" placeholder="Sponsored by" label="Sponsored by" />
                    <FormInputText name="title" placeholder="Title" label="Title" />

                    <FormSelectMulti
                        name="additions"
                        placeholder={'Additions'}
                        options={additions}
                        chipProps={{ className: '!px-[5px]' }}
                    />
                </div>
            </div>
        </>
    );
}

export default MediaNativeDetail;
