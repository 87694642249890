import clsx from 'clsx';
import Text from 'components/Text/Text';
import React from 'react';

function Holder({ ratio, title, className }) {
    return (
        <div className={clsx('flex flex-col gap-[5px]', className)}>
            <Text>
                {title}({ratio?.x}x{ratio.y})
            </Text>
            <div
                style={{ aspectRatio: ratio?.x / ratio.y, minWidth: ratio.x, minHeight: ratio.y }}
                className={clsx('max-h-max bg-blue-eae')}
            ></div>
        </div>
    );
}

export default Holder;
