import { IMAGE } from 'assets/imagePath';
import clsx from 'clsx';
import Avatar from 'components/Avatar/Avatar';
import { Svg } from 'components/Svg';
import Text from 'components/Text';
import { useEffect, useId } from 'react';
function UploadAvatar({ image, setImage, label, children, className, labelClass }) {
    const handleRemoveImage = () => {
        URL.revokeObjectURL(image?.preview);
        setImage();
    };
    const handleUpload = (e) => {
        // Remove blob when change Image
        handleRemoveImage();
        //
        const file = e.target.files[0];
        file.preview = URL.createObjectURL(file);
        console.log(file.preview);
        setImage(file);
    };

    useEffect(() => {
        return () => {
            // Remove blob when  component unmount

            handleRemoveImage();
        };
    }, []);

    const idImage = useId();
    return (
        <label className={className} htmlFor={idImage}>
            <input
                className="hidden"
                onChange={(e) => {
                    handleUpload(e);
                }}
                accept="image/*"
                id={idImage}
                multiple
                type="file"
            />
            <div className="flex flex-row gap-2.5 py-[5px] px-[15px] items-center cursor-pointer">
                <Avatar className={'rounded-full'} src={image?.preview || IMAGE.avatar_default} />
                <Text className={clsx('whitespace-nowrap mr-[18px] xxs:block hidden', labelClass)} size="14">
                    {label}
                </Text>
            </div>
        </label>
    );
}

export default UploadAvatar;
