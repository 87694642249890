import { useEffect, useState } from 'react';
import StepSelect from './PaymentMethod/StepSelect';
import { usePaymentModule } from 'context/ui/PaymentModuleContext';
import StepAddMethod from './PaymentMethod/StepAddMethod';
import FIATMethod from './FIATMethod';
import CryptoMethod from './CryptoMethod';
import ACHPayment from './ACHPayment';
import CreditCardPayment from './CreditCardPayment';

import StepCompleted from './PaymentMethod/StepCompleted';
import { BlockChainPayments } from 'pages/AccountConfig/Payments';
import BitcoinPayment from './BitcoinPayment';

function PaymentMethod() {
    const { step, setStep, isOpen } = usePaymentModule();

    useEffect(() => {
        if (!isOpen) {
            setStep('select_method');
        }
        return () => {
            setStep('select_method');
        };
    }, [isOpen]);

    return (
        <div className="payment_method py-2.5">
            {step === 'select_method' && <StepSelect />}
            {step === 'add_method' && <StepAddMethod />}
            {step === 'fiat' && <FIATMethod />}
            {step === 'crypto' && <CryptoMethod />}
            {step === 'bitcoin' && <BitcoinPayment />}
            {step === 'ach' && <ACHPayment />}
            {step === 'card' && <CreditCardPayment />}
            {step === 'completed' && <StepCompleted />}
        </div>
    );
}

export default PaymentMethod;
