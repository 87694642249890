import Box from 'components/Box/Box';
import FormSelectMulti from 'components/modules/form/FormSelectMulti';
import { useFormikContext } from 'formik';
import Holder from './Holder';
import { SelectedList } from './components';
import { Leaderboard, MediumRectangle } from './sizes';
import { SubTitle } from 'components/Title';

function CanvaDrawer() {
    const { values } = useFormikContext();
    const { sizes } = values;

    return (
        <div className="flex flex-col gap-2.5">
            <SubTitle>Creative</SubTitle>
            <Box className="bg-white-light py-[20px] md:py-[75px] pl-[20px] md:pl-[47px] pr-[20px] md:pr-[25px] w-full h-full overflow-auto">
                <div>
                    <Leaderboard
                        className={sizes?.find((item) => item.value === 'leader_board') ? 'visible' : 'invisible'}
                    />

                    <div className="flex flex-row gap-[30px] w-full mt-[10px]">
                        <MediumRectangle
                            className={sizes?.find((item) => item.value === 'medium_rec') ? 'visible' : 'invisible'}
                        />
                        {/* <Holder ratio={{ x: 320, y: 50 }} className={'mr-[70px]'} /> */}
                    </div>
                </div>
            </Box>
        </div>
    );
}

export default CanvaDrawer;
