export const labels = ['5%', '25%', '50%', '75%', '95%'];

export const mediaData = [0, 0.3, 1, 4, 10];

export const orgMediaCostData = [0, 0.2, 0.75, 3, 7.5];

export const baseMediaCostData = [0, 0.1, 0.5, 2, 6];

export const defaultStyleData = {
    fill: false,
    lineTension: 0.3,
    borderCapStyle: 'butt',
    borderWidth: 1,
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointRadius: 0,
    pointStyle: 'circle',
    pointBackgroundColor: 'transparent',
    pointHoverBorderWidth: 3,
};
