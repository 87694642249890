import clsx from 'clsx';
import Text from 'components/Text';
import Card from './Card';
import { Tooltip } from 'components/Tooltip';

import { BiDotsVerticalRounded } from 'react-icons/bi';

function CardSelect({ title, subTitle, className, active, action, ...props }) {
    return (
        <Card
            active={active}
            className={clsx('p-[15px] relative  h-[103px] w-[100px]  cursor-pointer overflow-hidden', className)}
            {...props}
        >
            <div className="flex flex-col justify-center items-center w-full h-full gap-2.5">
                <Text size="14" weight="bold" component="div">
                    {title}
                </Text>{' '}
                <Text size="12">{subTitle}</Text>
            </div>

            {action && (
                <div className="absolute top-2.5 right-[5px]">
                    <Tooltip tooltip="I am tooltip">
                        <BiDotsVerticalRounded className="text-xl" />
                    </Tooltip>
                </div>
            )}
        </Card>
    );
}

export default CardSelect;
