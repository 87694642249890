import React, { useEffect, useState } from 'react';
import DatePicker from './DatePicker';
import moment from 'moment';

function RangeDatePicker({ onChange, format = 'MMM DD YYYY', propsStart, propsEnd, value, ...props }) {
    const [min, setMin] = useState(value?.[0] ? moment(value[0]).format(format) : undefined);
    const [max, setMax] = useState(value?.[1] ? moment(value[1]).format(format) : undefined);

    useEffect(() => {
        if (min || max) {
            const arrDate = [min, max];
            onChange?.(arrDate);
        }
    }, [min, max]);

    return (
        <div className="flex items-center justify-between gap-5">
            <DatePicker
                onChange={setMin}
                classNameBox="w-full"
                size="small"
                label="Start Date"
                format={format}
                propsDate={{
                    max: max ? moment(max, format).format('YYYY-MM-DD') : undefined,
                }}
                defaultValue={min}
                {...props}
                {...propsStart}
            />
            <div> - </div>
            <DatePicker
                size="small"
                label="End Date"
                onChange={setMax}
                format={format}
                propsDate={{
                    min: min ? moment(min, format).format('YYYY-MM-DD') : undefined,
                }}
                defaultValue={max}
                {...props}
                {...propsEnd}
            />
        </div>
    );
}

export default RangeDatePicker;
