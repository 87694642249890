import { Select } from 'components/Input';
import { useFormik } from 'formik';
const optionsSystem = [
    { label: 'Markup 1', value: 'markup_1' },
    { label: 'Markup 2', value: 'markup_2' },
];
function MarkupContainer() {
    const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            alert(JSON.stringify(values));
        },
        // validationSchema: createAccountSchema,
    });

    return (
        <form onSubmit={handleSubmit}>
            <Select
                placeholder="Select Markup"
                placeholderTop
                options={optionsSystem}
                className="justify-between"
                propsDropdown={{ classNameBox: 'flex-1' }}
                setValue={setFieldValue}
                value={values.select_markup}
                name="select_markup"
                CustomList={({ onCloseDropdown }) => (
                    <ul className="flex flex-col z-50 divide-y-[1px] divide-blue-cfd">
                        {optionsSystem.map((option) => (
                            <li
                                onClick={() => {
                                    setFieldValue('select_markup', option.value);
                                    onCloseDropdown();
                                }}
                                key={option.value}
                                className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer"
                            >
                                <div className="flex flex-row items-center gap-[5px] flex-1">
                                    <span className="text-sm text-blue-dark">{option.label}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            />
        </form>
    );
}

export default MarkupContainer;
