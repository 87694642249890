import { IMAGE } from 'assets/imagePath';
import { Button } from 'components/Button';
import Image from 'components/Image';
import { InputText, SelectSearch } from 'components/Input';
import { useFormik } from 'formik';
import { forwardRef } from 'react';
import * as Yup from 'yup';

export const schema = Yup.object({
    full_name: Yup.string().trim(),
    email_address: Yup.string().trim(),
    phone_number: Yup.string().trim(),
    company_name: Yup.string().trim(),
    country: Yup.string().trim(),
});

const GetStarted = (props, ref) => {
    const { values, handleChange, handleSubmit, errors, setFieldValue } = useFormik({
        initialValues: {
            full_name: '',
            email_address: '',
            phone_number: '',
            company_name: '',
            country: '',
        },
        onSubmit: (values) => {
            alert(JSON.stringify(values));
        },
        validationSchema: schema,
    });

    return (
        <section
            ref={ref}
            id="request_demo"
            className="container w-full mx-auto xl:h-[534px] py-[30px] px-[20px] sm:p-[50px] flex flex-col xl:flex-row items-center  justify-between gap-5 sm:gap-[100px]"
        >
            <div className="left flex flex-col sm:flex-row gap-2.5 sm:gap-[50px] w-full justify-between items-center xl:w-[auto] xl:justify-start">
                <Image src={IMAGE.started_logo} className="w-[135px] sm:w-[270px] h-auto object-contain" />
                <div>
                    <div className="text-dark-nav font-bold  sm:text-start text-center text-[20px] leading-[30px] mb-[15px] font-sen">
                        Get Started With Your <br /> Personalized Demo
                    </div>
                    <div className="text-gray text-[14px] sm:text-start text-center leading-[24px] max-w-[407px]">
                        Ready to see how our platform can revolutionize your advertising strategy? Sign up for a
                        personalized demo today! Our team of experts will walk you through the platform's features and
                        capabilities, tailored specifically to your business needs. We'll show you how our innovative
                        targeting and optimization tools can help you reach your target audience more effectively,
                        driving engagement and conversions.
                    </div>
                </div>
            </div>
            <div className="right w-[100%] xl:w-[auto]">
                <form
                    className="p-[20px] rounded-[20px] shadow-outer w-[100%] xl:w-[400px] h-auto"
                    onSubmit={handleSubmit}
                >
                    <div className="sm:text-2xl text-xl  text-center tracking-tight xs:tracking-tighter  leading-[42px] font-bold text-shadow-outer mb-[10px] font-sen">
                        Request a FREE Demo
                    </div>
                    <div className="mb-[10px]">
                        <InputText
                            placeholder="Full Name"
                            rounded
                            onChange={handleChange}
                            value={values.full_name}
                            error={errors.full_name}
                            name="full_name"
                        />
                    </div>
                    <div className="mb-[10px]">
                        <InputText
                            placeholder="Email Address"
                            rounded
                            onChange={handleChange}
                            value={values.email_address}
                            error={errors.email_address}
                            name="email_address"
                        />
                    </div>
                    <div className="mb-[10px]">
                        <InputText
                            placeholder="Phone Number"
                            rounded
                            onChange={handleChange}
                            value={values.phone_number}
                            error={errors.phone_number}
                            name="phone_number"
                        />
                    </div>
                    <div className="mb-[10px]">
                        <InputText
                            placeholder="Company Name"
                            rounded
                            onChange={handleChange}
                            value={values.company_name}
                            error={errors.company_name}
                            name="company_name"
                        />
                    </div>
                    <div className="mb-[20px]">
                        {/* <InputText
                            placeholder="Country"
                            rounded
                            onChange={handleChange}
                            value={values.country}
                            error={errors.country}
                            name="country"
                        /> */}

                        <SelectSearch onSelectCountry={(value) => setFieldValue('country', value.name)} />
                    </div>
                    <Button className="w-full py-[15px] px-5" type="submit">
                        Submit
                    </Button>
                </form>
            </div>
        </section>
    );
};

export default forwardRef(GetStarted);
