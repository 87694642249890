import { Button } from 'components/Button';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import { APP_ROLE } from 'constants/auth';
import { useAdCanva } from 'context/AdCanvaContext';
import { useAuth } from 'hooks';
import React from 'react';

function DesignerInProgress() {
    const { setStep } = useAdCanva();

    const { currentRole } = useAuth();
    return (
        <div className="flex flex-col gap-5">
            <SubTitle>{currentRole === APP_ROLE.Si ? 'In Progress' : 'Upload Creatives'}</SubTitle>
            <Text size="14">
                Lacinia eget in eget molestie aliquam egestas proin. At diam tincidunt pellentesque orci. Tellus lorem
                nunc ornare fames lectus. Morbi pulvinar bibendum sed enim enim turpis sit aliquam. Proin nibh cursus
                massa elementum.
            </Text>
            <Button color="secondary" onClick={() => setStep((prev) => prev - 1)}>
                Back
            </Button>
        </div>
    );
}

export default DesignerInProgress;
