import { IMAGE, SVG } from 'assets/imagePath';
import PostsForm from 'components/AccountConfig/Education/PostsForm';
import { Button } from 'components/Button';
import Dropdown from 'components/Dropdown';
import { HeaderNavigate } from 'components/HeaderNavigate';
import Image from 'components/Image';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';

const ActionArea = () => {
    return (
        <div className="flex flex-row gap-5">
            <Dropdown
                options={
                    <ul className="flex flex-col z-50 divide-y-[1px] divide-blue-cfd">
                        <li className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer">
                            <div className="flex flex-row items-center gap-[5px] flex-1">
                                <span className="text-sm text-blue-dark">Agency Account</span>
                            </div>
                        </li>
                    </ul>
                }
            >
                <Button className={'px-[15px]'} color="secondary">
                    <div className=" flex flex-row gap-[5px] items-center">
                        <div>Status</div>
                        <Image src={SVG.chevron_down} />
                    </div>
                </Button>
            </Dropdown>
            <Button type="submit" color="primary">
                Invite
            </Button>
        </div>
    );
};

function EditPost() {
    useEffect(() => {
        //Call API get post detail
        //setImage(data?.image_post)
    }, []);

    const [image, setImage] = useState(IMAGE.post_image);

    const initialValues = {
        //...postDetail

        title: 'Tilte',
        media_type: 'media',
        post_category: 'category',
        type: 'internal',
        audience: 'all',
    };

    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                    }, 0);
                }}
            >
                <Form>
                    <HeaderNavigate
                        className={'mb-[30px]'}
                        detailInfo={{ detailName: 'Post', detailId: '#1234', chipLabel: 'Draft' }}
                        chipProps={{ color: 'white' }}
                        ActionArea={<ActionArea />}
                    />

                    <PostsForm image={image} setImage={setImage} />
                </Form>
            </Formik>
        </div>
    );
}

export default EditPost;
