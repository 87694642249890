import React, { useState } from 'react';
import { Line, Chart } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
} from 'chart.js';
import { kFormatter } from 'utils';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler);

const AreaChart = () => {
    const labels = [
        'Feb 2',
        'Feb 4',
        'Feb 6',
        'Feb 8',
        'Feb 10',
        'Feb 12',
        'Feb 14',
        'Feb 16',
        'Feb 18',
        'Feb 20',
        'Feb 22',

        'Feb 24',
        'Feb 28',

        'March 2',
        'March 4',
        'March 6',
        'March 8',
        'March 10',
        'March 12',
        'March 14',
        'March 16',
        'March 18',
        'March 20',
        'March 22',

        'March 2',
        'March 4',
        'March 6',
        'March 8',
        'March 10',
        'March 12',
        'March 14',
        'March 16',
        'March 18',
        'March 20',
        'March 22',

        'March 24',
        'March 26',
        'March 20',
        'March 22',

        'March 24',
        'March 26',

        'March 28',
        'March 28',
        'March 30',
        'Apr 3',
    ];

    const dataRandom = labels.map((item) => Math.floor(Math.random() * 1500) + 1500);
    const dataRandom2 = labels.map((item) => Math.floor(Math.random() * 1000) + 100);
    const data = {
        labels,
        datasets: [
            {
                label: 'Line 1',
                data: dataRandom,
                fill: false,
                borderWidth: 2,
                pointRadius: 0,
                borderColor: 'rgba(14, 101, 241, 1)',
            },
            {
                label: 'Line 2',
                data: dataRandom2,
                backgroundColor: '#bbd2f6',
                fill: '-1',
                borderWidth: 2,
                pointRadius: 0,
                borderColor: 'rgba(14, 101, 241, .35)',
            },
        ],
    };

    const opt = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            line: {
                tension: 0.4,
            },
        },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        plugins: {
            tooltip: {
                yAlign: 'bottom',

                displayColors: false,

                backgroundColor: 'white',
                titleAlign: 'center',
                titleFont: {
                    color: 'black',
                },
                titleColor: 'black',
                titleMarginBottom: 0,
                callbacks: {
                    label: () => '',
                    title: (value) => {
                        return '$' + (value[0]?.raw - value[1]?.raw);
                    },
                    footer: (tooltipItems) => {
                        return '';
                    },
                },
            },
        },

        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 20,
                    stepSize: 0.2,
                    font: {
                        size: 10,
                    },
                    minRotation: 45,
                    color: '#1D2533',
                },
            },
            y: {
                grid: {
                    display: false,
                },
                suggestedMin: 0,
                suggestedMax: 5000,
                ticks: {
                    callback: function (value, index, ticks) {
                        return kFormatter(value);
                    },
                    stepSize: 2500,
                    font: {
                        size: 10,
                    },
                    color: '#1D2533',
                    padding: 0,
                },
            },
            xAxes: [
                {
                    ticks: {
                        autoSkip: false,
                    },
                },
            ],
        },
    };
    return (
        <div>
            <Line data={data} options={opt} />
        </div>
    );
};

export default AreaChart;
