import clsx from 'clsx';
import React from 'react';

const ProgressCircle = ({
    progress,
    size,
    strokeWidth,
    sizeClass,
    ContentValue,
    circleColor,
    circleOneStroke = '#f2f4f6',
    className,
}) => {
    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference * (1 - progress / 100);

    return (
        <div className="relative max-w-fit">
            <svg
                className={clsx(`w-${sizeClass} h-${sizeClass} -rotate-90  rounded-full`, className)}
                viewBox={`0 0 ${size} ${size}`}
            >
                <circle
                    className={clsx('text-white-light')}
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    stroke={circleOneStroke}
                    fill="none"
                />
                <circle
                    className="text-primary"
                    cx={center}
                    cy={center}
                    r={radius}
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap=""
                    fill="none"
                    stroke={circleColor}
                />
            </svg>
            <div className="absolute w-full h-full top-1/2 right-1/2 translate-x-[50%] -translate-y-[50%] z-50 p-5">
                {ContentValue}
            </div>
        </div>
    );
};

export default ProgressCircle;
