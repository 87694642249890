import clsx from 'clsx';
import Box from 'components/Box/Box';
import { FilterButton, SearchBox } from 'components/FilterTools';
import { Checkbox } from 'components/Input';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import { Tree } from 'components/Tree';
import { PARTNERS } from 'constants/partner';
import { useState } from 'react';
import SelectedSide from './components/SelectedSide';

const data = [
    {
        title: <Text>NavEgg</Text>,
        key: '0-0',
        children: [
            {
                title: <Text className="px-2.5">Age</Text>,
                key: '0-0-0',
                children: [
                    {
                        title: (
                            <div className="pl-[30px] md:pl-[45px] pr-[25px] py-[15px] flex justify-between items-center border-b border-blue-cfd">
                                <Checkbox Label={<Text>NavEgg: Age 15-20 (NID)</Text>} />
                                <Svg className="h-[15px] w-[15px] child:fill-warning" name="star" />
                            </div>
                        ),
                        key: '0-0-0-0',
                    },
                    {
                        title: (
                            <div className="pl-[30px] md:pl-[45px] pr-[25px] py-[15px] flex justify-between items-center">
                                <Checkbox Label={<Text>NavEgg: Age 15-20 (NID)</Text>} />
                                <Svg className="h-[15px] w-[15px]" name="star" />
                            </div>
                        ),
                        key: '0-0-0-1',
                    },
                ],
            },
        ],
    },
];

function AudienceSegment() {
    const [step, setStep] = useState(1);
    return (
        <div className="flex flex-col gap-[10px]">
            <div className="mb-[5px]">
                <SubTitle className="!text-lg md:!text-lg">Audience Segments</SubTitle>
                <Text className="!text-sm md:!text-sm">Placeholder</Text>
            </div>

            <div className="flex items-center flex-row gap-5">
                <FilterButton />
                <SearchBox />
            </div>

            {step === 1 && (
                <Box
                    className="h-[271px] md:h-[570px] w-full bg-white-light overflow-auto grid-cols-1 grid xs:grid-cols-3 md:grid-cols-1 min-[800px]:grid-cols-2 lg:grid-cols-3 gap-2.5 p-5"
                    variant="contained"
                >
                    {PARTNERS.map((item) => (
                        <Box
                            key={item.id}
                            variant="contained"
                            className="flex items-center justify-center gap-5 bg-white h-[150px] cursor-pointer"
                            onClick={() => setStep(2)}
                        >
                            <img className="object-contain w-[150px]" src={item.img} alt="" />
                        </Box>
                    ))}
                </Box>
            )}

            {step === 2 && (
                <Box className="md:h-[570px] w-full bg-white-light overflow-auto gap-2.5 p-5" variant="contained">
                    <Tree
                        className={
                            'grid w-full grid-cols-1 divide-y-2 h-auto border border-blue-cfd rounded-[10px] bg-white'
                        }
                        treeData={data}
                        collapseProps={{
                            renderButton: ({ title, toggle, isOpen }) => {
                                return (
                                    <button
                                        className={clsx(
                                            'w-full  flex flex-row gap-2.5 justify-between border-b border-blue-cfd  h-[50px] items-center px-5 py-4',
                                        )}
                                        type="button"
                                        onClick={toggle}
                                    >
                                        <Text>{title}</Text>

                                        <Svg
                                            name="chevronDown"
                                            className={clsx(
                                                'transition-all w-full max-w-[20px] h-[20px] child:fill-black',
                                                isOpen ? '' : 'rotate-180',
                                            )}
                                        />
                                    </button>
                                );
                            },
                        }}
                    />
                </Box>
            )}
        </div>
    );
}

export default AudienceSegment;
