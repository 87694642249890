import { IMAGE, SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Image from 'components/Image/Image';
import Text from 'components/Text/Text';
import React from 'react';

function GenerativeButton({ label, className, ...rest }) {
    return (
        <div className={clsx('flex flex-row gap-[5px] items-center', className)} {...rest}>
            <Image className={'h-auto w-full aspect-square max-w-[24px] object-contain'} src={IMAGE.generate} />

            <Text underline>{label}</Text>
        </div>
    );
}

export default GenerativeButton;
