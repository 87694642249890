import { Button } from 'components/Button';
import Text from 'components/Text/Text';
import FormInputText from 'components/modules/form/FormInputText';
import FormTextArea from 'components/modules/form/FormTextArea';
import FormUpload from 'components/modules/form/FormUpload';
import { openai } from 'config/openAi';

import { Form, useFormikContext } from 'formik';
import { useState } from 'react';

function GenerateImage({ type }) {
    const { setValues, values, handleSubmit } = useFormikContext();

    function fakeGenerate() {
        return new Promise((resolve, reject) => {
            const img = `https://picsum.photos/520/345?random=${Math.floor(Math.random() * 101)}`;
            setTimeout(() => {
                resolve({
                    desc: 'Roof with contractors working on it',
                    img,
                });
            }, 500);
        });
    }

    const handleAutoGenerate = async () => {
        try {
            const res = await fakeGenerate();
            setValues({
                generateDesc: res.desc,
                generateImg: {
                    preview: res.img,
                },
            });
            setIsGenerated(true);
        } catch (error) {}
    };

    const [isGenerated, setIsGenerated] = useState(false);

    const [loading, setLoading] = useState(false);
    const generateImage = async () => {
        setLoading(true);

        try {
            const res = await openai.createImage({
                prompt: values.generateDesc,
                n: 1,
                size: '512x512',
            });

            setValues({
                generateDesc: values.generateDesc,
                generateImg: {
                    preview: res.data.data[0].url,
                },
            });

            setLoading(false);
            setIsGenerated(true);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Form className="flex flex-col gap-5">
            <Text size="14">Please describe the image you would like to see on background.</Text>

            <FormInputText name="generateDesc" placeholder="Description" />

            <FormUpload loading={loading} className="h-[345px]" readOnly name="generateImg" />

            {isGenerated ? (
                <div className="flex flex-row gap-5">
                    <Button color="secondary" onClick={generateImage} className={'flex-1'} type="button">
                        Regenerate
                    </Button>
                    <Button onClick={handleSubmit} className={'flex-1'} type="button">
                        Use
                    </Button>
                </div>
            ) : (
                <div className="flex flex-row gap-5">
                    <Button onClick={generateImage} className={'flex-1'} type="button">
                        Generate
                    </Button>
                </div>
            )}
        </Form>
    );
}

export default GenerateImage;
