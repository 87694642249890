import { Button } from 'components/Button';
import Text from 'components/Text/Text';
import FormInputText from 'components/modules/form/FormInputText';
import { Form, Formik } from 'formik';
import React from 'react';

function ResetPwEmailConfirm({ setStep }) {
    return (
        <div>
            <Text className="mb-5 text-center" weight="bold" size="24">
                Reset Password
            </Text>

            {/* <Text weight="bold" className="mb-5 text-center text-error">
                    {err}
                </Text> */}

            <Formik
                className=""
                initialValues={{}}
                onSubmit={(values, actions) => {
                    setStep(2);
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                    }, 0);
                }}
            >
                <Form>
                    <FormInputText className="mb-[20px]" placeholder="Email" rounded name="email" />

                    <Button
                        type="submit"
                        className="mx-auto flex  justify-center w-full max-w-[260px] py-[15px] px-5 mb-[20px] shadow-outer"
                    >
                        Reset Password
                    </Button>
                </Form>
            </Formik>
        </div>
    );
}

export default ResetPwEmailConfirm;
