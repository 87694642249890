import { IMAGE, SVG } from 'assets/imagePath';
import Box from 'components/Box';
import Dropdown from 'components/Dropdown';
import { PaymentModule } from 'components/GlobalModules';
import Image from 'components/Image';
import { InputText } from 'components/Input';
import { Modal } from 'components/Modal';
import { APP_ROLE } from 'constants/auth';
import { BREAKPOINT } from 'constants/breakpoints';
import { AUTH_KEY } from 'constants/localStorageKey';
import { COMPANY_LIST, MENU_COMPANY, MENU_USER } from 'constants/menu';
import { useViewport } from 'context/ViewportContext';
import { useNavigateSearch } from 'hooks';
import useLocalStorage from 'hooks/useLocalStorage';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { getCurrentRole, isPermissionView } from 'utils';
import TopNavUserBox from './TopNavUserBox';

const TopNav = () => {
    const CURRENT_ROLE = getCurrentRole();
    const navigateSearch = useNavigateSearch();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const [auth, setAuth] = useLocalStorage(AUTH_KEY);
    const { width } = useViewport();

    const logoImage = CURRENT_ROLE === APP_ROLE.SubCo || CURRENT_ROLE === APP_ROLE.Ag ? SVG.logo_agency : SVG.logo;
    const logoImageMobile =
        CURRENT_ROLE === APP_ROLE.SubCo || CURRENT_ROLE === APP_ROLE.Ag ? IMAGE.agency_default : SVG.logo_only;

    const handleOnClickCompanyMenu = (menuId) => {
        switch (menuId) {
            case 'company_account':
                navigateSearch(
                    CURRENT_ROLE === APP_ROLE.SubCo ? routesPath.account_subCo : routesPath.account_company,
                    { config: 'profile' },
                );
                break;
            case 'agency':
                navigateSearch(routesPath.account_agency, { config: 'profile' });
                break;
            case 'organization_account':
                navigateSearch(routesPath.account_organization, { config: 'profile' });
                break;
            case 'upgrade':
                setIsOpen(true);
                break;
            default:
                return;
        }
    };

    const handleOnClickMenuUser = (menuId) => {
        switch (menuId) {
            case 'log_out': {
                (() => {
                    setAuth({});
                    navigate(0);
                })();
                break;
            }
            case 'user_account': {
                (() => {
                    navigate(routesPath.account_single_user);
                })();
                break;
            }

            default:
                return;
        }
    };
    return (
        <>
            <div className="w-full h-full py-[10px] px-2.5 bg-blue-ebe border-b border-blue-cfd flex flex-row justify-between md:justify-start items-center gap-x-2.5 ">
                {/* Logo */}
                <div className="flex justify-center items-center mr-[13px]  lg:mx-[55px] max-w-[47px] w-full  lg:max-w-[150px]  h-[47px]">
                    <img
                        src={width >= BREAKPOINT.lg ? logoImage : logoImageMobile}
                        alt="logo"
                        className="w-full cursor-pointer  h-[auto] object-contain"
                        onClick={() => navigate('/')}
                    />
                </div>

                <div className="flex-1 hidden md:block">
                    <InputText
                        className="!bg-transparent  border border-blue-cfd  shadow-none "
                        icon={SVG.search}
                        placeholder="Search"
                    />
                </div>

                <Box className="hidden lg:flex flex-row gap-x-[5px] items-stretch h-full  p-[15px]  border border-blue-cfd ">
                    <img src={SVG.bell} className="object-cover" alt="bell" />

                    <p className="w-[17px] h-[17px] flex-1 bg-primary rounded-full text-white text-xs font-bold flex items-center justify-center">
                        2
                    </p>
                </Box>
                <div className="flex flex-start gap-[5px] xxs:gap-x-2.5 items-center ">
                    <Box className="p-[12.5px] xxs:p-[17.5px] md:hidden  flex justify-center items-center">
                        <Image className="object-contain h-[15px] w-[15px]" src={SVG.search} />
                    </Box>
                    {(CURRENT_ROLE === APP_ROLE.SubCo || CURRENT_ROLE === APP_ROLE.Co) && (
                        <Dropdown
                            options={
                                <ul className="flex flex-col  divide-y-[1px] divide-blue-cfd">
                                    {MENU_COMPANY.map((menu) => {
                                        const isHide = menu?.permissions && !isPermissionView(menu.permissions);
                                        return (
                                            !isHide && (
                                                <li
                                                    className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer"
                                                    key={menu.id}
                                                    onClick={() => handleOnClickCompanyMenu(menu?.id)}
                                                >
                                                    <div className="flex flex-row items-center gap-[5px] flex-1">
                                                        <span className="text-sm text-blue-dark">{menu.label}</span>
                                                    </div>
                                                </li>
                                            )
                                        );
                                    })}
                                </ul>
                            }
                        >
                            <TopNavUserBox
                                image={IMAGE.company_default}
                                info={{ name: 'Company Name', role: '1423' }}
                            />
                        </Dropdown>
                    )}
                    {CURRENT_ROLE === 'Org' && (
                        <Dropdown
                            className={'!min-w-[220px]'}
                            options={({ close }) => (
                                <ul className="flex flex-col z-50 divide-y-[1px] divide-blue-cfd">
                                    <li
                                        className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer"
                                        onClick={() => {
                                            handleOnClickCompanyMenu('organization_account');
                                            close();
                                        }}
                                    >
                                        <div className="flex flex-row items-center gap-[5px] flex-1">
                                            <span className="text-sm text-blue-dark whitespace-nowrap">
                                                Organization Account
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            )}
                        >
                            <Box className="p-[12.5px] xxs:p-[17.5px] w-full h-full  flex justify-center items-center">
                                <Image className="object-contain h-[15px] w-[15px]" src={SVG.nucleus_icon} />
                            </Box>
                        </Dropdown>
                    )}
                    {(CURRENT_ROLE === 'Ag' || CURRENT_ROLE === 'Org') && (
                        <Dropdown
                            options={
                                <ul className="flex flex-col z-50 divide-y-[1px] divide-blue-cfd">
                                    <li
                                        className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer"
                                        onClick={() => handleOnClickCompanyMenu('agency')}
                                    >
                                        <div className="flex flex-row items-center gap-[5px] flex-1">
                                            <span className="text-sm text-blue-dark">Agency Account</span>
                                        </div>
                                    </li>
                                </ul>
                            }
                        >
                            <TopNavUserBox image={IMAGE.agency_default} info={{ name: 'Top Agency', role: '234242' }} />
                        </Dropdown>
                    )}
                    {(CURRENT_ROLE === 'Ag' || CURRENT_ROLE === 'Org') && (
                        <Dropdown
                            options={
                                <ul className="flex flex-col z-50 divide-y-[1px] divide-blue-cfd">
                                    <li
                                        className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer"
                                        onClick={() => handleOnClickCompanyMenu('company_account')}
                                    >
                                        <div className="flex flex-row items-center gap-[5px] flex-1">
                                            <span className="text-sm text-blue-dark">Company Account</span>
                                        </div>
                                    </li>
                                    <li className="flex flex-row  items-center px-[15px] py-[11.5px] hover:bg-blue-ebe cursor-pointer">
                                        <div className="flex flex-row  items-center gap-[5px] flex-1">
                                            <div className="relative flex-1">
                                                <InputText
                                                    className="!bg-transparent border-none shadow-none !p-0 placeholder:text-blue-dark"
                                                    placeholder="Search"
                                                />
                                                <img
                                                    src={SVG.search}
                                                    alt="search"
                                                    className="absolute inset-y-0 right-0"
                                                />
                                            </div>
                                        </div>
                                    </li>
                                    <ul className="flex flex-col   max-h-[180px] overflow-auto z-50 divide-y-[1px] divide-blue-cfd">
                                        {COMPANY_LIST.map(
                                            (menu) =>
                                                !menu.isHide && (
                                                    <li
                                                        className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer"
                                                        key={menu.id}
                                                        onClick={() => handleOnClickCompanyMenu(menu.id)}
                                                    >
                                                        <div className="flex flex-row items-center gap-[5px] flex-1">
                                                            <span className="text-sm text-blue-dark">{menu.label}</span>
                                                        </div>
                                                    </li>
                                                ),
                                        )}
                                    </ul>
                                </ul>
                            }
                        >
                            <TopNavUserBox image={IMAGE.binoculars} info={{ name: 'Global Overview' }} />
                        </Dropdown>
                    )}
                    <Dropdown
                        options={
                            <ul className="flex flex-col divide-y-[1px] divide-blue-cfd">
                                {MENU_USER.map((menu) => (
                                    <li
                                        className="flex flex-row  items-center px-[15px] py-[11.5px] hover:bg-blue-ebe cursor-pointer"
                                        key={menu.id}
                                        onClick={() => handleOnClickMenuUser(menu.id)}
                                    >
                                        <div className="flex flex-row items-center gap-[5px] flex-1">
                                            <span className="text-sm text-blue-dark">{menu.label}</span>
                                        </div>

                                        {menu?.renderComponent ? menu.renderComponent() : undefined}
                                    </li>
                                ))}
                            </ul>
                        }
                    >
                        <TopNavUserBox info={{ name: 'User Name', role: 'User role' }} />
                    </Dropdown>
                </div>
            </div>
            <Modal
                bodyClass={'max-w-[800px] pb-[30px]'}
                title="Payments"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <PaymentModule />
            </Modal>
        </>
    );
};

export default TopNav;
