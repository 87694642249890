import { CardContent } from 'components/Card';
import Text from 'components/Text/Text';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function CompanySubscriptions() {
    const navigate = useNavigate();
    return (
        <div className="box">
            <Text className="mb-5 pt-7" size="18" weight="bold">
                Subscriptions
            </Text>
            <CardContent
                className={'max-w-[210px] w-full'}
                title={'Plan'}
                content={'Company'}
                actionText={'Change'}
                onAction={() => navigate(routesPath.upgrade_company_plan)}
            />
        </div>
    );
}

export default CompanySubscriptions;
