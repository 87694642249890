import { SVG } from 'assets/imagePath';
import Chip from 'components/Chip/Chip';
import ActionsButton from 'components/GridTable/ActionsButton';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

export const paymentColumn = [
    {
        field: 'id',

        headerName: 'ID',
        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id</li>
        //             </ul>
        //         );
        //     },
        // },
    },
    {
        field: 'service',

        headerName: 'Service',
    },
    {
        field: 'type',

        headerName: 'Type',
    },
    {
        field: 'method',

        headerName: 'Method',
    },
    {
        field: 'status',
        headerName: 'Status',

        renderCell: (row) => {
            return (
                <Chip className={'ml-[15px]'} color="success">
                    {row?.status}
                </Chip>
            );
        },
    },
    {
        field: 'amount',

        headerName: 'Amount',
    },

    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row, handle) => {
            return (
                <ActionsButton
                    onChange={(value) => {
                        handle(row, value);
                    }}
                />
            );
        },
    },
];

export const paymentRow = [
    {
        id: 324,
        service: 'Campaign 4',
        type: 'FIAT',
        method: 'Card',
        status: 'Paid',
        timestamp: '307 days 6 hrs ago',
        amount: '$345',
    },
    {
        id: 324,
        service: 'Campaign 4',
        type: 'FIAT',
        method: 'Card',
        status: 'Paid',
        timestamp: '307 days 6 hrs ago',
        amount: '$345',
    },
    {
        id: 324,
        service: 'Campaign 4',
        type: 'FIAT',
        method: 'Card',
        status: 'Paid',
        timestamp: '307 days 6 hrs ago',
        amount: '$345',
    },
    {
        id: 324,
        service: 'Campaign 4',
        type: 'FIAT',
        method: 'Card',
        status: 'Paid',
        timestamp: '307 days 6 hrs ago',
        amount: '$345',
    },
    {
        id: 324,
        service: 'Campaign 4',
        type: 'FIAT',
        method: 'Card',
        status: 'Paid',
        timestamp: '307 days 6 hrs ago',
        amount: '$345',
    },
    {
        id: 324,
        service: 'Campaign 4',
        type: 'FIAT',
        method: 'Card',
        status: 'Paid',
        timestamp: '307 days 6 hrs ago',
        amount: '$345',
    },
    {
        id: 324,
        service: 'Campaign 4',
        type: 'FIAT',
        method: 'Card',
        status: 'Paid',
        timestamp: '307 days 6 hrs ago',
        amount: '$345',
    },
    {
        id: 324,
        service: 'Campaign 4',
        type: 'FIAT',
        method: 'Card',
        status: 'Paid',
        timestamp: '307 days 6 hrs ago',
        amount: '$345',
    },
    {
        id: 324,
        service: 'Campaign 4',
        type: 'FIAT',
        method: 'Card',
        status: 'Paid',
        timestamp: '307 days 6 hrs ago',
        amount: '$345',
    },
    {
        id: 324,
        service: 'Campaign 4',
        type: 'FIAT',
        method: 'Card',
        status: 'Paid',
        timestamp: '307 days 6 hrs ago',
        amount: '$345',
    },
];
