import { SVG } from 'assets/imagePath';
import { CardContent, CardNumber } from 'components/Card';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import AccountEmail from './AccountEmail';
import DeactivateAccount from './DeactivateAccount';
import ChangePassword from './ChangePassword';
import Signatures from './Signatures';

function SettingsAgency() {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col w-full gap-y-[30px] max-w-[820px]">
            {/* <div className="grid grid-flow-row grid-cols-1 gap-5 xxs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-3"> */}
            <div className="flex flex-row flex-wrap gap-5">
                <CardNumber
                    className={'w-full  xs:max-w-[220px]'}
                    title={'Markup'}
                    value={'25%'}
                    actionText={'Manage'}
                    icon={SVG.markup_circle}
                    onAction={() => navigate(routesPath.markup)}
                />
                <CardNumber
                    className={' w-full  xs:max-w-[220px]'}
                    title={'Balance'}
                    value={'$12,000'}
                    actionText={'Manage'}
                    icon={SVG.money_circle}
                    onAction={() => navigate(routesPath.balance)}
                />
                <CardContent
                    className={'w-full xs:max-w-[220px]'}
                    title={'Subscriptions'}
                    icon={SVG?.logo_agency_icon}
                    content="Agency"
                    actionText={'Manage'}
                />
                <CardContent
                    className={'w-full xs:max-w-[220px]'}
                    title={'Branding'}
                    icon={SVG?.logo_agency_icon}
                    content="Top Agency"
                    actionText={'Manage'}
                    onAction={() => navigate(routesPath.branding)}
                />
                <CardContent
                    className={'w-full xs:max-w-[220px]'}
                    title={'Extra'}
                    icon={SVG?.extra_circle}
                    content="Embeds"
                    actionText={'Manage'}
                    onAction={() => navigate(routesPath.embed)}
                />
            </div>
            <AccountEmail />
            {/* <ChangePassword /> */}
            <Signatures />
            <DeactivateAccount />
        </div>
    );
}

export default SettingsAgency;
