import { SVG } from 'assets/imagePath';
import Image from 'components/Image';
import Step from 'components/Step';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AcceptInvitation, ConfirmDetails, CreatePassword, VerifyAccount } from './components';

function RegisterCompanyOrganizationUser() {
    const [searchParams] = useSearchParams();

    const step = searchParams.get('step') || '1';

    const navigate = useNavigate();

    return (
        <>
            <Image
                src={SVG.logo}
                onClick={() => navigate('/')}
                className="max-w-[200px] mx-auto mb-[40px] w-full h-auto cursor-pointer"
            />

            {step === '1' && <AcceptInvitation name={'AdNucleus'} />}

            {step === '2' && <ConfirmDetails />}

            {step === '3' && <CreatePassword />}

            {step === '4' && <VerifyAccount />}

            <Step maxStep={4} activeStep={step} />
        </>
    );
}

export default RegisterCompanyOrganizationUser;
