import { Svg } from 'components/Svg';
import { useState } from 'react';

const StarRating = ({ maxStars = 5, sizeStar = 24, onChangeRating, defaultRating = 0 }) => {
    const [rating, setRating] = useState(0);
    const [currentRating, setCurrentRating] = useState(defaultRating);

    const handleClick = (value) => {
        setRating(value);
    };
    const handleRating = () => {
        setCurrentRating(rating);
        onChangeRating && onChangeRating(rating);
    };
    return (
        <div
            className="flex items-center justify-center"
            onMouseLeave={() => {
                setRating(currentRating);
            }}
        >
            {[...Array(maxStars)].map((_, index) => (
                <Svg
                    name="star"
                    key={index}
                    className={`cursor-pointer hover:child:fill-yellow-500 ${
                        index < rating ? 'child:fill-yellow-500' : 'child:fill-blue-cfd'
                    }`}
                    width={sizeStar}
                    height={sizeStar}
                    onClick={() => handleRating()}
                    onMouseOver={() => handleClick(index + 1)}
                />
            ))}
        </div>
    );
};

export default StarRating;
