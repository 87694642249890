import clsx from 'clsx';

function Step({ maxStep, activeStep, activeClass }) {
    return (
        <div className="mx-auto flex items-center justify-center gap-[5px] py-[5px] px-[10px] w-[120px] h-[15px] shadow-outer rounded-[10px] bg-blue-eae mt-[20px]">
            {Array(maxStep)
                .fill(0)
                .map((_, index) => (
                    <div
                        key={index}
                        className={`rounded-[5px] h-[5px] w-[25px] ${
                            +activeStep === index + 1
                                ? clsx('shadow bg-dark-nav w-[40px]', activeClass)
                                : 'shadow-inner bg-blue-eae w-[25px]'
                        }`}
                    ></div>
                ))}
        </div>
    );
}

export default Step;
