import { IMAGE, SVG } from 'assets/imagePath';
import Image from 'components/Image';
import Text from 'components/Text';

const OurAnalysis = () => {
    return (
        <section className="w-full container mx-auto pt-[30px] px-[20px] xs:pt-[100px]">
            <div className="flex flex-col-reverse xl:flex-row justify-center items-center gap-2.5 md:gap-[60px] xl:gap-[140px] mx-[12px] ">
                <div className="left">
                    <div className="item">
                        <div className="text-lg font-bold sm:text-xl text-dark-nav font-sen">
                            Preview your campaign's <br className="hidden xl:block" /> potential with our analysis
                        </div>
                        <div className="item">
                            <div className="flex items-center gap-[15px] mt-[30px]">
                                <Image
                                    src={SVG.creation_tool}
                                    className="p-[5px] h-[37px] w-[37px] rounded-[50px] bg-white shadow-outer "
                                />

                                <div className="text-sm font-bold sm:text-base text-dark-nav">Ad Creation Tool</div>
                            </div>
                            <Text className="xl:max-w-[465px] mt-[20px] text-xs sm:text-sm leading-[21px] text-gray">
                                Take advantage of our in-depth creative consultation to match your creative assets with
                                your campaign's key performance indicators (KPIs), and optimize your ad creation
                                according to specific platform capabilities.
                            </Text>
                        </div>
                    </div>

                    <div className="item">
                        <div className="flex items-center gap-[15px] mt-[30px]">
                            <Image
                                src={SVG.planning_tool}
                                className="p-[5px] h-[37px] w-[37px] rounded-[50px] bg-white shadow-outer "
                            />

                            <div className="font-bold text-sm sm:text-base leading-[24px] text-dark-nav">
                                Planning Tool
                            </div>
                        </div>
                        <Text className="xl:max-w-[465px] mt-[20px] text-xs sm:text-sm leading-[21px] text-gray">
                            Our platform generates real-time, data-driven insights to identify high-value opportunities
                            for reaching customers across an optimal media mix.
                        </Text>
                    </div>

                    <div className="item">
                        <div className="flex items-center gap-[15px] mt-[30px]">
                            <Image
                                src={SVG.preview_tool}
                                className="p-[5px] h-[37px] w-[37px] rounded-[50px] bg-white shadow-outer "
                            />

                            <div className="text-sm font-bold sm:text-base text-dark-nav">Ad Preview Tool</div>
                        </div>
                        <Text className="xl:max-w-[465px] mt-[20px] text-xs sm:text-sm  leading-[21px] text-gray">
                            Preview your video, display, and native ads on desktop or mobile devices, covering 23
                            verticals, across 500+ publishers with our easy-to-use interface.
                        </Text>
                    </div>
                </div>
                <div className="right h-auto max-w-[600px]">
                    <Image src={IMAGE.home_1} />
                </div>
            </div>
        </section>
    );
};

export default OurAnalysis;
