import { Footer, GetStarted } from 'components/Public/Home';
import NavBar from 'components/Public/NavBar/NavBar';
import NavBarMobile from 'components/Public/NavBar/NavBarMobile';
import { useViewport } from 'context/ViewportContext';
import { useRef } from 'react';
import { Outlet } from 'react-router-dom';

export const PublicLayout = () => {
    // const { user } = useAuth();

    // if (!user) {
    //   return <Navigate to="/" />;
    // }

    const { width } = useViewport();
    const ref = useRef(null);

    const scrollToView = (elId) => {
        const el = document.getElementById(elId);
        const y = el.getBoundingClientRect().top + window.scrollY;
        window.scroll({
            top: y,
            behavior: 'smooth',
        });
    };

    return (
        <div className="flex flex-col w-full h-full min-h-screen overflow-auto overflow-x-hidden bg-blue-ebe ">
            {width >= 475 ? <NavBar scrollToView={scrollToView} /> : <NavBarMobile scrollToView={scrollToView} />}
            <div className="flex-1 ">
                <Outlet context={{ scrollToView }} />
            </div>
            <GetStarted ref={ref} />
            <Footer />
        </div>
    );
};
