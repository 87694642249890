import AccountEmail from './AccountEmail';
import ChangePassword from './ChangePassword';
import DeleteAccount from './DeleteAccount';
import Signatures from './Signatures';
import UpgradeCompany from './UpgradeCompany';

function SettingsSingleAccount() {
    return (
        <div className="flex flex-col gap-y-[30px] max-w-[820px]">
            <UpgradeCompany />
            <AccountEmail type={'user'} />
            <ChangePassword />
            <Signatures />
            <DeleteAccount />
        </div>
    );
}

export default SettingsSingleAccount;
