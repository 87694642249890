import { Button } from 'components/Button';
import { InputText, Select } from 'components/Input';
import { useFormik } from 'formik';
import { useNavigateSearch } from 'hooks';
import { useLocation } from 'react-router-dom';
import { createAccountSchema } from '../registerSchema';

const useCaseOptions = [
    {
        value: 'personal',
        label: 'Personal',
    },
    {
        value: 'business',
        label: 'Business',
    },
];

function CreateAccount() {
    const { pathname } = useLocation();
    const navigateSearch = useNavigateSearch();

    const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: {
            email: '',
            first_name: '',
            last_name: '',
            street_address: '',
            suite_or_apartment: '',
            city: '',
            zip_code: '',
            accept_term: false,
            company_name: '',
            business_street_address: '',
            business_suite_or_apartment: '',
            business_city: '',
            business_zip_code: '',
        },
        onSubmit: (values) => {
            console.log(values);
            navigateSearch(pathname, { step: '2' });
        },
        validationSchema: createAccountSchema,
    });

    return (
        <>
            <div className="text-center font-bold text-[24px] leading-[29px] text-dark-nav mb-[30px]">
                Create Account
            </div>

            <form onSubmit={handleSubmit}>
                <div className="mb-[20px]">
                    <InputText
                        placeholder="Email"
                        rounded
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        error={errors.email}
                    />
                </div>

                <div className="flex gap-[20px] mb-[20px]">
                    <InputText
                        placeholder="First Name"
                        rounded
                        name="first_name"
                        onChange={handleChange}
                        value={values.first_name}
                        error={errors.first_name}
                    />
                    <InputText
                        placeholder="Last Name"
                        rounded
                        name="last_name"
                        onChange={handleChange}
                        value={values.last_name}
                        error={errors.last_name}
                    />
                </div>

                <div className="mb-[20px]">
                    <InputText
                        placeholder="Street Address"
                        rounded
                        name="street_address"
                        onChange={handleChange}
                        value={values.street_address}
                        error={errors.street_address}
                    />
                </div>
                <div className="mb-[20px]">
                    <InputText
                        placeholder="Suite or Apartment"
                        rounded
                        name="suite_or_apartment"
                        onChange={handleChange}
                        value={values.suite_or_apartment}
                        error={errors.suite_or_apartment}
                    />
                </div>
                <div className="flex gap-[20px] mb-[20px]">
                    <InputText
                        placeholder="City"
                        rounded
                        name="city"
                        onChange={handleChange}
                        value={values.city}
                        error={errors.city}
                    />
                    <InputText
                        placeholder="Zip Code"
                        rounded
                        name="zip_code"
                        onChange={handleChange}
                        value={values.zip_code}
                        error={errors.zip_code}
                    />
                </div>

                <Select
                    placeholder="Select Use Case"
                    placeholderTop
                    options={useCaseOptions}
                    className="mb-[20px] justify-between "
                    setValue={setFieldValue}
                    value={values.select_use_case}
                    name="select_use_case"
                />

                {values.select_use_case === 'personal' && (
                    <div
                        className="flex items-center gap-[10px] mb-[20px]"
                        onClick={() => setFieldValue('accept_term', !values.accept_term)}
                    >
                        <div className="w-[20px] h-[20px] shadow-default  bg-white-light flex items-center justify-center rounded-[5px] cursor-pointer">
                            <div
                                className={`w-[10px] h-[10px]  rounded-[3px] shadow-check-box ${
                                    values.accept_term ? 'bg-blue-0e6' : 'bg-blue-cfd'
                                }`}
                            ></div>
                        </div>
                        <span className="select-none cursor-pointer text-[14px] leading-[15px] font-[400px]">
                            Accept <u>Terms & Conditions</u>
                        </span>
                    </div>
                )}

                {values.select_use_case === 'business' && (
                    <div className="business-form">
                        <div className="text-center font-bold text-[18px] leading-[22px] text-dark-nav mt-[40px] mb-[20px]">
                            Business Details
                        </div>

                        <div className="mb-[20px]">
                            <InputText
                                placeholder="Company Name"
                                rounded
                                name="company_name"
                                onChange={handleChange}
                                value={values.company_name}
                                error={errors.company_name}
                            />
                        </div>

                        <div className="mb-[20px]">
                            <InputText
                                placeholder="street_address"
                                rounded
                                name="business_street_address"
                                onChange={handleChange}
                                value={values.business_street_address}
                                error={errors.business_street_address}
                            />
                        </div>

                        <div className="mb-[20px]">
                            <InputText
                                placeholder="Suite or Apartment"
                                rounded
                                name="business_suite_or_apartment"
                                onChange={handleChange}
                                value={values.business_suite_or_apartment}
                                error={errors.business_suite_or_apartment}
                            />
                        </div>

                        <div className="flex gap-[20px] mb-[20px]">
                            <InputText
                                placeholder="City"
                                rounded
                                name="business_city"
                                onChange={handleChange}
                                value={values.business_city}
                                error={errors.business_city}
                            />
                            <InputText
                                placeholder="ZIP Code"
                                rounded
                                name="business_zip_code"
                                onChange={handleChange}
                                value={values.business_zip_code}
                                error={errors.business_zip_code}
                            />
                        </div>
                    </div>
                )}
                <Button className=" flex  justify-center mx-auto w-[260px] py-[15px] px-5 shadow-outer" type="submit">
                    Next
                </Button>
            </form>
        </>
    );
}

export default CreateAccount;
