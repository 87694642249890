import { DetailBox, DetailBox2 } from 'components/DetailBox';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import { useFormikContext } from 'formik';

function CanvasRequestInfo() {
    const { values } = useFormikContext();

    const column = [
        {
            field: 'sizes',
            headerName: 'Sizes',
            hideDetail: !values.sizes || values?.sizes?.length === 0,
            renderCell: (params) => {
                return (
                    <Text className="w-full !text-xs md:!text-xs" size="12" ellipsis>
                        {params?.sizes?.map((item) => item.label).join(', ')}
                    </Text>
                );
            },
        },
        {
            field: 'logo',
            headerName: 'Logo',
            hideDetail: !values.logo?.desc,
            renderCell: (params) => {
                return (
                    <Text className="w-full !text-xs md:!text-xs" size="12" ellipsis>
                        {params.logo?.desc}
                    </Text>
                );
            },
        },
        {
            field: 'images',
            headerName: 'Images',
            hideDetail: !values.background?.desc,
            renderCell: (params) => {
                return (
                    <Text className="w-full !text-xs md:!text-xs" size="12" ellipsis>
                        {params.background?.desc}
                    </Text>
                );
            },
        },
        {
            field: 'title',
            headerName: 'Title',
            hideDetail: !values.title?.text,
            renderCell: (params) => {
                return (
                    <Text className="w-full !text-xs md:!text-xs" size="12">
                        {params?.title?.text}
                    </Text>
                );
            },
        },
        {
            field: 'subTitle',
            headerName: 'Subtitle',
            hideDetail: !values.subTitle?.text,
            renderCell: (params) => {
                return (
                    <Text className="w-full !text-xs md:!text-xs" size="12" ellipsis>
                        {params?.subTitle?.text}
                    </Text>
                );
            },
        },
        {
            field: 'callAction',
            headerName: 'Call to action',
            hideDetail: !values.callAction?.text,
            renderCell: (params) => {
                return (
                    <Text className="w-full !text-xs md:!text-xs" size="12" ellipsis>
                        {params?.callAction?.text}
                    </Text>
                );
            },
        },
        {
            field: 'base',
            headerName: 'Base color',
            hideDetail: !values.base,
        },
        {
            field: 'accent',
            headerName: 'Accent color',
            hideDetail: !values.accent,
        },
        {
            field: 'name',
            headerName: 'Name',
            hideDetail: !values.name,
        },
    ];

    return (
        <div className="flex flex-col gap-2.5">
            <SubTitle>Request</SubTitle>
            <DetailBox
                column={column}
                row={values}
                title="Details"
                className="hidden md:block w-full h-full p-5 bg-white-light"
            />
            <DetailBox2
                column={column}
                row={values}
                title="Details"
                className="block md:hidden w-full h-full p-5 bg-white-light"
            />
        </div>
    );
}

export default CanvasRequestInfo;
