import Text from 'components/Text';
import React from 'react';

//type = company | user
function AccountEmail({ type = 'company' }) {
    const emailSub = type === 'company' ? ' Your company account primary user is' : 'Your account email address is';

    return (
        <div className="box">
            <Text className="pt-7  mb-5" size="18" weight="bold">
                Account Email
            </Text>
            <div className="flex flex-row flex-wrap items-center gap-2.5">
                <Text component="span" size="14">
                    {emailSub}
                </Text>
                <div className="flex gap-2.5">
                    <Text component="span" size="14" weight="bold">
                        johnwood@gmail.com
                    </Text>
                    <Text
                        className="underline-offset-[3px] underline text-center cursor-pointer leading-[17px]"
                        component="button"
                        size="14"
                    >
                        Change
                    </Text>
                </div>
            </div>
        </div>
    );
}

export default AccountEmail;
