import { IMAGE, SVG } from 'assets/imagePath';
import Box from 'components/Box/Box';
import { Button } from 'components/Button';
import ReviewBlock from 'components/Campaign/Order/ReviewBlock';
import { CardRadio } from 'components/Card';
import Chip from 'components/Chip/Chip';
import LibraryModule from 'components/GlobalModules/LibraryModule';
import ActionsButton from 'components/GridTable/ActionsButton';
import GridTable from 'components/GridTable/GridTable';
import Image from 'components/Image/Image';
import { Modal, ModalZoomImage } from 'components/Modal';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import FormInputText from 'components/modules/form/FormInputText';
import FormRadio from 'components/modules/form/FormRadio';
import FormSelect from 'components/modules/form/FormSelect';
import { useFormikContext } from 'formik';
import { useNavigateSearch } from 'hooks';
import { MediaNativeDetail } from 'pages/Media';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { checkAllowableSize, checkDimensions, checkReview } from 'utils';
import { displayRow, nativeRow, videoRow } from './data';
const types = [
    { label: 'Display', value: 'display' },
    { label: 'Native', value: 'native' },
    { label: 'Video', value: 'video' },
];
const goal_types = [{ label: 'URL', value: 'url' }];

const actions = [
    {
        label: 'View',
        value: 'view',
    },
    {
        label: 'Duplicate',
        value: 'duplicate',
    },
    {
        label: 'Assign attributes to all',
        value: 'assign_attribute',
    },
    {
        label: 'Remove',
        value: 'remove',
    },
];

const DisplayAd = ({ mediaRows = [] }) => {
    const { values: formValues } = useFormikContext();
    const navigate = useNavigate();

    const { creativeId } = useParams();

    const mediaCol = [
        {
            field: 'id',
            headerName: 'ID',
        },
        {
            field: 'review',
            headerName: 'Review',
            renderCell: (row) => {
                const { label, color } = checkReview(row?.review);
                return <Chip color={color}>{label}</Chip>;
            },
        },
        {
            field: 'attachment',
            headerName: 'Attachment',
            renderCell: (row) => {
                return (
                    <>
                        <Image
                            src={row?.attachment}
                            className={'w-full max-h-[50px] hidden md:block object-cover overflow-hidden'}
                        />
                        <Text className="md:hidden">Image</Text>
                    </>
                );
            },
        },
        {
            field: 'format',
            headerName: 'Format',
        },
        {
            field: 'dimensions',
            headerName: 'Dimensions',

            renderCell: (row) => {
                const isAllow = checkDimensions(row?.dimensions);
                return isAllow ? (
                    <Text>{row?.dimensions}</Text>
                ) : (
                    <div className="flex flex-row gap-[5px] items-center">
                        <Text className="text-error">{row?.dimensions}</Text>
                        <Svg name="infoOutline" className="child:fill-error" />
                    </div>
                );
            },
        },
        {
            field: 'size',
            headerName: 'Size',
            renderCell: (row) => {
                const isAllow = checkAllowableSize(row?.size);
                return isAllow ? (
                    <Text>{row?.size}</Text>
                ) : (
                    <div className="flex flex-row gap-[5px] items-center">
                        <Text className="text-error">{row?.size}</Text>
                        <Svg name="infoOutline" className="child:fill-error" />
                    </div>
                );
            },
        },
        {
            field: 'attributes',
            headerName: 'Attributes',
        },
        {
            field: 'action',
            headerName: 'Actions',
            renderCell: (row) => {
                return <ActionsButton onChange={(menuItem) => handleChangeMenuItem(menuItem, row)} options={actions} />;
            },
        },
    ];
    const handleNavigate = (type, id) => {
        switch (type) {
            case 'display':
                navigate(routesPath.media_display_detail(id));
                break;
            case 'video':
                navigate(routesPath.media_video_detail(id));

                break;
            case 'native':
                navigate(routesPath.media_native_detail(id));

                break;

            default:
                break;
        }
    };
    const handleChangeMenuItem = (menuItem, row) => {
        switch (menuItem.value) {
            case 'view':
                handleNavigate(formValues?.type, row.id);
                break;
            case 'duplicate':
                alert('Call Api duplicate');
                break;
            case 'assign_attribute':
                alert('assign attribute');

                // navigate('/');
                //remove()
                break;
            case 'remove':
                alert('remove');

                // navigate('/');
                //remove()
                break;
            default:
                break;
        }
    };

    const [open, setOpen] = useState(false);
    return (
        <>
            <div className="flex flex-col gap-5">
                <div className="flex flex-col sm:flex-row justify-between gap-2.5">
                    <SubTitle className={'mt-7'}>Media</SubTitle>
                    <div className="grid flex-row grid-cols-2 gap-5 xs:flex">
                        <Button variant="outlined">Remove All</Button>
                        <Button variant="outlined" onClick={() => setOpen(true)}>
                            Library
                        </Button>
                        <Button variant="outlined">Upload</Button>
                        <Button variant="outlined">Create blank</Button>
                    </div>
                </div>
                {mediaRows?.length === 0 ? (
                    <Box
                        // onClick={() => navigate(routesPath.add_user)}
                        onClick={() => navigate(routesPath.media_display_detail(null))}
                        shadow="default"
                        className="flex justify-center items-center p-5 h-[200px] w-full cursor-pointer bg-white-light"
                    >
                        <Image className={'max-w-[60px] w-full'} src={SVG.upload} />
                    </Box>
                ) : (
                    <div className="flex flex-col rounded-[10px] border border-blue-cfd">
                        <GridTable className={'w-full table-fixed'} column={mediaCol} row={mediaRows} />
                    </div>
                )}
            </div>

            <Modal
                bodyClass={'max-w-[800px] w-full pb-[30px] z-[100] '}
                className="z-[100]"
                title="Library"
                isOpen={open}
                onClose={() => setOpen(false)}
            >
                <LibraryModule
                    onCreateCanvas={() => {
                        setOpen(false);
                    }}
                />
            </Modal>
        </>
    );
};

function CreativeForm({ formValues }) {
    const [mediaRows, setMediaRow] = useState([]);
    const navigate = useNavigate();

    const location = useLocation();

    const searchParams = queryString.parse(location.search);

    const navigateSearch = useNavigateSearch();

    const [modalImage, setModalImage] = useState({
        open: false,
        image: '',
    });

    useEffect(() => {
        //fetchMediaRows()
        switch (formValues?.type) {
            case 'display':
                setMediaRow(displayRow);
                navigateSearch(location.pathname, {
                    type: 'display',
                });
                break;
            case 'native':
                setMediaRow(nativeRow);
                setMediaRow(displayRow);
                navigateSearch(location.pathname, {
                    type: 'native',
                });
                break;
            case 'video':
                setMediaRow(videoRow);
                navigateSearch(location.pathname, {
                    type: 'video',
                });

                break;

            default:
                setMediaRow(displayRow);
                navigateSearch(location.pathname, {
                    type: 'display',
                });
                break;
        }
    }, [formValues?.type]);

    return (
        <>
            <div className="flex flex-col gap-[30px]">
                <div className="flex flex-col gap-5">
                    <SubTitle className={'mt-7'}>Details</SubTitle>
                    <FormInputText name="name" placeholder="Name" />
                </div>
                <div className="flex flex-col gap-5">
                    <SubTitle className={'mt-7'}>Goal</SubTitle>
                    <FormSelect options={goal_types} placeholderTop name="goal_type" placeholder="Type" />
                    <FormInputText name="url" placeholder="URL" />
                </div>
                <div className="flex flex-col gap-5">
                    <SubTitle className={'mt-7'}>Type</SubTitle>

                    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
                        <FormRadio
                            defaultChecked
                            name="type"
                            value="display"
                            className="bg-white !shadow-none !peer-checked:bg-white peer-checked:shadow-none h-full"
                            renderComponent={
                                <CardRadio
                                    img={IMAGE.creative_display}
                                    title={'Display Ad'}
                                    content={
                                        "The traditional image or video format ad that catches the audience's attention. It is more noticeable and distinct from the surrounding content."
                                    }
                                />
                            }
                        />
                        <FormRadio
                            name="type"
                            value="native"
                            className="bg-white !shadow-none !peer-checked:bg-white peer-checked:shadow-none h-full"
                            renderComponent={
                                <CardRadio
                                    img={IMAGE.creative_native}
                                    title={'Native Ad'}
                                    content={
                                        'An ad that seamlessly blends in with the content and format of the platform, it appears more natural and less like traditional ads.'
                                    }
                                />
                            }
                        />
                        <FormRadio
                            name="type"
                            value="video"
                            className="bg-white !shadow-none !peer-checked:bg-white peer-checked:shadow-none h-full"
                            renderComponent={
                                <CardRadio
                                    img={IMAGE.creative_video}
                                    title={'Video Ad'}
                                    content={
                                        'An ad that seamlessly blends in with the content and format of the platform, it appears more natural and less like traditional ads.'
                                    }
                                />
                            }
                        />
                    </div>

                    {/* <div className="flex flex-col xxs:flex-row text-right justify-end gap-2.5">
                        <Text size="12">Would like to convert to Native Ad?</Text>
                        <Text underline size="12">
                            Convert to Native Ad
                        </Text>
                    </div> */}
                </div>

                {searchParams.type === 'display' && <DisplayAd />}
                {searchParams.type === 'native' && <MediaNativeDetail />}

                <div className="max-w-[900px]">
                    <ReviewBlock reviewStatus={'pending'} />
                </div>
            </div>

            <ModalZoomImage
                image={modalImage.image}
                isOpen={modalImage.open}
                setIsOpen={(isOpen) => setModalImage((prev) => ({ ...prev, open: isOpen }))}
            />
        </>
    );
}

export default CreativeForm;
