import clsx from 'clsx';
import Text from 'components/Text';
import ToolTip from 'components/Tooltip/Tooltip';

function RowDetail({ column, row, rowStyle, isText, boxId, handle }) {
    return (
        <div className="flex flex-col w-full">
            {column?.map((column, idx) => {
                return (
                    !column?.hideDetail && (
                        <div
                            key={idx}
                            className={clsx('flex flex-row gap-[5px] h-[30px]  w-full items-center', rowStyle)}
                        >
                            <div className="flex-1 w-full field">
                                <ToolTip tooltip={column.headerName} parentId={boxId}>
                                    <Text component="span" size="12">
                                        {column.headerName}
                                    </Text>
                                </ToolTip>
                            </div>
                            {column?.renderCell && !isText ? (
                                <div className="flex justify-end flex-1 w-1/2 text-right field__value">
                                    {column?.renderCell(row, handle)}
                                </div>
                            ) : (
                                <div className="flex justify-end flex-1 w-1/2 field__value">
                                    <ToolTip tooltip={column?.tooltip && row?.[column?.field]} parentId={boxId}>
                                        <Text
                                            className="relative overflow-hidden text-right"
                                            component="p"
                                            size="14"
                                            ellipsis
                                        >
                                            {row?.[column?.field]}
                                        </Text>
                                    </ToolTip>
                                </div>
                            )}
                        </div>
                    )
                );
            })}
        </div>
    );
}

export default RowDetail;
