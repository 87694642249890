import { PaymentModule } from 'components/GlobalModules';
import { Modal } from 'components/Modal';
import React, { useContext, useEffect, useRef, useState } from 'react';

const PaymentModuleContext = React.createContext();

const stepTreePayment = [
    {
        step: 'add_method',
        parentStep: 'select_method',
    },

    {
        step: 'fiat',
        parentStep: 'add_method',
    },

    {
        step: 'crypto',
        parentStep: 'add_method',
    },

    {
        step: 'history',
        parentStep: 'add_method',
    },

    {
        step: 'card',
        parentStep: 'fiat',
    },

    {
        step: 'ach',
        parentStep: 'fiat',
    },

    {
        step: 'bitcoin',
        parentStep: 'crypto',
    },
    {
        step: 'bitcoin',
        parentStep: 'crypto',
    },

    {
        step: 'completed',
        parentStep: 'select_method',
    },
];

export const usePaymentModule = () => {
    const { step, setStep, showModalPayment, closeModalPayment, setPaymentDetail, ...rest } =
        useContext(PaymentModuleContext);

    return { step, setStep, showModalPayment, closeModalPayment, setPaymentDetail, ...rest };
};

function PaymentProvider({ children }) {
    // This is the exact same logic that we previously had in our hook

    const [step, setStep] = useState('select_method');

    const [tab, setTab] = useState({ label: 'Payment', value: 'payment' });

    const [isOpen, setIsOpen] = useState(false);

    const [paymentDetail, setPaymentDetail] = useState({});

    const showModalPayment = () => {
        setIsOpen(true);
    };
    const closeModalPayment = () => {
        setIsOpen(false);
    };

    const handleOnBack = () => {
        if (step === 'select_method') {
            closeModalPayment();
        } else {
            const backStep = stepTreePayment.find((item) => item.step === step);
            setStep(backStep.parentStep);
        }
    };

    useEffect(() => {
        if (!isOpen) {
            setStep('payment_method');
            setTab({ label: 'Payment', value: 'payment' });
            setPaymentDetail({});
        }
    }, [isOpen]);

    /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */

    return (
        <PaymentModuleContext.Provider
            value={{ step, setStep, showModalPayment, closeModalPayment, setPaymentDetail, isOpen, tab, setTab }}
        >
            <Modal
                onBack={handleOnBack}
                bodyClass={'max-w-[800px] pb-[30px]'}
                title="Payments"
                isOpen={isOpen}
                onClose={closeModalPayment}
            >
                <PaymentModule paymentDetail={paymentDetail} />
            </Modal>
            {children}
        </PaymentModuleContext.Provider>
    );
}

export default PaymentProvider;
