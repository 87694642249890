import clsx from 'clsx';
import Text from 'components/Text';
import React from 'react';

function Title({ children, className, ...props }) {
    return (
        <Text className={clsx('!text-xl !md:text-2xl leading-[29px]', className)} weight="bold" {...props}>
            {children}
        </Text>
    );
}

export default Title;
