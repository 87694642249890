import { SVG } from 'assets/imagePath';
import { Button } from 'components/Button';
import Image from 'components/Image';
import { Svg } from 'components/Svg';
import React from 'react';

function ChatInput({ propsInput }) {
    return (
        <div className="flex flex-row items-center">
            <div className="flex-1 flex items-center rounded-l-[15px] rounded-r-[0px] bg-white dark:bg-gray-700 relative">
                <textarea
                    id="chat"
                    rows={1}
                    className="placeholder:text-grey-light  placeholder:text-sm block bg-transparent w-full py-[15px] px-[20px]  focus:outline-none text-sm resize-none"
                    placeholder="Message"
                    {...propsInput}
                />

                <div className=" flex gap-2.5 mr-3 ">
                    <Svg sizeIcon={15} name="smile" />
                    <Image src={SVG.chevron_down} />
                </div>
            </div>
            <Button
                size="medium"
                className={'w-[50px] !p-[12.5px] rounded-none rounded-r-[10px] bg-blue-eae'}
                color="secondary"
            >
                <Svg sizeIcon={24} name="send" />
            </Button>
        </div>
    );
}

export default ChatInput;
