import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Image from 'components/Image';
import Text from 'components/Text';
import React, { useId, useRef } from 'react';

function Radio({ label, onSelect, infoIcon = false, renderComponent, className, ...props }) {
    const radioId = useId();
    const ref = useRef();

    const handleOnChange = (e) => {
        const value = e.target.value;
        onSelect && onSelect();
    };

    const labelChecked = `peer-checked:shadow-both peer-checked:bg-white-light peer-checked:border-[3px] peer-checked:!border-primary`;
    return (
        <div className="w-full h-auto radio">
            <input ref={ref} type="radio" id={radioId} className={`peer hidden`} {...props} />
            <label
                className={clsx(
                    'px-[15px] h-[50px] text-black  cursor-pointer w-full flex items-center gap-[5px] rounded-[10px] shadow-outer bg-blue-eae border-2 border-blue-eae',
                    labelChecked,
                    className,
                )}
                htmlFor={radioId}
            >
                {renderComponent ? (
                    renderComponent
                ) : (
                    <>
                        <Text className="whitespace-nowrap">{label}</Text>

                        {infoIcon && (
                            <Image className="h-[17px] w-[17px] object-contain" src={SVG.info_outlined} alt="icon" />
                        )}
                    </>
                )}
            </label>
        </div>
    );
}

export default Radio;
