import { OverallPerformance, PerformanceLocation, PerformanceSocial } from 'components/Campaign/Analyze';
import React from 'react';

function Analyze() {
    return (
        <div className="flex flex-col gap-y-[30px]">
            <OverallPerformance />
            <PerformanceLocation />
            <PerformanceSocial />
        </div>
    );
}

export default Analyze;
