import InputUpload from 'components/Input/InputUpload';
import { useField } from 'formik';

function FormUpload({ ...props }) {
    const [field, meta, helpers] = useField(props);

    return (
        <InputUpload error={meta.error} helper={meta.error} setValueFormik={helpers.setValue} {...field} {...props} />
    );
}

export default FormUpload;
