import clsx from 'clsx';
import { Svg } from 'components/Svg';
import { useEffect, useState } from 'react';

const DefaultButton = ({ toggle, title = '' }) => {
    return (
        <button
            className="flex items-center justify-between w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={toggle}
        >
            <span>{title}</span>
            <Svg name="chevronDown" />
        </button>
    );
};

function Collapse({ renderButton, title = '', className, defaultToggle = true, children }) {
    const [isOpen, setIsOpen] = useState(defaultToggle);

    function toggle(value) {
        if (typeof value === 'boolean') return setIsOpen(value);
        setIsOpen((prev) => !prev);
    }

    useEffect(() => {
        return () => {
            //To set default when the component unmount
            setIsOpen(defaultToggle);
        };
    }, []);

    return (
        <div className={clsx('w-full', className)}>
            {renderButton ? renderButton({ toggle, isOpen, title }) : <DefaultButton toggle={toggle} title={title} />}
            <div className={clsx('transition-all', isOpen ? 'h-auto' : 'h-[0px]')}>
                <div className="w-full h-full">{isOpen && children}</div>
            </div>
        </div>
    );
}

export default Collapse;
