import React, { useMemo } from 'react';
import { tailoredData } from './data';
import Image from 'components/Image/Image';
import Text from 'components/Text/Text';
import InlineHtml from 'components/InlineHtml/InlineHtml';
import clsx from 'clsx';
import { Svg } from 'components/Svg';

const TailoredItem = ({ title, desc, className, icon }) => {
    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-row items-center gap-[15px]">
                <div
                    className={clsx(
                        'flex justify-center items-center w-[30px] h-[30px]  aspect-square rounded-full m-[5px] ring-[5px] ring-blue-eae shadow-outer',
                        className,
                    )}
                >
                    <Svg name={icon} />
                </div>
                <Text size="16" weight="bold">
                    {title}
                </Text>
            </div>

            <Text>{desc}</Text>
        </div>
    );
};

function Tailored({ offer }) {
    const data = useMemo(() => tailoredData[offer.toLowerCase()], [offer]);
    return (
        <section className="relative z-10 flex flex-col gap-[50px] pb-[60px]  w-full bg-white">
            <Image src={data?.image} className="w-full aspect-[3/1]" />
            <div className="px-5">
                <div className="max-w-[960px] w-full mx-auto">
                    <div className="max-w-[640px] flex flex-col items-center mx-auto mb-[50px]">
                        <Text
                            size="custom"
                            family="sens"
                            weight="bold"
                            className="text-3xl md:text-5xl tracking-tighter text-center my-[30px] mb-5"
                        >
                            {data?.title}
                        </Text>
                        <Text size="16" weight="medium" className="text-center">
                            <InlineHtml html={data?.html} />
                        </Text>
                    </div>

                    <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-x-[30px] gap-y-[50px]">
                        {data?.benefits.map((item, idx) => (
                            <TailoredItem
                                key={idx}
                                className={item.className}
                                icon={item?.icon}
                                title={item?.title}
                                desc={item?.desc}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Tailored;
