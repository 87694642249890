import { SVG } from 'assets/imagePath';
import { Button, IconButton } from 'components/Button';
import { Modal } from 'components/Modal';
import { Portal } from 'components/Portal';
import { Form, Formik } from 'formik';
import { useState } from 'react';

function FilterButton({ onApply, onReset, FilterForm, modalSettings, defaultValues, ...rest }) {
    const [isOpen, setIsOpen] = useState(false);

    const handleApply = (data) => {
        onApply?.(data);
        setIsOpen(false);
    };

    const handleResetFilter = (dataRs) => {
        onReset?.(dataRs);
        setIsOpen(false);
    };
    return (
        <>
            <IconButton
                variant="outlined"
                className="!w-[50px] h-[50px]  !shadow-none !p-[17.5px]"
                source={SVG.filter}
                {...rest}
                onClick={() => setIsOpen(true)}
            />

            {FilterForm && (
                <Portal showPortal={isOpen}>
                    <Modal
                        bodyClass="max-w-[580px] w-full"
                        title="Filter"
                        {...modalSettings}
                        isOpen={isOpen}
                        onClose={() => {
                            setIsOpen(false);
                        }}
                    >
                        <Formik
                            initialValues={{ ...defaultValues }}
                            onSubmit={(data) => {
                                handleApply(data);
                            }}
                            onReset={(values, { resetForm }) => {
                                handleResetFilter({});
                            }}
                        >
                            <Form className="flex flex-col gap-5">
                                <div>{FilterForm}</div>

                                <div className="flex flex-row gap-5">
                                    <Button type="reset" className={'flex-1'} color="secondary">
                                        Reset Filter
                                    </Button>

                                    <Button type="submit" className={'flex-1'}>
                                        Apply
                                    </Button>
                                </div>
                            </Form>
                        </Formik>
                    </Modal>
                </Portal>
            )}
        </>
    );
}

export default FilterButton;
