import UpgradePlanItem from 'components/AccountConfig/Settings/UpgradePlanItem';
import { HeaderNavigate } from 'components/HeaderNavigate';
import Text from 'components/Text';
import { APP_ROLE } from 'constants/auth';
import { usePaymentModule } from 'context/ui/PaymentModuleContext';
import { useEffect, useState } from 'react';
import { getCurrentRole, isPermissionView } from 'utils';

const row = {
    date: new Date(Date.now()).toLocaleString().split(',')[0],
    time: new Date(Date.now()).toLocaleString().split(',')[1],
    service: 'Agency Subscription',
    amount: '$299.00',
    currency: 'USD',
    billing: 'Monthly Recurring Payment',
};

function UpgradeCompanyPlan() {
    const CURRENT_ROLE = getCurrentRole();

    const [isOpen, setIsOpen] = useState(false);

    const { showModalPayment, setPaymentDetail } = usePaymentModule();

    useEffect(() => {
        setPaymentDetail(row);
    }, []);
    return (
        <div className="p-[30px] flex flex-col gap-[30px]">
            <HeaderNavigate detailInfo={{ detailName: 'Plans' }} />

            <div className="p-2.5 flex max-[475px]:justify-center max-md:flex-wrap  flex-row gap-5">
                <UpgradePlanItem
                    active={CURRENT_ROLE === APP_ROLE.Si}
                    cardProps={{
                        title: 'Current Plan',
                        content: 'Single User',
                        actionText: 'View',
                        onAction: () => {},
                        className: 'w-[210px]',
                    }}
                    benefits={[{ svg: 'aiCanva', title: 'AI Canva Tool' }]}
                />
                <UpgradePlanItem
                    active={isPermissionView([APP_ROLE.Co, APP_ROLE.SubCo])}
                    cardProps={{
                        title: 'Plan',
                        content: 'Company',
                        actionText: 'Change plan',
                        onAction: () => showModalPayment(),

                        className: 'w-[210px]',
                    }}
                    benefits={[
                        { svg: 'users', title: 'Multiple users' },
                        { svg: 'aiCanva', title: 'AI Canva Tool' },
                    ]}
                />
                <UpgradePlanItem
                    active={isPermissionView([APP_ROLE.Ag, APP_ROLE.Org])}
                    cardProps={{
                        title: 'Plan',
                        content: 'Agency',
                        actionText: 'Change plan',
                        onAction: () => showModalPayment(),
                        className: 'w-[210px]',
                    }}
                    benefits={[
                        { svg: 'users', title: 'Multiple users' },
                        { svg: 'building', title: 'Multiple companies' },
                        { svg: 'brand', title: 'Custom Branding' },
                        { svg: 'market', title: 'Markup & Resale Ads' },
                        { svg: 'aiCanva', title: 'AI Canva Tool' },
                    ]}
                />
            </div>
        </div>
    );
}

export default UpgradeCompanyPlan;
