import React, { useLayoutEffect, useState } from 'react';
import { SVG } from 'assets/imagePath';
import { Button, IconButton } from 'components/Button';
import { Drawer } from 'components/drawer';
import { MENU_HOME } from 'constants/menu';
import { Offer } from 'components/Public/Home';
import { routesPath } from 'routes/routes';
import { NavLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import './Navbar.css';

function NavBar({ scrollToView }) {
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [styleArrow, setStyleArrow] = useState({ left: 0 });

    const calcLeftByParent = (id) => {
        const parent = document.getElementById(id);
        if (parent) {
            return parent.offsetLeft + parent.offsetWidth / 2;
        }
        return 0;
    };

    const handleNavigateIndustyOffer = (label) => {
        navigate(routesPath.industries, { state: { industryLabel: label } });
        window.scrollTo(0, 0);
    };

    useLayoutEffect(() => {
        const left = calcLeftByParent('industries');
        setStyleArrow({ left });
    }, [window.innerWidth]);

    return (
        <div className="relative">
            <nav className="container mx-auto flex justify-between items-center py-[45px] px-[10px] sm:px-[0px]">
                <div className="flex items-center justify-between">
                    <div
                        onClick={() => navigate('/')}
                        className="xs:min-w-[120px] cursor-pointer lg:min-w-[200px] flex items-center justify-center mr-5 xl:mr-[90px]"
                    >
                        <img className="w-full h-auto" src={SVG.logo} alt="logo" />
                    </div>
                    <div className="items-center hidden lg:flex gap-5  xl:gap-[50px]">
                        {MENU_HOME.map((link, idx) => (
                            <NavLink
                                className={({ isActive }) =>
                                    clsx(
                                        'flex gap-[50px] text-sm',
                                        isActive ? 'text-dark-nav font-bold' : 'text-gray-nav font-medium',
                                    )
                                }
                                to={link.to}
                                key={link.id}
                                id={link.id}
                            >
                                <span className={link.id === 'industries' ? 'nav-item' : ''}>{link.label}</span>
                                {link.id === 'industries' && (
                                    <div
                                        className="nav-item__mennu absolute top-[125px] left-0 right-0"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <div className="relative">
                                            <div
                                                className={`absolute -top-[14px] w-[28px] h-[28px] bg-dark-nav -translate-x-1/2 rotate-[45deg]`}
                                                style={{ left: styleArrow?.left }}
                                            ></div>
                                        </div>
                                        <Offer isTitle={false} onChange={handleNavigateIndustyOffer} />
                                    </div>
                                )}
                            </NavLink>
                        ))}
                    </div>
                </div>
                <div className="flex items-center gap-5 ">
                    <IconButton className={'lg:hidden block'} source={SVG.bar} onClick={() => setOpenDrawer(true)} />
                    <Button color="secondary" className="px-[15px]" onClick={() => scrollToView('request_demo')}>
                        Request a Live Demo
                    </Button>
                    <Button onClick={() => navigate(routesPath.login)}>Login</Button>
                </div>
            </nav>
            <Drawer
                handleClose={() => setOpenDrawer(false)}
                open={openDrawer}
                position={'right'}
                DrawerContent={
                    <div className="flex flex-col  mt-14  min-w-[300px]">
                        {MENU_HOME.map((nav, idx) => {
                            return (
                                <NavLink
                                    key={nav.id}
                                    to={nav.to}
                                    className={({ isActive }) =>
                                        'h-full p-2.5 flex items-center text-black text-sm font-semibold font-inter hover:bg-nav'
                                    }
                                >
                                    {nav.label}
                                </NavLink>
                            );
                        })}
                    </div>
                }
            />
        </div>
    );
}

export default NavBar;
