export const fetchRelativeLocation = async () => {
    try {
        //This API need money
        return fetch('https://ipinfo.io?token=de10545a66d758')
            .then((res) => res.json())
            .then((data) => data);
    } catch (error) {
        console.error('Error fetching IP information: ' + error);
    }
};

export const validateLocationObject = (objLocation) => {
    //Interface ObjectLocation
    //    {
    //     name?: string;
    //     lat: number;
    //     lng: number;
    //     rad:number
    //    }

    if (typeof objLocation !== 'object') {
        return {
            error: true,
            textError: 'Not invalid',
        };
    }

    let error = false;
    let textError = '';

    if (!objLocation['lat'] || !objLocation['lng']) {
        //Check object location exist lng or lat
        error = true;
        textError = 'One of coordidates existing lat or lng';
    } else if (isNaN(parseFloat(objLocation['lat'])) || isNaN(parseFloat(objLocation['lng']))) {
        error = true;
        textError = 'Lat or Lng is not a number';
    }

    return { error, textError };
};

export const checkValidCoordinates = (coordidatesArr) => {
    if (!Array.isArray(coordidatesArr)) return;

    let errors = {
        error: false,
        textError: '',
    };

    coordidatesArr.forEach((location, idx) => {
        if (errors.error) {
            return;
        }

        errors = { ...validateLocationObject(location) };
    });

    return errors;
};
