import Text from 'components/Text';
import React, { useId, useState } from 'react';

function Checkbox({ value, onChange, Label, setValueFormik, ...props }) {
    const checkboxId = useId();

    const [checked, setChecked] = useState(false);

    const handleChange = (e) => {
        const checked = e.target.checked;
        const value = e.target.value;
        setChecked(checked);
        setValueFormik && setValueFormik(checked ? value : '');

        onChange && onChange(e);
    };
    return (
        <label className="flex items-center flex-row gap-2.5 w-full" htmlFor={checkboxId}>
            <div className="aspect-square w-5 h-[20px] flex justify-center items-center shadow-default bg-white-light rounded-[5px]">
                <input
                    checked={checked}
                    value={value || checked}
                    className="hidden peer"
                    type="checkbox"
                    id={checkboxId}
                    onChange={handleChange}
                    {...props}
                />
                <div className="shadow-check-box aspect-square w-2.5 rounded-[3px]  peer-checked:bg-primary  bg-blue-cfd" />
            </div>
            {Label ? (
                Label
            ) : (
                <>
                    <Text size="14px" className="inline-block " component="span">
                        Accept
                    </Text>
                    <Text size="14px" underline>
                        Terms & Conditions
                    </Text>
                </>
            )}
        </label>
    );
}

export default Checkbox;
