export const ALLOWABLE_SIZES = 100;

export const RECOMMEND_DIMENSIONS = ['320x50', '320x250'];

export const COLOR_DEFAULT = ['#CFD6E4', '#FFFFFF', '#0E65F1', '#E32416', '#E39115', '#268743'];

export const FONT_OPTIONS = [
    { label: 'Inter', value: 'font-inter' },
    { label: 'Mono', value: 'font-mono' },
    { label: 'Serif', value: 'font-serif' },
];

export const SIZE_OPTIONS = [
    { label: '12', value: '12' },
    { label: '14', value: '14' },
    { label: '16', value: '16' },
];
