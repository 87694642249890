import NoImage from 'assets/images/noImage.png';
import { ALLOWABLE_SIZES, RECOMMEND_DIMENSIONS } from 'constants/common';
import { AUTH_KEY } from 'constants/localStorageKey';

export const lazyLoadImageOnError = (event) => {
    event.currentTarget.onerror = null;
    event.currentTarget.src = NoImage;
};

export const isFunction = (functionToCheck) => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

export function objecIsEmpty(obj) {
    return Object.getOwnPropertyNames(obj).length === 0;
}

export const getCurrentRole = () => {
    return JSON.parse(localStorage.getItem(AUTH_KEY))?.user?.role;
};

export const isPermissionView = (roles = [], currentRole = getCurrentRole()) => {
    return roles.includes(currentRole);
};

export function currencyFormat({ num, currencySymbols = '$', fixed = 2, positionCurrency = 'left' }) {
    const number =
        Math.abs(num) > 999
            ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(fixed) + 'K'
            : (Math.sign(num) * Math.abs(num)).toFixed(fixed);

    if (positionCurrency === 'left') {
        return currencySymbols + number.toString();
    } else {
        return number.toString() + currencySymbols;
    }
}

export function kFormatter(num) {
    const number =
        Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'K' : Math.sign(num) * Math.abs(num);
    return number.toString().replace('.', ',');
}

export function percentFormatter(num) {
    const number = Math.sign(num) * Math.abs(num);
    if (number < 1 && number > 0) {
        const _numb = number.toString().slice(1);
        return _numb + '%';
    }

    return number + '%';
}

export const checkStatusCampaign = (status) => {
    switch (status) {
        case 'draft':
            return { color: 'white', label: 'Draft' };
        case 'active':
            return { color: 'success', label: 'Active' };
        case 'embed':
            return { color: 'error', label: 'Embed' };
        case 'paused':
            return { color: 'warning', label: 'Paused' };
        case 'archived':
            return { color: 'info', label: 'Archived' };
        default:
            return {};
    }
};

export const checkReview = (review) => {
    switch (review) {
        case 'approved':
            return { color: 'success', label: 'Approved' };
        case 'rejected':
            return { color: 'error', label: 'Rejected' };

        default:
            return {};
    }
};

export const getNumberFromString = (string) => {
    const replaced = string.replace(/\D/g, ''); //

    let num;

    if (replaced !== '') {
        num = Number(replaced);
    }
    return num;
};

export const checkDimensions = (values) => {
    return RECOMMEND_DIMENSIONS.includes(values);
};

export const checkAllowableSize = (size) => {
    const num = getNumberFromString(size);
    return num <= ALLOWABLE_SIZES;
};

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'kB', 'mB', 'GB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function getTypeFile(type) {
    if (typeof type !== 'string') return '';

    return type.split('/')[1].toUpperCase();
}

export function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex?.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}

export function setContrast(hex = '#000') {
    // Randomly update colours
    const rgb = hexToRgb(hex);

    // http://www.w3.org/TR/AERT#color-contrast
    const brightness = Math.round((parseInt(rgb.r) * 299 + parseInt(rgb.g) * 587 + parseInt(rgb.b) * 114) / 1000);
    const isBrightness = brightness > 125;

    return { isBrightness, brightness };
}

export function generateRandomColor() {
    // Generate random R, G, B values
    var r = Math.floor(Math.random() * 256);
    var g = Math.floor(Math.random() * 256);
    var b = Math.floor(Math.random() * 256);

    // Convert RGB to hexadecimal
    var hexColor = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);

    return hexColor;
}

export function metersToMiles(meters) {
    const metersInOneMile = 1609.34;
    const miles = meters / metersInOneMile;
    return miles;
}
