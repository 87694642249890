export const variantClass = (variant = 'outlined') => {
    switch (variant) {
        case 'outlined':
            return 'bg-blue-ebe border border-blue-cfd';

        case 'contained':
            return 'bg-white-light';
        default:
            break;
    }
};

export const variantLabelClass = (variant = 'outlined') => {
    switch (variant) {
        case 'outlined':
            return 'bg-blue-ebe border-none border-blue-cfd';

        case 'contained':
            return 'bg-white-light border-none';
        default:
            break;
    }
};

export const shadowClass = (shadow = 'none') => {
    switch (shadow) {
        case 'default':
            return 'shadow-default';

        case 'none':
            return 'shadow-none';

        default:
            break;
    }
};
