import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Image from 'components/Image';
import * as React from 'react';

export default function Drawer({ open, position, handleClose, classNameBox, DrawerContent, Header = false }) {
    //Style
    let positionClass;
    switch (position) {
        case 'left':
            positionClass = !open ? '-translate-x-full top-0 left-0  w-auto h-full' : 'top-0 left-0 w-auto h-full';
            break;
        case 'right':
            positionClass = !open ? 'translate-x-full top-0 right-0  w-auto h-full' : 'top-0 right-0 w-auto h-full';
            break;
        case 'bottom':
            positionClass = !open ? 'translate-y-full bottom-0 w-full h-auto' : 'bottom-0 w-full h-auto';
            break;
        case 'top':
            positionClass = !open ? '-translate-y-full top-0 w-full h-auto' : 'top-0 w-full h-auto';
            break;
        default:
            break;
    }
    React.useEffect(() => {
        if (open) {
            document.body.classList.add('overflow-hidden');
        }
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, [open]);

    return (
        <>
            <div
                className={`fixed bg-[#0000006e] w-full top-0  h-full ${open ? '' : '-translate-x-[120%]'} z-[11]`}
                onClick={handleClose}
            ></div>
            <div
                className={clsx(
                    `z-40 fixed overflow-auto max-h-full h-full max-w-full transition-transform  bg-blue-eae `,
                    positionClass,
                    classNameBox,
                )}
            >
                <div className="relative w-full h-full">
                    {Header ? (
                        Header
                    ) : (
                        <button
                            type="button"
                            data-drawer-hide="drawer-navigation"
                            aria-controls="drawer-navigation"
                            onClick={handleClose}
                            className="text-white bg-transparent rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center"
                        >
                            <Image src={SVG.close_icon} />
                            <span className="sr-only">Close menu</span>
                        </button>
                    )}
                    {DrawerContent}
                </div>
            </div>
        </>
    );
}
