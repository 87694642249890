import { AUTH_KEY } from './localStorageKey';

export const APP_ROLE = {
    Si: 'Si',
    Co: 'Co',
    SubCo: 'SubCo',
    Ag: 'Ag',
    Org: 'Org',
};

export const CURRENT_ROLE = JSON.parse(localStorage.getItem(AUTH_KEY))?.user?.role;
//  Si = Single user'
//  Co = "Company user"
//  SubCo = "Sub Company user"
//  Ag = "Agency User"
//  Org  = "Organization User"

export const fakeUser = [
    {
        email: 'company@gmail.com',
        password: '123456',
        fullName: 'Company Name',
        role: 'Co',
    },
    {
        email: 'user@gmail.com',
        password: '123456',
        fullName: 'User Name',
        role: 'Si',
    },
    {
        email: 'subCo@gmail.com',
        password: '123456',
        fullName: 'Sub Company Name',
        role: 'SubCo',
    },
    {
        email: 'agency@gmail.com',
        password: '123456',
        fullName: 'Agency Name',
        role: 'Ag',
    },
    {
        email: 'organization@gmail.com',
        password: '123456',
        fullName: 'Organization Name',
        role: 'Org',
    },
];
