import { BoxContent } from 'components/BoxContent';
import { ChatBox, ChatInput } from 'components/ChatBox';
import { chatContents } from 'components/ChatBox/fakeData';
import React from 'react';

function LeadLog() {
    return (
        <BoxContent title={'Comments'} className=" max-w-[820px]">
            <div className="flex flex-col gap-5">
                <ChatBox chatContents={chatContents} />
                <ChatInput placeholder="Message" />
            </div>
        </BoxContent>
    );
}

export default LeadLog;
