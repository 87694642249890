import { Button } from 'components/Button';
import { useAdCanva } from 'context/AdCanvaContext';
import { CanvasRequestInfo } from '../components';
import { useFormikContext } from 'formik';

function MyCanvasLayout({ children, lastStep }) {
    const { setStep, step } = useAdCanva();

    const { handleSubmit } = useFormikContext();

    const onNext = () => {
        if (lastStep) {
            handleSubmit((data) => {
                console.log(data);
            });
            return;
        } else {
            setStep((prev) => prev + 1);
        }
    };

    return (
        <div className="flex flex-col gap-5 mt-5 min-h-[350px]">
            <div className="flex flex-col-reverse gap-5 md:flex-row">
                <div className="md:w-[300px]">
                    {children}

                    <div className="flex flex-row w-full gap-5 mt-5">
                        <Button
                            onClick={(prev) => setStep(step - 1)}
                            className={'flex-1'}
                            color="secondary"
                            variant="contained"
                        >
                            Back
                        </Button>

                        <Button className={'flex-1'} onClick={onNext}>
                            {lastStep ? 'Submit' : 'Continue'}
                        </Button>
                    </div>
                </div>

                <div className="flex-[3] overflow-hidden">
                    <CanvasRequestInfo />
                </div>
            </div>
        </div>
    );
}

export default MyCanvasLayout;
