import { Button } from 'components/Button';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { InputText, Select } from 'components/Input';
import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

const optionsSystem = [
    { label: 'Admin', value: 'admin' },
    { label: 'User', value: 'user' },
];
function AddUser() {
    const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: { job_title: 'Ad Buyer' },
        onSubmit: (values) => {
            alert(JSON.stringify(values));
        },
        // validationSchema: createAccountSchema,
    });

    const navigate = useNavigate();

    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <HeaderNavigate detailInfo={{ detailName: 'Add User' }} />

            <form onSubmit={handleSubmit} className="flex flex-col gap-5 max-w-[820px] w-full mt-[30px]">
                <div className="flex flex-col gap-5 sm:flex-row">
                    <InputText
                        placeholder="First Name"
                        rounded
                        name="first_name"
                        onChange={handleChange}
                        value={values.first_name}
                        error={errors.first_name}
                    />
                    <InputText
                        placeholder="Last Name"
                        rounded
                        name="last_name"
                        onChange={handleChange}
                        value={values.last_name}
                        error={errors.last_name}
                    />
                </div>
                <div className="flex flex-col gap-5 xs:flex-row">
                    <InputText
                        placeholder="User’s Corporate Email"
                        rounded
                        name="corp_email"
                        onChange={handleChange}
                        value={values.corp_email}
                        error={errors.corp_email}
                    />
                    <InputText
                        placeholder="Mobile Phone (SMS Invite)"
                        rounded
                        name="sms_invite"
                        onChange={handleChange}
                        value={values.sms_invite}
                        error={errors.sms_invite}
                    />
                </div>

                <div className="flex flex-col gap-5 xs:flex-row">
                    <Select
                        placeholder="System Access Role"
                        placeholderTop
                        options={optionsSystem}
                        className="mb-[20px] justify-between"
                        propsDropdown={{ classNameBox: 'flex-1' }}
                        setValue={setFieldValue}
                        value={values.role}
                        helper="These are your new team member's system access privileges"
                        name="role"
                        CustomList={({ onCloseDropdown }) => (
                            <ul className="flex flex-col z-50 divide-y-[1px] divide-blue-cfd">
                                {optionsSystem.map((option) => (
                                    <li
                                        onClick={() => {
                                            setFieldValue('role', option.value);
                                            onCloseDropdown();
                                        }}
                                        key={option.value}
                                        className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer"
                                    >
                                        <div className="flex flex-row items-center gap-[5px] flex-1">
                                            <span className="text-sm text-blue-dark">{option.label}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    />
                    <InputText
                        placeholder="Job Title"
                        label={'Job Title'}
                        helper={'Required, please enter a valid job function'}
                        rounded
                        classNameBox="flex-1"
                        name="job_title"
                        onChange={handleChange}
                        value={values.job_title}
                        error={true}
                    />
                </div>
                <InputText
                    placeholder="Invite Message"
                    rounded
                    classNameBox="flex-1"
                    name="invite_mess"
                    onChange={handleChange}
                    value={values.invite_mess}
                    error={true}
                />

                <Button type="submit" className={'max-w-[260px]'} color="primary">
                    Send Invite
                </Button>
            </form>
        </div>
    );
}

export default AddUser;
