export const routesPath = {
    //Private Routes
    dashboard: '/',
    campaign: '/campaign',
    audiences: '/audiences',
    creative: '/creatives',
    account: '/account',
    education: '/education',
    test_map: '/test',

    account_single_user: '/account/single-user',

    account_subCo: '/account/sub-company',
    account_company: '/account/company',

    add_company: '/account/company/add-company',
    company_detail: (companyId = ':companyId') => `/company/detail/${companyId}`,

    account_agency: '/account/agency',
    account_organization: '/account/organization',

    add_user: '/account/users/add-user',
    user_detail: (userId = ':userId') => `/users/detail/${userId}`,
    agency_detail: (agencyId = ':agencyId') => `/agency/detail/${agencyId}`,

    posts_management: '/account/education/posts',
    categories_management: '/account/education/categories',

    add_post: '/account/education/add-post',
    edit_post: (postId = ':postId') => `/account/education/edit-post/${postId}`,
    post_review: (postId = ':postId') => `/account/education/post-review/${postId}`,

    edit_lead: (leadId = ':leadId') => `/account/leads/edit-lead/${leadId}`,
    lead_review: (leadId = ':leadId') => `/account/leads/lead-review/${leadId}`,
    qaqc_review: (qaqcId = ':qaqcId') => `/account/leads/qaqc-review/${qaqcId}`,

    add_lead: '/account/leads/add-lead',
    upgrade_company_plan: '/account/settings/upgrade-company-plan',
    markup: '/account/settings/markup',
    embed: '/account/settings/embed',
    balance: '/account/settings/balance',
    branding: '/account/settings/branding',
    deals: '/account/settings/deals',
    pricing: '/account/settings/pricing',

    blockchain_payments: '/account/payments/blockchain-payments',
    card_payments: '/account/payments/card-payments',

    create_company_form: '/create-company-form',
    campaign_detail: (campaignId = ':campaignId') => `/campaign/detail/${campaignId}`,
    campaign_services: (campaignId = ':campaignId') => `/campaign/services/${campaignId}`,

    ticket_detail: (ticketId = ':ticketId') => `/ticket/detail/${ticketId}`,
    ticket_comment: (ticketId = ':ticketId') => `/ticket/comment/${ticketId}`,

    earning_detail: (earningId = ':earningId') => `/earning/detail/${earningId}`,
    earning_review: (earningId = ':earningId') => `/earning/review/${earningId}`,

    payment_detail: (paymentId = ':paymentId') => `/payments/${paymentId}`,

    audience_detail: (audienceId = ':audienceId') => `/audiences/detail/${audienceId}`,
    audience_add: `/audiences/add`,
    audience_edit: (audienceId = ':audienceId') => `/audiences/edit/${audienceId}`,

    creative_detail: (creativeId = ':creativeId') => `/creatives/detail/${creativeId}`,

    creative_add: `/creatives/add`,
    creative_edit: (creativeId = ':creativeId') => `/creatives/edit/${creativeId}`,

    media_display_detail: (mediaId = ':mediaId') => `/media/display/${mediaId}`,
    media_video_detail: (mediaId = ':mediaId') => `/media/video/${mediaId}`,
    media_native_detail: (mediaId = ':mediaId') => `/media/native/${mediaId}`,
    topic_detail: (topicId = ':topicId') => `/education/topic/${topicId}`,
    post_detail: (postId = ':postId') => `/education/post/detail/${postId}`,

    //Public Routes
    home: '/',
    industries: '/industries',

    primary_user: '/primary-user',
    company_organization_user: '/company-organization-user',
    agency_sub_company_user: '/agency-sub-company-user',
    agency_company_user: '/agency-company-user',
    create_account: '/create-account',
    create_password: '/create-password',
    accept_invitation: '/accept-invitation',
    confirm_details: '/confirm-details',
    verify_account: '/verify-account',
    public_education: '/public-education',
    public_post_detail: (postId = ':postId') => `/public-education/post/${postId}`,
    //Auth
    login: '/login',
    register: '/register',
    reset_password: '/reset-password',
    change_email: '/change-email',

    //Not match
    not_match: '/*',
};
