import { SVG } from 'assets/imagePath';
import Image from 'components/Image';
import Step from 'components/Step';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AcceptInvitation, ConfirmDetails, CreatePassword, VerifyAccount } from './components';
function RegisterAgencySubCompanyUser() {
    const [searchParams] = useSearchParams();

    const step = searchParams.get('step') || '1';

    const navigate = useNavigate();

    return (
        <>
            <Image
                onClick={() => navigate('/')}
                src={SVG.logo_agency}
                className="max-w-[200px] mx-auto mb-[40px] w-full cursor-pointer h-auto"
            />
            {step === '1' && <AcceptInvitation name={'Agency'} />}
            {step === '2' && <ConfirmDetails />}
            {step === '3' && <CreatePassword agencyView />}
            {step === '4' && <VerifyAccount />}
            {step !== '3' && <Step maxStep={4} activeStep={step} />}
        </>
    );
}

export default RegisterAgencySubCompanyUser;
