import * as Yup from 'yup';

export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const confirmDetailsSchema = Yup.object({
    first_name: Yup.string().trim(),
    last_name: Yup.string().trim(),
    email: Yup.string().trim(),
    mobile_phone: Yup.string().trim(),
    country: Yup.string().trim(),
    city: Yup.string().trim(),
    street_address: Yup.string().trim(),
    suite_or_apartment: Yup.string().trim(),
    zip_code: Yup.string().trim(),
});

export const createAccountSchema = Yup.object({
    email: Yup.string().trim(),
    first_name: Yup.string().trim(),
    last_name: Yup.string().trim(),
    street_address: Yup.string().trim(),
    suite_or_apartment: Yup.string().trim(),
    city: Yup.string().trim(),
    zip_code: Yup.string().trim(),
    accept_term: Yup.string().trim(),
    company_name: Yup.string().trim(),
    business_street_address: Yup.string().trim(),
    business_suite_or_apartment: Yup.string().trim(),
    business_city: Yup.string().trim(),
    business_zip_code: Yup.string().trim(),
});

export const createPasswordSchema = Yup.object({
    email: Yup.string().trim(),
    first_name: Yup.string().trim(),
    last_name: Yup.string().trim(),
    street_address: Yup.string().trim(),
    suite_or_apartment: Yup.string().trim(),
    city: Yup.string().trim(),
    zip_code: Yup.string().trim(),
    accept_term: Yup.string().trim(),
    company_name: Yup.string().trim(),
    business_street_address: Yup.string().trim(),
    business_suite_or_apartment: Yup.string().trim(),
    business_city: Yup.string().trim(),
    business_zip_code: Yup.string().trim(),
});

export const verifyAccountSchema = Yup.object({
    confirmation_code: Yup.string().trim(),
});
