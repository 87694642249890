import clsx from 'clsx';
import React from 'react';
import BoxLabel from './BoxLabel';

function BoxLabelOutline({ label, children, className }) {
    return (
        <BoxLabel
            variant="outlined"
            shadow="none"
            label={label}
            className={clsx('flex justify-center gap-5 items-center p-[15px] md:p-[30px]', className)}
            classNameLabel={'left-[30px] border-none rounded-none'}
        >
            {children}
        </BoxLabel>
    );
}

export default BoxLabelOutline;
