import Chip from 'components/Chip/Chip';
import { Svg } from 'components/Svg';
import Text from 'components/Text';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function CreateForm({ createFormSuggest }) {
    const location = useLocation();
    const navigate = useNavigate();

    const [isCreateForm, setIsCreateForm] = useState(location?.state?.createSuccess);
    return (
        <div className="create__form">
            {isCreateForm ? (
                //Create Success
                <Chip color="white">
                    <div className="flex flex-row gap-[5px] items-center">
                        <Svg className="w-[15px] h-[15px]" name="link" />
                        <Text size="12">4312 Company Form Page</Text>
                        <Svg
                            className="w-[15px] h-[15px] cursor-pointer"
                            name="close"
                            onClick={() => {
                                window.history.replaceState({}, document.title);
                                setIsCreateForm(false);
                            }}
                        />
                    </div>
                </Chip>
            ) : (
                // Create failed or default
                <>
                    <Text component="span" size="14">
                        Would you like to create company form page for getting company profile details?{' '}
                    </Text>
                    <Text
                        component="button"
                        underline
                        size="14"
                        onClick={() =>
                            navigate(routesPath.create_company_form, {
                                state: { isPermission: true, suggestName: createFormSuggest },
                            })
                        }
                    >
                        Create form page
                    </Text>
                </>
            )}
        </div>
    );
}

export default CreateForm;
