import { usePaymentModule } from 'context/ui/PaymentModuleContext';
import SelectMethod from '../SelectMethod';

function StepAddMethod() {
    const { setStep, setTab } = usePaymentModule();

    const onGetMethod = (method) => {
        setStep(method);

        if (method === 'history') {
            setTab({ label: 'Payment history', value: 'history' });
        }
    };

    return (
        <div>
            <SelectMethod onGetMethod={onGetMethod} />
        </div>
    );
}

export default StepAddMethod;
