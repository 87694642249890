import { IMAGE } from 'assets/imagePath';
import Box from 'components/Box/Box';
import Image from 'components/Image/Image';
import Text from 'components/Text/Text';

function CanvaTemplate({ id, onClick }) {
    return (
        <Box className="bg-white p-[10px_15px] cursor-pointer" onClick={onClick}>
            <div className="flex flex-col">
                {id && <Text>Template #{id}</Text>}

                <div className="relative w-full p-[30px_15px]">
                    <Image src={IMAGE.template_holder} alt="holder" />
                </div>
            </div>
        </Box>
    );
}

export default CanvaTemplate;
