import { SVG } from 'assets/imagePath';
import Avatar from 'components/Avatar/Avatar';
import Box from 'components/Box';

function TopNavUserBox({ image, info }) {
    return (
        <Box className="p-[10px] md:py-[5px] md:px-5 flex flex-row items-center xxs:h-[52px]  gap-x-2.5">
            <Avatar className="w-[20px] h-[20px] xxs:!h-[30px] xxs:!w-[30px] object-contain" src={image} />
            <div className="hidden md:block w-[133px]">
                {info?.name && <p className="text-sm text-blue-dark">{info?.name}</p>}
                {info?.role && <p className="text-xs text-grey-light">{info?.role}</p>}
            </div>
            <div className="hidden md:block">
                <img src={SVG.chevron_down} className="object-cover" alt="icon-chevron-down" />
            </div>
        </Box>
    );
}

export default TopNavUserBox;
