import { BoxContent } from 'components/BoxContent';
import { ChatBox, ChatInput } from 'components/ChatBox';
import { chatContents } from 'components/ChatBox/fakeData';
import { useAdCanva } from 'context/AdCanvaContext';
import { useFormikContext } from 'formik';
import { CanvasRequestInfo, DesignerCreative, DesignApproved, DesignInProgress, DesignReview } from '../components';

function DesignerProgress({ children, lastStep }) {
    const { setStep, step, template } = useAdCanva();

    const { handleSubmit } = useFormikContext();

    const onNext = () => {
        if (lastStep) {
            handleSubmit((data) => {
                console.log(data);
            });
            return;
        } else {
            setStep((prev) => prev + 1);
        }
    };

    return (
        <div className="flex flex-col gap-5 mt-5">
            <div className="flex flex-col md:flex-row gap-5">
                <div className="md:w-[300px]">
                    {template.status === 'in_progress' && <DesignInProgress />}
                    {template.status === 'review' && <DesignReview />}
                    {template.status === 'approve' && <DesignApproved />}
                </div>

                <div className="flex-1">
                    <div className="flex flex-col gap-[30px] w-full">
                        <CanvasRequestInfo />
                        <DesignerCreative />

                        <BoxContent title={'Comments'} className=" max-w-[820px]">
                            <div className="flex flex-col gap-5 ">
                                <ChatBox chatContents={chatContents} />
                                <ChatInput placeholder="Message" />
                            </div>
                        </BoxContent>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DesignerProgress;
