import { IMAGE } from 'assets/imagePath';

export const displayRow = [
    {
        id: '1234',
        review: 'approved',
        attachment: IMAGE.home_map,
        format: 'PNG',
        dimensions: '320x50',
        size: '35 KB',
        attributes: '',
    },
    {
        id: '1234',
        review: 'rejected',
        attachment: IMAGE.no_image,
        format: 'PNG',
        dimensions: '320x250',
        size: '35 KB',
        attributes: '',
    },
    {
        id: '1234',
        review: 'rejected',
        attachment: IMAGE.no_image,
        format: 'PNG',
        dimensions: '300x601',
        size: '35 KB',
        attributes: '',
    },
];

export const nativeRow = [
    {
        id: '1234',
        review: 'approved',
        attachment: IMAGE.no_image,
        format: 'PNG',
        dimensions: '320x50',
        size: '103 KB',
        attributes: 'Title, Description',
    },
    {
        id: '1234',
        review: 'rejected',
        attachment: IMAGE.no_image,
        format: 'PNG',
        dimensions: '320x250',
        size: '35 KB',
        attributes: '',
    },
    {
        id: '1234',
        review: 'rejected',
        attachment: IMAGE.no_image,
        format: 'PNG',
        dimensions: '300x601',
        size: '35 KB',
        attributes: '',
    },
];
export const videoRow = [
    {
        id: '1234',
        review: 'approved',
        attachment: IMAGE.no_image,
        format: 'MP4',
        dimensions: '320x50',
        size: '103 KB',
        attributes: '',
    },
    {
        id: '1234',
        review: 'rejected',
        attachment: IMAGE.no_image,
        format: 'MP4',
        dimensions: '320x250',
        size: '35 KB',
        attributes: '',
    },
    {
        id: '1234',
        review: 'rejected',
        attachment: IMAGE.no_image,
        format: 'MP4',
        dimensions: '300x601',
        size: '35 KB',
        attributes: 'Video targeting settings',
    },
];
