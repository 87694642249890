import { SVG } from 'assets/imagePath';
import Chip from 'components/Chip/Chip';
import ActionsButton from 'components/GridTable/ActionsButton';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

export const blockchainPaymentCol = [
    {
        field: 'id',
        headerName: 'Id',
    },
    {
        field: 'service',
        headerName: 'Service',
    },

    {
        field: 'status',
        headerName: 'Status',

        renderCell: (row) => <Chip color="success">{row?.status}</Chip>,
    },

    {
        field: 'timestamp',
        headerName: 'Timestamp',
    },
    {
        field: 'amount',
        headerName: 'Amount',
    },
    {
        field: 'currency',
        headerName: 'Currency',
    },
    {
        field: 'hash',
        headerName: 'Hash',
    },
    {
        field: 'block',
        headerName: 'Block',
        hideDetail: true,
    },
    {
        field: 'from',
        headerName: 'From',
        hideDetail: true,
    },
    {
        field: 'to',
        headerName: 'To',
        hideDetail: true,
    },
    {
        field: 'txn_fee',
        headerName: 'TXN Fee',
    },
    {
        field: 'gas_price',
        headerName: 'GAS Price',
    },
    {
        field: 'actions',
        headerName: 'Actions',
        hideDetail: true,
        renderCell: (row) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const navigate = useNavigate();
            return (
                <button
                    className="flex justify-center items-center p-0 md:p-[15px]"
                    onClick={() =>
                        navigate(routesPath.payment_detail(row?.id), {
                            state: {
                                type: 'blockchain',
                            },
                        })
                    }
                >
                    <img className="mx-auto object-contain cursor-pointer" src={SVG.more_vert} alt="more" />
                </button>
            );
        },
    },
];

export const cardPaymentCol = [
    {
        field: 'id',
        headerName: 'Id',
    },
    {
        field: 'service',
        headerName: 'Service',
    },

    {
        field: 'campaign',
        headerName: 'Campaign',
        hideCol: true,
    },
    {
        field: 'order',
        headerName: 'Order',
        hideCol: true,
    },

    {
        field: 'status',
        headerName: 'Status',
        renderCell: (row) => <Chip color="success">{row?.status}</Chip>,
    },
    {
        field: 'type',
        headerName: 'Type',
        hideCol: true,
    },
    {
        field: 'method',
        headerName: 'Method',
        hideCol: true,
    },
    {
        field: 'timestamp',
        headerName: 'Timestamp',
    },
    {
        field: 'amount',
        headerName: 'Amount',
    },
    {
        field: 'currency',
        headerName: 'Currency',
    },
    {
        field: 'authcode',
        headerName: 'Authcode',
    },
    {
        field: 'resptext',
        headerName: 'Resptext',
    },
    {
        field: 'setlstat',
        headerName: 'Setlstat',
        hideCol: true,
    },
    {
        field: 'commcard',
        headerName: 'Commcard',
        hideCol: true,
    },
   

    {
        field: 'retref',
        headerName: 'Retref',
    },
    {
        field: 'respcode',
        headerName: 'Respcode',
    },
    {
        field: 'batchid',
        headerName: 'Batchid',
    },

    {
        field: 'actions',
        headerName: 'Actions',
        hideDetail: true,
        renderCell: (row, handle) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const options = [
                { label: 'View', value: 'view' },
                { label: 'Refund', value: 'refund' },
            ];

            return <ActionsButton onChange={(item) => handle(item, row)} options={options} />;
        },
    },
];

export const blockchainPaymentRow = [
    {
        id: 324,
        service: 'Campaign 4',
        status: 'Paid',
        timestamp: '307 days ago',
        amount: '0.317906620228751472',
        currency: 'ETH',
        hash: '0x23b3cb8f4513014c25333f03ecdf36883a448c33b27e1c4f7344bf28a8bf1d10',
        block: '14663759',
        from: '0x232aaA2471475d3d134C0aeA22a1402E507597AF',
        to: '0x292aaA2471475d3d134C0aeA22a1402E507597AF',
        txn_fee: '0.001609699128429',
        gas_price: '76.652339449',
        authcode: '046221',
        resptext: 'Approval',
        retref: '121312124234',
        respcode: '00',
        batchid: '13565445',
    },
    {
        id: 324,
        service: 'Campaign 4',
        status: 'Paid',
        timestamp: '307 days ago',
        amount: '0.317906620228751472',
        currency: 'ETH',
        hash: '0x23b3cb8f4513014c25333f03ecdf36883a448c33b27e1c4f7344bf28a8bf1d10',
        block: '14663759',
        from: '0x232aaA2471475d3d134C0aeA22a1402E507597AF',
        to: '0x292aaA2471475d3d134C0aeA22a1402E507597AF',
        txn_fee: '0.001609699128429',
        gas_price: '76.652339449',
        authcode: '046221',
        resptext: 'Approval',
        retref: '121312124234',
        respcode: '00',
        batchid: '13565445',
    },
    {
        id: 324,
        service: 'Campaign 4',
        status: 'Paid',
        timestamp: '307 days ago',
        amount: '0.317906620228751472',
        currency: 'ETH',
        hash: '0x23b3cb8f4513014c25333f03ecdf36883a448c33b27e1c4f7344bf28a8bf1d10',
        block: '14663759',
        from: '0x232aaA2471475d3d134C0aeA22a1402E507597AF',
        to: '0x292aaA2471475d3d134C0aeA22a1402E507597AF',
        txn_fee: '0.001609699128429',
        gas_price: '76.652339449',
        authcode: '046221',
        resptext: 'Approval',
        retref: '121312124234',
        respcode: '00',
        batchid: '13565445',
    },
    {
        id: 324,
        service: 'Campaign 4',
        status: 'Paid',
        timestamp: '307 days ago',
        amount: '0.317906620228751472',
        currency: 'ETH',
        hash: '0x23b3cb8f4513014c25333f03ecdf36883a448c33b27e1c4f7344bf28a8bf1d10',
        block: '14663759',
        from: '0x232aaA2471475d3d134C0aeA22a1402E507597AF',
        to: '0x292aaA2471475d3d134C0aeA22a1402E507597AF',
        txn_fee: '0.001609699128429',
        gas_price: '76.652339449',
        authcode: '046221',
        resptext: 'Approval',
        retref: '121312124234',
        respcode: '00',
        batchid: '13565445',
    },
    {
        id: 324,
        service: 'Campaign 4',
        status: 'Paid',
        timestamp: '307 days ago',
        amount: '0.317906620228751472',
        currency: 'ETH',
        hash: '0x23b3cb8f4513014c25333f03ecdf36883a448c33b27e1c4f7344bf28a8bf1d10',
        block: '14663759',
        from: '0x232aaA2471475d3d134C0aeA22a1402E507597AF',
        to: '0x292aaA2471475d3d134C0aeA22a1402E507597AF',
        txn_fee: '0.001609699128429',
        gas_price: '76.652339449',
        authcode: '046221',
        resptext: 'Approval',
        retref: '121312124234',
        respcode: '00',
        batchid: '13565445',
    },
    {
        id: 324,
        service: 'Campaign 4',
        status: 'Paid',
        timestamp: '307 days ago',
        amount: '0.317906620228751472',
        currency: 'ETH',
        hash: '0x23b3cb8f4513014c25333f03ecdf36883a448c33b27e1c4f7344bf28a8bf1d10',
        block: '14663759',
        from: '0x232aaA2471475d3d134C0aeA22a1402E507597AF',
        to: '0x292aaA2471475d3d134C0aeA22a1402E507597AF',
        txn_fee: '0.001609699128429',
        gas_price: '76.652339449',
        authcode: '046221',
        resptext: 'Approval',
        retref: '121312124234',
        respcode: '00',
        batchid: '13565445',
    },
    {
        id: 324,
        service: 'Campaign 4',
        status: 'Paid',
        timestamp: '307 days ago',
        amount: '0.317906620228751472',
        currency: 'ETH',
        hash: '0x23b3cb8f4513014c25333f03ecdf36883a448c33b27e1c4f7344bf28a8bf1d10',
        block: '14663759',
        from: '0x232aaA2471475d3d134C0aeA22a1402E507597AF',
        to: '0x292aaA2471475d3d134C0aeA22a1402E507597AF',
        txn_fee: '0.001609699128429',
        gas_price: '76.652339449',
        authcode: '046221',
        resptext: 'Approval',
        retref: '121312124234',
        respcode: '00',
        batchid: '13565445',
    },
    {
        id: 324,
        service: 'Campaign 4',
        status: 'Paid',
        timestamp: '307 days ago',
        amount: '0.317906620228751472',
        currency: 'ETH',
        hash: '0x23b3cb8f4513014c25333f03ecdf36883a448c33b27e1c4f7344bf28a8bf1d10',
        block: '14663759',
        from: '0x232aaA2471475d3d134C0aeA22a1402E507597AF',
        to: '0x292aaA2471475d3d134C0aeA22a1402E507597AF',
        txn_fee: '0.001609699128429',
        gas_price: '76.652339449',
        authcode: '046221',
        resptext: 'Approval',
        retref: '121312124234',
        respcode: '00',
        batchid: '13565445',
    },
    {
        id: 324,
        service: 'Campaign 4',
        status: 'Paid',
        timestamp: '307 days ago',
        amount: '0.317906620228751472',
        currency: 'ETH',
        hash: '0x23b3cb8f4513014c25333f03ecdf36883a448c33b27e1c4f7344bf28a8bf1d10',
        block: '14663759',
        from: '0x232aaA2471475d3d134C0aeA22a1402E507597AF',
        to: '0x292aaA2471475d3d134C0aeA22a1402E507597AF',
        txn_fee: '0.001609699128429',
        gas_price: '76.652339449',
        authcode: '046221',
        resptext: 'Approval',
        retref: '121312124234',
        respcode: '00',
        batchid: '13565445',
    },
];
