import clsx from 'clsx';
import Text from 'components/Text';
import React from 'react';

function RangeReview({ className, from, to, value, classNameBox, classNameRange, colorProgress = 'bg-primary' }) {
    return (
        <div className={clsx('flex flex-col gap-2.5 w-full py-[5px]', classNameBox)}>
            <div className={clsx('w-full h-[5px] relative bg-transparent overflow-hidden rounded-full', className)}>
                <div
                    className={clsx('w-full h-full absolute bg-primary opacity-[0.25]', colorProgress, classNameRange)}
                />
                <div
                    className={clsx('w-full h-full absolute bg-primary opacity-100', colorProgress, classNameRange)}
                    style={{ width: `${value}%` }}
                />
            </div>
            {(from || to) && (
                <div className="flex items-center justify-between">
                    <Text size="10">{from}</Text>
                    <Text size="10">{to}</Text>
                </div>
            )}
        </div>
    );
}

export default RangeReview;
