import Chip from 'components/Chip/Chip';
import ActionsButton from 'components/GridTable/ActionsButton';

export const leadCol = [
    {
        field: 'id',
        headerName: 'ID',
    },
    {
        field: 'first_name',
        headerName: 'First Name',
    },
    {
        field: 'last_name',
        headerName: 'Last Name',
    },
    {
        field: 'status',
        headerName: 'Status',
        renderCell: (row) => {
            return <Chip color="white">{row?.status}</Chip>;
        },
    },
    {
        field: 'company',
        headerName: 'Company',
    },
    {
        field: 'country',
        headerName: 'Coutry',
    },
    {
        field: 'email',
        headerName: 'Email',
    },
    {
        field: 'phone_num',
        headerName: 'Phone Number',
    },
    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row, handle) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return (
                <ActionsButton
                    onChange={(value) => {
                        handle(row, value);
                    }}
                />
            );
        },
    },
];

export const leadRow = [
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Lead',
        company: 'Company',
        country: 'Country',
        email: 'johnwood@gmail.com',
        phone_num: '123-456-7890',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Lead',
        company: 'Company',
        country: 'Country',
        email: 'johnwood@gmail.com',
        phone_num: '123-456-7890',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Lead',
        company: 'Company',
        country: 'Country',
        email: 'johnwood@gmail.com',
        phone_num: '123-456-7890',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Lead',
        company: 'Company',
        country: 'Country',
        email: 'johnwood@gmail.com',
        phone_num: '123-456-7890',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Lead',
        company: 'Company',
        country: 'Country',
        email: 'johnwood@gmail.com',
        phone_num: '123-456-7890',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Lead',
        company: 'Company',
        country: 'Country',
        email: 'johnwood@gmail.com',
        phone_num: '123-456-7890',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Lead',
        company: 'Company',
        country: 'Country',
        email: 'johnwood@gmail.com',
        phone_num: '123-456-7890',
    },
];
