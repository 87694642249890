import { IMAGE } from 'assets/imagePath';
import { Button } from 'components/Button';
import Image from 'components/Image';
import InlineHtml from 'components/InlineHtml/InlineHtml';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import { useMemo } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { introduceData } from './data';

const Introduce = ({ offer = '' }) => {
    const { scrollToView } = useOutletContext();
    const navigate = useNavigate();
    const data = useMemo(() => introduceData[offer.toLowerCase()], [offer]);

    return (
        <section className="relative w-full z-10 flex flex-col gap-[45px] px-5 py-[60px]">
            <div className="max-w-[640px] flex flex-col items-center mx-auto">
                <div className="p-5 rounded-full bg-white-light shadow-outer">
                    <Svg name={data?.icon} className="svg-path:fill-black md:w-[64px] md:h-[64px]" sizeIcon={'64'} />
                </div>
                <Text
                    size="custom"
                    family="sens"
                    weight="bold"
                    className="text-3xl md:text-5xl tracking-tighter text-center my-[30px] mb-5"
                >
                    {data?.title}
                </Text>
                <Text size="16" weight="medium" className="text-center">
                    <InlineHtml html={data?.html} />
                </Text>
            </div>

            <div className="mx-auto gap-2.5">
                <Button
                    className={'flex-[5] h-[50px] px-[15px] mr-2.5'}
                    color="secondary"
                    onClick={() => {
                        scrollToView('request_demo');
                    }}
                >
                    Request a Live Demo
                </Button>
                <Button className={'flex-[2] h-[50px]'} onClick={() => navigate(routesPath.login)}>
                    Sign up
                </Button>
            </div>

            <div className="max-w-[960px] w-full mx-auto">
                <Image src={IMAGE.hero_industry} className="object-cover" />
            </div>
        </section>
    );
};

export default Introduce;
