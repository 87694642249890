import { HeaderTableSummary } from 'components/GridTable';
import GridTable from 'components/GridTable/GridTable';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { resourceColumn, resourceRow } from './data';

function EducationOrg({}) {
    const navigate = useNavigate();

    const handleActionTable = (row, action, tableName) => {
        switch (tableName) {
            case 'POST': {
                switch (action.value) {
                    case 'view':
                        navigate(routesPath.post_review(row.id));
                        break;

                    default:
                        break;
                }
                break;
            }
            case 'CATEGORY': {
                switch (action.value) {
                    case 'view':
                        navigate(routesPath.post_review(row.id));
                        break;

                    default:
                        break;
                }
                break;
            }

            default:
                break;
        }
    };

    return (
        <div>
            <div className="flex flex-col gap-5">
                <HeaderTableSummary
                    title="Posts"
                    viewAll={() => navigate(routesPath.posts_management)}
                    add={{ label: 'Add Post', onAdd: () => navigate(routesPath.add_post) }}
                />
                <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                    <GridTable
                        className={'w-full table-fixed'}
                        column={resourceColumn}
                        row={resourceRow.slice(0, 5)}
                        handle={(row, action) => {
                            handleActionTable(row, action, 'POST');
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-5 mt-[30px]">
                <HeaderTableSummary
                    title="Categories"
                    viewAll={() => navigate(routesPath.categories_management)}
                    add={{ label: 'Add Category', onAdd: () => navigate(routesPath.add) }}
                />
                <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                    <GridTable
                        className={'w-full table-fixed'}
                        column={resourceColumn}
                        row={resourceRow.slice(0, 5)}
                        handle={(row, action) => {
                            handleActionTable(row, action, 'CATEGORY');
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default EducationOrg;
