import DatePicker from 'components/Input/DatePicker';
import RangeDatePicker from 'components/Input/RangeDatePicker';
import { SubTitle } from 'components/Title';
import FormDoubleRange from 'components/modules/form/FormDoubleRange';
import FormRangeDatePicker from 'components/modules/form/FormRangeDatePicker';

function FormFilterCampaign() {
    return (
        <div>
            <SubTitle className={'mb-2.5'}>Budged</SubTitle>
            <FormDoubleRange name="budged" />

            <SubTitle className={'mb-2.5'}>Range Date</SubTitle>
            <FormRangeDatePicker name="rangeDate" />
        </div>
    );
}

export default FormFilterCampaign;
