import clsx from 'clsx';
import { Collapse } from 'components/Collapse';
import { Checkbox } from 'components/Input';
import Text from 'components/Text/Text';
import React, { useMemo } from 'react';
import { BiChevronDown } from 'react-icons/bi';

const sizes = [
    {
        label: 'Medium Rectangle (300x250)',
        value: 'medium_rec',
        package: 1,
    },
    {
        label: 'Leaderboard (728x90)',
        value: 'leader_board',
        package: 1,
    },
    {
        label: 'Mobile leaderboard (320x50)',
        value: 'mobile_leader_board',
        package: 1,
    },
    {
        label: 'Skyscraper (160x600)',
        value: 'skyscraper',
        package: 2,
    },
    {
        label: 'Mobile Banner (300x50)',
        value: 'mobile_banner',
        package: 2,
    },
    {
        label: 'Half Page (300x600)',
        value: 'half_page',
        package: 2,
    },
    {
        label: 'Billboard (970x250)',
        value: 'billboard',
        package: 3,
    },
    {
        label: 'Super Leaderboard / Pushdown (970x250)',
        value: 'super_leader_board',
        package: 3,
    },
    {
        label: 'Portrait / Pushdown (300x1050)',
        value: 'portrait',
        package: 3,
    },
    {
        label: 'Vertical Interstitial Mobile (320x480)',
        value: 'vertical_interstitial_mb',
        package: 3,
    },
    {
        label: 'Horizontal Interstitial Mobile (480x320)',
        value: 'horizontal_interstitial_mb',
        package: 3,
    },
    {
        label: 'Vertical Interstitial Table (768x1024)',
        value: 'vertical_interstitial_tb',
        package: 3,
    },
    {
        label: 'Horizontal Interstitial Table (768x1024)',
        value: 'horizontal_interstitial_tb',
        package: 3,
    },
];

const CollapseContainer = ({ levelPackage, setSelected, selected }) => {
    const checkedItem = (checked, select) => {
        const currentSelected = [...selected];
        if (checked) {
            setSelected([...currentSelected, select]);
        } else {
            setSelected(currentSelected.filter((item) => item.value !== select.value));
        }
    };

    return (
        <div className="flex flex-col">
            {sizes.map((item) => (
                <div
                    className={clsx(
                        'flex items-center justify-between px-5 py-1.5 sm:px-10 sm:py-2.5',
                        levelPackage >= item.package ? 'flex' : 'hidden',
                    )}
                    key={item.value}
                >
                    <Checkbox
                        onChange={(e) => checkedItem(e.target.checked, item)}
                        checked={selected.some((select) => select.value === item.value)}
                        Label={<Text className="text-xxs sm:text-xs">{item.label}</Text>}
                    />
                </div>
            ))}
        </div>
    );
};

function SelectedList({ ...props }) {
    const { onCloseDropdown, setSelected, selected } = props;

    const basic_package = sizes.filter((item) => item.package === 1);
    const complete_package = sizes.filter((item) => item.package <= 2);

    const isCheckedPackage = useMemo(() => {
        return {
            classic: selected.filter((item) => item.package === 1).length === basic_package.length,
            complete: selected.filter((item) => item.package <= 2).length === complete_package.length,
            full: selected.filter((item) => item.package <= 3).length === sizes.length,
        };
    }, [selected]);

    const handleCheckedPackaged = (checked, type) => {
        if (checked) {
            switch (type) {
                case 'basic':
                    setSelected(basic_package);
                    break;
                case 'complete':
                    setSelected(complete_package);
                    break;
                default:
                    setSelected(sizes);
            }
        } else {
            setSelected([]);
        }
    };

    return (
        <div className="w-full h-full">
            <Collapse
                renderButton={({ toggle, isOpen }) => (
                    <div className="flex items-center justify-between px-2.5 py-1.5 sm:px-5 sm:py-2.5">
                        <Checkbox
                            onChange={(e) => handleCheckedPackaged(e.target.checked, 'basic')}
                            classNameBox="w-full"
                            checked={isCheckedPackage.classic}
                            Label={<Text className="text-xxs sm:text-xs">Basic Package</Text>}
                        />
                        <BiChevronDown className={clsx('text-[16px]', isOpen ? '' : 'rotate-180')} onClick={toggle} />
                    </div>
                )}
            >
                <CollapseContainer levelPackage={1} {...props} />
            </Collapse>

            <Collapse
                renderButton={({ toggle, isOpen }) => (
                    <div className="flex items-center justify-between px-2.5 py-1.5 sm:px-5 sm:py-2.5">
                        <Checkbox
                            onChange={(e) => handleCheckedPackaged(e.target.checked, 'complete')}
                            classNameBox="w-full"
                            checked={isCheckedPackage.complete}
                            Label={<Text className="text-xxs sm:text-xs">Complete Package</Text>}
                        />
                        <BiChevronDown className={clsx('text-[16px]', isOpen ? '' : 'rotate-180')} onClick={toggle} />
                    </div>
                )}
            >
                <CollapseContainer levelPackage={2} {...props} />
            </Collapse>

            <Collapse
                renderButton={({ toggle, isOpen }) => (
                    <div className="flex items-center justify-between px-2.5 py-1.5 sm:px-5 sm:py-2.5">
                        <Checkbox
                            onChange={(e) => handleCheckedPackaged(e.target.checked)}
                            classNameBox="w-full"
                            checked={isCheckedPackage.full}
                            Label={<Text className="text-xxs sm:text-xs">Full Package</Text>}
                        />
                        <BiChevronDown className={clsx('text-[16px]', isOpen ? '' : 'rotate-180')} onClick={toggle} />
                    </div>
                )}
            >
                <CollapseContainer levelPackage={3} {...props} />
            </Collapse>
        </div>
    );
}

export default SelectedList;
