import { Button } from 'components/Button';
import { SubTitle } from 'components/Title';
import FormSelect from 'components/modules/form/FormSelect';
import FormUpload from 'components/modules/form/FormUpload';
import { useState } from 'react';
import { formatBytes, getTypeFile } from 'utils';

const attachment_methods = [{ label: 'Upload', value: 'upload' }];

function MediaDisplayDetail({ formValues, data }) {
    const { type, width, height, size } = formValues?.attachment_image || {};
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <div className="flex flex-col gap-[30px]">
                <div className="flex flex-col w-full h-full gap-5">
                    <SubTitle className={'mt-7'}>Attachment</SubTitle>
                    <div className="flex flex-col gap-5 xs:flex-row">
                        <FormSelect
                            options={attachment_methods}
                            name="attachment_type"
                            placeholder="Type"
                            placeholderTop
                            propsDropdown={{ classNameBox: 'flex-1' }}
                        />
                        <Button type="button" color="secondary" onClick={() => setIsOpen(true)}>
                            Create Using AdCanva
                        </Button>
                    </div>
                    <FormUpload
                        className="max-h-[500px] h-[200px]"
                        label="Attachment"
                        name="attachment_image"
                        helper={
                            formValues?.attachment_image &&
                            `Size: ${width}x${height}, Format:  ${getTypeFile(type)}, Size: ${formatBytes(size)}`
                        }
                    />
                </div>
                {/* <div className="flex flex-col gap-5">
                    <SubTitle className={'mt-7'}>Attributes</SubTitle>
                    <FormInputText name="url" placeholder="URL (Optional)" />
                </div> */}

                {/* <div className="max-w-[900px]">
                    <ReviewBlock reviewStatus={data?.review} />
                </div> */}
            </div>
        </>
    );
}

export default MediaDisplayDetail;
