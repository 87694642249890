import React, { useEffect, useRef, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { AiOutlineSearch } from 'react-icons/ai';
import { useClickOutside } from 'hooks';

const SelectSearch = ({ onSelectCountry }) => {
    const [countries, setCountries] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [selected, setSelected] = useState('');
    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    useClickOutside(ref, () => setOpen(false));

    useEffect(() => {
        fetch('https://restcountries.com/v2/all?fields=name')
            .then((res) => res.json())
            .then((data) => {
                setCountries(data);
            });
    }, []);
    return (
        <div className="relative">
            <div
                onClick={() => setOpen(!open)}
                className={`bg-white shadow-default w-full px-5 py-[15px] flex items-center text-[14px] justify-between rounded-[10px]  ${
                    !selected && 'text-gray-700 bg-white-light'
                }`}
            >
                {selected ? (selected?.length > 25 ? selected?.substring(0, 25) + '...' : selected) : 'Country'}
                <BiChevronDown size={20} className={`${open && 'rotate-180'}`} />
            </div>
            <ul
                ref={ref}
                className={`absolute w-full  z-10 top-0 left-0 bg-white shadow-default border border-blue-cfd rounded-[10px] mt-2 overflow-y-auto ${
                    open ? 'max-h-60' : 'max-h-0 border-0'
                } `}
            >
                <div className="sticky top-0 z-10 flex items-center px-2 bg-white border-b border-blue-cfd">
                    <AiOutlineSearch size={18} className="text-gray-700" />
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value.toLowerCase())}
                        placeholder="Enter country name"
                        className="w-full p-2 outline-none placeholder:text-gray-700 "
                    />
                </div>
                {countries?.map((country) => (
                    <li
                        key={country?.name}
                        className={`p-2 text-sm hover:bg-sky-600 hover:text-white
            ${country?.name?.toLowerCase() === selected?.toLowerCase() && 'bg-sky-600 text-white'}
            ${country?.name?.toLowerCase().startsWith(inputValue) ? 'block' : 'hidden'}`}
                        onClick={(e) => {
                            if (country?.name?.toLowerCase() !== selected.toLowerCase()) {
                                setSelected(country?.name);
                                onSelectCountry?.(country);
                                setOpen(false);
                                setInputValue('');
                            }
                        }}
                    >
                        {country?.name}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SelectSearch;
