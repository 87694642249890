import Text from 'components/Text';
import { SubTitle } from 'components/Title';
import React from 'react';

function AudienceSummary() {
    return (
        <div className="flex flex-col gap-5">
            <Text size="18" weight="bold" className=" mt-7">
                Audience Summary
            </Text>

            <div className="p-[15px] bg-white-light rounded-[15px] shadow-default">
                <div className="flex flex-col gap-y-5">
                    <div className="flex flex-col ">
                        <h2 className="text-xs font-semibold ">Details</h2>
                        <div className="flex justify-between items-center h-[25px]">
                            <p className="text-xs font-normal">Name</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                    </div>
                    <div className="flex flex-col ">
                        <h2 className="text-xs font-semibold ">Type</h2>
                        <div className="flex justify-between items-center h-[25px]">
                            <p className="text-xs font-normal">Type</p>
                            <p className="text-xs font-normal">Location</p>
                        </div>
                    </div>
                    <div className="flex flex-col ">
                        <h2 className="text-xs font-semibold">Location</h2>

                        <div className="flex justify-between items-center h-[25px]">
                            <p className="text-xs font-normal">323423</p>
                            <p className="text-xs font-normal">$1.24 (35K)</p>
                        </div>
                        <div className="flex justify-between items-center h-[25px]">
                            <p className="text-xs font-normal">332428</p>
                            <p className="text-xs font-normal">$1.01 (10K)</p>
                        </div>
                        {/* <div className="flex justify-between items-center h-[25px]">
                            <p className="text-xs font-normal">Country</p>
                            <p className="text-xs font-normal">+ US</p>
                        </div>
                        <div className="flex justify-between items-center h-[25px]">
                            <p className="text-xs font-normal">Region</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                        <div className="flex justify-between items-center h-[25px]">
                            <p className="text-xs font-normal">City</p>
                            <p className="text-xs font-normal">- Los Angeles</p>
                        </div>
                        <div className="flex justify-between items-center h-[25px]">
                            <p className="text-xs font-normal">Cordinates</p>
                            <p className="text-xs font-normal">+ Unnamed Location</p>
                        </div> */}
                    </div>

                    <div className="flex flex-col">
                        <div className="flex justify-between items-center h-[25px]">
                            <Text size="12" weight="bold">
                                Total Avails
                            </Text>
                            <Text size="12" weight="bold">
                                25K
                            </Text>
                        </div>
                        <div className="flex justify-between items-center h-[25px]">
                            <Text size="12" weight="bold">
                                Total Price
                            </Text>
                            <Text size="12" weight="bold">
                                $2.25
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AudienceSummary;
