import { SelectMulti } from 'components/Input';
import FormInputText from 'components/modules/form/FormInputText';
import FormSelectMulti from 'components/modules/form/FormSelectMulti';
import { SubTitle } from 'components/Title';
import React from 'react';
import CollapseLocationTargeting from './CollapseLocationTargeting';

const countries = [
    { label: 'USA', value: 'US' },
    { label: 'Canada', value: 'CAN' },
];

const cities = [
    { label: 'Miami', value: 'miami' },
    { label: 'Los Angeles', value: 'los_angeles' },
];
function LocationTargeting() {
    return (
        <div className="flex flex-col gap-5">
            <SubTitle className={'py-[13px]'}>Location Targeting</SubTitle>
            <FormSelectMulti name="country" placeholder={'Country'} options={countries} chipProps={{ icon: true }} />
            <FormInputText name="zip" placeholder="Zip Codes" label="Zip Codes" />
            <FormSelectMulti name="city" placeholder={'City'} options={cities} chipProps={{ icon: true }} />
        </div>
    );
}

export default LocationTargeting;
