import SideBar from 'components/SideBar';
import TopNav from 'components/TopNav';
import { MENU } from 'constants/menu';
import { Outlet } from 'react-router-dom';

export const DashboardLayout = () => {
    // const { user } = useAuth();

    // if (!user) {
    //   return <Navigate to="/" />;
    // }

    return (
        <div className="flex flex-col w-full h-full min-h-screen overflow-auto overflow-x-hidden">
            <TopNav />
            <div className="flex flex-col flex-1 w-full h-full lg:flex-row ">
                <div className="p-[15px] lg:p-[30px]  overflow-auto  bg-blue-ebe border-r border-blue-cfd">
                    <SideBar linkRoutes={MENU} />
                </div>
                <div className="flex-1 bg-blue-ebe">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};
