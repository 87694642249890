import AccountEmail from './AccountEmail';
import ChangePassword from './ChangePassword';
import CompanySubscriptions from './CompanySubscriptions';
import DeactivateAccount from './DeactivateAccount';
import Signatures from './Signatures';

function SettingsCompany() {
    return (
        <div className="flex flex-col gap-y-[30px] max-w-[820px]">
            <CompanySubscriptions />
            <AccountEmail />
            <ChangePassword />
            <Signatures />
            <DeactivateAccount />
        </div>
    );
}

export default SettingsCompany;
