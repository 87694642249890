import { Button } from 'components/Button';
import Text from 'components/Text/Text';
import FormInputPassword from 'components/modules/form/FormInputPassword';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import * as Yup from 'yup';

function ResetCreateNewPw({ setStep }) {
    const schema = Yup.object({
        new_password: Yup.string().required('Password is required'),
        confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    });

    const navigate = useNavigate();
    return (
        <div>
            <Text className="mb-5 text-center" weight="bold" size="24">
                Create New Password
            </Text>

            <Formik
                className=""
                initialValues={{}}
                onSubmit={(values, actions) => {
                    navigate(routesPath.login, { replace: true });
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                    }, 0);
                }}
                validationSchema={schema}
                validateOnChange
            >
                <Form>
                    <FormInputPassword className="mb-[20px]" placeholder="New Password" rounded name="new_password" />
                    <FormInputPassword
                        className="mb-[20px]"
                        placeholder="Confirm Password"
                        rounded
                        name="confirm_password"
                    />

                    <Button
                        type="submit"
                        className="mx-auto flex  justify-center w-full max-w-[260px] py-[15px] px-5 mb-[20px] shadow-outer"
                        onClick={() => {
                            setStep(1);
                            navigate(routesPath.login);
                        }}
                    >
                        Confirm
                    </Button>
                </Form>
            </Formik>
        </div>
    );
}

export default ResetCreateNewPw;
