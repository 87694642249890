import Box from 'components/Box';
import React from 'react';
import RowDetail from './RowDetail';

function TableMobile({ col, rows, handle }) {
    return (
        <Box
            variant="outlined"
            className="flex flex-col divide-y md:hidden divide-blue-cfd bg-white-light"
            shadow="default"
        >
            {rows.map((row, idx) => (
                <div key={idx} className="p-2.5">
                    <RowDetail column={col} row={row} handle={handle} />
                </div>
            ))}
        </Box>
    );
}

export default TableMobile;
