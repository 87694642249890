import RangeDatePicker from 'components/Input/RangeDatePicker';
import RangeSlide from 'components/Input/RangeSlide';
import { useField } from 'formik';

function FormRangeDatePicker(props) {
    const [field, meta, helpers] = useField(props);

    const { setValue: setValueFormik } = helpers;
    return <RangeDatePicker helper={meta.error} error={meta.error} {...field} onChange={setValueFormik} {...props} />;
}

export default FormRangeDatePicker;
