export const customTooltips = function (context, callbackRenderHtml) {
    // Tooltip Element
    let tooltipEl = document.getElementById('chartjs-tooltip');

    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.innerHTML = '<table></table>';
        document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = '0';
        return;
    }

    // Set caret Position (above, below,no-transform ).As I need above I don't delete that class
    tooltipEl.classList.remove('below', 'no-transform');

    // Set HTML & Data
    if (tooltipModel.body) {
        const dataFromCurrentElement = tooltipModel.dataPoints[0];
        const currentElement = dataFromCurrentElement.dataIndex;

        const formattedValue = dataFromCurrentElement.formattedValue.trim();
        const currentDataToShow = formattedValue.substr(1, formattedValue.length - 2).split(' ');
        const innerHtml = callbackRenderHtml({ dataFromCurrentElement });

        tooltipEl.querySelector('table').innerHTML = innerHtml;
    }

    const position = context.chart.canvas.getBoundingClientRect();

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX - tooltipEl.clientWidth / 2 + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY - tooltipEl.clientHeight - 15 + 'px';
    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
    tooltipEl.style.zIndex = 10000;

    tooltipEl.style.pointerEvents = 'none';
};
