import { Circle } from 'components/Circle';
import { InputText } from 'components/Input';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import { useClickOutside } from 'hooks';
import { useRef, useState } from 'react';
import { metersToMiles } from 'utils';

const LocationItem = ({ isWarning, onRemove = () => {}, onHoverRemove, place, renderIcon, inputProps }) => {
    const [editName, setEditName] = useState(false);

    const nameRef = useRef(null);

    useClickOutside(nameRef, () => setEditName(false));

    const [isHoveringRemove, setIsHoveringRemove] = useState(false);

    return (
        <div className="flex flex-row border border-blue-cfd items-center gap-2.5 w-full bg-white-light rounded-[10px] p-2.5">
            <div className="flex flex-row w-full gap-2.5 items-center">
                {renderIcon ? (
                    renderIcon()
                ) : (
                    <Circle className={isHoveringRemove ? 'bg-error' : 'bg-success'}>
                        <Svg name={isHoveringRemove ? 'minus' : 'plus'} className="child:fill-white" />
                    </Circle>
                )}
                <div className="flex flex-col flex-1">
                    {editName ? (
                        <InputText
                            ref={nameRef}
                            defaultValue={place?.name}
                            className={'!px-2.5 !py-[5px]'}
                            {...inputProps}
                        />
                    ) : (
                        <Text weight="bold" size="14" onClick={() => setEditName(true)}>
                            {place?.name || 'Coordinates'}
                        </Text>
                    )}

                    <div className="flex flex-row gap-2.5">
                        <Text color="secondary">
                            Avails : {place?.avail || 12.5}K, {place?.lat?.toFixed(8)}, {place?.lng?.toFixed(8)},{' '}
                            {metersToMiles(parseFloat(place?.rad))?.toFixed(2)} miles radius
                        </Text>
                    </div>
                </div>
                <Svg
                    name="close"
                    className="child:fill-black max-w-[17px] cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        onRemove();
                    }}
                    onMouseOver={() => {
                        onHoverRemove(place.id);
                        setIsHoveringRemove(true);
                    }}
                    onMouseLeave={() => {
                        onHoverRemove(null);
                        setIsHoveringRemove(false);
                    }}
                />
            </div>
        </div>
    );
};

export default LocationItem;
