import React from 'react';

function useLocalStorage(key, defaultValue = null) {
    const value = JSON.parse(localStorage.getItem(key)) ?? defaultValue;
    const set = (newValue) => localStorage.setItem(key, JSON.stringify(newValue));

    return [value, set];
}

export default useLocalStorage;
