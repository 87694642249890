import { Switch } from 'components/Input';
import Text from 'components/Text';
import React from 'react';

function EmailNotifications({ checked, onChange }) {
    return (
        <div className="box">
            <Text className="mb-5 pt-7" size="18" weight="bold">
                Email Notifications
            </Text>
            <div className="flex flex-col gap-5">
                <Switch
                    propsInput={{
                        value: checked,
                        name: 'email_notification',
                    }}
                    onChange={onChange}
                    label="Marketing Newsletters"
                />

                <Switch
                    propsInput={{
                        value: checked,
                        name: 'browser_push_notification',
                    }}
                    onChange={onChange}
                    label="Browser Push Notifications"
                />
            </div>
        </div>
    );
}

export default EmailNotifications;
