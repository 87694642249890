import { SVG } from 'assets/imagePath';
import { CardContent } from 'components/Card';
import { HeaderNavigate } from 'components/HeaderNavigate';

function Branding() {
    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <div className="flex flex-col gap-[30px]">
                <HeaderNavigate detailInfo={{ detailName: 'Branding' }} />
                <div className="flex flex-row">
                    <CardContent
                        className={'w-[210px] h-[230px]'}
                        title={'Logo'}
                        icon={SVG.logo_agency_icon}
                        content={'AGENCY'}
                        actionText="Change"
                    />
                </div>
            </div>
        </div>
    );
}

export default Branding;
