import clsx from 'clsx';
import React from 'react';

function Card({ active = false, className, children, ...props }) {
    return (
        <div
            className={clsx(
                'rounded-[20px] ',
                active
                    ? 'shadow-both bg-white-light border-2 border-blue-eae'
                    : 'bg-blue-eae shadow-outer border-2 border-transparent',
                className,
            )}
            {...props}
        >
            {children}
        </div>
    );
}

export default Card;
