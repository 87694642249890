import { AgenciesOrg } from 'components/AccountConfig/Agencies';
import { CompaniesAgency } from 'components/AccountConfig/Companies';
import EarningAgency from 'components/AccountConfig/Earnings/EarningAgency';
import { EducationOrg } from 'components/AccountConfig/Education';
import { LeadsOrg } from 'components/AccountConfig/Leads';
import { NotificationSubCo } from 'components/AccountConfig/Notifications';
import PaymentsOrganization from 'components/AccountConfig/Payments/PaymentsOrganization';
import ProfileOrganization from 'components/AccountConfig/Profile/ProfileOrganization';
import { QaQcOrg } from 'components/AccountConfig/QaQc';
import { SettingsOrganization } from 'components/AccountConfig/Settings';
import StatusOrgazination from 'components/AccountConfig/Status/StatusOrgazination';
import { SupportSubCo } from 'components/AccountConfig/Support';
import { UsersSubCo } from 'components/AccountConfig/Users';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import { APP_ROLE } from 'constants/auth';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { isPermissionView } from 'utils';

const accountTabs = [
    { label: 'Profile', value: 'profile' },
    { label: 'Settings', value: 'settings' },
    {
        label: 'Notifications',
        value: 'notifications',
    },

    { label: 'Payments', value: 'payments' },
    { label: 'Education', value: 'education' },
    { label: 'Support', value: 'support' },
    { label: 'Status', value: 'status' },
    { label: 'Users', value: 'users' },
    { label: 'Companies', value: 'companies' },
    { label: 'Earnings', value: 'earnings' },
    { label: 'Agencies', value: 'agencies' },
    { label: 'Leads', value: 'leads' },
    { label: 'QA/QC', value: 'qaQc' },
];

function AccountConfigOrganization() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const defaultConfig = urlParams.get('config');

    const defaultTabs = accountTabs.find((item) => item.value === defaultConfig);
    const [searchParams, setSearchParams] = useSearchParams({
        config: defaultTabs?.value,
    });

    const locationState = useLocation()?.state;

    // const defaultTabs = accountTabs.find((item) => item.value === !!locationState?.config);

    const [tabsVal, setTabsVal] = useState(defaultTabs || accountTabs[0]);
    const { Ag, SubCo, Org } = APP_ROLE;
    const navigate = useNavigate();
    if (!isPermissionView([Org])) {
        navigate(routesPath.dashboard);
    }
    const renderContent = () => {
        switch (tabsVal.value) {
            case 'profile':
                return <ProfileOrganization />;
            case 'settings':
                return <SettingsOrganization />;
            case 'notifications':
                return <NotificationSubCo />;
            case 'payments':
                return <PaymentsOrganization />;
            case 'education':
                return <EducationOrg />;
            case 'support':
                return <SupportSubCo />;
            case 'status':
                return <StatusOrgazination />;
            case 'users':
                return <UsersSubCo />;
            case 'companies':
                return <CompaniesAgency />;
            case 'earnings':
                return <EarningAgency />;
            case 'leads':
                return <LeadsOrg />;
            case 'agencies':
                return <AgenciesOrg />;
            case 'qaQc':
                return <QaQcOrg />;
            default:
                break;
        }
    };

    useEffect(() => {
        if (!defaultTabs) {
            setSearchParams({ config: tabsVal.value });
        }
    }, []);

    return (
        <div className="p-2.5 md:p-[30px] flex flex-col gap-[30px]">
            <Text className="py-[10.5px]" size="24" weight="bold">
                Organization Account
            </Text>
            <Tabs
                className={'absolute'}
                onChange={(tab) => {
                    setTabsVal(tab);
                    setSearchParams({
                        config: tab.value,
                    });
                }}
                tabList={accountTabs}
                value={tabsVal.value}
            />

            {renderContent()}
        </div>
    );
}

export default AccountConfigOrganization;
