import { SubTitle } from 'components/Title';
import FormSelect from 'components/modules/form/FormSelect';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

function DeviceTarget() {
    const cities = [
        { label: 'Miami', value: 'miami' },
        { label: 'Los Angeles', value: 'los_angeles' },
    ];

    return (
        <div className="flex flex-col gap-5">
            <SubTitle className={'py-[13px]'}>Device Targeting</SubTitle>
            <FormSelect
                name="operating_system"
                placeholder="Operating Systems"
                propsDropdown={{ classNameBox: 'flex-1' }}
                placeholderTop
                options={cities}
            />
            <FormSelect
                name="devices_type"
                placeholder="Device Types"
                propsDropdown={{ classNameBox: 'flex-1' }}
                placeholderTop
                options={cities}
            />
            <FormSelect
                name="devices"
                placeholder="Devices"
                propsDropdown={{ classNameBox: 'flex-1' }}
                placeholderTop
                options={cities}
            />
            <FormSelect
                name="supply_type"
                placeholder="Supply Type"
                propsDropdown={{ classNameBox: 'flex-1' }}
                placeholderTop
                options={cities}
            />{' '}
            <FormSelect
                name="category"
                placeholder="Category"
                propsDropdown={{ classNameBox: 'flex-1' }}
                placeholderTop
                options={cities}
            />
        </div>
    );
}

export default DeviceTarget;
