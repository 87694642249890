import Step from 'components/Step/Step';
import { useEffect, useState } from 'react';
import { ChangeNewEmail, VerifyNewEmail } from './components';

function ChangeEmail() {
    const [step, setStep] = useState(1);

    useEffect(() => {
        return () => {
            setStep(1);
        };
    }, []);

    return (
        <div className="flex justify-center items-center py-[120px] px-[10px] min-h-[100vh]  bg-blue-eae">
            <div className="w-full max-w-[400px]">
                {step === 1 && <ChangeNewEmail setStep={setStep} />}
                {step === 2 && <VerifyNewEmail setStep={setStep} />}

                <Step maxStep={2} activeStep={step} activeClass={'flex-[2]'} />
            </div>
        </div>
    );
}

export default ChangeEmail;
