import clsx from 'clsx';

function DoughnutLegend({ headerLegend, dataLegend, configData }) {
    const classNameColumn = 'flex-1 text-[10px]';
    return (
        <div>
            {/* Header Legend */}
            <div className="flex justify-start items-center gap-[5px]">
                <div className="w-2.5 h-2.5 aspect-square rounded-full bg-blue-cfd" />
                {headerLegend.map((headerItem, idx) => (
                    <div key={idx} className={clsx(classNameColumn, headerItem?.classNameCell)}>
                        {headerItem?.headerName}
                    </div>
                ))}
            </div>

            {dataLegend.map((data, idx) => (
                <div key={data?.id || idx} className="flex justify-start items-center gap-[5px]">
                    <div
                        className="w-2.5 h-2.5 aspect-square rounded-full bg-blue-cfd"
                        style={{
                            background: configData?.backgroundColor[idx],
                        }}
                    />
                    {headerLegend.map((item, idx) => (
                        <div className={clsx(classNameColumn, item?.classNameCell)} key={idx}>
                            {data[item?.field]}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

export default DoughnutLegend;
