import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Image from 'components/Image';
import Text from 'components/Text';

function TextArea({
    label,
    copy = false,
    iconInfo = false,
    className,
    classNameBox,
    editor,
    helper = false,
    error = false,
    ...propsInput
}) {
    const copyContent = async () => {
        try {
            await navigator.clipboard.writeText(propsInput?.value);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return (
        <div className="flex-1  flex flex-col items-center rounded-[15px] shadow-default bg-white dark:bg-gray-700 relative">
            {label && (
                <div
                    className={clsx(
                        'px-[10px]  absolute  z-0  rounded-[30px] shadow-default-top left-[15px] -top-[5px] flex flex-row items-center max-w-[calc(100%-35px)]',
                        propsInput?.value ? 'bg-white' : 'bg-white-light',
                        classNameBox,
                    )}
                >
                    <Text size="12" className="overflow-hidden text-ellipsis whitespace-nowrap">
                        {label}
                    </Text>
                    {iconInfo && <Image className={'ml-[5px]'} src={SVG.info_outlined} />}
                </div>
            )}
            {editor && (
                <div className="flex flex-row self-start w-full overflow-hidden border-b border-blue-cfd">
                    <button type="button" className="p-[5px] border-r border-blue-cfd cursor-pointer">
                        <Image src={SVG.editor_bold} />
                    </button>
                    <button type="button" className="p-[5px] border-r border-blue-cfd cursor-pointer">
                        <Image src={SVG.editor_italic} />
                    </button>
                    <button type="button" className="p-[5px] border-r border-blue-cfd cursor-pointer">
                        <Image src={SVG.editor_underline} />
                    </button>
                    <button type="button" className="p-[5px] border-r border-blue-cfd cursor-pointer">
                        <Image src={SVG.editor_numberend_list} />
                    </button>
                    <button type="button" className="p-[5px] border-r border-blue-cfd cursor-pointer">
                        <Image src={SVG.editor_dot_list} />
                    </button>
                    <button type="button" className="p-[5px] border-r border-blue-cfd cursor-pointer">
                        <Image src={SVG.editor_html} />
                    </button>
                    <button type="button" className="p-[5px] border-r border-blue-cfd cursor-pointer">
                        <Image src={SVG.editor_image} />
                    </button>
                </div>
            )}
            <textarea
                id="chat"
                rows={1}
                className={clsx(
                    'placeholder:text-grey-light shadow-default  placeholder:text-sm block rounded-[10px]  w-full p-[15px]  focus:outline-none text-sm resize-none',
                    propsInput.value ? 'bg-white' : 'bg-white-light',
                    className,
                )}
                {...propsInput}
            />
            {helper && (
                <Text
                    className={clsx(
                        'px-[10px] absolute bg-inherit z-0  rounded-[30px] shadow-default-bottom  bg-blue-eae left-[15px] -bottom-[10px] text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                        error ? 'text-error' : '',
                    )}
                    size="10"
                >
                    {helper}
                </Text>
            )}

            {copy && (
                <button type="button" className="absolute bottom-[15px] right-[15px]" onClick={copyContent}>
                    <Image src={SVG.copy_light} />
                </button>
            )}
        </div>
    );
}

export default TextArea;
