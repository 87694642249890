import { Button } from 'components/Button';
import { DetailBox } from 'components/DetailBox';
import React from 'react';

function SegmentDetail({ detail, onEdit }) {
    const column = [
        {
            field: 'segment_type',
            headerName: 'Segment Type',
        },
        {
            field: 'deal_id',
            headerName: 'Deal ID',
        },
        {
            field: 'deal_cost',
            headerName: 'Deal Cost',
        },
        {
            field: 'available_impressions',
            headerName: 'Available Impressions',
        },
        {
            field: 'deal_name',
            headerName: 'Deal Name',
        },
        {
            field: 'description',
            headerName: 'Description',
        },
        {
            field: 'vendor',
            headerName: 'Vendor',
        },
    ];

    const row = {
        segment_type: 'Audience',
        deal_id: '23909876546',
        deal_cost: '$1.20',
        available_impressions: '1,400,000',
        deal_name: 'Cryptocurrencies',
        description: 'Active crypto currency consumers',
        vendor: 'Oracle',
    };

    return (
        <div className="flex flex-col gap-5">
            <DetailBox title="Details" row={detail} column={column} />

            <Button className={'max-w-[260px] w-full'} onClick={onEdit}>
                Edit
            </Button>
        </div>
    );
}

export default SegmentDetail;
