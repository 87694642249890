import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import FormCheckboxSquare from 'components/modules/form/FormCheckboxSquare';
import FormInputText from 'components/modules/form/FormInputText';
import { useAdCanva } from 'context/AdCanvaContext';
import { useFormikContext } from 'formik';

function CanvaSaved({ isSave }) {
    const { setStep, template } = useAdCanva();
    const { handleSubmit } = useFormikContext();

    return (
        <div className="flex flex-col gap-5 md:w-[300px]">
            <SubTitle>Save</SubTitle>

            <FormInputText name="canva_name" label="Name" placeholder="Name" defaultValue="#1234" />

            {isSave && (
                <div>
                    <FormCheckboxSquare name="share" Label={<Text>Share template with Community</Text>} />
                </div>
            )}
        </div>
    );
}

export default CanvaSaved;
