import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Box from 'components/Box';
import Image from 'components/Image';
import { InputText } from 'components/Input';
import Text from 'components/Text';
import React from 'react';

function ROICalculator() {
    return (
        <div className="flex flex-col gap-5">
            <Text size="14">
                ROI calculator is a kind of investment calculator that enables you to estimate the profit or loss on
                your investment.
            </Text>

            <InputText
                mark="$"
                className={'pl-[29px]'}
                placeholder={'Cost'}
                label="Campaign Revenue"
                helper={'Total revenue generated by your campaign'}
            />
            <InputText placeholder={'Gross Profit %'} />
            <InputText mark="$" className={'pl-[29px]'} placeholder={'Maketing Costs'} label="Marketing Costs" />

            <Box shadow="default" className={clsx('bg-white-light p-[15px] w-full')}>
                <Text className="my-[7.5px]" size="12" weight="bold">
                    Campaign Revenue
                </Text>

                <div className="flex flex-col w-full">
                    <div className="flex gap-[5px] px-2.5 py-[5px] bg-blue-eae">
                        <Text size="12">Full Campaign Revenue</Text>
                        <Image src={SVG.info_outlined} />
                    </div>
                    <Text size="12" className="px-2.5 py-[5px]">
                        $1000
                    </Text>
                </div>
                <div className="flex flex-col w-full">
                    <div className="flex gap-[5px] px-2.5 py-[5px] bg-blue-eae">
                        <Text size="12">Gross Profit</Text>
                        <Image src={SVG.info_outlined} />
                    </div>
                    <Text size="12" className="px-2.5 py-[5px]">
                        $450
                    </Text>
                </div>
                <div className="flex flex-col w-full">
                    <div className="flex gap-[5px] px-2.5 py-[5px] bg-blue-eae">
                        <Text size="12">Marketing Costs</Text>
                        <Image src={SVG.info_outlined} />
                    </div>
                    <Text size="12" className="px-2.5 py-[5px]">
                        $200
                    </Text>
                </div>
                <div className="flex flex-col w-full">
                    <div className="flex gap-[5px] px-2.5 py-[5px] bg-blue-eae">
                        <Text size="12">ROI on Full Campaign Revenue</Text>
                        <Image src={SVG.info_outlined} />
                    </div>
                    <Text size="12" className="px-2.5 py-[5px]">
                        400%
                    </Text>
                </div>
                <div className="flex flex-col w-full">
                    <div className="flex gap-[5px] px-2.5 py-[5px] bg-blue-eae">
                        <Text size="12">ROI on Gross Profit</Text>
                        <Image src={SVG.info_outlined} />
                    </div>
                    <Text size="12" className="px-2.5 py-[5px]">
                        125%
                    </Text>
                </div>
                <div className="flex flex-col w-full">
                    <div className="flex gap-[5px] px-2.5 py-[5px] bg-blue-eae">
                        <Text size="12">Net Profit</Text>
                        <Image src={SVG.info_outlined} />
                    </div>
                    <Text size="12" className="px-2.5 py-[5px]">
                        $250
                    </Text>
                </div>
            </Box>
        </div>
    );
}

export default ROICalculator;
