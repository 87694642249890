import FormSelectMulti from 'components/modules/form/FormSelectMulti';
import React from 'react';
import { SelectedList } from './components';
import { SubTitle } from 'components/Title';

function CanvaChooseSize() {
    return (
        <div>
            <div className="flex w-full flex-1 flex-col gap-[5px]">
                <SubTitle>Select Size</SubTitle>
            </div>

            <FormSelectMulti
                chipProps={{ className: 'h-[30px] !px-2' }}
                name="sizes"
                className="h-full"
                placeholder={'Sizes'}
                renderSelectList={({ close, setSelected, selected }) => (
                    <SelectedList onCloseDropdown={close} setSelected={setSelected} selected={selected} />
                )}
                restDropdown={{
                    clientParent: {
                        width: 500,
                    },
                    className: 'w-full max-sm:translate-x-0 top-0 left-0 h-[300px]  overflow-x-hidden overflow-y-auto',
                }}
            />
        </div>
    );
}

export default CanvaChooseSize;
