import { BarElement, Chart } from 'chart.js';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { kFormatter } from 'utils';
Chart.register(BarElement);

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const dataRandom = labels.map((item) => Math.floor(Math.random() * 50000));

const data = {
    datasets: [
        {
            type: 'bar',
            data: dataRandom,
            backgroundColor: 'rgba(14, 101, 241, .35)',
            hoverBackgroundColor: '#0E65F1',
            zIndex: 0,
            order: 1,
        },
    ],
    labels: labels,
};
const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                display: false,
            },
            ticks: {
                autoSkip: true,
                maxTicksLimit: 20,
                stepSize: 0.2,
                font: {
                    size: 10,
                },
                minRotation: 45,
                color: '#1D2533',
            },
        },
        y: {
            grid: {
                display: false,
            },
            suggestedMin: 0,
            suggestedMax: 100000,
            ticks: {
                callback: function (value, index, ticks) {
                    return kFormatter(value);
                },
                stepSize: 50000,
                font: {
                    size: 10,
                },
                color: '#1D2533',
                padding: 0,
            },
        },
    },
};
function BarChart() {
    return <Bar data={data} options={options} />;
}

export default BarChart;
