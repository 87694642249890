import Chip from 'components/Chip/Chip';
import { EducationTopic } from 'components/Education';
import { POSTS } from 'components/Education/data';
import Text from 'components/Text/Text';
import React, { useState } from 'react';

const EXAMPLE_CATEGORIES = [
    {
        id: 1,
        category: 'Category',
        post_count: '12',
    },
    {
        id: 2,
        category: 'Category',
        post_count: '15',
    },
    {
        id: 3,
        category: 'Category',
        post_count: '8',
    },
    {
        id: 4,
        category: 'Category',
        post_count: '2',
    },
    {
        id: 5,
        category: 'Category',
        post_count: '8',
    },
];
function PublicEducation() {
    // eslint-disable-next-line no-unused-vars
    const [categories, setCategories] = useState(EXAMPLE_CATEGORIES);

    return (
        <section className="container w-full mx-auto max-w-[1240px] py-[30px] px-[20px]  flex flex-col items-center gap-[30px] ">
            <div className="flex flex-col items-center w-full gap-5 overflow-auto">
                <Text weight="bold" className="!text-5xl font-sen text-shadow-outer drop-shadow-outer tracking-tighter">
                    Education
                </Text>

                <div className="flex flex-row gap-[10px] w-full items-center justify-center ">
                    {categories.map((ct, idx) => (
                        <Chip key={ct.id} color="white">
                            <Text className="whitespace-nowrap">
                                {ct.category} {ct.post_count}
                            </Text>
                        </Chip>
                    ))}
                </div>
            </div>

            <div className="mx-auto max-w-[1080px] max-xs:px-[40px] ">
                <EducationTopic topic={'Recent Articles'} posts={POSTS} isPublic />
                <EducationTopic topic={'Category Articles'} posts={POSTS} isPublic />
                <EducationTopic topic={'Category Articles'} posts={POSTS} isPublic />
                <EducationTopic topic={'Category Articles'} posts={POSTS} isPublic />
            </div>
        </section>
    );
}

export default PublicEducation;
