import { DetailBox } from 'components/DetailBox';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { audiencesColumn, fakeRowAudiences } from 'pages/Dashboard/data';

function AudienceDetail() {
    // const navigate = useNavigate();

    const { audienceId } = useParams();

    const rowDetail = fakeRowAudiences.find((item) => {
        return item?.id == audienceId;
    });

    const [row, setRow] = useState(rowDetail || fakeRowAudiences[0]);

    useEffect(() => {
        //Fetch Row Earning Detail
        // setRow(rowDetail)
    }, []);

    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <div className="flex flex-col gap-[30px]">
                <HeaderNavigate detailInfo={{ detailName: 'Audience', detailId: audienceId }} />

                <div className="max-w-[400px]">
                    <DetailBox
                        title="Audience"
                        column={audiencesColumn.filter((item) => item?.field !== 'actions')}
                        row={row}
                    />
                </div>
            </div>
        </div>
    );
}

export default AudienceDetail;
