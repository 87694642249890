import Box from 'components/Box/Box';
import { SubTitle } from 'components/Title';
import { useFormikContext } from 'formik';
import Holder from '../Holder';
import { Leaderboard, MediumRectangle } from '../sizes';
import { Button } from 'components/Button';
import { useAuth } from 'hooks';
import { APP_ROLE } from 'constants/auth';
import { useAdCanva } from 'context/AdCanvaContext';

function DesignerCreative() {
    const { values } = useFormikContext();

    const { sizes } = values;

    const { currentRole } = useAuth();

    const { template } = useAdCanva();

    return (
        <div className="flex flex-col gap-2.5">
            <div className="flex items-center justify-between">
                <SubTitle>Creative</SubTitle>

                {currentRole === APP_ROLE.Org && template?.status !== 'approve' && (
                    <div className="flex gap-5 ">
                        <Button variant="outlined">Upload</Button>
                        <Button variant="outlined">Clear</Button>
                    </div>
                )}
            </div>
            <Box className="bg-white-light py-[20px] md:py-[75px] pl-[20px] md:pl-[47px] pr-[20px] md:pr-[25px] w-full h-full overflow-auto">
                <div>
                    <Leaderboard
                        className={sizes?.find((item) => item.value === 'leader_board') ? 'visible' : 'invisible'}
                    />

                    <div className="flex flex-row gap-[30px] w-full mt-5">
                        <MediumRectangle
                            className={sizes?.find((item) => item.value === 'medium_rec') ? 'visible' : 'invisible'}
                        />
                        {/* <Holder ratio={{ x: 320, y: 50 }} className={'mr-[70px] invisible'} /> */}
                    </div>
                </div>
            </Box>
        </div>
    );
}

export default DesignerCreative;
