import { InputUploadVideo } from 'components/Input';
import { useField } from 'formik';

function FormUploadVideo({ ...props }) {
    const [field, meta, helpers] = useField(props);

    return (
        <InputUploadVideo
            error={meta.error}
            helper={meta.error}
            setValueFormik={helpers.setValue}
            {...field}
            {...props}
        />
    );
}

export default FormUploadVideo;
