export const IMAGE = {
    avatar: require('./images/avatar.png'),
    audi: require('./images/audi.png'),
    home_map: require('./images/home_map.png'),
    circle_shadow_map: require('./images/circle_shadow_map.png'),
    landing_map: require('./images/landing_map.png'),
    landing_map_jpeg: require('./images/landing_map.jpg'),

    smile_home: require('./images/smile_home.png'),
    checked_shadow: require('./images/checked_shadow.png'),
    location_shadow: require('./images/location_shadow.png'),
    star_shadow: require('./images/star_shadow.png'),
    eye_shadow: require('./images/eye_shadow.png'),
    strategy: require('./images/strategy.png'),
    home_1: require('./images/home_1.png'),
    home_2: require('./images/home_2.png'),
    home_3: require('./images/home_3.png'),
    home_rating: require('./images/home_rating.png'),
    started_logo: require('./images/started_logo.png'),
    bg_home_partnership: require('./images/bg_home_partnership.png'),
    no_image: require('./images/noImage.png'),
    avatar_default: require('./images/avatar_default.png'),
    company_default: require('./images/company_default.png'),
    agency_default: require('./images/agency_default.png'),
    binoculars: require('./images/binoculars.png'),
    post_image: require('./images/post_image.png'),
    map: require('./images/map.jpg'),
    qr_bitcoin: require('./images/qr_bitcoin.png'),
    signature_default: require('./images/signature_default.png'),
    lib_image: require('./images/lib_image.jpg'),
    map_coordinates: require('./images/map_coordinates.jpg'),
    map_miami2: require('./images/map_miami2.jpg'),

    template_holder: require('./images/template_holder.png'),
    hero_industry: require('./images/hero_industry.png'),
    industry_ad: require('./images/industry_ad.png'),
    industry_app: require('./images/industry_app.png'),
    tailored_agencies: require('./images/tailored_agencies.jpg'),
    tailored_blockchain: require('./images/tailored_blockchain.jpg'),
    tailored_finance: require('./images/tailored_finance.jpg'),
    tailored_government: require('./images/tailored_government.jpg'),
    tailored_healthcare: require('./images/tailored_healthcare.jpg'),
    tailored_retail: require('./images/tailored_retail.jpg'),
    tailored_travel: require('./images/tailored_travel.jpg'),
    location: require('./images/location.png'),
    segment: require('./images/segment.png'),
    creative_native: require('./images/creative_native.png'),

    creative_video: require('./images/creative_video.png'),

    creative_display: require('./images/creative_display.png'),
    generate: require('./images/generate.png'),
    adcanvas_logo_default: require('./images/adcanvas_logo_default.png'),

    partner_audiencerate: require('./images/partner_audiencerate.png'),
    partner_audigent: require('./images/partner_audigent.png'),
    partner_adex: require('./images/partner_adex.png'),
    partner_justtag: require('./images/partner_justtag.png'),
    partner_liveramp: require('./images/partner_liveramp.png'),
    partner_lotame: require('./images/partner_lotame.png'),
    partner_navegg: require('./images/partner_navegg.png'),
    partner_zeotap: require('./images/partner_zeotap.png'),
    partner_weborama: require('./images/partner_weborama.png'),
    partner_sidata: require('./images/partner_sidata.png'),
    partner_osdatasolutions: require('./images/partner_osdatasolutions.png'),
    partner_onaudience: require('./images/partner_onaudience.png'),
    partner_nxteck: require('./images/partner_nxteck.png'),
};

export const SVG = {
    dashboard_icon: require('./images/dashboard_icon.svg').default,
    binoculars: require('./images/binoculars.svg').default,
    bitcoin: require('./images/bitcoin.svg').default,
    metamask: require('./images/metamask.svg').default,
    coinbase: require('./images/coinbase.svg').default,
    ledger: require('./images/ledger.svg').default,

    nucleus_icon: require('./images/nucleus_icon.svg').default,
    close_icon: require('./images/close_icon.svg').default,
    campaign_icon: require('./images/campaign_icon.svg').default,
    audiences_icon: require('./images/audiences_icon.svg').default,
    creatives_icon: require('./images/creatives_icon.svg').default,
    copy_light: require('./images/copy_light.svg').default,
    phone_icon: require('./images/phone_icon.svg').default,
    uil_at_icon: require('./images/uil_at_icon.svg').default,
    twitter_icon: require('./images/twitter_icon.svg').default,
    linkedin_icon: require('./images/linkedin_icon.svg').default,
    facebook_icon: require('./images/facebook_icon.svg').default,
    logo_agency: require('./images/logo-agency.svg').default,
    support: require('./images/support.svg').default,
    search: require('./images/search.svg').default,
    user_light: require('./images/user_light.svg').default,
    plus_light: require('./images/plus_light.svg').default,
    back_light: require('./images/back_light.svg').default,
    filter: require('./images/filter.svg').default,
    more_vert: require('./images/more_vert.svg').default,
    logo: require('./images/logo.svg').default,
    logo_only: require('./images/logo_only.svg').default,
    cancel_circle: require('./images/cancel_circle.svg').default,

    info: require('./images/info.svg').default,
    info_light: require('./images/info_light.svg').default,
    info_outlined: require('./images/info_outlined.svg').default,
    chevron_down: require('./images/chevron_down.svg').default,
    chevron_left: require('./images/chevron_left.svg').default,
    signature_icon: require('./images/signature_icon.svg').default,
    pending_circle: require('./images/pending_circle.svg').default,

    chevron_down_light: require('./images/chevron_down_light.svg').default,
    chevron_right_light: require('./images/chevron_right_light.svg').default,
    chevron_right: require('./images/chevron_right.svg').default,

    chevron_left_light: require('./images/chevron_left_light.svg').default,

    bell: require('./images/bell.svg').default,
    education: require('./images/education.svg').default,

    agency: require('./images/agency.svg').default,
    finance: require('./images/finance.svg').default,
    government: require('./images/government.svg').default,
    healthcare: require('./images/healthcare.svg').default,
    retail: require('./images/retail.svg').default,
    blockchain: require('./images/blockchain.svg').default,
    travel: require('./images/travel.svg').default,
    youtube: require('./images/youtube.svg').default,
    creation_tool: require('./images/creation_tool.svg').default,
    planning_tool: require('./images/planning_tool.svg').default,
    preview_tool: require('./images/preview_tool.svg').default,
    strategy_tool: require('./images/strategy_tool.svg').default,
    marketplace: require('./images/marketplace.svg').default,
    audience: require('./images/audience.svg').default,
    analytics: require('./images/analytics.svg').default,
    optimize: require('./images/optimize.svg').default,
    info_green: require('./images/info_green.svg').default,
    checked_circle_primary: require('./images/checked_circle_primary.svg').default,

    audio: require('./images/audio.svg').default,
    display: require('./images/display.svg').default,
    game: require('./images/game.svg').default,
    native: require('./images/native.svg').default,
    tv: require('./images/tv.svg').default,
    video: require('./images/video.svg').default,
    logo_light: require('./images/logo_light.svg').default,
    bar: require('./images/bar.svg').default,
    navigate_icon: require('./images/navigate_icon.svg').default,
    money_shadow: require('./images/money_shadow.svg').default,
    notice_shadow: require('./images/notice_shadow.svg').default,
    checked_shadow: require('./images/checked_shadow.svg').default,
    dashboard_shadow: require('./images/dashboard_shadow.svg').default,
    storage_shadow: require('./images/storage_shadow.svg').default,
    checked_circle: require('./images/checked_circle.svg').default,
    link_icon: require('./images/link_icon.svg').default,
    logo_agency_icon: require('./images/logo_agency_icon.svg').default,
    money_circle: require('./images/money_circle.svg').default,
    markup_circle: require('./images/markup_circle.svg').default,
    extra_circle: require('./images/extra_circle.svg').default,
    trash_icon: require('./images/trash_icon.svg').default,
    trash_light: require('./images/trash_light.svg').default,
    upload: require('./images/upload.svg').default,

    editor_bold: require('./images/editor_bold.svg').default,

    editor_italic: require('./images/editor_italic.svg').default,

    editor_underline: require('./images/editor_underline.svg').default,

    editor_dot_list: require('./images/editor_dot_list.svg').default,
    editor_numberend_list: require('./images/editor_numberend_list.svg').default,

    editor_html: require('./images/editor_html.svg').default,
    editor_image: require('./images/editor_image.svg').default,

    star: require('./images/star.svg').default,
    generate: require('./images/generate.svg').default,
};
