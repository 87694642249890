import React, { memo } from 'react';

const PlanSummary = memo(() => {
    return (
        <div className="">
            <div className="py-[14px] mb-5">
                <h3 className="text-lg font-bold text-blue-dark">Plan Summary</h3>
            </div>
            <div className="p-[15px] bg-white-light rounded-[15px] shadow-default">
                <div className="flex flex-col gap-y-5">
                    <div className="flex flex-col gap-y-2.5">
                        <h2 className="text-xs font-semibold">Details</h2>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">Name</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">Start Date</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">End Date</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">Time zone</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-2.5">
                        <h2 className="text-xs font-semibold">Audices</h2>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">Name</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-2.5">
                        <h2 className="text-xs font-semibold">Advert</h2>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">Name</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-2.5">
                        <h2 className="text-xs font-semibold">Budget</h2>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">Select Currency</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">Budget</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">Daily Spend Cap</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-2.5">
                        <h2 className="text-xs font-semibold">Pricing</h2>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">Price</p>
                            <p className="text-xs font-normal">Floor</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">Floor</p>
                            <p className="text-xs font-normal">Publisher</p>
                        </div>
                        <div className="flex justify-between">
                            <p className="text-xs font-normal">Media Price</p>
                            <p className="text-xs font-normal">Empty</p>
                        </div>
                    </div>

                    <div className="h-[1px] w-full bg-blue-cfd" />

                    <div className="flex justify-between">
                        <p className="text-xs font-semibold">Total</p>
                        <p className="text-xs font-normal">$3,200</p>
                    </div>
                </div>
            </div>
        </div>
    );
});

export { PlanSummary };
