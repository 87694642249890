import { SVG } from 'assets/imagePath';
import { IconButton } from 'components/Button';
import Chip from 'components/Chip/Chip';
import { SegmentModule } from 'components/GlobalModules';
import LocationModule from 'components/GlobalModules/LocationModule';
import GridTable from 'components/GridTable/GridTable';
import TableHead from 'components/GridTable/TableHead';
import { Modal } from 'components/Modal';
import { Svg } from 'components/Svg';
import Text from 'components/Text';
import { useState } from 'react';

function SegmentTable() {
    const fakeSegment = [
        {
            id: '19541',
            state: 'ADD',
            provider: 'Navegg',
            name: 'Navegg: > Age > 16-17 (NID)',
            price: '$0.264',
            desc: 'Teenagers',
        },
        {
            id: '19541',
            state: 'REMOVE',
            provider: 'Navegg',
            name: 'Navegg: > Age > 16-17 (NID)',
            price: '$0.264',
            desc: 'Teenagers',
        },
        {
            id: '19541',
            state: 'ADD',
            provider: 'Navegg',
            name: 'Navegg: > Age > 16-17 (NID)',
            price: '$0.264',
            desc: 'Teenagers',
        },
    ];
    const segmentsColumn = [
        {
            field: 'id',
            headerName: 'ID',
        },
        {
            field: 'state',
            headerName: 'State',
            renderCell: (row) => {
                let stateColors;
                switch (row.state) {
                    case 'ADD':
                        stateColors = {
                            color: 'success',
                            label: 'Add',
                        };
                        break;
                    case 'REMOVE':
                        stateColors = {
                            color: 'error',
                            label: 'Remove',
                        };
                        break;

                    default:
                        break;
                }

                return stateColors && <Chip color={stateColors?.color}>{stateColors?.label}</Chip>;
            },
        },
        {
            field: 'provider',
            headerName: 'Provider',
        },
        {
            field: 'name',
            headerName: 'Name',
        },
        {
            field: 'price',
            headerName: 'Price',
        },
        {
            field: 'desc',
            headerName: 'Description',
        },
    ];

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="flex flex-col gap-5">
            <TableHead
                title={'Segment'}
                actionButton={{
                    label: 'Add Segment',
                    onClick: () => {
                        setIsOpen(true);
                    },
                }}
            />
            <GridTable isRadiusTable className={'table-fixed w-full'} column={segmentsColumn} row={fakeSegment} />

            <Modal
                bodyClass={'max-w-[1130px] pb-[30px]'}
                title="Segment"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                renderHeader={() => (
                    <div className="flex items-center justify-between">
                        <IconButton
                            classNameImage={'w-5 h-5'}
                            className="w-[50px] h-50px md:mr-[20px]"
                            source={SVG.chevron_left}
                            onClick={() => setIsOpen(false)}
                        />
                        <div className="flex-1 text-center md:text-left">
                            <Text component="div" size="24" weight="bold">
                                Segment
                            </Text>
                        </div>
                        <button type="button" onClick={() => setIsOpen(false)}>
                            <Svg className="child:fill-black w-[30px] h-[30px]" name="close" />
                        </button>
                    </div>
                )}
            >
                <SegmentModule />
            </Modal>
        </div>
    );
}

export default SegmentTable;
