import { SVG } from 'assets/imagePath';
import { routesPath } from 'routes/routes';

export const MENU = [
    {
        id: 'dashboard',
        to: routesPath.dashboard,
        label: 'Dashboard',
        icon: SVG.dashboard_icon,
    },
    {
        id: 'campaign',
        to: routesPath.campaign,
        label: 'Campaigns',
        icon: SVG.campaign_icon,
    },
    {
        id: 'audiences',
        to: routesPath.audiences,
        label: 'Audiences',
        icon: SVG.audiences_icon,
    },
    {
        id: 'creative',
        to: routesPath.creative,
        label: 'Creatives',
        icon: SVG.creatives_icon,
    },
    {
        id: 'education',
        to: routesPath.education,
        label: 'Education',
        icon: SVG.education,
    },
];

export const MENU_USER = [
    { id: 'user_account', label: 'User Account' },
    { id: 'add_company', label: 'Add Company' },
    { id: 'log_out', label: 'Log out' },
];
export const MENU_COMPANY = [
    {
        id: 'company_account',
        label: 'Company Account',
    },
    { id: 'upgrade', label: 'Upgrade to Agency', permissions: ['SubCo'] },
];

export const COMPANY_LIST = [
    { id: 'global_overview', label: 'Global Overview' },
    { id: 'company_1', label: 'Company 1' },
    { id: 'company_1', label: 'Company 1' },
    { id: 'company_2', label: 'Company 2' },

    { id: 'company_3', label: 'Company 3' },
    { id: 'company_4', label: 'Company 4' },

    { id: 'company_5', label: 'Company 5' },
];

export const MENU_HOME = [
    {
        id: 'home',
        to: routesPath.home,
        label: 'Home',
    },
    {
        id: 'industries',
        to: routesPath.industries,
        label: 'Industries',
    },
    {
        id: 'product',
        to: 'routesPath.home',
        label: 'Product',
    },
    {
        id: 'channels',
        to: ' routesPath.home',
        label: 'Channels',
    },
    {
        id: 'why_us',
        to: ' routesPath.home',
        label: 'Why Us?',
    },
    {
        id: 'education',
        to: routesPath.public_education,
        label: 'Education',
    },
];
