import { DetailBox } from 'components/DetailBox';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { agencyColumn, agencyRow } from 'pages/Dashboard/data';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function AgencyDetail() {
    const { agencyId } = useParams();

    const rowDetail = agencyRow.find((item) => item?.id === agencyId);

    const [row, setRow] = useState(rowDetail || agencyRow[0]);

    useEffect(() => {
        //Fetch Row Earning Detail
        // setRow(rowDetail)
    }, []);

    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <div className="flex flex-col gap-[30px]">
                <HeaderNavigate detailInfo={{ detailName: 'Agency', detailId: agencyId }} />

                <div className="max-w-[400px]">
                    <DetailBox
                        title="Agency"
                        column={agencyColumn.filter((item) => item?.field !== 'actions')}
                        row={row}
                    />
                </div>
            </div>
        </div>
    );
}

export default AgencyDetail;
