import { BoxLabelOutline } from 'components/BoxLabel';
import Chip from 'components/Chip/Chip';
import GridTable from 'components/GridTable';
import { SubTitle } from 'components/Title';
import React from 'react';

const column = [
    {
        field: 'status',
        headerName: 'Status',
        renderCell: (row) => {
            return (
                <Chip className={'md:mx-[5px]'} color="success">
                    {row?.status}
                </Chip>
            );
        },
    },
    {
        field: 'amount',
        headerName: 'Amount',
    },
    {
        field: 'data',
        headerName: 'Date',
    },
    {
        field: 'description',
        headerName: 'Description',
    },
    {
        field: 'details',
        headerName: 'Details',
        renderCell: (row) => {
            return (
                <Chip className={'md:mx-auto'} color="white">
                    {row?.details}
                </Chip>
            );
        },
    },
];

const row = [
    {
        status: 'Processed',
        amount: '$375.00',
        data: 'Jan 27, 2023',
        description: 'Campaign #193',
        details: 'Details',
    },
];

function PaymentHistory() {
    return (
        <div className="balance_method py-2.5">
            <SubTitle className="mb-5 mt-7">Payment History</SubTitle>

            <BoxLabelOutline label="Transactions">
                <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden w-full">
                    <GridTable className={'w-full table-fixed'} column={column} row={row} />
                </div>
            </BoxLabelOutline>
        </div>
    );
}

export default PaymentHistory;
