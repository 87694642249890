import { BoxLabelOutline } from 'components/BoxLabel';
import { Button } from 'components/Button';
import PhoneNumberInput from 'components/Input/PhoneNumberInput';
import FormInputText from 'components/modules/form/FormInputText';
import FormSelect from 'components/modules/form/FormSelect';
import { Form, Formik } from 'formik';
import React from 'react';

function AddBank() {
    return (
        <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
                setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                }, 0);
            }}
        >
            <Form className="flex flex-col gap-5 mt-5">
                <BoxLabelOutline label="Account Details">
                    <div className="flex flex-col gap-5 w-full">
                        <div className="flex flex-col sm:flex-row gap-5">
                            <FormSelect
                                placeholder="Country"
                                propsDropdown={{ classNameBox: 'flex-1' }}
                                placeholderTop
                                name="country"
                                options={[]}
                            />

                            <FormInputText classNameBox="flex-1" placeholder="City" name="city" />
                            <FormInputText classNameBox="flex-1" placeholder="Sate / Region" name="sate" />
                        </div>
                        <div className="flex flex-col sm:flex-row  gap-5">
                            <FormInputText placeholder="Address" classNameBox="flex-[1.5]" name="address" />
                            <FormInputText placeholder="ZIP Code" classNameBox="flex-1" name="zip" />
                        </div>
                    </div>
                </BoxLabelOutline>
                <BoxLabelOutline label="Contact Details">
                    <div className="flex flex-col gap-5 w-full">
                        <div className="flex flex-col sm:flex-row gap-5">
                            <div className="flex-1 h-full">
                                <PhoneNumberInput />
                            </div>

                            <FormInputText classNameBox="flex-1" placeholder="Email" name="email" />
                        </div>
                    </div>
                </BoxLabelOutline>
                <BoxLabelOutline label="Bank Details">
                    <div className="flex flex-col gap-5 w-full">
                        <div className="flex-1 flex-col sm:flex-row  flex gap-5">
                            <FormInputText classNameBox="flex-1" placeholder="Account Holder" name="account_holder" />
                            <FormInputText classNameBox="flex-1" placeholder="Bank Name" name="card_number" />
                            <FormInputText
                                classNameBox="flex-1"
                                placeholder="Authorized Signer Name"
                                name="auth_signer"
                            />
                        </div>

                        <div className="flex-1 flex-col sm:flex-row  flex gap-5">
                            <FormInputText classNameBox="flex-1" placeholder="Routing Number" name="routing_number" />
                            <FormInputText classNameBox="flex-1" placeholder="Account Number" name="account_number" />

                            <div className="flex-1">
                                <Button className={'flex-1 w-full'} type="submit">
                                    Add
                                </Button>
                            </div>
                        </div>
                    </div>
                </BoxLabelOutline>
            </Form>
        </Formik>
    );
}

export default AddBank;
