import { Button } from 'components/Button';
import { CreativeForm } from 'components/Creative';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { Form, Formik } from 'formik';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function CreativeService() {
    const { creativeId } = useParams();

    const formikRef = useRef(null);

    return (
        <div className="flex-1 p-5 md:p-[30px]">
            <HeaderNavigate
                navigateTo={routesPath.creative}
                detailInfo={{
                    detailName: 'Creative',
                    detailId: creativeId,
                    chipLabel: creativeId ? 'Draft' : undefined,
                }}
                chipProps={{ color: 'white' }}
                ActionArea={
                    <div>
                        <Button
                            onClick={() => {
                                formikRef.current?.submitForm();
                            }}
                        >
                            Save
                        </Button>
                    </div>
                }
            />
            <Formik
                innerRef={formikRef}
                initialValues={{
                    type: 'display',
                }}
                onSubmit={(values) => {
                    alert(JSON.stringify(values));
                }}
            >
                {({ values }) => (
                    <Form>
                        <CreativeForm formValues={values} />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default CreativeService;
