import Chip from 'components/Chip/Chip';
import { SegmentModule } from 'components/GlobalModules';
import LocationModule from 'components/GlobalModules/LocationModule';
import ActionsButton from 'components/GridTable/ActionsButton';
import GridTable from 'components/GridTable/GridTable';
import TableHead from 'components/GridTable/TableHead';
import { Modal } from 'components/Modal';
import { Portal } from 'components/Portal';
import { useState } from 'react';

function LocationTable() {
    const fakeSegment = [
        {
            id: '19541',
            state: 'ADD',
            type: 'Coordinates',
            latitude: '25.7784039',
            longitude: '-80.3545026',
            radius: '0.8 miles',
            price: '$1.24',
            available: '1.01M',
        },
        {
            id: '19541',
            state: 'REMOVE',
            type: 'Coordinates',
            name: 'Navegg: > Age > 16-17 (NID)',
            latitude: '25.7784039',
            longitude: '-80.3545026',
            radius: '0.8 miles',
            price: '$1.24',
            available: '1.01M',
        },
    ];
    const segmentsColumn = [
        {
            field: 'id',
            headerName: 'ID',
        },
        {
            field: 'state',
            headerName: 'State',
            renderCell: (row) => {
                let stateColors;
                switch (row.state) {
                    case 'ADD':
                        stateColors = {
                            color: 'success',
                            label: 'Add',
                        };
                        break;
                    case 'REMOVE':
                        stateColors = {
                            color: 'error',
                            label: 'Remove',
                        };
                        break;

                    default:
                        break;
                }

                return stateColors && <Chip color={stateColors?.color}>{stateColors?.label}</Chip>;
            },
        },
        {
            field: 'type',
            headerName: 'Type',
        },
        {
            field: 'latitude',
            headerName: 'Latitude',
        },
        {
            field: 'longitude',
            headerName: 'Longitude',
        },
        {
            field: 'radius',
            headerName: 'Radius',
        },
        {
            field: 'price',
            headerName: 'Price',
        },
        {
            field: 'available',
            headerName: 'Avails',
        },
        {
            field: 'action',
            headerName: 'Actions',
            renderCell: (row) => {
                return <ActionsButton />;
            },
        },
    ];

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="flex flex-col gap-5">
            <TableHead
                title={'Location'}
                actionButton={{
                    label: 'Add Location',
                    onClick: () => {
                        setIsOpen(true);
                    },
                }}
            />
            <GridTable className={'table-fixed w-full'} isRadiusTable column={segmentsColumn} row={fakeSegment} />

            <Portal>
                <Modal
                    bodyClass={'max-w-[1130px] pb-[30px]'}
                    title="Location"
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                >
                    <LocationModule />
                </Modal>
            </Portal>
        </div>
    );
}

export default LocationTable;
