import { SVG } from 'assets/imagePath';
import { BoxContent } from 'components/BoxContent';
import { CardContent } from 'components/Card';
import { ChatBox, ChatInput } from 'components/ChatBox';
import { chatContents } from 'components/ChatBox/fakeData';
import Text from 'components/Text';
import React from 'react';

function StatusSubCo() {
    return (
        <div className="flex flex-col gap-[30px]">
            <div className={'box__content'}>
                <Text className=" mb-5" size="18" weight="bold">
                    Review
                </Text>

                <CardContent
                    className={'max-w-[210px]'}
                    title="Status"
                    icon={SVG.checked_circle}
                    content="Approved"
                    actionText={'Refresh'}
                />
            </div>

            <BoxContent title={'Comments'} className=" max-w-[820px]">
                <div className="flex flex-col gap-5">
                    <ChatBox chatContents={chatContents} />
                    <ChatInput placeholder="Message" />
                </div>
            </BoxContent>
        </div>
    );
}

export default StatusSubCo;
