import { IMAGE, SVG } from 'assets/imagePath';
import { Button } from 'components/Button';
import Image from 'components/Image';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

const Introduce = () => {
    const navigate = useNavigate();
    return (
        <section className="relative z-10 flex flex-col mx-auto lg:flex-row">
            <div className="flex  flex-col gap-5 md:mx-auto md:ml-[120px] md:max-w-[615px] relative z-10 flex-1 xs:py-[182px] py-[90px]">
                <h1 className="px-[30px] md:whitespace-nowrap  md:px-[0] text-center md:text-left text-4xl xs:text-5xl font-bold text-dark-nav font-sens tracking-[-2.4px] text-shadow-outer">
                    Engage audiences seamlessly
                </h1>
                <p className="px-[30px]  md:px-[0] text-sm lg:text-base text-center md:text-left md:mr-[85px]">
                    Our cutting-edge technology, superior quality, and vast scale provide the best advertising platform
                    for targeting audiences, data, and identity across CTV, apps, and the web.
                </p>

                <Button
                    onClick={() => navigate(routesPath.register)}
                    color="secondary"
                    size="small"
                    className="mx-auto md:mx-0 px-[15px] lg:mt-2.5 w-fit"
                >
                    Get Started
                </Button>
                <div className="absolute lg:left-[13px] left-[14%] lg:top-[52px] top-[23px] z-[1] shadow-outer p-[5px] rounded-full">
                    <Image src={SVG.money_shadow} alt="shadow_icon" />
                </div>

                <div className="absolute lg:left-[86px] left-[26.25%] lg:top-[505px] top-[426px] shadow-outer p-[5px] rounded-full  z-[1]">
                    <Image src={SVG.dashboard_shadow} alt="shadow_icon" />
                </div>

                <div className="absolute lg:left-[292px] left-[45.625%] lg:top-[72px] top-[40px] shadow-outer p-[5px] rounded-full z-[1]">
                    <Image src={SVG.notice_shadow} alt="shadow_icon" />
                </div>

                <div className="absolute lg:left-[507px] left-[77.1875%]  lg:top-[15px] top-[12px] shadow-outer p-[5px] rounded-full z-[1]">
                    <Image src={SVG.checked_shadow} alt="shadow_icon" />
                </div>
                <div className="absolute lg:left-[435px] left-[68.4375%]  lg:top-[405px] top-[397px] shadow-outer p-[5px] rounded-full z-[1]">
                    <Image src={SVG.storage_shadow} alt="shadow_icon" />
                </div>
            </div>
            <div className="relative self-end w-full h-auto flex-1   pt-[15px]">
                <div className="bottom-0 z-0 w-full h-auto">
                    <div className="relative block w-full h-auto">
                        <img alt="landing" src={IMAGE.landing_map} className="object-cover w-full h-full" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Introduce;
