import React from 'react';
import SelectedSide from '../components/SelectedSide';

function SegmentLocationLayout({ children, ...rest }) {
    return (
        <div className="flex flex-col md:flex-row gap-[30px]">
            <div className="flex-1">{children}</div>

            <div className="md:max-w-[370px] w-full">
                <SelectedSide {...rest} />
            </div>
        </div>
    );
}

export default SegmentLocationLayout;
