import { InputText } from 'components/Input';
import Text from 'components/Text';
import React from 'react';

function CPMCalculator() {
    return (
        <div className="flex flex-col mt-5 gap-5">
            <Text>CPM is the cost of 1,000 impressions (literary Cost Per Mille).</Text>

            <InputText placeholder={'Cost'} />
            <InputText placeholder={'Impressions'} />
            <InputText placeholder={'CPM'} />
        </div>
    );
}

export default CPMCalculator;
