import { AnalyticReview } from 'components/Analytics';
import { Button } from 'components/Button';

import { CardContent } from 'components/Card';
import { BarLineChart, BarLineLegend } from 'components/Charts';
import AreaChart from 'components/Charts/AreaChart/AreaChart';
import GridTable from 'components/GridTable';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import { APP_ROLE } from 'constants/auth';
import { useNavigateSearch } from 'hooks';
import { campaignColumn, campaignRow } from 'pages/Campaign/data';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { getCurrentRole, isPermissionView } from 'utils';
import {
    agencyColumn,
    agencyRow,
    audiencesColumn,
    companiesColumn,
    companiesRow,
    creativeColumn,
    fakeRowAudiences,
    rowCreative,
    usersColumn,
    usersRow,
} from './data';
import { useState } from 'react';
const LegendOverallPerformance = () => {
    return (
        <div className="flex flex-row  items-center justify-center  gap-2.5">
            <div className="flex items-center ">
                <div className="h-[1.5px] w-[9px] bg-primary mr-[6.5px] rounded-[3px]" />
                <Text size="10">Revenue</Text>
            </div>
            <div className="flex items-center">
                <div className="h-[1.5px] w-[9px] bg-primary opacity-[0.35] mr-[6.5px] rounded-[3px]" />
                <Text size="10">Cost</Text>
            </div>
            <div className="flex items-center">
                <div className="h-[9px] w-[9px] rounded-full opacity-[0.25] bg-primary mr-[6.5px]" />
                <Text size="10">Profit</Text>
            </div>
        </div>
    );
};

function Dashboard() {
    const navigate = useNavigate();
    const navigateSearch = useNavigateSearch();
    const CURRENT_ROLE = getCurrentRole();
    const [rowActive, setRowActive] = useState(null);

    const handleActionTable = (row, action, tableName) => {
        switch (tableName) {
            case 'CAMPAIGN': {
                switch (action.value) {
                    case 'view':
                        navigate(routesPath.campaign_detail(row.id));
                        break;

                    default:
                        break;
                }
                break;
            }
            case 'AUDIENCE': {
                switch (action.value) {
                    case 'view':
                        navigate(routesPath.audience_detail(row.id));
                        break;

                    default:
                        break;
                }
                break;
            }
            case 'CREATIVE': {
                switch (action.value) {
                    case 'view':
                        navigate(routesPath.creative_detail(row.id));
                        break;

                    default:
                        break;
                }
                break;
            }
            case 'USER': {
                switch (action.value) {
                    case 'view':
                        navigate(routesPath.user_detail(row.id));
                        break;

                    default:
                        break;
                }
                break;
            }

            case 'COMPANY': {
                switch (action.value) {
                    case 'view':
                        navigate(routesPath.company_detail(row.id));
                        break;

                    default:
                        break;
                }
                break;
            }

            case 'AGENCY': {
                switch (action.value) {
                    case 'view':
                        navigate(routesPath.agency_detail(row.id));
                        break;

                    default:
                        break;
                }

                break;
            }

            default: {
                break;
            }
        }
    };
    return (
        <div className="p-2.5 md:p-[30px]  flex flex-col gap-2.5 md:gap-[30px]">
            <div className="font-extrabold text-xl text-blue-dark h-[40px] md:h-[50px] flex items-center">
                <span className="my-auto">Dashboard</span>
            </div>

            <div className="flex flex-col gap-2.5 md:gap-5">
                <div className="flex flex-col gap-[20px] w-full">
                    <SubTitle className={'h-[50px] flex items-center'}>Tasks</SubTitle>

                    <div className="flex flex-row flex-wrap gap-[30px]">
                        <CardContent
                            className={'max-w-[unset] xs:max-w-[210px] w-full'}
                            title={'Pending Account Approvals'}
                            content={'28'}
                            actionText={'View'}
                        />
                        <CardContent
                            className={'max-w-[unset] xs:max-w-[210px] w-full'}
                            title={'Pending Account Approvals'}
                            content={'8'}
                            actionText={'View'}
                        />
                        <CardContent
                            className={'max-w-[unset] xs:max-w-[210px] w-full'}
                            title={'Open Support Tickets'}
                            content={'12'}
                            actionText={'View'}
                        />
                        <CardContent
                            className={'max-w-[unset] xs:max-w-[210px] w-full'}
                            title={'Pending Refund Requests'}
                            content={'2'}
                            actionText={'View'}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-[20px] w-full">
                    <SubTitle className={'h-[50px] flex items-center'}>Performance</SubTitle>

                    <AnalyticReview
                        className={'w-full'}
                        title={'Overall Preformance'}
                        CustomReview={
                            <div className="flex  mx-auto flex-col w-[95%] h-full max-h-[170px]  gap-[5px] mb-5 xs:mb-0">
                                <BarLineLegend
                                    legend={
                                        <div className="hidden xs:block">
                                            <LegendOverallPerformance />
                                        </div>
                                    }
                                    legendYLeft={'Revenue, Costs'}
                                    legendYRight="CTR"
                                />
                                <div className="flex justify-center w-full">
                                    <BarLineChart />
                                </div>
                                <div className="block px-5 xs:hidden">
                                    <LegendOverallPerformance />
                                </div>
                            </div>
                        }
                    />
                </div>

                <div className="flex flex-col gap-[20px] w-full">
                    <SubTitle className={'h-[50px] flex items-center'}>Statistics</SubTitle>

                    <div className="flex flex-row gap-[20px] flex-wrap">
                        <CardContent className={'min-w-[210px]'} title={'Users'} content={'105'} actionText={'View'} />
                        <CardContent
                            className={'max-w-[unset] xs:max-w-[210px] w-full'}
                            title={'Campaigns'}
                            content={'235'}
                            actionText={'View'}
                        />

                        <CardContent
                            className={'max-w-[unset] xs:max-w-[210px] w-full'}
                            title={'Audiences'}
                            content={'22'}
                            actionText={'View'}
                        />
                        <CardContent
                            className={'max-w-[unset] xs:max-w-[210px] w-full'}
                            title={'Creatives'}
                            content={'94'}
                            actionText={'View'}
                        />
                        <CardContent
                            className={'max-w-[unset] xs:max-w-[210px] w-full'}
                            title={'Payments'}
                            content={'85'}
                            actionText={'View'}
                        />

                        <CardContent
                            className={'max-w-[unset] xs:max-w-[210px] w-full'}
                            title={'Withdraws'}
                            content={'3'}
                            actionText={'View'}
                        />
                    </div>
                </div>
                {/* <div className="flex justify-start  md:justify-between md:flex-row flex-col gap-2.5  md:items-center">
                    <SubTitle className="md:self-end md:mt-0 mt-[21px]">Analytics</SubTitle>
                </div> */}
                {/* <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 md:grid-cols-2">
                    <AnalyticReview
                        className={'flex-1'}
                        title={'Impressions'}
                        Chart={<DoughnutChart />}
                        LegendComponent={
                            <DoughnutLegend
                                headerLegend={fakeColumnImpression}
                                dataLegend={fakeRowLegend}
                                configData={doughnutStyle}
                            />
                        }
                    />

                    <AnalyticReview
                        className={'flex-1'}
                        title={'CTR'}
                        Chart={<DoughnutChart />}
                        LegendComponent={
                            <DoughnutLegend
                                headerLegend={fakeColumnCtr}
                                dataLegend={fakeRowLegend}
                                configData={doughnutStyle}
                            />
                        }
                    />

                    <AnalyticReview
                        className={'flex-1'}
                        title={'CPM & Total Cost'}
                        Chart={<DoughnutChart />}
                        LegendComponent={
                            <DoughnutLegend
                                headerLegend={fakeColumnCPM}
                                dataLegend={fakeRowLegend}
                                configData={doughnutStyle}
                            />
                        }
                    />
                </div> */}
                {isPermissionView([APP_ROLE.Ag]) && (
                    <AnalyticReview
                        className={'flex-1'}
                        title={'Profit'}
                        CustomReview={
                            <div className="flex flex-col w-[95%] mx-auto h-full max-h-[170px]  gap-[5px] mb-5 xs:mb-0">
                                <BarLineLegend
                                    legendYRight={
                                        <div className="">
                                            <LegendOverallPerformance />
                                        </div>
                                    }
                                    legendYLeft={'Profit, $'}
                                />
                                <div className="flex-1">
                                    <AreaChart />
                                </div>
                            </div>
                        }
                    />
                )}
            </div>

            <div className="flex flex-col gap-2.5 md:gap-5">
                <div className="flex justify-start  md:justify-between md:flex-row flex-col  gap-2.5  md:items-center">
                    <SubTitle className="md:self-end md:mt-0 mt-[21px]">Recent Campaigns</SubTitle>
                    <div className="flex gap-2.5 md:gap-5">
                        <Button
                            className={'!p-2.5 text-xs'}
                            size="none"
                            variant="outlined"
                            onClick={() => navigate(routesPath.campaign)}
                        >
                            View All
                        </Button>
                        <Button
                            className={'!p-2.5 text-xs'}
                            size="none"
                            variant="outlined"
                            onClick={() => navigate(routesPath.campaign_services(312))}
                        >
                            Add Campaign
                        </Button>
                    </div>
                </div>
                <div className="flex flex-col rounded-[10px] border border-blue-cfd">
                    <GridTable
                        className={'table-fixed w-full'}
                        isRadiusTable
                        column={campaignColumn}
                        row={campaignRow.slice(0, 5)}
                        handle={(row, action) => handleActionTable(row, action, 'CAMPAIGN')}
                    />
                </div>
            </div>

            <div className="flex flex-col gap-2.5 md:gap-5">
                <div className="flex justify-start  md:justify-between md:flex-row flex-col gap-2.5  md:items-center">
                    <SubTitle className="md:self-end md:mt-0 mt-[21px]">Recent Audiences</SubTitle>

                    <div className="flex gap-2.5 md:gap-5">
                        <Button
                            className={'!p-2.5 text-xs'}
                            size="none"
                            variant="outlined"
                            onClick={() => navigate(routesPath.audiences)}
                        >
                            View All
                        </Button>
                        <Button
                            className={'!p-2.5 text-xs'}
                            size="none"
                            variant="outlined"
                            onClick={() => navigate(routesPath.audience_add)}
                        >
                            Add Audience
                        </Button>
                    </div>
                </div>
                <div className="flex flex-col rounded-[10px] border border-blue-cfd">
                    <GridTable
                        className={'w-full table-fixed'}
                        column={audiencesColumn}
                        isRadiusTable
                        row={fakeRowAudiences.slice(0, 5)}
                        handle={(row, action) => handleActionTable(row, action, 'AUDIENCE')}
                    />
                </div>
            </div>

            <div className="flex flex-col gap-2.5 md:gap-5">
                <div className="flex justify-start  md:justify-between md:flex-row flex-col gap-2.5  md:items-center">
                    <SubTitle className="md:self-end md:mt-0 mt-[21px]">Creatives</SubTitle>

                    <div className="flex gap-2.5 md:gap-5">
                        <Button
                            className={'!p-2.5 text-xs'}
                            size="none"
                            variant="outlined"
                            onClick={() => navigate(routesPath.creative)}
                        >
                            View All
                        </Button>
                        <Button
                            className={'!p-2.5 text-xs'}
                            size="none"
                            variant="outlined"
                            onClick={() => navigate(routesPath.creative_add)}
                        >
                            Add Creative
                        </Button>
                    </div>
                </div>
                <div className="flex flex-col rounded-[10px] border border-blue-cfd">
                    <GridTable
                        isRadiusTable
                        className={'w-full table-fixed'}
                        column={creativeColumn}
                        row={rowCreative.slice(0, 5)}
                        handle={(row, action) => handleActionTable(row, action, 'CREATIVE')}
                    />
                </div>
            </div>

            {isPermissionView(['Co', 'SubCo', 'Ag', 'Org']) && (
                <div className="flex flex-col gap-2.5 md:gap-5">
                    <div className="flex justify-start  md:justify-between md:flex-row flex-col gap-2.5  md:items-center">
                        <SubTitle className="md:self-end md:mt-0 mt-[21px]">Users</SubTitle>

                        <div className="flex gap-2.5 md:gap-5">
                            <Button
                                className={'!p-2.5 text-xs'}
                                size="none"
                                variant="outlined"
                                onClick={() =>
                                    navigateSearch(
                                        CURRENT_ROLE === APP_ROLE.SubCo
                                            ? routesPath.account_subCo
                                            : routesPath.account_company,
                                        { config: 'users' },
                                    )
                                }
                            >
                                View All
                            </Button>
                            <Button
                                className={'!p-2.5 text-xs'}
                                size="none"
                                variant="outlined"
                                onClick={() => navigate(routesPath.add_user)}
                            >
                                Add User
                            </Button>
                        </div>
                    </div>
                    <div className="flex flex-col rounded-[10px] border border-blue-cfd">
                        <GridTable
                            isRadiusTable
                            className={'w-full table-fixed'}
                            column={usersColumn}
                            row={usersRow.slice(0, 5)}
                            handle={(row, action) => handleActionTable(row, action, 'USER')}
                        />
                    </div>
                </div>
            )}

            {isPermissionView(['Ag', 'Org']) && (
                <div className="flex flex-col gap-2.5 md:gap-5">
                    <div className="flex justify-start  md:justify-between md:flex-row flex-col gap-2.5  md:items-center">
                        <SubTitle className="md:self-end md:mt-0 mt-[21px]">Companies</SubTitle>

                        <div className="flex gap-2.5 md:gap-5">
                            <Button
                                className={'!p-2.5 text-xs'}
                                size="none"
                                variant="outlined"
                                onClick={() =>
                                    navigateSearch(
                                        CURRENT_ROLE === APP_ROLE.Org
                                            ? routesPath.account_organization
                                            : routesPath.account_agency,
                                        { config: 'companies' },
                                    )
                                }
                            >
                                View All
                            </Button>
                            <Button className={'!p-2.5 text-xs'} size="none" variant="outlined">
                                Add Campaign
                            </Button>
                        </div>
                    </div>
                    <div className="flex flex-col rounded-[10px] border border-blue-cfd">
                        <GridTable
                            isRadiusTable
                            className={'w-full table-fixed'}
                            column={companiesColumn}
                            row={companiesRow.slice(0, 5)}
                            handle={(row, action) => {
                                handleActionTable(row, action, 'COMPANY');
                            }}
                        />
                    </div>
                </div>
            )}
            {isPermissionView(['Org']) && (
                <div className="flex flex-col gap-2.5 md:gap-5">
                    <div className="flex justify-start  md:justify-between md:flex-row flex-col gap-2.5  md:items-center">
                        <SubTitle className="md:self-end md:mt-0 mt-[21px]">Agencies</SubTitle>

                        <div className="flex gap-2.5 md:gap-5">
                            <Button
                                className={'!p-2.5 text-xs'}
                                size="none"
                                variant="outlined"
                                onClick={() => navigateSearch(routesPath.account_organization, { config: 'agencies' })}
                            >
                                View All
                            </Button>
                            <Button className={'!p-2.5 text-xs'} size="none" variant="outlined">
                                Add Agencies
                            </Button>
                        </div>
                    </div>
                    <div className="flex flex-col rounded-[10px] border border-blue-cfd">
                        <GridTable
                            isRadiusTable
                            className={'w-full table-fixed'}
                            column={agencyColumn}
                            row={agencyRow.slice(0, 5)}
                            handle={(row, action) => {
                                handleActionTable(row, action, 'AGENCY');
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Dashboard;
