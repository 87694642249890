import React, { forwardRef } from 'react';
import clsx from 'clsx';

// radius : normal | full | none
// variant: outlined | contained
// shadow: outer | inner |  both  | none

function Box(
    {
        Component = 'div',
        radius = 'normal',
        variant = 'contained',
        shadow = 'none',
        className,
        children = '',
        ...props
    },
    ref,
) {
    //
    let radiusClass = '';

    switch (radius) {
        case 'normal':
            radiusClass = 'rounded-[10px]';
            break;
        case 'full':
            radiusClass = 'rounded-full';
            break;

        default:
            radiusClass = '';
            break;
    }

    let variantClass = '';

    switch (variant) {
        case 'outlined':
            variantClass = 'border-none';
            break;
        case 'contained':
            variantClass = 'border border-blue-cfd';
            break;

        default:
            variantClass = '';
            break;
    }

    let shadowClass;

    switch (shadow) {
        case 'inner':
            shadowClass = 'shadow-inner bg-white-light';
            break;
        case 'outer':
            shadowClass = 'shadow-outer bg-blue-ebe';
            break;
        case 'both':
            shadowClass = 'shadow-both';
            break;
        case 'light':
            shadowClass = 'shadow-light';
            break;
        case 'default':
            shadowClass = 'shadow-default';
            break;
        default:
            shadowClass = '';
            break;
    }

    return (
        <Component
            ref={ref}
            className={clsx('bg-inherit', radiusClass, variantClass, shadowClass, className)}
            {...props}
        >
            {children}
        </Component>
    );
}

export default forwardRef(Box);
