import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Tooltip } from 'chart.js';
import { kFormatter, percentFormatter } from 'utils';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip);

const labels = [
    'Feb 2',
    'Feb 4',
    'Feb 6',
    'Feb 8',
    'Feb 10',
    'Feb 12',
    'Feb 14',
    'Feb 16',
    'Feb 18',
    'Feb 20',
    'Feb 22',
    'Feb 24',
    'Feb 28',
    'March 2',
    'March 4',
    'March 6',
    'March 8',
    'March 10',
    'March 12',
    'March 14',
    'March 16',
    'March 18',
    'March 20',
    'March 22',

    'March 24',
    'March 26',

    'March 28',
    'March 30',
    'Apr 3',
];

const dataRandom = labels.map((item, idx) => {
    return Math.floor(((idx + 1) / labels.length) * 5000);
});
const dataRandom2 = labels.map((item, idx) => {
    return Math.floor((idx / labels.length) * 4000);
});

const fakeData = {
    labels,
    datasets: [
        {
            label: 'My First dataset',
            fill: false,
            lineTension: 0.4,
            backgroundColor: '#0E65F1',
            borderColor: '#0E65F1',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointRadius: 0,
            data: dataRandom,
        },
    ],
};

const exampleOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        intersect: false,
    },
    plugins: {
        tooltip: {
            yAlign: 'bottom',
            displayColors: false,
            backgroundColor: 'white',
            titleAlign: 'center',
            titleFont: {
                color: 'black',
            },
            titleColor: 'black',
            titleMarginBottom: 0,
            callbacks: {
                label: () => '',
                title: (value) => {
                    // console.log(value);
                    return '$' + value[0]?.formattedValue;
                },
                footer: (tooltipItems) => '',
            },
        },
    },
    scales: {
        y: {
            grid: {
                display: false,
            },
            beginAtZero: true,
            type: 'linear',

            suggestedMin: 0,
            suggestedMax: 5000,
            ticks: {
                callback: function (value, index, ticks) {
                    return kFormatter(value);
                },
                stepSize: 2500,
                font: {
                    size: 10,
                },
                color: '#1D2533',
                padding: 0,
            },
        },
        x: {
            grid: {
                display: false,
            },
        },
    },
};

const LineChart = ({ data = fakeData, options = exampleOptions }) => <Line data={data} options={options} />;

export default LineChart;
