export const fakeColumnCity = [
    {
        field: 'country',
        headerName: 'Country',
    },

    {
        field: 'impressions',
        headerName: 'Impressions',
        classNameCell: 'text-right',
    },
    {
        field: 'clicks',
        headerName: 'Clicks',
        classNameCell: 'text-right',
    },
    {
        field: 'ctr',
        headerName: 'CTR',
        classNameCell: 'text-right',
    },
];
export const fakeColumnLabel = [
    {
        field: 'label',
        headerName: 'Label',
    },

    {
        field: 'impressions',
        headerName: 'Impressions',
        classNameCell: 'text-right',
    },
    {
        field: 'clicks',
        headerName: 'Clicks',
        classNameCell: 'text-right',
    },
    {
        field: 'ctr',
        headerName: 'CTR',
        classNameCell: 'text-right',
    },
];

export const fakeRowPerformance = [
    {
        country: 'USA',
        label: 'Label',
        impressions: '89,321',
        clicks: '401',
        ctr: '0.41%',
    },
    {
        country: 'China',
        label: 'Label',
        impressions: '72,321',
        clicks: '201',
        ctr: '0.12%',
    },
    {
        country: 'Canada',
        label: 'Label',
        impressions: '29,321',
        clicks: '38',
        ctr: '0.2%',
    },
    {
        country: 'Spain',
        label: 'Label',
        impressions: '19,321',
        clicks: '12',
        ctr: '0.01%',
    },
    {
        country: 'Germany',
        label: 'Label',
        impressions: '9,321',
        clicks: '3',
        ctr: '0.02%',
    },
];
