import Radio from 'components/Input/Radio';
import { useField } from 'formik';

function FormRadio(props) {
    const [field, meta, helpers] = useField(props);

    const { setValue: setValueFormik } = helpers;
    return <Radio helper={meta.error} error={meta.error} setValueFormik={setValueFormik} {...field} {...props} />;
}

export default FormRadio;
