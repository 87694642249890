import clsx from 'clsx';
import { BoxLabel } from 'components/BoxLabel';
import InputColor from 'components/Input/InputColor';
import { Svg } from 'components/Svg';
import { COLOR_DEFAULT } from 'constants/common';
import { useEffect, useRef, useState } from 'react';

const ColorSelect = ({ color, active = true, ...rest }) => {
    return (
        <div className="cursor-pointer aspect-square flex justify-center items-center w-[25px] h-[25px]" {...rest}>
            <div
                className={clsx(
                    'w-5 rounded-full h-5  border-[1px] border-blue-cfd',
                    active && 'outline outline-2 outline-offset-1 outline-primary',
                )}
                style={{
                    backgroundColor: color,
                }}
            ></div>
        </div>
    );
};

function ColorPicker({ label, defaultPicker, name, onChangeColor, externalColor }) {
    const [colors, setColors] = useState(COLOR_DEFAULT);
    const ref = useRef(null);
    const [colorActive, setColorActive] = useState(colors.find((item) => item === defaultPicker));

    const handleOnChangeColor = (color) => {
        setColors([...colors, color]);
    };

    useEffect(() => {
        //set autogenerate Color
        if (externalColor) {
            setColors([...colors, externalColor]);
        }
    }, [externalColor]);

    return (
        <BoxLabel className={'p-[15px]'} label={label} classNameLabel={'!left-[15px] !text-[10px]'}>
            <div className="flex flex-row flex-wrap gap-[5px] items-center">
                {colors.map((color) => {
                    return (
                        <ColorSelect
                            active={colorActive === color}
                            color={color}
                            onClick={() => {
                                setColorActive(color);
                                onChangeColor?.(color);
                            }}
                        />
                    );
                })}

                <InputColor onChangeComplete={(color) => handleOnChangeColor(color)} ref={ref}>
                    <div className="aspect-square  flex justify-center items-center w-[25px] h-[25px]">
                        <div
                            className={clsx(
                                'flex justify-center items-center w-5 rounded-full h-5  ring-2 ring-blue-cfd',
                            )}
                        >
                            <Svg name="plus" className="child:fill-black" />
                        </div>
                    </div>
                </InputColor>
            </div>
        </BoxLabel>
    );
}

export default ColorPicker;
