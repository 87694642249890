import { Modal } from 'components/Modal';
import { Portal } from 'components/Portal';
import FormInputText from 'components/modules/form/FormInputText';
import FormTextArea from 'components/modules/form/FormTextArea';
import { Formik, useFormikContext } from 'formik';
import { useState } from 'react';
import GenerateText from '../GenerateText';
import GenerativeButton from '../GenerativeButton';

function DesignChooseText() {
    const [openModal, setOpenModal] = useState({
        open: false,
        data: {},
    });

    const handleCloseModal = () => {
        setOpenModal((prev) => ({
            ...prev,
            open: false,
        }));
    };
    const handleOpenModal = () => {
        setOpenModal((prev) => ({
            ...prev,
            open: true,
        }));
    };

    const { setValues, values } = useFormikContext();
    return (
        <>
            <div className="flex flex-col gap-5">
                <GenerativeButton
                    label="Try Our Generative Text"
                    onClick={() => {
                        handleOpenModal();
                    }}
                />

                <FormInputText className="px-5 py-4" name="title.text" label="Title" placeholder="Title" />

                <FormTextArea
                    className="px-5 py-4"
                    multiline
                    rows={4}
                    name="subTitle.text"
                    label="Subtitle"
                    placeholder="Subtitle"
                />

                <FormInputText
                    className="px-5 py-4"
                    name="callAction.text"
                    label="Call to action"
                    placeholder="Call to action"
                />
            </div>

            <Portal>
                <Modal
                    bodyClass="max-w-[580px] w-full"
                    isOpen={openModal.open}
                    onClose={handleCloseModal}
                    title="Generate Text"
                >
                    <Formik
                        initialValues={{
                            logoDesc: '',
                            imgLogo: '',
                        }}
                        onSubmit={(data, actions) => {
                            setValues({
                                ...values,
                                logo: {
                                    desc: data.logoDesc,
                                    img: data.imgLogo,
                                },
                            });
                            handleCloseModal();
                        }}
                    >
                        <GenerateText />
                    </Formik>
                </Modal>
            </Portal>
        </>
    );
}

export default DesignChooseText;
