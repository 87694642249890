import { SVG } from 'assets/imagePath';
import { CardContent } from 'components/Card';
import { SubTitle } from 'components/Title';

function AgreementBlock({ agreement_signed, term_checked }) {
    return (
        <div className="flex flex-col gap-5">
            <SubTitle>Agreement</SubTitle>

            <div className="grid grid-flow-row grid-cols-1 gap-[30px] xs:grid-cols-2">
                <CardContent
                    className={'w-full'}
                    content={agreement_signed ? 'Signed' : 'Not Signed'}
                    title="Agreement"
                    actionText={'View'}
                    icon={agreement_signed ? SVG.checked_circle : SVG.cancel_circle}
                />
                <CardContent
                    className={'w-full'}
                    content={term_checked ? 'Checked' : 'Not Checked'}
                    title="Terms"
                    actionText={'View'}
                    icon={term_checked ? SVG.checked_circle : SVG.cancel_circle}
                />
            </div>
        </div>
    );
}

export default AgreementBlock;
