import { IMAGE, SVG } from 'assets/imagePath';
import { CardContent } from 'components/Card';
import Image from 'components/Image/Image';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function SettingsOrganization() {
    const navigate = useNavigate();
    return (
        <div className="flex flex-row gap-x-[30px]">
            <CardContent
                className={'max-w-[186px] w-full'}
                title={'Audience'}
                content={'36'}
                actionText="View"
                onAction={() => navigate(routesPath.deals)}
            />
            <CardContent
                className={'max-w-[186px] w-full'}
                title={'Performance'}
                content={'36'}
                actionText="View"
                onAction={() => navigate(routesPath.deals)}
            />
            <CardContent
                className={'max-w-[186px] w-full'}
                title={'Pricing'}
                content={<Image className={'w-8 h-8 object-contain'} src={SVG.money_circle} />}
                actionText="Manage"
                onAction={() => navigate(routesPath.pricing)}
            />
        </div>
    );
}

export default SettingsOrganization;
