import Chip from 'components/Chip/Chip';
import Image from 'components/Image/Image';
import Text from 'components/Text/Text';

function PublicEducationPostDetail({ post }) {
    return (
        <div className="flex flex-col items-center w-full h-auto gap-5">
            <Text
                weight="bold"
                className="!text-5xl text-center font-sen text-shadow-outer drop-shadow-outer tracking-tighter"
            >
                {post?.title}
            </Text>

            <Chip color="white">{post?.category}</Chip>

            <div className="flex flex-col !text-sm gap-[60px] post__detail">
                {/* { post.content} */}
                <Text className="!text-sm mx-auto max-w-[640px]">
                    Lorem ipsum dolor sit amet consectetur. Augue pulvinar blandit in ut. Sollicitudin tempor leo
                    phasellus nulla rhoncus. Elit id phasellus adipiscing nunc pulvinar aliquam tortor. Turpis amet
                    ullamcorper orci aliquam nulla aliquam. Amet est magna eget tristique lectus dignissim tellus
                    phasellus mi. Ipsum ullamcorper orci venenatis egestas turpis eget nisi nec platea. Id odio et hac
                    bibendum facilisis duis mi tincidunt lorem. Integer erat fames aliquet dui arcu vulputate eros. Sed
                    fermentum ultrices pretium turpis eu. Risus blandit egestas vel sed dui. Dictum dolor orci commodo
                    in imperdiet dignissim elit convallis volutpat. Neque iaculis mauris justo purus feugiat sit sed ut.
                    Non egestas senectus convallis eget sed.
                </Text>

                <div className="aspect-square xs:aspect-[auto] mx-auto  xs:max-w-[960px] max-w-[280px] w-full h-auto rounded-[20px] overflow-hidden">
                    <Image
                        className="object-cover w-full h-full"
                        src="https://picsum.photos/960/540"
                        alt="post-image"
                    />
                </div>

                <Text className="!text-sm mx-auto max-w-[640px]">
                    Lorem ipsum dolor sit amet consectetur. Augue pulvinar blandit in ut. Sollicitudin tempor leo
                    phasellus nulla rhoncus. Elit id phasellus adipiscing nunc pulvinar aliquam tortor. Turpis amet
                    ullamcorper orci aliquam nulla aliquam. Amet est magna eget tristique lectus dignissim tellus
                    phasellus mi. Ipsum ullamcorper orci venenatis egestas turpis eget nisi nec platea. Id odio et hac
                    bibendum facilisis duis mi tincidunt lorem. Integer erat fames aliquet dui arcu vulputate eros. Sed
                    fermentum ultrices pretium turpis eu. Risus blandit egestas vel sed dui. Dictum dolor orci commodo
                    in imperdiet dignissim elit convallis volutpat. Neque iaculis mauris justo purus feugiat sit sed ut.
                    Non egestas senectus convallis eget sed.
                </Text>

                <div className="aspect-square xs:aspect-[auto] mx-auto  xs:max-w-[960px] max-w-[280px] w-full h-auto rounded-[20px] overflow-hidden">
                    <Image
                        className="object-cover w-full h-full"
                        src="https://picsum.photos/960/540"
                        alt="post-image"
                    />
                </div>
                <Text className="!text-sm mx-auto max-w-[640px]">
                    Lorem ipsum dolor sit amet consectetur. Augue pulvinar blandit in ut. Sollicitudin tempor leo
                    phasellus nulla rhoncus. Elit id phasellus adipiscing nunc pulvinar aliquam tortor. Turpis amet
                    ullamcorper orci aliquam nulla aliquam. Amet est magna eget tristique lectus dignissim tellus
                    phasellus mi. Ipsum ullamcorper orci venenatis egestas turpis eget nisi nec platea. Id odio et hac
                    bibendum facilisis duis mi tincidunt lorem. Integer erat fames aliquet dui arcu vulputate eros. Sed
                    fermentum ultrices pretium turpis eu. Risus blandit egestas vel sed dui. Dictum dolor orci commodo
                    in imperdiet dignissim elit convallis volutpat. Neque iaculis mauris justo purus feugiat sit sed ut.
                    Non egestas senectus convallis eget sed.
                </Text>

                <div className="aspect-square xs:aspect-[auto] mx-auto  xs:max-w-[960px] max-w-[280px] w-full h-auto rounded-[20px] overflow-hidden">
                    <Image
                        className="object-cover w-full h-full"
                        src="https://picsum.photos/960/540"
                        alt="post-image"
                    />
                </div>
            </div>
        </div>
    );
}

export default PublicEducationPostDetail;
