import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Text from 'components/Text';
import { useEffect, useRef, useState } from 'react';
import { shadowClass, variantClass, variantLabelClass } from './BoxLabel.style';

//variant = outlined | contained

function BoxLabel({
    children,
    label = '',
    variant = 'contained',
    shadow = 'default',
    helper,
    className,
    classNameLabel,
    propsText,
    hasCopy = false,
    ...props
}) {
    const textRef = useRef();

    const [height, setHeight] = useState(0);

    useEffect(() => {
        setHeight(textRef.current?.offsetHeight);
    }, []);

    const boxRef = useRef();

    useEffect(() => {}, []);

    return (
        <div
            ref={boxRef}
            className={clsx('relative rounded-[10px] ', variantClass(variant), shadowClass(shadow), className)}
            {...props}
        >
            {children}

            <Text
                ref={textRef}
                className={clsx(
                    'px-[10px] absolute bg-inherit z-0  rounded-[30px] left-[30px] top-[-8px]',
                    variantLabelClass(variant),
                    shadowClass(shadow),
                    classNameLabel,
                )}
                size="12"
                {...propsText}
            >
                {label}
            </Text>

            <Text
                className={clsx(
                    'px-[10px] absolute bg-inherit z-0  rounded-[30px] left-[10px]',
                    variantLabelClass(variant),
                    // shadowClass(shadow),
                    classNameLabel,
                )}
                style={{
                    bottom: -height / 2 + 'px',
                }}
                size="10"
                {...propsText}
            >
                {helper}
            </Text>

            {hasCopy && (
                <button className="absolute flex items-center justify-center mx-auto bottom-2 right-2">
                    <img className="object-contain mx-auto cursor-pointer" src={SVG.copy_light} alt="more" />
                </button>
            )}
        </div>
    );
}

export default BoxLabel;
