import { SVG } from 'assets/imagePath';
import { DetailBox } from 'components/DetailBox';
import ActionsButton from 'components/GridTable/ActionsButton';
import GridTable from 'components/GridTable/GridTable';
import Image from 'components/Image/Image';
import { Svg } from 'components/Svg';
import Tabs from 'components/Tabs/Tabs';
import Text from 'components/Text/Text';
import ToolTip from 'components/Tooltip/Tooltip';
import { useState } from 'react';

function ViewSegment({ dealData }) {
    const dealList = [
        {
            value: 'summary',
            label: 'Summary',
        },
        {
            value: 'audience_segment',
            label: 'Audience Segments',
            sub: 17,
        },
        {
            value: 'semantic_segments',
            label: 'Semantic Segments',
            sub: 9,
        },
    ];

    const dealsColumn = [
        {
            field: 'id',
            headerName: 'Deal ID',
        },

        {
            field: 'createdAt',
            headerName: 'Created at',
        },
        {
            field: 'creative_sizes',
            headerName: 'Creative Sizes',
            renderCell: (row) => {
                return (
                    <ToolTip
                        className={'!w-fit'}
                        parentId="dealTable"
                        tooltip={
                            <ol className="p-2.5 list-disc list-inside">
                                {row?.creative_sizes.map((item, idx) => (
                                    <li key={idx} className="text-start">
                                        {item}
                                    </li>
                                ))}
                            </ol>
                        }
                    >
                        <div className="flex flex-row max-md:justify-end items-center gap-[5px]">
                            <Text>{row?.creative_sizes?.length}</Text>
                            <Svg name="infoOutline" className="w-[15px] h-[15px]" />
                        </div>
                    </ToolTip>
                );
            },
        },
        {
            field: 'audience_sizes',
            headerName: 'Audience Segments',
            renderCell: (row) => {
                return (
                    <ToolTip className={'!w-fit'} parentId="dealTable" tooltip={'hello world'}>
                        <div className="flex flex-row  max-md:justify-end items-center gap-[5px]">
                            <Text>7</Text>
                            <Svg name="infoOutline" className="w-[15px] h-[15px]" />
                        </div>
                    </ToolTip>
                );
            },
        },
        {
            field: 'semantic_sizes',
            headerName: 'Semantic Sizes',
            renderCell: (row) => {
                return (
                    <ToolTip className={'!w-fit'} parentId="dealTable" tooltip={'hello world'}>
                        <div className=" flex flex-row max-md:justify-end items-center gap-[5px]">
                            <Text>7</Text>
                            <Svg name="infoOutline" className="w-[15px] h-[15px]" />
                        </div>
                    </ToolTip>
                );
            },
        },

        {
            field: 'data_cost',

            headerName: 'Data Cost',
        },
        {
            field: 'media_cost',

            headerName: 'Media Cost',
        },
        {
            field: 'currency',

            headerName: 'Currency',
        },
        {
            field: 'country',

            headerName: 'Country',
        },

        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (row) => {
                return (
                    <ActionsButton
                        options={[
                            {
                                label: 'View',
                                value: 'view',
                            },
                            {
                                label: 'Edit',
                                value: 'edit',
                            },
                        ]}
                        // onChange={(valueAction) => onChangeAction(valueAction, row)}
                    />
                );
            },
        },
    ];

    const segmentsColumn = [
        {
            field: 'id',
            headerName: 'ID',
        },
        {
            field: 'provider',
            headerName: 'Provider',
        },
        {
            field: 'name',
            headerName: 'Name',
        },
        {
            field: 'price',
            headerName: 'Price',
        },
        {
            field: 'desc',
            headerName: 'Description',
        },
    ];

    const fakeAudienceSegments = [
        {
            id: '19541',
            provider: 'Navegg',
            name: 'Navegg: > Age > 16-17 (NID)',
            price: '$0.264',
            desc: 'Teenagers',
        },
    ];
    const fakeSemanticSegments = [
        {
            id: '19541',
            provider: 'Navegg',
            name: 'Langauge > English',
            price: '$0.10',
            desc: 'English Langauge Speaking Audience',
        },
    ];
    const [tabsVal, setTabsVal] = useState(dealList[0]);
    return (
        <div className="flex flex-col gap-5">
            <Tabs
                onChange={(tab) => {
                    setTabsVal(tab);
                }}
                tabList={dealList}
                value={tabsVal?.value}
            />

            {tabsVal.value === 'summary' && <DetailBox title={'Details'} column={dealsColumn} row={dealData} />}
            {tabsVal.value === 'audience_segment' && (
                <div id="" className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                    <GridTable className={'w-full'} column={segmentsColumn} row={fakeAudienceSegments} />

                    <div className="bg-white-light flex justify-end p-[15px]  gap-[5px]">
                        <p className="text-sm font-normal text-grey-light">17 of 17 records</p>
                        <Image src={SVG.chevron_left_light} />
                        <Image src={SVG.chevron_right_light} />
                    </div>
                </div>
            )}

            {tabsVal.value === 'semantic_segments' && (
                <div id="" className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                    <GridTable className={'w-full table-fixed'} column={segmentsColumn} row={fakeSemanticSegments} />

                    <div className="bg-white-light flex justify-end p-[15px]  gap-[5px]">
                        <p className="text-sm font-normal text-grey-light">17 of 17 records</p>
                        <Image src={SVG.chevron_left_light} />
                        <Image src={SVG.chevron_right_light} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ViewSegment;
