import clsx from 'clsx';
import Text from 'components/Text';
import ToolTip from 'components/Tooltip/Tooltip';

function RowDetail2({ column, row, rowStyle, isText, boxId, handle }) {
    return (
        <div className="flex flex-col w-full">
            {column?.map((column, idx) => {
                return (
                    !column?.hideDetail && (
                        <div key={idx} className={clsx('flex flex-col gap-[5px] w-full', rowStyle)}>
                            <div className="px-2.5 bg-blue-eae field">
                                <ToolTip tooltip={column.headerName} parentId={boxId}>
                                    <Text className="!text-xs md:!text-xs" component="span" size="12">
                                        {column.headerName}
                                    </Text>
                                </ToolTip>
                            </div>
                            {column?.renderCell && !isText ? (
                                <div className="flex-1 px-2.5 pb-[5px] w-full field__value">
                                    {column?.renderCell(row, handle)}
                                </div>
                            ) : (
                                <div className="flex-1 px-2.5 w-full field__value">
                                    <ToolTip tooltip={column?.tooltip && row?.[column?.field]} parentId={boxId}>
                                        <Text
                                            className="relative overflow-hidden !text-xs md:!text-xs"
                                            component="p"
                                            size="12"
                                            ellipsis
                                        >
                                            {row?.[column?.field]}
                                        </Text>
                                    </ToolTip>
                                </div>
                            )}
                        </div>
                    )
                );
            })}
        </div>
    );
}

export default RowDetail2;
