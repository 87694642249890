import Tabs from 'components/Tabs/Tabs';
import { useAdCanva } from 'context/AdCanvaContext';
import { useState } from 'react';
import { Designer, Templates, Community } from './AdCanva/components/tabs';
import MyCanvas from './AdCanva/components/tabs/MyCanvas';
import { useViewport } from 'context/ViewportContext';
import Dropdown, { DropdownMenu } from 'components/Dropdown';
import Text from 'components/Text';
import Image from 'components/Image';
import { SVG } from 'assets/imagePath';

const tabs = [
    { label: 'My Canvas', value: 'my_canvas' },
    { label: 'Templates', value: 'templates' },

    { label: 'Community', value: 'community' },
    { label: 'Designer', value: 'designer' },
];
function AdCanvaModule() {
    const { width: widthScreen } = useViewport();
    const { step, setStep, setTemplate, template } = useAdCanva();
    const [tab, setTab] = useState(tabs[0]);

    // useEffect(() => {
    //     setTemplate({
    //         id: template,
    //         status: 'Draft',
    //     });
    // }, [template]);

    return (
        <div>
            {(widthScreen || 0) >= 768 ? (
                <Tabs
                    className={'sticky'}
                    classNameBox={'my-[30px]'}
                    onChange={(tab) => {
                        setTab(tab);
                        setStep(1);
                    }}
                    tabList={tabs}
                    value={tab.value}
                />
            ) : (
                <Dropdown
                    options={({ close }) => (
                        <DropdownMenu
                            menu={tabs}
                            onChange={(menuValue) => {
                                setTab(menuValue);
                                setStep(1);
                                close();
                            }}
                        />
                    )}
                    classNameBox={'w-full my-[30px]'}
                    className={'max-w-full'}
                >
                    <div className="rounded-[10px] shadow-raise border-2 border-blue-ebe bg-blue-ebe flex flex-row justify-between w-full items-center gap-2.5 h-[50px] px-[15px]">
                        <Text>{tab.label}</Text>
                        <Image src={SVG.chevron_down} />
                    </div>
                </Dropdown>
            )}

            {tab.value === 'my_canvas' && <MyCanvas />}
            {tab.value === 'templates' && <Templates />}
            {tab.value === 'community' && <Community />}
            {tab.value === 'designer' && <Designer />}
        </div>
    );
}

export default AdCanvaModule;
