import { Button } from 'components/Button';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { InputText, Select } from 'components/Input';
import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

const countryOptions = [
    {
        value: 'personal',
        label: 'Personal',
    },
    {
        value: 'business',
        label: 'Business',
    },
];
function AddCompany() {
    const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: { job_title: 'Ad Buyer' },
        onSubmit: (values) => {
            alert(JSON.stringify(values));
        },
        // validationSchema: createAccountSchema,
    });

    const navigate = useNavigate();

    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <HeaderNavigate detailInfo={{ detailName: 'Add Company' }} />

            <form onSubmit={handleSubmit} className="flex flex-col gap-5 max-w-[820px] w-full mt-[30px]">
                <div className="flex flex-col gap-5 sm:flex-row">
                    <InputText
                        placeholder="Company Name"
                        label="Company Name"
                        rounded
                        name="company_name"
                        onChange={handleChange}
                        value={values.company_name}
                        error={errors.first_name}
                    />
                </div>
                <div className="flex flex-col gap-5 sm:flex-row">
                    <InputText
                        placeholder="First Name"
                        rounded
                        name="first_name"
                        label={'First Name'}
                        onChange={handleChange}
                        value={values.first_name}
                        error={errors.first_name}
                    />
                    <InputText
                        placeholder="Last Name"
                        rounded
                        name="last_name"
                        label={'Last Name'}
                        onChange={handleChange}
                        value={values.last_name}
                        error={errors.last_name}
                    />
                </div>
                <div className="flex flex-col gap-5 sm:flex-row">
                    <InputText
                        placeholder="User Email"
                        label={'User Email'}
                        rounded
                        name="user_email"
                        onChange={handleChange}
                        value={values.user_email}
                        error={errors.user_email}
                    />
                </div>
                <div className="flex flex-col gap-5 sm:flex-row">
                    <InputText
                        placeholder="Mobile Phone"
                        label="Mobile Phone"
                        rounded
                        type="number"
                        name="mobile_phone"
                        onChange={handleChange}
                        value={values.mobile_phone}
                        error={errors.mobile_phone}
                    />
                </div>

                <div className="flex flex-col gap-5 xs:flex-row">
                    <Select
                        placeholder="Country"
                        options={countryOptions}
                        setValue={setFieldValue}
                        value={values.country}
                        classNameText="flex-1"
                        name="country"
                        propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                    />
                    <Select
                        placeholder="City"
                        options={countryOptions}
                        setValue={setFieldValue}
                        value={values.city}
                        classNameText="flex-1"
                        propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                        name="city"
                    />
                </div>
                <InputText
                    placeholder="Street Address"
                    label="Street Address"
                    rounded
                    classNameBox="flex-1"
                    name="street_address"
                    onChange={handleChange}
                    value={values.street_address}
                    error={true}
                />
                <div className="flex flex-col gap-5 xs:flex-row">
                    <InputText
                        placeholder={'Suite or Apartment'}
                        label={'Suite or Apartment'}
                        rounded
                        name="suite_or_apartment"
                        onChange={handleChange}
                        value={values.suite_or_apartment}
                        error={errors.suite_or_apartment}
                    />
                    <InputText
                        placeholder="Zip Code"
                        label="Zip Code"
                        rounded
                        name="zip_code"
                        onChange={handleChange}
                        value={values.zip_code}
                        error={errors.zip_code}
                    />
                </div>
                <InputText
                    placeholder="Invite Message"
                    rounded
                    classNameBox="flex-1"
                    name="invite_mess"
                    onChange={handleChange}
                    value={values.invite_mess}
                    error={true}
                />

                <Button type="submit" className={'max-w-[260px]'} color="primary">
                    Send Invite
                </Button>
            </form>
        </div>
    );
}

export default AddCompany;
