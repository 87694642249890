import Step from 'components/Step/Step';
import { useEffect, useState } from 'react';
import { ResetCreateNewPw, ResetPwEmailConfirm, ResetVerifyPw } from './components';

function ResetPassword() {
    const [step, setStep] = useState(1);

    useEffect(() => {
        return () => {
            setStep(1);
        };
    }, []);

    return (
        <div className="flex justify-center items-center py-[120px] px-[10px] min-h-[100vh]  bg-blue-eae">
            <div className="w-full max-w-[400px]">
                {step === 1 && <ResetPwEmailConfirm setStep={setStep} />}
                {step === 2 && <ResetVerifyPw setStep={setStep} />}
                {step === 3 && <ResetCreateNewPw setStep={setStep} />}

                <Step maxStep={3} activeStep={step} />
            </div>
        </div>
    );
}

export default ResetPassword;
