export const POSTS = [
    {
        id: 1,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 2,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 3,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 4,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },

    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
];


export const POSTS_PUBLIC = [
    {
        id: 1,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 2,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 3,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 4,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },

    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
    {
        id: 234,
        post: 'Post about topic',
        category: 'Category',
        image: `https://picsum.photos/400/300?random=${Math.floor(Math.random() * 101)}`,
    },
];
