import { Button } from 'components/Button';
import { InputPassword, InputText } from 'components/Input';
import Step from 'components/Step';
import { useFormik } from 'formik';
import { useNavigateSearch } from 'hooks';
import { useLocation, useSearchParams } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { createPasswordSchema } from '../registerSchema';

function CreatePassword({ agencyView = false }) {
    const { pathname } = useLocation();
    const navigateSearch = useNavigateSearch();

    const is_primary_user = pathname.includes(routesPath.primary_user);

    const { values, handleChange, handleSubmit, errors } = useFormik({
        initialValues: {
            user_name: '',
            password: '',
            confirm_password: '',
        },
        onSubmit: (values) => {
            console.log(values);

            navigateSearch(pathname, { step: is_primary_user ? '3' : '4' });
        },
        validationSchema: createPasswordSchema,
    });

    const [searchParams] = useSearchParams();
    const step = searchParams.get('step') || '1';

    return (
        <>
            <div className="text-center font-bold text-[24px] leading-[29px] text-dark-nav mb-[30px]">
                Create Password
            </div>
            <div className="text-center  text-[14px] leading-[21px] text-dark-nav mb-[20px]">
                {is_primary_user
                    ? 'Welcome, John! Please create a password.'
                    : 'Welcome, John! You have been assigned an admin user role. Please complete registration.'}
            </div>

            <form onSubmit={handleSubmit}>
                {is_primary_user && (
                    <div className="mb-[20px]">
                        <InputText
                            placeholder="Username"
                            rounded
                            name="user_name"
                            onChange={handleChange}
                            value={values.user_name}
                            errors={errors.user_name}
                        />
                    </div>
                )}
                <div className="mb-[20px]">
                    <InputPassword
                        placeholder="Password"
                        rounded
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                        errors={errors.password}
                    />
                </div>
                <div className="mb-[20px]">
                    <InputPassword
                        placeholder="Confirm Password"
                        rounded
                        name="confirm_password"
                        onChange={handleChange}
                        value={values.confirm_password}
                        errors={errors.confirm_password}
                    />
                </div>
                {agencyView && (
                    <div className="w-full mb-5">
                        <Step maxStep={4} activeStep={step} />
                    </div>
                )}
                <Button
                    className=" flex  justify-center mx-auto w-full max-w-[260px] py-[15px] px-5 shadow-outer"
                    type="submit"
                >
                    Register
                </Button>
            </form>
        </>
    );
}

export default CreatePassword;
