import { InputText } from 'components/Input';
import { useField } from 'formik';

function FormInputText({ ...props }) {
    const [field, meta] = useField(props);

    return <InputText rounded error={meta.error} helper={meta.error} {...field} {...props} />;
}

export default FormInputText;
