import { SVG } from 'assets/imagePath';
import Chip from 'components/Chip/Chip';
import Dropdown from 'components/Dropdown';
import ActionsButton from 'components/GridTable/ActionsButton';
import Image from 'components/Image';
import { useNavigate } from 'react-router-dom';

export const audiencesColumn = [
    {
        field: 'id',
        headerStyle: { cursor: 'pointer', minWidth: '65px' },
        headerName: 'ID',
    },
    {
        field: 'name',
        colSpan: 1.6,
        rowStyle: {},
        headerStyle: { cursor: 'pointer' },
        headerName: 'Name',
    },
    {
        field: 'company',
        headerName: 'Company',
        hideCol: ['Ag', 'Org'],
    },
    {
        field: 'agency',
        headerName: 'Agency',
        hideCol: ['Org'],
    },
    {
        field: 'audience',
        headerStyle: { cursor: 'pointer' },

        headerName: 'Audience',
    },

    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row, handle) => {
            return (
                <ActionsButton
                    onChange={(actionVal) => {
                        handle(row, actionVal);
                    }}
                />
            );
        },
    },
];

export const fakeRowAudiences = [
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
    {
        id: 324,
        name: 'Audience',
        company: 'Company 1',
        agency: 'Agency 1',
        audience: '1.3 m',
    },
];

export const creativeColumn = [
    {
        field: 'id',
        headerStyle: { cursor: 'pointer' },
        headerName: 'ID',
    },
    {
        field: 'name',
        headerStyle: { cursor: 'pointer' },
        headerName: 'Name',
    },
    {
        field: 'company',

        headerName: 'Company',
        hideCol: ['Ag', 'Org'],
    },
    {
        field: 'agency',
        headerName: 'Agency',
        hideCol: ['Org'],
    },
    {
        field: 'review',

        headerName: 'Review',
        renderCell: (row) => {
            return <Chip color="success">{row?.review}</Chip>;
        },
    },
    {
        field: 'type',
        headerStyle: { cursor: 'pointer', minWidth: '80px' },

        headerName: 'Type',
    },

    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row, handle) => {
            return (
                <ActionsButton
                    onChange={(actionVal) => {
                        handle(row, actionVal);
                    }}
                />
            );
        },
    },
];

export const usersColumn = [
    {
        field: 'id',
        headerName: 'Id',
    },
    {
        field: 'first_name',
        headerName: 'First Name',
    },

    {
        field: 'last_name',
        headerName: 'Last Name',
    },
    {
        field: 'status',
        headerName: 'Status',
        renderCell: (row) => {
            return (
                <Chip className={''} color="success">
                    {row?.status}
                </Chip>
            );
        },
    },

    {
        field: 'company',
        headerName: 'Company',
    },
    {
        field: 'agency',
        headerName: 'Agency',
    },
    {
        field: 'email',
        headerName: 'Email',
    },
    {
        field: 'role',
        headerName: 'Role',
        renderCell: (row) => {
            return (
                <Dropdown
                    options={
                        <div className="p-2.5 flex flex-col gap-[5px]">
                            <div>Viewer</div>
                            <div>Viewer</div>
                        </div>
                    }
                >
                    <div className="flex items-center justify-between">
                        <p className="flex-1">{row?.role}</p>
                        <Image src={SVG.chevron_down} />
                    </div>
                </Dropdown>
            );
        },
    },
    {
        field: 'actions',
        headerName: 'Actions',
        renderCell: (row, handle) => {
            return (
                <ActionsButton
                    onChange={(actionVal) => {
                        // console.log(actionVal);
                        handle(row, actionVal);
                    }}
                />
            );
        },
    },
];

export const usersRow = [
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
    {
        id: 324,
        first_name: 'John',
        last_name: 'Wood',
        status: 'Active',
        company: 'Company 1',
        agency: 'Agency 1',
        email: 'johnwood@gmail.com',
        role: 'Viewer',
    },
];
export const agencyColumn = [
    {
        field: 'id',
        headerName: 'Id',
    },
    {
        field: 'name',
        headerName: 'Name',
    },

    {
        field: 'status',
        headerName: 'Status',
        renderCell: (row) => {
            return (
                <Chip className={''} color="success">
                    {row?.status}
                </Chip>
            );
        },
    },

    {
        field: 'primary_contact',
        headerName: 'Primary Contact',
    },

    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row, handle) => {
            return (
                <ActionsButton
                    onChange={(value) => {
                        handle(row, value);
                    }}
                />
            );
        },
    },
];
export const agencyRow = [
    {
        id: 324,
        name: 'Top Agency',
        status: 'Active',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Top Agency',
        status: 'Active',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Top Agency',
        status: 'Active',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Top Agency',
        status: 'Active',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Top Agency',
        status: 'Active',
        primary_contact: 'John Wood',
    },
];

export const companiesColumn = [
    {
        field: 'id',
        headerName: 'Id',
    },
    {
        field: 'name',
        headerName: 'Name',
    },

    {
        field: 'status',
        headerName: 'Status',
        renderCell: (row) => {
            return (
                <Chip className={''} color="success">
                    {row?.status}
                </Chip>
            );
        },
    },

    {
        field: 'agency',
        headerName: 'Agency',
    },
    {
        field: 'primary_contact',
        headerName: 'Primary Contact',
    },

    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row, handle) => {
            return (
                <ActionsButton
                    onChange={(val) => {
                        handle(row, val);
                    }}
                />
            );
        },
    },
];

export const companiesRow = [
    {
        id: 324,
        name: 'Audi',
        status: 'Active',
        agency: 'Top Agency',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Audi',
        status: 'Active',
        agency: 'Top Agency',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Audi',
        status: 'Active',
        agency: 'Top Agency',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Audi',
        status: 'Active',
        agency: 'Top Agency',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Audi',
        status: 'Active',
        agency: 'Top Agency',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Audi',
        status: 'Active',
        agency: 'Top Agency',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Audi',
        status: 'Active',
        agency: 'Top Agency',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Audi',
        status: 'Active',
        agency: 'Top Agency',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Audi',
        status: 'Active',
        agency: 'Top Agency',
        primary_contact: 'John Wood',
    },
    {
        id: 324,
        name: 'Audi',
        status: 'Active',
        agency: 'Top Agency',
        primary_contact: 'John Wood',
    },
];

export const rowCreative = [
    {
        id: 324,
        name: 'Creative 1',
        company: 'Company 1',
        agency: 'Agency 1',
        review: 'Approve',

        type: 'Display',
        sizes: '300x250, 728x90, 320x50',
    },
    {
        id: 324,
        name: 'Creative 1',
        company: 'Company 1',
        agency: 'Agency 1',
        review: 'Approve',

        type: 'Display',
        sizes: '300x250, 728x90, 320x50',
    },
    {
        id: 324,
        name: 'Creative 1',
        company: 'Company 1',
        agency: 'Agency 1',
        review: 'Approve',

        type: 'Display',
        sizes: '300x250, 728x90, 320x50',
    },
    {
        id: 324,
        name: 'Creative 1',
        company: 'Company 1',
        agency: 'Agency 1',
        review: 'Approve',

        type: 'Display',
        sizes: '300x250, 728x90, 320x50',
    },
    {
        id: 324,
        name: 'Creative 1',
        company: 'Company 1',
        agency: 'Agency 1',
        review: 'Approve',

        type: 'Display',
        sizes: '300x250, 728x90, 320x50',
    },

    {
        id: 324,
        name: 'Creative 1',
        company: 'Company 1',
        agency: 'Agency 1',
        review: 'Approve',

        type: 'Display',
        sizes: '300x250, 728x90, 320x50',
    },
    {
        id: 324,
        name: 'Creative 1',
        company: 'Company 1',
        agency: 'Agency 1',
        review: 'Approve',

        type: 'Display',
        sizes: '300x250, 728x90, 320x50',
    },
    {
        id: 324,
        name: 'Creative 1',
        company: 'Company 1',
        agency: 'Agency 1',
        review: 'Approve',

        type: 'Display',
        sizes: '300x250, 728x90, 320x50',
    },
    {
        id: 324,
        name: 'Creative 1',
        company: 'Company 1',
        agency: 'Agency 1',
        review: 'Approve',

        type: 'Display',
        sizes: '300x250, 728x90, 320x50',
    },
];

export const fakeColumnImpression = [
    {
        field: 'campaign',
        headerName: 'Campaign',
    },

    {
        field: 'impressions',
        headerName: 'Impressions',
        classNameCell: 'text-right',
    },
];
export const fakeColumnCtr = [
    {
        field: 'campaign',
        headerName: 'Campaign',
    },

    {
        field: 'ctr',
        headerName: 'CTR',
        classNameCell: 'text-right',
    },
];

export const fakeColumnCPM = [
    {
        field: 'campaign',
        headerName: 'Campaign',
    },

    {
        field: 'cpm',
        headerName: 'CPM',
        classNameCell: 'text-right',
    },
    {
        field: 'total_cost',
        headerName: 'Total cost',
        classNameCell: 'text-right',
    },
];

export const fakeRowLegend = [
    {
        campaign: 'Campaign 1',
        impressions: '9.6M',
        ctr: '10%',
        cpm: '$26.5',
        total_cost: '$15,000',
    },

    {
        campaign: 'Campaign 2',
        impressions: '9.6M',
        ctr: '8%',
        cpm: '$22.5',
        total_cost: '$10,000',
    },
    {
        campaign: 'Campaign 3',
        impressions: '9.6M',
        ctr: '6%',
        cpm: '$21.5',
        total_cost: '$9,000',
    },
    {
        campaign: 'Campaign 4',
        impressions: '9.6M',
        ctr: '3%',
        cpm: '$20.5',
        total_cost: '$7,000',
    },
    {
        campaign: 'Campaign 5',
        impressions: '9.6M',
        ctr: '2%',
        cpm: '$19.5',
        total_cost: '$3,000',
    },
];
