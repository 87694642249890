import { Collapse } from 'components/Collapse';
import Tree from './Tree';

function TreeNode({ node, collapseProps, ...rest }) {
    const hasCollapse = node?.children ? true : false;

    return (
        <>
            {hasCollapse ? (
                <Collapse title={node?.title} {...collapseProps}>
                    <Tree treeData={node.children} collapseProps={collapseProps} {...rest} />
                </Collapse>
            ) : (
                <div>{node?.title}</div>
            )}
        </>
    );
}

export default TreeNode;
