import clsx from 'clsx';
import Text from 'components/Text/Text';
import React from 'react';

function CardRadio({ img, title, content, className }) {
    return (
        <div
            className={clsx(
                'py-2.5 flex border-transparent border flex-row gap-5 justify-center items-center',
                className,
            )}
        >
            <img src={img} className="flex-1 object-contain max-w-[160px] w-full" alt="radio-type" />
            <div className="flex flex-col justify-center flex-1">
                <Text weight="bold">{title}</Text>
                <Text size="12">{content}</Text>
            </div>
        </div>
    );
}

export default CardRadio;
