import { SVG } from 'assets/imagePath';
import GridTable from 'components/GridTable';
import TableHead from 'components/GridTable/TableHead';
import { HeaderNavigate } from 'components/HeaderNavigate';
import Image from 'components/Image';
import { blockchainPaymentRow, cardPaymentCol } from './data';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { Modal } from 'components/Modal';
import RefundForm from './RefundForm';

function CardPayments() {
    //options Actions
    const [refundModal, setRefundModal] = useState({ isOpen: false, row: '' });
    const navigate = useNavigate();
    const handleActions = (actionsValue, row) => {
        switch (actionsValue?.value) {
            case 'refund':
                setRefundModal({
                    isOpen: true,
                    row: row,
                });
                break;
            case 'view':
                navigate(routesPath.earning_detail(row?.id));
                break;

            default:
                break;
        }
    };
    return (
        <>
            <div className="p-2.5 md:p-[30px] w-full">
                <HeaderNavigate detailInfo={{ detailName: 'Card Payments' }} />

                <div className="mt-[30px] flex flex-col ">
                    <TableHead title={'Card Payments'} />
                    <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                        <GridTable
                            className={'w-full table-fixed'}
                            handle={handleActions}
                            column={cardPaymentCol}
                            row={blockchainPaymentRow}
                        />

                        <div className="bg-white-light  flex  justify-end p-[15px]  gap-[5px]">
                            <p className="text-grey-light font-normal text-sm">2 of 2 records</p>
                            <Image src={SVG.chevron_left_light} />
                            <Image src={SVG.chevron_right_light} />
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                bodyClass={'max-w-[362px]'}
                title="Confirm Action"
                isOpen={refundModal.isOpen}
                onClose={() => setRefundModal({ isOpen: false, row: '' })}
            >
                <RefundForm />
            </Modal>
        </>
    );
}

export default CardPayments;
