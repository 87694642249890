import Box from 'components/Box/Box';
import SearchInput from 'components/Input/SearchInput';
import Text from 'components/Text/Text';
import { useAdCanva } from 'context/AdCanvaContext';
import CanvaTemplate from './CanvaTemplate';
import { IconButton } from 'components/Button';
import { SVG } from 'assets/imagePath';
import CreateNewRequest from './components/CreateNewRequest';
import { useFormikContext } from 'formik';

const fakeRequest = {
    sizes: [
        {
            label: 'Medium Rectangle (300x250)',
            value: 'medium_rec',
            package: 1,
        },
        {
            label: 'Leaderboard (728x90)',
            value: 'leader_board',
            package: 1,
        },
    ],
    logo: {
        desc: 'Logo for roofing.png',
        img: '',
    },
    background: {
        desc: 'Roof with contractors working on it.jpg',
        img: '',
    },
    title: {
        text: 'You are one click away from success!',
    },
    subTitle: {
        text: 'For every big goal you have in life, the best way to tackle it is to create smaller goals that you can get started on right away',
    },
    callAction: {
        text: 'Learn more',
    },
    base: '#0E65F1',
    accent: '#e39115',
    name: '',
};

function SelectTemplate({ disableNextStep, title, subTitle, onSetNewRequest }) {
    const { step, setStep, template, setTemplate } = useAdCanva();

    const { setValues } = useFormikContext();

    const onSelect = (id) => {
        if (!disableNextStep) {
            setStep(step + 1);

            if (id) {
                const fakeItem = {
                    id: id,
                    status: id === 2 ? 'in_progress' : id === 3 ? 'review' : 'approve',
                };
                setTemplate(fakeItem);
            } else {
            }
        }
    };

    return (
        <div className="flex flex-col gap-5">
            <div className="flex flex-col sm:flex-row gap-[30px] items-start">
                <div className="flex items-start flex-1 flex-col gap-[5px]">
                    <Text size="18" weight="bold">
                        {title || 'Title'}
                    </Text>
                    <Text className="text-left">{subTitle || 'Placeholder'}</Text>
                </div>
                <div className="flex flex-row flex-1 gap-5">
                    <IconButton
                        variant="outlined"
                        className="!w-[50px] h-[50px]  !shadow-none !p-[17.5px]"
                        source={SVG.filter}
                        onClick={() => {}}
                    />
                    <SearchInput className={'flex-1 w-full'} />
                </div>
            </div>

            <Box className="grid grid-flow-row grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2.5 p-5 bg-white-light">
                {Array.from({ length: 6 }).map((_, idx) =>
                    idx === 0 ? (
                        <CreateNewRequest
                            key={idx}
                            onClick={() => {
                                onSelect();
                                onSetNewRequest?.(true);
                            }}
                        />
                    ) : (
                        <CanvaTemplate
                            key={idx}
                            id={idx + 1}
                            onClick={() => {
                                onSelect(idx + 1);
                                setValues(fakeRequest);
                                onSetNewRequest?.(false);
                            }}
                        />
                    ),
                )}
            </Box>
        </div>
    );
}

export default SelectTemplate;
