import Text from 'components/Text';
import React from 'react';

function BarLineLegend({ legendYRight, legendYLeft, legend }) {
    return (
        <div className="grid grid-cols-2 xxs:grid-cols-3">
            <div className="flex items-end ">
                <Text size="10">{legendYLeft}</Text>
            </div>
            <div className="hidden xxs:block legend">{legend}</div>
            <div className="flex justify-end items-end">
                <Text size="10" className="text-right">
                    {legendYRight}
                </Text>
            </div>
        </div>
    );
}

export default BarLineLegend;
