import { DetailBox, DetailBox2 } from 'components/DetailBox';
import React from 'react';

const column = [
    {
        field: 'date',
        headerName: 'Date',
    },
    {
        field: 'time',
        headerName: 'Time',
    },
    {
        field: 'service',
        headerName: 'Service',
    },
    {
        field: 'amount',
        headerName: 'Amount',
    },
    {
        field: 'currency',
        headerName: 'Currency',
    },
    {
        field: 'billing',
        headerName: 'Billing',
    },
    {
        field: 'method',
        headerName: 'Method',
    },
    {
        field: 'form',
        headerName: 'Form',
    },
    {
        field: 'type',
        headerName: 'Type',
    },
    {
        field: 'status',
        headerName: 'Status',
    },
];
const row = {
    date: '',
    time: '',
    service: 'Agency Subscription',
    amount: '$149.00',
    currency: 'USD',
    billing: 'Monthly Recurring Payment',
    method: 'New',
    form: 'FIAT',
    type: 'Card',
    status: 'Completed',
};

function StepCompleted() {
    return <DetailBox2 title="Details" row={row} column={column} />;
}

export default StepCompleted;
