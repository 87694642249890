import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Image from 'components/Image';
import Text from 'components/Text';
import React from 'react';
import Card from './Card';

function CardNumber({ title, value, icon, actionText, onAction, className }) {
    return (
        <Card className={clsx('p-5 min-h-[130px]', className)}>
            <div className="flex flex-1 gap-2.5 h-full">
                <div className="flex flex-col justify-between flex-1  h-full">
                    <div className="flex flex-col gap-2.5">
                        <Text size="12" component="span">
                            {title}
                        </Text>
                        <Text size="20" weight="bold">
                            {value}
                        </Text>
                    </div>
                    <div className="flex cursor-pointer justify-start items-center" onClick={onAction}>
                        <Text className="!text-[10px]">{actionText}</Text>
                        <Image src={SVG.chevron_right} className="w-3 h-3" />
                    </div>
                </div>

                <div className="w-12 h-12">
                    <Image className={'h-full w-full object-contain'} src={icon} alt="icon-card" />
                </div>
            </div>
        </Card>
    );
}

export default CardNumber;
