import { IMAGE } from 'assets/imagePath';
import { Svg } from 'components/Svg';
import { useEffect, useState } from 'react';
import { ImSpinner10 } from 'react-icons/im';

function UploadImage({
    defaultImage = '',
    children = 'Choose Image',
    className,
    setValueFormik,
    renderRemoveButton,
    readOnly,
    keepBlob, // use  when u want keep blob image to show in the other component

    loading,
    ...props
}) {
    const [image, setImage] = useState();

    const handleUpload = (e) => {
        const file = e.target.files[0];
        file.width = e.target?.offsetWidth;
        file.height = e.target?.offsetHeight;

        //Preview Image

        file.preview = URL.createObjectURL(file);
        setImage(file.preview);

        setValueFormik?.(file);
    };

    const handleRemoveImage = () => {
        URL.revokeObjectURL(image?.preview);

        if (props.value) {
            URL.revokeObjectURL(props?.value?.preview);
        }

        setImage(undefined);
        setValueFormik && setValueFormik(null);
    };

    const handleGetNaturalImage = ({ target: img }) => {
        // Get natural WIdth and hieght of image after image had loaded to set value formik
        if (props.value && typeof props.value === 'object' && props.value) {
            props.value.width = img.naturalWidth;
            props.value.height = img.naturalHeight;
            setValueFormik(props.value);
        }
    };

    useEffect(() => {
        return () => {
            //Remove Blob when component unmount to clear memory
            if (!keepBlob) {
                handleRemoveImage();
            }
        };
    }, []);

    return (
        <div className="relative w-full h-full">
            {loading ? (
                <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full ">
                    <ImSpinner10 className="text-2xl animate-spin" />
                </div>
            ) : (
                <>
                    {!image?.preview && !props?.value?.preview ? (
                        <div className="flex flex-col items-center justify-center h-full">
                            <div className={(className, readOnly ? 'pointer-events-none' : '')}>
                                <input
                                    hidden
                                    onChange={(e) => {
                                        handleUpload(e);
                                    }}
                                    accept="image/*"
                                    id={props.name}
                                    multiple
                                    type="file"
                                />
                                {loading && (
                                    <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full ">
                                        <ImSpinner10 className="text-2xl animate-spin" />
                                    </div>
                                )}
                                {children}
                            </div>
                        </div>
                    ) : (
                        <div className="relative w-full h-full">
                            <img
                                className="object-contain w-full h-full"
                                src={props.value?.preview}
                                alt="Avatar"
                                onLoad={handleGetNaturalImage}
                                onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGE.no_image))}
                            />
                            {renderRemoveButton ? (
                                renderRemoveButton({ removeImage: handleRemoveImage })
                            ) : (
                                <Svg className="absolute bottom-0 right-0" name="trash" onClick={handleRemoveImage} />
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default UploadImage;
