import { AnalyticReview } from 'components/Analytics';
import { CardContent } from 'components/Card';
import LineChart from 'components/Charts/LineChart/LineChart';
import { WithdrawModule } from 'components/GlobalModules';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { Modal } from 'components/Modal';
import Text from 'components/Text';
import { useState } from 'react';

function Balance() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className="p-2.5 md:p-[30px] w-full">
                <div className="flex flex-col gap-[30px]">
                    <HeaderNavigate detailInfo={{ detailName: 'Earning' }} />
                    <div className="flex flex-row">
                        <CardContent
                            className={'w-[170px]'}
                            title={'Balance'}
                            content={'$12,000'}
                            actionText="Withdraw"
                            onAction={() => setIsOpen(true)}
                        />
                    </div>
                    <div className="flex flex-row">
                        <AnalyticReview
                            className={'w-full'}
                            title={'Balance'}
                            classNameBox={'pb-8'}
                            CustomReview={
                                <div className="flex flex-col w-[99.9%] h-full max-h-[170px]  gap-[5px] mb-5 xs:mb-0">
                                    <div className="flex justify-between">
                                        <Text size="10">Earning, $</Text>
                                        <div className="flex items-center mr-5">
                                            <div className="h-[1.5px] w-[9px] bg-primary mr-[6.5px] rounded-[3px]" />
                                            <Text size="10">Earning</Text>
                                        </div>
                                    </div>

                                    <LineChart />
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
            <Modal
                bodyClass={'max-w-[362px] w-full pb-[30px]'}
                title="Withdraw"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <WithdrawModule />
            </Modal>
        </>
    );
}

export default Balance;
