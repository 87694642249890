import { IMAGE, SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Image from 'components/Image';
import Text from 'components/Text';
import { PARTNERS } from 'constants/partner';
import { useEffect, useState, useTransition } from 'react';
import { useNavigate } from 'react-router-dom';

const Partnerships = () => {
    const sliceArray = (arrayFull, limit, lastIndex = 0) => {
        const lengthFull = arrayFull.length;

        if (lastIndex + limit > lengthFull) {
            lastIndex = 0;
        }

        const newArr = arrayFull.slice(lastIndex ? lastIndex + 1 : 0, lastIndex + limit);

        return newArr;
    };
    const [carouselList, setCarouseList] = useState(sliceArray(PARTNERS, 7));
    const [isPending, startTransition] = useTransition();

    useEffect(() => {
        const intervalId = setInterval(() => {
            let lastIndex = PARTNERS.findIndex((item) => item.id === carouselList[carouselList.length - 1]?.id);

            const arr = sliceArray(PARTNERS, 7, lastIndex);

            startTransition(() => {
                setCarouseList(arr);
            });
        }, 2000);

        return () => {
            clearInterval(intervalId);
        };
    }, [carouselList]);
    const navigate = useNavigate();
    return (
        <section className="mx-auto container pb-[45px] pt-[30px]  xs:py-[60px]">
            <Text className="text-center text-grey-light tracking-[0.2rem] text-sm font-normal  uppercase mb-[15px] xs:mb-[30px]">
                Data providers
            </Text>

            {/* //Desktop Image */}

            <div className="flex gap-[50px] mx-[-100px] min-h-[50px] justify-between text-g mb-[90px] overflow-hidden">
                {carouselList.map((item) => (
                    <Image
                        className={clsx(
                            'transition-all min-w-[150px] h-[50px] duration-500  ease-in-out object-contain',
                            isPending ? 'opacity-0' : 'opacity-100',
                        )}
                        src={item.img}
                        key={item.id}
                    />
                ))}
            </div>
            <div className="px-[15px] mb-[90px] mx-[12px] relative md:mx-auto flex flex-col justify-center items-center max-w-[930px] xl:h-[540px] xs:h-[270px] md:h-[320px] h-[157px] bg-dark-nav rounded-[20px] overflow-hidden">
                <Image src={IMAGE.bg_home_partnership} className="absolute w-[100%] h-[100%] object-cover" />
                <div className="overlay absolute w-[100%] h-[100%] opacity-[0.9] bg-dark-nav"></div>
                <div className="relative">
                    <Image src={SVG.youtube} className="w-[32px] md:w-[64px] h-auto mx-auto mb-[10px] md:mb-[21px]" />
                    <div className="text-center mx-auto  text-white font-bold xs:text-base md:text-2xl max-w-[532px] font-sen">
                        Are you prepared to discover what sets us apart and why we're the best choice?
                    </div>
                    <div className=" p-[10px] hidden xs:flex items-center justify-center w-[116px] h-[47px] border-2 rounded-[10px] border-blue-eae mx-auto text-white mt-[35px] text-[14px] leading-[17px]">
                        Watch now
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partnerships;
