import PhoneInput from 'react-phone-input-2';
import './styles/Input.css';
function PhoneNumberInput({ ...props }) {
    return (
        <PhoneInput
            buttonClass="!bg-transparent !hover:bg-transparent !border-none"
            inputClass="!w-full !h-[50px] bg-white-light shadow-default !rounded-[10px] !border-none"
            containerClass="w-full h-full nucleus"
            country={'us'}
        />
    );
}

export default PhoneNumberInput;
