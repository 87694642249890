import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Image from 'components/Image/Image';
import useDebounce from 'hooks/useDebounce';
import React, { forwardRef } from 'react';

function SearchInput(
    {
        loading,
        onChange,
        onFocus,
        onBlur,
        placeholder = 'Search',
        className,
        searchValue,
        showLoading = true,
        disabled,
    },
    ref,
) {
    const inputId = React.useId();
    const [value, setValue] = React.useState('');

    const debounceVal = useDebounce(value);

    React.useEffect(() => {
        if (debounceVal) {
            //Call API search
            // setIsLoading(true);
            // setTimeout(() => {
            //     setIsLoading(false);
            // }, 1000);
        }
        //onChange value from searchAPI
        if (searchValue !== debounceVal) {
            onChange && onChange(debounceVal);
        }
    }, [debounceVal]);

    React.useEffect(() => {
        //SetValue lại nếu value của component cha có thay dổi
        setValue(searchValue || debounceVal);
    }, [searchValue]);

    return (
        <label
            ref={ref}
            className={clsx(
                'flex flex-row gap-[5px] h-[50px] bg-white-light px-[15px] items-center rounded-[10px]',
                className,
            )}
            htmlFor={inputId}
        >
            <Image className="max-w-[20px]" src={SVG.search} alt="search" />
            <input
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                }}
                className="flex-1 overflow-hidden text-sm bg-transparent caret-primary outline-0 focus:outline-0 text-ellipsis"
                id={inputId}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
            />
        </label>
    );
}

export default forwardRef(SearchInput);
