import { NotificationSubCo } from 'components/AccountConfig/Notifications';
import { PaymentsSubCo } from 'components/AccountConfig/Payments';
import ProfileSingleUser from 'components/AccountConfig/Profile/ProfileSingleUser';
import { SettingsSingleAccount } from 'components/AccountConfig/Settings';
import StatusOrgazination from 'components/AccountConfig/Status/StatusOrgazination';
import { SupportSubCo } from 'components/AccountConfig/Support';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const accountTabs = [
    { label: 'Profile', value: 'profile' },
    { label: 'Settings', value: 'settings' },
    {
        label: 'Notifications',
        value: 'notifications',
    },

    { label: 'Payments', value: 'payments' },
    { label: 'Education', value: 'education' },
    { label: 'Support', value: 'support' },
    { label: 'Stats', value: 'stats' },
];

function AccountConfigSingleUser() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const defaultConfig = urlParams.get('config');

    const defaultTabs = accountTabs.find((item) => item.value === defaultConfig);
    const [searchParams, setSearchParams] = useSearchParams({
        config: defaultTabs?.value,
    });

    const locationState = useLocation()?.state;

    // const defaultTabs = accountTabs.find((item) => item.value === !!locationState?.config);

    const [tabsVal, setTabsVal] = useState(defaultTabs || accountTabs[0]);

    const renderContent = () => {
        switch (tabsVal.value) {
            case 'profile':
                return <ProfileSingleUser />;
            case 'settings':
                return <SettingsSingleAccount />;
            case 'notifications':
                return <NotificationSubCo />;
            case 'payments':
                return <PaymentsSubCo />;
            case 'support':
                return <SupportSubCo />;
            case 'stats':
                return <StatusOrgazination />;

            default:
                break;
        }
    };

    useEffect(() => {
        if (!defaultTabs) {
            setSearchParams({ config: tabsVal.value });
        }
    }, []);

    return (
        <div className="p-2.5 md:p-[30px] flex flex-col gap-[30px]">
            <Text className="py-[10.5px]" size="24" weight="bold">
                {'User account'}
            </Text>
            <Tabs
                onChange={(tab) => {
                    setTabsVal(tab);
                    setSearchParams({
                        config: tab.value,
                    });
                }}
                tabList={accountTabs}
                value={tabsVal.value}
            />

            {renderContent()}
        </div>
    );
}

export default AccountConfigSingleUser;
