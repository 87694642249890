import { IMAGE, SVG } from 'assets/imagePath';
import Box from 'components/Box';
import { BoxLabelOutline } from 'components/BoxLabel';
import { Button } from 'components/Button';
import Dropdown, { DropdownMenu } from 'components/Dropdown';
import Image from 'components/Image';
import Text from 'components/Text';
import { SubTitle } from 'components/Title';
import { usePaymentModule } from 'context/ui/PaymentModuleContext';
import React, { useRef, useState } from 'react';

const menu = [
    {
        label: 'View Details',
        value: 'view_detail',
    },
    {
        label: 'View Bitcoin',
        value: 'view_bitcoin',
    },
];
function BitcoinPayment() {
    const codeBitcoinRef = useRef();
    const [dropDownValue, setDropdownVal] = useState(menu[0]);

    const { setStep } = usePaymentModule();
    const copyContent = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            console.log('Content copied to clipboard');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const handleOnPay = () => {
        setStep('completed');
    };
    return (
        <div className="flex flex-col gap-5 credit__payment">
            <SubTitle className={'mt-7'}>Web3 Payment</SubTitle>

            <BoxLabelOutline label="Bitcoin Network Selected">
                <Box
                    shadow="default"
                    className="flex  flex-col items-center gap-5 max-w-xs w-full bg-white-light p-[30px]"
                >
                    <div className="flex flex-col items-center justify-center">
                        <Text size="14">ECOM Industries</Text>
                        <Text className="mt-2.5" size="24" weight="bold">
                            0.21835896
                        </Text>

                        <Dropdown
                            options={({ close }) => (
                                <DropdownMenu
                                    menu={menu}
                                    onChange={(menuValue) => {
                                        setDropdownVal(menuValue);
                                        close();
                                    }}
                                />
                            )}
                        >
                            <div className="flex flex-row items-center gap-2.5 h-[50px] px-[15px]">
                                <Text>{dropDownValue.label}</Text>
                                <Image src={SVG.chevron_down} />
                            </div>
                        </Dropdown>
                    </div>

                    <Image src={IMAGE.qr_bitcoin} className="max-w-[260px] w-full h-auto object-contain" />

                    <div className="flex flex-col items-center justify-center w-full">
                        <Text size="14">Bitcoin Address</Text>

                        <div className="flex gap-2.5 w-full px-2">
                            <Text ref={codeBitcoinRef} className="flex-1 max-w-full" ellipsis size="14">
                                bc1phguwrgmdg2n7sueyhpjbc1phguwrgmdg2n7sueyhpj
                            </Text>
                            <Text
                                onClick={() => copyContent(codeBitcoinRef.current?.innerText)}
                                className="cursor-pointer"
                                ellipsis
                                size="14"
                                weight="bold"
                            >
                                Copy
                            </Text>
                        </div>
                    </div>

                    <Button className={'w-full'} onClick={handleOnPay}>
                        Pay
                    </Button>
                </Box>
            </BoxLabelOutline>
        </div>
    );
}

export default BitcoinPayment;
