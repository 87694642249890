import { AnalyticReview } from 'components/Analytics';
import EarningsTable from './EarningsTable';
import Text from 'components/Text/Text';
import { LineChart } from 'components/Charts';

function EarningAgency() {
    return (
        <div className="company">
            <div className="flex flex-row">
                <AnalyticReview
                    className={'w-full'}
                    title={'Balance'}
                    classNameBox={'pb-8'}
                    CustomReview={
                        <div className="flex flex-col w-[99.9%] h-full max-h-[170px]  gap-[5px] mb-5 xs:mb-0">
                            <div className="flex justify-between">
                                <Text size="10">Balance, $</Text>
                                <div className="flex items-center mr-5">
                                    <div className="h-[1.5px] w-[9px] bg-primary mr-[6.5px] rounded-[3px]" />
                                    <Text size="10">Balance</Text>
                                </div>
                            </div>

                            <LineChart />
                        </div>
                    }
                />
            </div>
            <div className="mt-[30px]">
                <EarningsTable />
            </div>
        </div>
    );
}

export default EarningAgency;
