import { SVG } from 'assets/imagePath';
import { CardContent } from 'components/Card';
import { SubTitle } from 'components/Title';

const switchStatusReview = (reviewStatus) => {
    let review = {
        icon: '',
        content: '',
    };

    switch (reviewStatus) {
        case 'pending':
            review.icon = SVG.pending_circle;
            review.content = 'Pending';
            break;
        case 'reject':
            review.icon = SVG.cancel_circle;
            review.content = 'Reject';
            break;

        case 'approve':
            review.icon = SVG.checked_circle;
            review.content = 'Approve';
            break;

        default:
            break;
    }

    return review;
};

function ReviewBlock({ reviewStatus = 'pending', className }) {
    const { icon, content } = switchStatusReview(reviewStatus);
    return (
        <div className="flex w-full flex-col gap-5">
            <SubTitle>Review</SubTitle>

            <div className="grid grid-cols-1 xxs:grid-cols-2 grid-flow-row gap-5 lg:grid-cols-4">
                <CardContent
                    className={'flex-1 min-w-max'}
                    title={'Status'}
                    content={content}
                    icon={icon}
                    actionText={'Refresh'}
                />
            </div>
        </div>
    );
}

export default ReviewBlock;
