import { SVG } from 'assets/imagePath';
import { Drawer } from 'components/drawer';
import { MENU } from 'constants/menu';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

function SideBar({ linkRoutes }) {
    return (
        <div className="flex flex-row  lg:flex-col flex-start lg:justify-between h-full">
            <div className="flex-1 flex flex-row  lg:flex-col gap-2.5">
                {linkRoutes.map((link, idx) => (
                    <NavLink
                        className={({ isActive }) =>
                            `flex flex-row gap-[5px] items-center lg:gap-x-2.5 py-[11.5px]  min-w-fit  lg:min-w-[220px] px-2.5 lg:px-[15px] ${
                                isActive ? 'shadow-inner lg:shadow-both rounded-[10px] bg-white font-bold' : ''
                            }`
                        }
                        to={link.to}
                        key={link.id}
                    >
                        <img src={link.icon} alt="icon-link" />
                        <p className={`text-xs md:text-sm `}>{link.label}</p>
                    </NavLink>
                ))}
                <div className="md:hidden flex  flex-row gap-x-2.5 py-[11.5px] px-2.5">
                    <img src={SVG.support} alt="icon-link" />
                    <p className="text-xs md:text-sm">Support</p>
                </div>
            </div>
            <div className="hidden md:flex  flex-row gap-x-2.5 py-[15px] px-5">
                <img src={SVG.support} alt="icon-link" />
                <p className="text-xs md:text-sm">Support</p>
            </div>
        </div>
    );
}

export default SideBar;
