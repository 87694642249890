import { DealsTable } from 'components/AccountConfig/Settings';
import { CardContent } from 'components/Card';
import { HeaderNavigate } from 'components/HeaderNavigate';

function Deals() {
    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <div className="flex flex-col gap-[30px]">
                <HeaderNavigate detailInfo={{ detailName: 'Deals' }} />
                <CardContent
                    className={'max-w-[170px] w-full'}
                    title={'Total issue'}
                    content={'104'}
                    actionText={'View'}
                />

                <DealsTable />
            </div>
        </div>
    );
}

export default Deals;
