import React, { useId, useState } from 'react';
import { InputText } from './InputText';
import moment from 'moment';

// US UK format date = 'MMM DD YYYY''
function DatePicker({ format = 'MMM DD YYYY', onChange, propsDate, ...props }) {
    const [date, setDate] = useState();

    const onChangeDate = (e) => {
        let dateChange = e.target.value;
        if (format) {
            dateChange = moment(e.target.value).format(format);
        }
        setDate(dateChange);
        onChange?.(dateChange);
    };
    const dateId = useId();
    return (
        <div className="relative">
            <InputText {...props} value={date} readOnly />

            <InputText
                classNameBox="!absolute top-0 left-0 bg-transparent opacity-0"
                id={dateId}
                hidden
                type="date"
                {...propsDate}
                value={date}
                onChange={onChangeDate}
            />
        </div>
    );
}

export default DatePicker;
