import { Button } from 'components/Button';
import { DropdownMenu } from 'components/Dropdown';
import Dropdown from 'components/Dropdown/Dropdown';
import { EducationPost } from 'components/Education';
import EducationPostDetail from 'components/Education/EducationPostDetail';
import { POSTS } from 'components/Education/data';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function PostDetail() {
    const { postId } = useParams();

    const [post, setPost] = useState();
    useEffect(() => {
        //call api
        const fakePost = {
            id: 1,
            title: 'Post about topic',
            category: 'Category',
            relatedPosts: POSTS.slice(0, 3),
        };
        //call Related Posts
        setTimeout(() => {
            setPost(fakePost);
        }, 500);
    }, []);

    const menu = [{ label: 'Share', value: 'share' }];

    return (
        <div className="flex h-full">
            <div className="flex flex-col gap-[30px] flex-1 p-5 md:p-[30px]">
                <HeaderNavigate
                    detailInfo={{ detailName: 'Campaign', detailId: postId, chipLabel: post?.category }}
                    chipProps={{ color: 'white' }}
                    ActionArea={
                        <Dropdown options={<DropdownMenu menu={menu} />}>
                            <Button color="secondary" className={'px-[15px]'}>
                                <div className={'flex flex-row gap-[5px] items-center'}>
                                    <Text> Share</Text>
                                    <Svg name="chevronDown" />
                                </div>
                            </Button>
                        </Dropdown>
                    }
                />

                <EducationPostDetail post={post} />
            </div>
            <div className="lg:block hidden px-5 py-[30px] w-[280px]  bg-blue-ebe border-l border-blue-cfd h-full">
                <div className="flex flex-col items-center gap-5">
                    <SubTitle className={'mt-7'}>Related Posts</SubTitle>
                    {Array.isArray(post?.relatedPosts) &&
                        post?.relatedPosts.map((post, idx) => <EducationPost key={post?.id || idx} post={post} />)}
                </div>
            </div>
        </div>
    );
}

export default PostDetail;
