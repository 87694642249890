import { useState } from 'react';
import { DrawSignature, SavedSignature, SignatureSelectMethod, TypeSignature, UploadSignature } from './Signature';

function SignatureModule() {
    const [method, setMethod] = useState('');

    const onGetMethod = (method) => {
        setMethod(method);
    };

    const renderMethodContainer = () => {
        switch (method) {
            case 'saved':
                return <SavedSignature />;
            case 'type':
                return <TypeSignature />;
            case 'draw':
                return <DrawSignature />;
            case 'upload':
                return <UploadSignature />;

            default:
                return <></>;
        }
    };

    return (
        <div className="flex flex-col gap-5">
            <SignatureSelectMethod onGetMethod={onGetMethod} />

            {renderMethodContainer()}
        </div>
    );
}

export default SignatureModule;
