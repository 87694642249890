import { SVG } from 'assets/imagePath';
import Box from 'components/Box/Box';
import { BoxLabel } from 'components/BoxLabel';
import {
    MediaCostLineChart,
    baseMediaCostData,
    defaultStyleData,
    mediaData,
    labels,
    orgMediaCostData,
} from 'components/Charts/MediaCostChart';
import { MarkupCalculator } from 'components/GlobalModules/Calculators';
import { HeaderNavigate } from 'components/HeaderNavigate';
import Image from 'components/Image/Image';
import { InputText } from 'components/Input';
import { Modal } from 'components/Modal';
import ProgressCircle from 'components/ProgressCircle/ProgressCircle';
import Text from 'components/Text';
import FormInputText from 'components/modules/form/FormInputText';
import FormRange from 'components/modules/form/FormRange';
import { Form, Formik } from 'formik';
import { useState } from 'react';

function Markup() {
    const [isOpen, setIsOpen] = useState(false);
    const progress = 50;
    const size = 120;
    const sizeClass = '[120px]';
    const strokeWidth = 10;
    const circleOneStroke = '#f2f4f6';
    const circleTwoStroke = '#3E85F4';
    const circleErr = '#E32416';

    // console.log(config.theme.colors('error'));
    return (
        <>
            <div className="p-2.5 md:p-[30px] w-full">
                <div className="flex flex-col gap-[30px]">
                    <HeaderNavigate detailInfo={{ detailName: 'Markup' }} />

                    <Formik
                        initialValues={{
                            media_price_after_markup: (1.5).toFixed(2),
                        }}
                        onSubmit={(data) => {
                            console.log(data);
                        }}
                    >
                        {(props) => (
                            <Form className="flex flex-col gap-[30px]">
                                <Text>Base Cost: $1.50</Text>

                                <div className="flex flex-row gap-5">
                                    <InputText
                                        name="retail_data_cost_markup"
                                        placeholder=""
                                        label="Retail Data Cost Markup"
                                        // type="number"
                                        step="any"
                                        onChange={(e) => {
                                            let currentValue = e.target.value;

                                            // e.target.value = `${e.target.value}%`;
                                            if (currentValue) {
                                                if (currentValue.includes('%')) {
                                                    currentValue = currentValue.split('%').join('');
                                                    if (currentValue < 0 && currentValue > 100) {
                                                        return;
                                                    }
                                                    e.target.value = `${currentValue}%`;
                                                    props.handleChange(e);
                                                } else {
                                                    e.target.value = `${currentValue}%`;
                                                }
                                            }
                                            props.setFieldValue(
                                                'retail_data_cost_markup',
                                                Number(currentValue.split('%').join('')) / 100,
                                            );
                                        }}
                                    />
                                    <FormInputText
                                        name="media_price_after_markup"
                                        placeholder=""
                                        label="Media Price After Markup"
                                        value={1.75}
                                        readOnly
                                        mark={'$'}
                                        type="number"
                                        step="any"
                                    />
                                </div>

                                <Text>Base Media Cost: ${props.values.retail_media_cost_markup}</Text>

                                <div className="flex flex-row gap-5">
                                    <InputText
                                        name="retail_media_cost_markup"
                                        placeholder=""
                                        label="Retail Media Cost Markup"
                                        onChange={(e) => {
                                            let currentValue = e.target.value;

                                            // e.target.value = `${e.target.value}%`;
                                            if (currentValue) {
                                                if (currentValue.includes('%')) {
                                                    currentValue = currentValue.split('%').join('');
                                                    e.target.value = `${currentValue}%`;
                                                } else {
                                                    e.target.value = `${currentValue}%`;
                                                }
                                            }
                                            props.setFieldValue(
                                                'retail_media_cost_markup',
                                                Number(currentValue.split('%').join('')) / 100,
                                            );
                                        }}
                                        // type="number"
                                        step="any"
                                    />
                                    <FormInputText
                                        name="media_price_after_markup"
                                        placeholder=""
                                        label="Media Price After Markup"
                                        value={1.75}
                                        readOnly
                                        mark={'$'}
                                        type="number"
                                        step="any"
                                    />
                                </div>
                                <div className="flex flex-row gap-2.5">
                                    <Text>Need help calculating? Try our</Text>
                                    <Text component="button" underline onClick={() => setIsOpen(true)}>
                                        Markup Calculator
                                    </Text>
                                </div>

                                <div className="flex flex-row gap-5">
                                    <Box
                                        shadow="default"
                                        variant="outlined"
                                        className="w-full h-[200px]  flex-1 bg-white-light p-[15px]"
                                    >
                                        <div>
                                            <MediaCostLineChart
                                                customTitle={
                                                    <div className="flex flex-1 xs:flex-row flex-col gap-2.5 justify-between">
                                                        <Text weight="bold" size="12">
                                                            Media CPM per impressions (in %)
                                                        </Text>

                                                        <div className="flex flex-row gap-5">
                                                            <div className="flex gap-[5px] items-center">
                                                                <div className="w-[10px] h-[10px] bg-error rounded-full" />
                                                                <Text component="p" size="12">
                                                                    Base Media Cost
                                                                </Text>
                                                                <Image src={SVG.info_light} />
                                                            </div>
                                                            <div className="flex gap-[5px] items-center">
                                                                <div className="w-[10px] h-[10px] bg-primary rounded-full" />
                                                                <Text component="p" size="12">
                                                                    Retail Media Cost
                                                                </Text>
                                                                <Image src={SVG.info_light} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                data={{
                                                    labels,
                                                    datasets: [
                                                        {
                                                            ...defaultStyleData,
                                                            label: 'Media Cost',
                                                            backgroundColor: '#0E65F1',
                                                            borderColor: '#0E65F1',
                                                            data: mediaData,
                                                        },
                                                        {
                                                            ...defaultStyleData,
                                                            label: 'Base Media Cost',
                                                            backgroundColor: circleErr,
                                                            borderColor: circleErr,
                                                            data: baseMediaCostData,
                                                        },
                                                        {
                                                            ...defaultStyleData,
                                                            // label: 'OrgMedia Cost',
                                                            backgroundColor: '#E39115',
                                                            borderColor: '#E39115',
                                                            data: orgMediaCostData,
                                                        },
                                                    ],
                                                }}
                                            />
                                        </div>
                                    </Box>
                                    <div className="flex flex-1 flex-col items-center gap-[15px]">
                                        <BoxLabel
                                            variant="outlined"
                                            shadow="none"
                                            label="Media cost"
                                            classNameLabel={'left-[40px] border-none rounded-none'}
                                            className={'max-w-[450px] w-full'}
                                        >
                                            <div className="px-[30px] pt-[30px] pb-5">
                                                <FormRange
                                                    color="error"
                                                    name="retail_media_cost_markup"
                                                    defaultValue={50}
                                                />
                                            </div>
                                        </BoxLabel>

                                        <div className="flex flex-row gap-[30px] ">
                                            <div className="rounded-full shadow-outer">
                                                <ProgressCircle
                                                    progress={progress}
                                                    size={size}
                                                    sizeClass={sizeClass}
                                                    strokeWidth={strokeWidth}
                                                    circleOneStroke={circleOneStroke}
                                                    circleColor={circleTwoStroke}
                                                    ContentValue={
                                                        <div className="flex flex-col items-center justify-center aspect-square gap-[5px]">
                                                            <div className="flex items-center px-[7px]">
                                                                <Text className="text-center" component="p" size="10">
                                                                    Retail <br /> Media Cost
                                                                </Text>
                                                            </div>
                                                            <Text size="20" weight="semibold">
                                                                $0.63
                                                            </Text>
                                                            <Text component="p" size="10">
                                                                15% Markup
                                                            </Text>
                                                        </div>
                                                    }
                                                />
                                            </div>

                                            <div className="rounded-full shadow-outer">
                                                <ProgressCircle
                                                    progress={progress}
                                                    size={size}
                                                    sizeClass={sizeClass}
                                                    strokeWidth={strokeWidth}
                                                    circleOneStroke={circleOneStroke}
                                                    circleColor={circleErr}
                                                    ContentValue={
                                                        <div className="flex flex-col items-center justify-center aspect-square gap-[5px]">
                                                            <div className="flex items-center px-[7px]">
                                                                <Text className="text-center" component="p" size="10">
                                                                    Retail <br /> Media Cost
                                                                </Text>
                                                            </div>
                                                            <Text size="20" weight="semibold">
                                                                $0.63
                                                            </Text>
                                                            <Text component="p" size="10">
                                                                15% Markup
                                                            </Text>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>

            <Modal
                bodyClass={'w-full max-w-[362px] pb-[30px]'}
                title="Markup Calculator"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <MarkupCalculator />
            </Modal>
        </>
    );
}

export default Markup;
