import { SVG } from 'assets/imagePath';
import { Button } from 'components/Button';
import { AnalyzeSummary } from 'components/Campaign/Analyze';
import { OrderSummary } from 'components/Campaign/Order';
import Dropdown from 'components/Dropdown';
import { HeaderNavigate } from 'components/HeaderNavigate';
import Image from 'components/Image';
import { PlanSummary } from 'components/Plan';
import Tabs from 'components/Tabs';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { Analyze } from '../Analyze';
import Order from '../Order';
import { Plan } from '../Plan';

const campaignDetailTabs = [
    { label: 'Plan', value: 'plan' },
    { label: 'Order', value: 'order' },
    { label: 'Analyze', value: 'analyze' },
];
function CampaignService() {
    // const navigate = useNavigate();

    const { campaignId } = useParams();
    console.log(campaignId);

    const defaultValue = 'plan';

    const [tab, setTab] = useState(campaignDetailTabs.find((item) => item.value === defaultValue));
    console.log(tab);

    const renderTabPage = (type) => {
        // console.log(type);
        switch (tab.value) {
            case 'plan':
                if (type === 'between') {
                    return <Plan />;
                } else if (type === 'summary') {
                    return <PlanSummary />;
                } else if (type === 'action') {
                    return (
                        <div className="flex gap-2.5">
                            <Button color="secondary">Save</Button>
                            <Button disabled>Continue</Button>
                        </div>
                    );
                }
                break;
            case 'order':
                if (type === 'between') {
                    return <Order />;
                } else if (type === 'summary') {
                    return <OrderSummary />;
                } else if (type === 'action') {
                    return (
                        <div className="flex gap-2.5">
                            <Button color="secondary">Save</Button>
                            <Button disabled>Continue</Button>
                        </div>
                    );
                }
                break;
            case 'analyze':
                if (type === 'between') {
                    return <Analyze />;
                } else if (type === 'summary') {
                    return <AnalyzeSummary />;
                } else if (type === 'action') {
                    return (
                        <div className="flex gap-2.5">
                            <Dropdown
                                options={
                                    <ul className="flex flex-col z-50 divide-y-[1px] divide-blue-cfd">
                                        <li className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer">
                                            <div className="flex flex-row items-center gap-[5px] flex-1">
                                                <span className="text-sm text-blue-dark">Agency Account</span>
                                            </div>
                                        </li>
                                    </ul>
                                }
                            >
                                <Button className={'px-[15px]'} color="secondary">
                                    <div className=" flex flex-row gap-[5px] items-center">
                                        <div>Status</div>
                                        <Image src={SVG.chevron_down} />
                                    </div>
                                </Button>
                            </Dropdown>
                            <Button color="secondary"> Export</Button>
                            <Button>Save</Button>
                        </div>
                    );
                }
                break;
            default:
                break;
        }
    };

    return (
        <div className="flex h-full">
            <div className="flex-1 p-5 md:p-[30px]">
                <HeaderNavigate
                    navigateTo={routesPath.campaign}
                    detailInfo={{ detailName: 'Campaign', detailId: campaignId, chipLabel: 'Draft' }}
                    chipProps={{ color: 'white' }}
                    ActionArea={renderTabPage('action')}
                />

                <div className="my-[30px]">
                    <Tabs
                        value={defaultValue}
                        onChange={(newValue) => {
                            setTab(newValue);
                        }}
                        tabList={campaignDetailTabs}
                    />
                </div>

                <div>{renderTabPage('between')}</div>
            </div>
            <div className="lg:block hidden px-5 py-[30px] w-[280px] bg-blue-ebe border-l border-blue-cfd h-full">
                {renderTabPage('summary')}
            </div>
        </div>
    );
}

export default CampaignService;
