import { SVG } from 'assets/imagePath';
import GridTable from 'components/GridTable';
import TableHead from 'components/GridTable/TableHead';
import { HeaderNavigate } from 'components/HeaderNavigate';
import Image from 'components/Image';
import { blockchainPaymentCol, blockchainPaymentRow } from './data';

function BlockChainPayments() {
    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <HeaderNavigate detailInfo={{ detailName: 'Blockchain Payments' }} />

            <div className="mt-[30px] flex flex-col ">
                <TableHead title={'Blockchain Payments'} />
                <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                    <GridTable
                        className={'w-full table-fixed'}
                        column={blockchainPaymentCol}
                        row={blockchainPaymentRow}
                    />

                    <div className="bg-white-light  flex  justify-end p-[15px]  gap-[5px]">
                        <p className="text-sm font-normal text-grey-light">2 of 2 records</p>
                        <Image src={SVG.chevron_left_light} />
                        <Image src={SVG.chevron_right_light} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlockChainPayments;
