import { TextArea } from 'components/Input';
import { useField } from 'formik';
import { objecIsEmpty } from 'utils';

function FormTextArea({ ...props }) {
    const [field, meta, helpers] = useField(props);
    console.log(meta);
    return <TextArea onChange={helpers.setValue} {...field} {...props} />;
}

export default FormTextArea;
