import { useAdCanva } from 'context/AdCanvaContext';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import CanvaChooseSize from '../../CanvaChooseSize';
import CanvaSaved from '../../CanvaSaved';
import CanvaUpdateBg from '../../CanvaUpdateBg';
import CanvaUpdateColors from '../../CanvaUpdateColors';
import SelectTemplate from '../../SelectTemplate';
import { DesignerLayout } from '../../layouts';
import DesignChooseText from '../DesignChooseText';
import DesignerProgress from '../DesignerProgress';

function Designer() {
    const { step, setStep, template, setTemplate } = useAdCanva();

    const [newRequest, setNewRequest] = useState(false);

    return (
        <Formik
            initialValues={{}}
            onSubmit={(data) => {
                console.log(data);
            }}
        >
            <Form>
                {step === 1 && (
                    <SelectTemplate title="Choose Request" subTitle="Placeholder" onSetNewRequest={setNewRequest} />
                )}

                {step === 2 ? (
                    newRequest ? (
                        <DesignerLayout title={'Update Images'} subTitle={'Placeholder'}>
                            <CanvaChooseSize />
                        </DesignerLayout>
                    ) : (
                        <DesignerProgress />
                    )
                ) : (
                    <></>
                )}

                {step === 3 && (
                    <DesignerLayout title={'Update Images'} subTitle={'Placeholder'}>
                        <CanvaUpdateBg />
                    </DesignerLayout>
                )}
                {step === 4 && (
                    <DesignerLayout title={'Update Text'} subTitle={'Placeholder'}>
                        <DesignChooseText />
                    </DesignerLayout>
                )}
                {step === 5 && (
                    <DesignerLayout title={'Update Text'} subTitle={'Placeholder'}>
                        <CanvaUpdateColors />
                    </DesignerLayout>
                )}
                {step === 6 && (
                    <DesignerLayout lastStep title={'Save Creatives Set'} subTitle={'Placeholder'}>
                        <CanvaSaved />
                    </DesignerLayout>
                )}
            </Form>
        </Formik>
    );
}

export default Designer;
