import clsx from 'clsx';
import { Button } from 'components/Button';
import Image from 'components/Image/Image';
import Text from 'components/Text/Text';
import { useFormikContext } from 'formik';
import { setContrast } from 'utils';
import Holder from '../Holder';
import { IMAGE } from 'assets/imagePath';

function MediumRectangle({ className }) {
    const {
        values: { background, logo, title, subTitle, callAction, base, accent },
    } = useFormikContext();

    const { isBrightness } = setContrast(base);
    const { isBrightness: isBrightnessAccent } = setContrast(accent);

    return !background?.img?.preview ? (
        <Holder
            className={clsx('flex flex-col gap-[5px]', className)}
            title={'MediumRectangle'}
            ratio={{ x: 300, y: 250 }}
        />
    ) : (
        <>
            <div className={clsx('flex flex-col gap-[5px] w-full', className)}>
                <Text>Medium Rectangle (300x250)</Text>
                <div
                    style={{ aspectRatio: 300 / 250 }}
                    className={clsx('overflow-hidden relative w-[300px] h-[250px] bg-white')}
                >
                    <Image className={'w-[300px] h-[250px] object-center'} src={background?.img?.preview} />

                    <div
                        style={{
                            backgroundColor: base || '#0E65F1',
                        }}
                        className="absolute top-0 left-0 w-full h-[68%] bg-[#0E65F1] clip polygon c-60"
                    >
                        <div className="flex flex-row items-center gap-5 p-[15px]">
                            <div className={clsx('flex flex-col')}>
                                <Text
                                    style={{ fontSize: '16px' }}
                                    className={clsx(isBrightness ? 'text-black' : 'text-white', title.font)}
                                    weight="bold"
                                >
                                    {title?.text}
                                </Text>
                                <Text
                                    style={{ fontSize: '12px' }}
                                    className={clsx(isBrightness ? 'text-black' : 'text-white', subTitle.font)}
                                >
                                    {subTitle?.text}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <Image
                        src={logo?.img?.preview || IMAGE.adcanvas_logo_default}
                        className={'absolute bottom-[15px] left-[15px] object-contain rounded-full  w-[70px] h-[70px]'}
                    />

                    <Button
                        style={{
                            background: accent || '#E39115',
                            fontSize: `${callAction?.size}px`,
                        }}
                        size="none"
                        className={clsx(
                            '!shadow-none absolute right-[15px] bottom-[15px] uppercase rounded-full py-[5px] !px-2.5 font-bold',
                            callAction.font,
                            isBrightnessAccent ? '!text-black' : '!text-white',
                        )}
                    >
                        {callAction?.text}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default MediumRectangle;
