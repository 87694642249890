import React from 'react';
import Modal from './Modal';
import Image from 'components/Image/Image';
import { Portal } from 'components/Portal';

function ModalZoomImage({ isOpen, setIsOpen, image, ...props }) {
    return (
        <Portal>
            <Modal
                bodyClass={'max-w-[800px] w-full pb-[30px] z-[100] '}
                className="z-[100]"
                title="Image"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                {...props}
            >
                <Image className={'mx-auto'} src={image} alt="image-zoom" />
            </Modal>
        </Portal>
    );
}

export default ModalZoomImage;
