import { Button } from 'components/Button';
import { DetailBox } from 'components/DetailBox';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { Modal } from 'components/Modal';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import RefundForm from './RefundForm';
import { blockchainPaymentCol, blockchainPaymentRow, cardPaymentCol } from './data';

function PaymentDetail() {
    const location = useLocation();

    const column = location?.state?.type === 'blockchain' ? blockchainPaymentCol : cardPaymentCol;

    const { paymentId } = useParams();

    const rowDetail = blockchainPaymentRow.find((item) => item?.id === paymentId);

    const [row, setRow] = useState(rowDetail || blockchainPaymentRow[0]);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        //Fetch Row Earning Detail
        // setRow(rowDetail)
    }, []);

    return (
        <>
            <div className="p-2.5 md:p-[30px] w-full">
                <div className="flex flex-col gap-[30px]">
                    <HeaderNavigate
                        detailInfo={{ detailName: 'Payment', detailId: paymentId }}
                        ActionArea={
                            <Button color="secondary" onClick={() => setIsOpen(true)}>
                                Refund
                            </Button>
                        }
                    />

                    <div className="max-w-[400px] w-full">
                        <DetailBox title="Payment" column={column} row={row} />
                    </div>
                </div>
            </div>
            <Modal bodyClass={'max-w-[362px]'} title="Confirm Action" isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <RefundForm />
            </Modal>
        </>
    );
}

export default PaymentDetail;
