import React, { useState } from 'react';
import { InputText, Range } from 'components/Input';
import { SVG } from 'assets/imagePath';
import { BoxLabelOutline } from 'components/BoxLabel';
import Box from 'components/Box';
import Text from 'components/Text';

function MarkupCalculator() {
    const [value, setValue] = useState('14000');
    const [markupVal, setMarkupVal] = useState(35);
    return (
        <div className="flex flex-col gap-5 mt-5">
            <InputText
                value={value}
                defaultValue={14000}
                onChange={(e) => setValue(e.target.value)}
                className={'h-[40px] pl-[29px]'}
                label="Monthly Campaign Budgets"
                mark={'$'}
            />

            <BoxLabelOutline label={'Markup'}>
                <div className="w-full">
                    <Range
                        defaultValue={markupVal}
                        onCustomChange={(value) => setMarkupVal(value)}
                        minValue={0}
                        maxValue={70}
                    />
                </div>
            </BoxLabelOutline>

            <div className="flex flex-1 gap-5">
                <Box className="flex-1 flex flex-col items-center gap-[5px] py-[15px]">
                    <Text weight="bold">Base Media Cost</Text>
                    <Text size="24" weight="bold">
                        $1.35
                    </Text>

                    <Text component="span">
                        Base $1 +{' '}
                        <Text component="span" weight="bold" className="text-primary">
                            {markupVal}%
                        </Text>{' '}
                    </Text>
                </Box>
                <Box className="flex-1 flex flex-col items-center gap-[5px] py-[15px]">
                    <Text weight="bold">Base Data Cost</Text>
                    <Text size="24" weight="bold">
                        $1.35
                    </Text>

                    <Text component="span">
                        Base $1 +{' '}
                        <Text component="span" weight="bold" className="text-primary">
                            {markupVal}%
                        </Text>{' '}
                    </Text>
                </Box>
            </div>

            <Box className="flex-1 flex flex-col items-center gap-[5px] py-[15px]">
                <Text>Profit</Text>
                <Text size="24" weight="bold">
                    $3629.62
                </Text>
            </Box>
        </div>
    );
}

export default MarkupCalculator;
