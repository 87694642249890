import { BoxLabelOutline } from 'components/BoxLabel';
import FormInputText from 'components/modules/form/FormInputText';
import FormSelect from 'components/modules/form/FormSelect';
import { FONT_OPTIONS, SIZE_OPTIONS } from 'constants/common';
import GenerativeButton from './GenerativeButton';
import { useState } from 'react';
import { Portal } from 'components/Portal';
import { Modal } from 'components/Modal';
import { Formik, useFormikContext } from 'formik';
import GenerateText from './GenerateText';
import { SubTitle } from 'components/Title';

function CanvaUpdateContent() {
    const [openModal, setOpenModal] = useState({
        open: false,
        data: {},
    });

    const handleCloseModal = () => {
        setOpenModal((prev) => ({
            ...prev,
            open: false,
        }));
    };
    const handleOpenModal = () => {
        setOpenModal((prev) => ({
            ...prev,
            open: true,
        }));
    };

    const { setValues, values } = useFormikContext();
    return (
        <>
            <div className="flex flex-col gap-5">
                <SubTitle>Choose Copy</SubTitle>
                <GenerativeButton
                    label="Try Our Generative Text"
                    className={'justify-center'}
                    onClick={() => {
                        handleOpenModal('logo');
                    }}
                />
                <BoxLabelOutline className={'flex-col flex !p-5'} label={'Title'}>
                    <FormInputText name="title.text" label="Text" placeholder="Text" />

                    {/* <ColorPicker /> */}

                    <FormSelect
                        placeholder="Font"
                        propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                        placeholderTop
                        name="title.font"
                        currentValue={FONT_OPTIONS[0].value}
                        options={FONT_OPTIONS}
                    />

                    <FormSelect
                        placeholder="Size"
                        propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                        placeholderTop
                        defaultValue={'16'}
                        name="title.size"
                        options={SIZE_OPTIONS}
                    />
                </BoxLabelOutline>
                <BoxLabelOutline className={'flex-col flex !p-5'} label={'Subtitle'}>
                    <FormInputText name="subTitle.text" label="Text" placeholder="Text" />

                    {/* <ColorPicker /> */}
                    <FormSelect
                        placeholder="Font"
                        propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                        placeholderTop
                        name="subTitle.font"
                        defaultValue={FONT_OPTIONS[0].value}
                        options={FONT_OPTIONS}
                    />

                    <FormSelect
                        placeholder="Size"
                        propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                        placeholderTop
                        name="subTitle.size"
                        defaultValue={'12'}
                        options={SIZE_OPTIONS}
                    />
                </BoxLabelOutline>

                <BoxLabelOutline className={'flex-col flex !p-5'} label={'Call to action'}>
                    <FormInputText name="callAction.text" label="Text" placeholder="Text" />

                    {/* <ColorPicker /> */}
                    <FormSelect
                        placeholder="Font"
                        propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                        placeholderTop
                        name="callAction.font"
                        defaultValue={FONT_OPTIONS[0].value}
                        options={FONT_OPTIONS}
                    />

                    <FormSelect
                        placeholder="Size"
                        propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                        placeholderTop
                        name="callAction.size"
                        defaultValue={'12'}
                        options={SIZE_OPTIONS}
                    />
                </BoxLabelOutline>
            </div>

            <Portal>
                <Modal
                    bodyClass="max-w-[580px] w-full"
                    isOpen={openModal.open}
                    onClose={handleCloseModal}
                    title="Generate Text"
                >
                    <Formik
                        initialValues={{
                            logoDesc: '',
                            imgLogo: '',
                        }}
                        onSubmit={(data, actions) => {
                            setValues({
                                ...values,
                                logo: {
                                    desc: data.logoDesc,
                                    img: data.imgLogo,
                                },
                            });
                            handleCloseModal();
                        }}
                    >
                        <GenerateText />
                    </Formik>
                </Modal>
            </Portal>
        </>
    );
}

export default CanvaUpdateContent;
