import { DetailBox2 } from 'components/DetailBox';

const columnPayment = [
    {
        field: 'date',
        headerName: 'Date',
    },
    {
        field: 'time',
        headerName: 'Time',
    },
    {
        field: 'service',
        headerName: 'Service',
    },
    {
        field: 'amount',
        headerName: 'amount',
    },
    {
        field: 'currency',
        headerName: 'Currency',
    },
    {
        field: 'billing',
        headerName: 'Billing',
    },
];

const columnBalance = [
    {
        field: 'date',
        headerName: 'Total Balance (All currencies)',
    },
    {
        field: 'time',
        headerName: 'Account Name',
    },
    {
        field: 'service',
        headerName: 'Service Level',
    },

    {
        field: 'currency',
        headerName: 'Currency',
    },
    {
        field: 'billing',
        headerName: 'Billing',
    },
    {
        field: 'amount',
        headerName: 'Payment method',
    },
];
export default function PaymentDetail({ row, detailType }) {
    return <DetailBox2 title="Details" row={row} column={detailType === 'payment' ? columnPayment : columnBalance} />;
}
