import RangeSlide from 'components/Input/RangeSlide';
import { useField } from 'formik';

function FormDoubleRange(props) {
    const [field, meta, helpers] = useField(props);

    const { setValue: setValueFormik } = helpers;
    return <RangeSlide helper={meta.error} error={meta.error} {...field} onChange={setValueFormik} {...props} />;
}

export default FormDoubleRange;
