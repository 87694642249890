import React from 'react';
import { SubTitle } from 'components/Title';
import { CardContent } from 'components/Card';
import { IMAGE } from 'assets/imagePath';

function AuthorBlock() {
    return (
        <div className="flex flex-col gap-5">
            <SubTitle>Author</SubTitle>

            <div className="grid grid-cols-1 xxs:grid-cols-2 grid-flow-row gap-5 lg:grid-cols-3">
                <CardContent
                    className={'w-full'}
                    title={'User'}
                    content="User Name"
                    actionText={'View'}
                    icon={IMAGE.avatar_default}
                />
            </div>
        </div>
    );
}

export default AuthorBlock;
