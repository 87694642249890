import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import clsx from 'clsx';
import { forwardRef, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { requestGPSPermission } from 'utils/permission';

function GgMap({ setGoogle, children, className, ...rest }, ref) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
    });

    const [center, setCenter] = useState({ lat: 44, lng: -80 });

    const [isRequesting, setIsRequesting] = useState(true);

    useLayoutEffect(() => {
        requestGPSPermission(
            (location) => {
                setCenter(location);
                setIsRequesting(false);
            },
            (error) => {
                //Handle Error action here

                //error
                setIsRequesting(false);
            },
        );
    }, []);

    const google = window.google;

    if (!isLoaded || isRequesting) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <GoogleMap
                mapContainerClassName={clsx('w-full h-full', className)}
                center={center}
                zoom={18}
                onLoad={(mp) => ref && (ref.current = mp)}
                options={{
                    disableDefaultUI: true,
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                    },
                }}
                {...rest}
            >
                {/* children is the callback function to get class Google after install the script  */}
                {/* Example to use: {(google) => <Component google={google} /> }  */}

                {children(google)}
            </GoogleMap>
        </>
    );
}

export default forwardRef(GgMap);
