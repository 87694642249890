import { Button } from 'components/Button';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import { APP_ROLE } from 'constants/auth';
import { useAdCanva } from 'context/AdCanvaContext';
import { useAuth } from 'hooks';
import React from 'react';

function DesignApproved() {
    const { setStep, closeModalAdCanva } = useAdCanva();

    const { currentRole } = useAuth();

    const handleDuplicate = () => {
        //Logic call api duplicate

        // Close modal when call api success
        closeModalAdCanva();
    };
    return (
        <div className="flex flex-col gap-5">
            <SubTitle>{currentRole === APP_ROLE.Org ? 'Creatives Approved' : 'Reuse Request Details'}</SubTitle>
            <Text size="14">
                Lacinia eget in eget molestie aliquam egestas proin. At diam tincidunt pellentesque orci. Tellus lorem
                nunc ornare fames lectus. Morbi pulvinar bibendum sed enim enim turpis sit aliquam. Proin nibh cursus
                massa elementum.
            </Text>

            <div className="flex flex-row gap-5">
                {currentRole === APP_ROLE.Org ? (
                    <></>
                ) : (
                    <Button className={'w-full'} onClick={handleDuplicate}>
                        Duplicate
                    </Button>
                )}
            </div>
        </div>
    );
}

export default DesignApproved;
