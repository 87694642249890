import React, { useState } from 'react';
import EmailNotifications from './EmailNotifications';

function NotificationsSubCo() {
    const [notificationEmail, setNotificationEmail] = useState(false);
    return (
        <div>
            <EmailNotifications checked={notificationEmail} onChange={(value) => setNotificationEmail(value)} />
        </div>
    );
}

export default NotificationsSubCo;
