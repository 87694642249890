import { InputText } from 'components/Input';
import Text from 'components/Text';
import { useFormik } from 'formik';
import { useState } from 'react';
import UploadAvatar from './UploadAvatar';

function ProfileSingleUser() {
    const [image, setImage] = useState('');
    const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: {
            // email: '',
            // first_name: '',
            // last_name: '',
            // street_address: '',
            // suite_or_apartment: '',
            // city: '',
            // zip_code: '',
            // accept_term: false,
            // company_name: '',
            // business_street_address: '',
            // business_suite_or_apartment: '',
            // business_city: '',
            // business_zip_code: '',
        },
        onSubmit: (values) => {
            console.log(values);
            // navigateSearch(pathname, { step: '2' });
        },
        // validationSchema: createAccountSchema,
    });

    return (
        <div className="max-w-[820px]">
            <Text className="mb-5 pt-7" size="18" weight="bold">
                Profile
            </Text>

            <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-y-[20px]">
                    <div className="flex flex-col-reverse  xs:flex-row items-center gap-[20px]">
                        <InputText
                            onChange={handleChange}
                            value={values.company_name}
                            error={errors.company_name}
                            classNameBox={'flex-1'}
                            name="first_name"
                            placeholder={'First Name'}
                            rounded
                        />
                        <InputText
                            onChange={handleChange}
                            value={values.company_name}
                            error={errors.company_name}
                            classNameBox={'flex-1'}
                            name="last_name"
                            placeholder={'Last Name'}
                            rounded
                        />

                        <UploadAvatar
                            className={'flex-1'}
                            labelClass="!block"
                            image={image}
                            setImage={setImage}
                            label={'Change Profile Image'}
                        />
                    </div>

                    <div className="flex flex-col xs:flex-row items-center gap-[20px]">
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.email_address}
                            error={errors.email_address}
                            name="email_address"
                            placeholder={'Contact Email Address'}
                            rounded
                        />
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.phone_number}
                            error={errors.phone_number}
                            name="phone_number"
                            placeholder={'Phone Number'}
                            rounded
                        />
                    </div>

                    <InputText
                        onChange={handleChange}
                        value={values.bio}
                        error={errors.bio}
                        classNameBox="flex-1"
                        name="bio"
                        placeholder={'Bio'}
                        rounded
                    />
                </div>
            </form>
        </div>
    );
}

export default ProfileSingleUser;
