import Chip from 'components/Chip/Chip';
import ActionsButton from 'components/GridTable/ActionsButton';

export const earningColumn = [
    {
        field: 'id',
        headerName: 'Id',
    },
    {
        field: 'company',
        headerName: 'Company',
    },

    {
        field: 'campaign_id',
        headerName: 'Campaign ID',
    },

    {
        field: 'payment_id',
        headerName: 'Payment ID',
    },
    {
        field: 'markup',
        headerName: 'Markup',
    },
    {
        field: 'tcc',
        headerName: 'TCC',
    },
    {
        field: 'tcptu',
        headerName: 'TCPTU',
    },
    {
        field: 'tre',
        headerName: 'TRE',
    },
    {
        field: 'cm_id',
        headerName: 'CM ID',
    },
    {
        field: 'ct_date',
        headerName: 'CT Date',
    },
    {
        field: 'status',
        headerName: 'Status',
        renderCell: () => {
            return <Chip color="success">Paid</Chip>;
        },
    },

    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row, handle) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return (
                <ActionsButton
                    onChange={(val) => {
                        handle(row, val);
                    }}
                />
            );
        },
    },
];

export const earningRow = [
    {
        id: '231',
        company: 'Audi',
        campaign_id: '322',
        payment_id: '322',
        markup: '$134.00',
        tcc: '$3024.00',
        tcptu: '$3158',
        tre: 'Value',
        cm_id: 'Value',
        ct_date: 'March 20, 2023',
        status: 'Paid',
    },
    {
        id: '324',
        company: 'Audi',
        campaign_id: '322',
        payment_id: '322',
        markup: '$134.00',
        tcc: '$3024.00',
        tcptu: '$3158',
        tre: 'Value',
        cm_id: 'Value',
        ct_date: 'March 20, 2023',
        status: 'Paid',
    },
    {
        id: '324',
        company: 'Audi',
        campaign_id: '322',
        payment_id: '322',
        markup: '$134.00',
        tcc: '$3024.00',
        tcptu: '$3158',
        tre: 'Value',
        cm_id: 'Value',
        ct_date: 'March 20, 2023',
        status: 'Paid',
    },
    {
        id: '324',
        company: 'Audi',
        campaign_id: '322',
        payment_id: '322',
        markup: '$134.00',
        tcc: '$3024.00',
        tcptu: '$3158',
        tre: 'Value',
        cm_id: 'Value',
        ct_date: 'March 20, 2023',
        status: 'Paid',
    },
    {
        id: '324',
        company: 'Audi',
        campaign_id: '322',
        payment_id: '322',
        markup: '$134.00',
        tcc: '$3024.00',
        tcptu: '$3158',
        tre: 'Value',
        cm_id: 'Value',
        ct_date: 'March 20, 2023',
        status: 'Paid',
    },
    {
        id: '324',
        company: 'Audi',
        campaign_id: '322',
        payment_id: '322',
        markup: '$134.00',
        tcc: '$3024.00',
        tcptu: '$3158',
        tre: 'Value',
        cm_id: 'Value',
        ct_date: 'March 20, 2023',
        status: 'Paid',
    },
    {
        id: '324',
        company: 'Audi',
        campaign_id: '322',
        payment_id: '322',
        markup: '$134.00',
        tcc: '$3024.00',
        tcptu: '$3158',
        tre: 'Value',
        cm_id: 'Value',
        ct_date: 'March 20, 2023',
        status: 'Paid',
    },
];
