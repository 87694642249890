import { IMAGE } from 'assets/imagePath';
import Box from 'components/Box';
import { Button } from 'components/Button';
import Checkbox from 'components/Input/Checkbox';
import { Svg } from 'components/Svg';
import Text from 'components/Text';
import { useViewport } from 'context/ViewportContext';
import { useEffect, useRef, useState } from 'react';
import CanvasDraw from 'react-canvas-draw';

const defaultProps = {
    onChange: null,
    loadTimeOffset: 5,
    lazyRadius: 30,
    brushRadius: 12,
    brushColor: '#444',
    catenaryColor: '#0a0302',
    gridColor: 'rgba(150,150,150,0.17)',
    hideGrid: false,
    canvasWidth: 400,
    canvasHeight: 400,
    disabled: false,
    imgSrc: '',
    saveData: null,
    immediateLoading: false,
    hideInterface: false,
    gridSizeX: 25,
    gridSizeY: 25,
    gridLineWidth: 0.5,
    hideGridX: true,
    hideGridY: true,
    enablePanAndZoom: false,
    mouseZoomFactor: 0.01,
    zoomExtents: { min: 0.33, max: 3 },
};

function UploadSignature() {
    const [image, setImage] = useState('');
    const canvasRef = useRef();

    const ref = useRef(null);

    const [boundedCanvas, setBoundedCanvas] = useState({
        width: 330,
        height: 100,
    });

    const { width } = useViewport();
    useEffect(() => {
        setBoundedCanvas({
            width: ref?.current?.offsetWidth - 30, // padding-x
            height: ref?.current?.offsetHeight - 50, // padding-y and
        });
    }, [width]);

    return (
        <div className="flex flex-col gap-[30px] items-center ">
            <Box shadow="default" ref={ref} className={'max-w-[360px] w-full  h-[150px] p-[15px] bg-white-light'}>
                <CanvasDraw
                    ref={canvasRef}
                    backgroundColor="#f4f6f8"
                    canvasWidth={boundedCanvas?.width}
                    canvasHeight={boundedCanvas?.height}
                    className="w-full h-full"
                    hideGrid
                    brushRadius={5}
                    onChange={(data) => {
                        setImage(canvasRef?.current?.getDataURL());
                    }}
                />
                <Svg
                    onClick={() => canvasRef.current?.clear()}
                    className="ml-auto w-[17px] h-[17px] child:fill-blue-cfd cursor-pointer"
                    name="trash"
                />
            </Box>
            <div className="max-w-[260px] w-full flex justify-end">
                <Checkbox Label={<Text>Save for future use</Text>} />
            </div>

            <Button className="max-w-[260px] w-full">Sign</Button>
        </div>
    );
}

export default UploadSignature;
