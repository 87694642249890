import Radio from 'components/Input/Radio';
import Text from 'components/Text';
import { memo } from 'react';

function SignatureSelectMethod({ onGetMethod }) {
    return (
        <div className="select__method">
            <Text size="18" weight="bold" className={'py-[14px] text-center mb-5'}>
                Signature
            </Text>
            <div className="grid grid-flow-row  grid-cols-1 xxs:grid-cols-2 md:grid-cols-4 gap-5">
                <Radio
                    label="Saved"
                    value="saved"
                    name="signature_method"
                    onChange={(e) => onGetMethod(e.target.value)}
                />

                <Radio
                    label="Type"
                    value="type"
                    name="signature_method"
                    onChange={(e) => onGetMethod(e.target.value)}
                />
                <Radio
                    label="Draw"
                    value="draw"
                    name="signature_method"
                    onChange={(e) => onGetMethod(e.target.value)}
                />
                <Radio
                    label="Upload"
                    value="upload"
                    name="signature_method"
                    onChange={(e) => onGetMethod(e.target.value)}
                />
            </div>
        </div>
    );
}

export default memo(SignatureSelectMethod);
