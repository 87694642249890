import { IMAGE } from 'assets/imagePath';
import clsx from 'clsx';
import { Svg } from 'components/Svg';
import { useEffect, useId } from 'react';
function UploadVideo({ video, setVideo, children = 'Choose Image', className, setValueFormik, renderRemoveButton }) {
    const handleUpload = (e) => {
        const file = e.target.files[0];
        file.preview = URL.createObjectURL(file);
        file.width = e.target?.offsetWidth;
        file.height = e.target?.offsetHeight;

        setVideo(file);
    };

    const handleRemove = () => {
        URL.revokeObjectURL(video?.preview);
        setVideo('');
        setValueFormik('');
    };

    const handleGetVideoSize = ({ target }) => {
        // Get natural WIdth and hieght of image after image had loaded to set value formik

        video.width = target.videoWidth;
        video.height = target.videoHeight;
        setValueFormik(video);
    };
    const upload__file = 'flex justify-center items-center cursor-pointer';

    const idVideo = useId();

    useEffect(() => {
        return () => {
            handleRemove();
        };
    }, []);

    return (
        <div className="w-full h-full">
            {!video ? (
                <div className="h-full flex justify-center items-center flex-col">
                    <label htmlFor={idVideo}>
                        <input
                            className="hidden"
                            onChange={(e) => {
                                handleUpload(e);
                            }}
                            accept="video/*"
                            id={idVideo}
                            multiple
                            type="file"
                        />
                        <div className={clsx(upload__file, className)}>{children}</div>
                    </label>
                </div>
            ) : (
                <div className="w-full h-full relative">
                    <video
                        onLoadedMetadata={handleGetVideoSize}
                        className="h-full w-full object-contain"
                        src={video?.preview}
                        controls
                        onError={(e) => ((e.target.onerror = null), (e.target.src = IMAGE.no_image))}
                    />
                    {renderRemoveButton ? (
                        renderRemoveButton({ remove: handleRemove })
                    ) : (
                        <Svg className="absolute right-0 bottom-0" name="trash" onClick={handleRemove} />
                    )}
                </div>
            )}
        </div>
    );
}

export default UploadVideo;
