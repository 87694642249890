import { Button } from 'components/Button';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import FormInputText from 'components/modules/form/FormInputText';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function VerifyNewEmail({ setStep }) {
    const navigate = useNavigate();
    return (
        <div>
            <Text className="mb-5 text-center" weight="bold" size="24">
                Verify New Email
            </Text>

            <Text size="14" className="mb-5 text-center">
                Please check your email for the code or verification link sent from an adNucleus.com email address.
            </Text>
            {/* <Text weight="bold" className="mb-5 text-center text-error">
                    {err}
                </Text> */}

            <Formik
                className=""
                initialValues={{}}
                onSubmit={(values, actions) => {
                    navigate(routesPath.login, { replace: true });

                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                    }, 0);
                }}
            >
                <Form>
                    <FormInputText className="mb-[20px]" placeholder="Confirm Code" rounded name="confirm_code" />

                    <Button
                        type="submit"
                        className="mx-auto flex  justify-center w-full max-w-[260px] py-[15px] px-5 mb-[20px] shadow-outer"
                    >
                        Verify
                    </Button>
                </Form>
            </Formik>

            <div className="flex flex-start justify-center gap-[5px] items-center">
                <Text size="14">Don't received?</Text>
                <Svg name="infoOutline" />
                <Text size="14" className="cursor-pointer" underline>
                    Resend
                </Text>
            </div>
        </div>
    );
}

export default VerifyNewEmail;
