import { IMAGE, SVG } from 'assets/imagePath';
import Image from 'components/Image';
import Text from 'components/Text';

const OurTechnology = () => {
    return (
        <section className="w-full container mx-auto  p-[30px] px-[20px] xs:pb-[100px] ">
            <div className="flex justify-center items-center gap-2.5 md:gap-[60px] xl:gap-[140px] flex-col-reverse xl:flex-row mx-[12px]">
                <div className="left">
                    <div className="font-bold text-lg sm:text-xl  leading-[30px] text-dark-nav font-sen">
                        Understand campaign effectiveness <br className="hidden xl:block" />
                        with our AI technology
                    </div>
                    <div className="item">
                        <div className="flex items-center gap-[15px] mt-[30px]">
                            <Image
                                src={SVG.optimize}
                                className="p-[5px] h-[37px] w-[37px] rounded-[50px] bg-white shadow-outer "
                            />

                            <div className="font-bold text-sm sm:text-base  leading-[24px] text-dark-nav">
                                AI-Powered Optimization
                            </div>
                        </div>
                        <Text className="xl:max-w-[465px] mt-[20px] text-xs sm:text-sm   text-gray">
                            Our technology leverages machine learning and AI to automate performance decisions, enabling
                            your campaigns to achieve much more at scale.
                        </Text>
                    </div>

                    <div className="item">
                        <div className="flex items-center gap-[15px] mt-[30px]">
                            <Image
                                src={SVG.analytics}
                                className="p-[5px] h-[37px] w-[37px] rounded-[50px] bg-white shadow-outer "
                            />

                            <div className="font-bold text-sm sm:text-base  leading-[24px] text-dark-nav">
                                Advanced Reporting
                            </div>
                        </div>
                        <Text className="xl:max-w-[465px] mt-[20px] text-xs sm:text-sm   text-gray">
                            Our reporting stack provides a consolidated view of campaign data and features customization
                            and scheduling capabilities. It also integrates with industry-leading reporting tools such
                            as TapClicks, Google Data Studio, Supermetrics, and Funnel.io.
                        </Text>
                    </div>

                    <div className="item">
                        <div className="flex items-center gap-[15px] mt-[30px]">
                            <Image
                                src={SVG.info_green}
                                className="p-[5px] h-[37px] w-[37px] rounded-[50px] bg-white shadow-outer "
                            />

                            <div className="font-bold text-sm sm:text-base  leading-[24px] text-dark-nav">
                                Actionable Insights
                            </div>
                        </div>
                        <Text className="xl:max-w-[465px] mt-[20px] text-xs sm:text-sm   text-gray">
                            Our platform offers lift and location-based intelligence partnerships, enabling us to
                            measure conversion contributions and go the extra mile in delivering valuable insights.
                        </Text>
                    </div>
                </div>
                <div className="right h-auto max-w-[600px]">
                    <Image src={IMAGE.home_3} />
                </div>
            </div>
        </section>
    );
};

export default OurTechnology;
