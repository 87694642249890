import LeadProfileForm from 'components/AccountConfig/Leads/LeadProfileForm';
import { BoxContent } from 'components/BoxContent';
import React from 'react';

function LeadProfile() {
    return (
        <BoxContent title="Lead Profile">
            <div className="max-w-[820px]">
                <LeadProfileForm />
            </div>
        </BoxContent>
    );
}

export default LeadProfile;
