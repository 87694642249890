import { useLoadScript } from '@react-google-maps/api';
import Dropdown, { DropdownMenu } from 'components/Dropdown';
import SearchInput from 'components/Input/SearchInput';
import React, { useEffect } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

function PlacesAutocomplete({ setSelected }) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: ['places'],
    });

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
        init,
    } = usePlacesAutocomplete({ initOnMount: false });

    const handleSelect = async (address) => {
        setValue(address, false);
        clearSuggestions();

        const results = await getGeocode({ address });
        const location = await getLatLng(results[0]);

        setSelected({
            ...location,
            name: address,
        });
    };

    useEffect(() => {
        if (isLoaded) {
            init();
        }
    }, [isLoaded]);

    return (
        <div>
            <Dropdown
                options={
                    data.length !== 0
                        ? ({ close }) => (
                              <DropdownMenu
                                  menu={data}
                                  labelField="description"
                                  onChange={(menuValue) => {
                                      handleSelect(menuValue.description);
                                      close();
                                  }}
                                  currentValue={''}
                              />
                          )
                        : []
                }
                classNameBox={'w-full '}
                className={'min-w-0'}
            >
                <SearchInput disabled={!ready} searchValue={value} onChange={(val) => setValue(val)} />
            </Dropdown>
        </div>
    );
}

export default PlacesAutocomplete;
