import { EducationTopic } from 'components/Education';
import { POSTS } from 'components/Education/data';
import {
    Introduce,
    NewKind,
    Offer,
    OurAdvertising,
    OurAnalysis,
    OurPlatform,
    OurTechnology,
    Partnerships,
    Strategy,
} from 'components/Public/Home';
import FindAudiences from 'components/Public/Home/FindAudiences';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function Home() {
    const navigate = useNavigate();

    const handleNavigateIndustyOffer = (label) => {
        navigate(routesPath.industries, { state: { industryLabel: label } });
        window.scrollTo(0, 0);
    };
    return (
        <div className="flex flex-col">
            <Introduce />
            <Offer onChange={handleNavigateIndustyOffer} />
            <Partnerships />
            <Strategy />
            <NewKind />
            <OurAnalysis />
            <OurPlatform />
            <OurTechnology />
            <FindAudiences />
            <div className="bg-blue-eae py-[60px] px-5">
                <div className="mx-auto max-w-[1080px]">
                    <EducationTopic topic={'Recent Articles'} posts={POSTS} isPublic />
                </div>
            </div>

            <OurAdvertising />
        </div>
    );
}

export default Home;
