import { SVG } from 'assets/imagePath';
import { Button } from 'components/Button';
import Dropdown from 'components/Dropdown';
import { HeaderNavigate } from 'components/HeaderNavigate';
import Image from 'components/Image';
import Tabs from 'components/Tabs';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LeadLog from './LeadLog';
import LeadProfile from './LeadProfile';
import { routesPath } from 'routes/routes';
const leadTabs = [
    { label: 'Profile', value: 'profile' },
    { label: 'Log', value: 'log' },
];

const ActionArea = () => {
    return (
        <div className="flex flex-row gap-5">
            <Dropdown
                options={
                    <ul className="flex flex-col z-50 divide-y-[1px] divide-blue-cfd">
                        <li className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer">
                            <div className="flex flex-row items-center gap-[5px] flex-1">
                                <span className="text-sm text-blue-dark">Agency Account</span>
                            </div>
                        </li>
                    </ul>
                }
            >
                <Button className={'px-[15px]'} color="secondary">
                    <div className=" flex flex-row gap-[5px] items-center">
                        <div>Status</div>
                        <Image src={SVG.chevron_down} />
                    </div>
                </Button>
            </Dropdown>
            <Button type="submit" color="primary">
                Invite
            </Button>
        </div>
    );
};

function AddLeads() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const defaultConfig = urlParams.get('tab');

    const defaultTabs = leadTabs.find((item) => item.value === defaultConfig);
    const [searchParams, setSearchParams] = useSearchParams({
        tab: defaultTabs?.value,
    });

    const [tabsVal, setTabsVal] = useState(leadTabs[0]);

    const renderContent = () => {
        switch (tabsVal.value) {
            case 'profile':
                return <LeadProfile />;
            case 'log':
                return <LeadLog />;

            default:
                break;
        }
    };

    useEffect(() => {
        if (!defaultTabs) {
            setSearchParams({ tab: tabsVal.value });
        }
    }, []);

    return (
        <div className="p-2.5 md:p-[30px] flex flex-col gap-[30px]">
            <Formik
                initialValues={{}}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                    }, 0);
                }}
            >
                <Form>
                    <HeaderNavigate
                        navigateTo={{ pathname: routesPath.account_organization, search: '?config=leads' }}
                        className={'mb-[30px]'}
                        detailInfo={{ detailName: 'Add Lead' }}
                        ActionArea={<ActionArea />}
                    />

                    <Tabs
                        className={'absolute'}
                        onChange={(tab) => {
                            setTabsVal(tab);
                            setSearchParams({
                                config: tab.value,
                            });
                        }}
                        tabList={leadTabs}
                        value={tabsVal.value}
                    />

                    <div className="content mt-[30px]">{renderContent()}</div>
                </Form>
            </Formik>
        </div>
    );
}

export default AddLeads;
