import clsx from 'clsx';
import Image from 'components/Image';
import { colorStyle, variantStyle } from './styles';

export const IconButton = ({
    type = 'button',
    disabled,
    color,
    className,
    source,
    variant,
    classNameImage,
    ...props
}) => {
    return (
        <button
            type={type}
            className={clsx(
                'inline-flex justify-center items-center rounded-[10px] text-sm shadow-outer p-[15px]',
                disabled ? 'opacity-25' : '',
                colorStyle(color),
                variantStyle(variant),

                className,
            )}
            disabled={disabled}
            {...props}
        >
            <Image
                className={clsx('min-w-[15px] min-h-[15px] w-full h-auto  object-contain', classNameImage)}
                src={source}
                alt="button-icon"
            />
        </button>
    );
};
