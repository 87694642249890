import { SVG } from 'assets/imagePath';
import Image from 'components/Image/Image';
import React, { useState, useEffect, useMemo, memo } from 'react';

function Pagination({ pageSize = 10, total, currentPage, onChangePageSize, onChangeCurrentPage }) {
    const calTotalPage = (count, pSize) => {
        return Math.ceil(count / pSize);
    };

    const [totalPage, setTotalPage] = useState(calTotalPage(total, pageSize));

    const arrNumber = Array.from({ length: totalPage });

    useEffect(() => {
        const totalPage = calTotalPage(total, pageSize);
        setTotalPage(totalPage);
    }, [pageSize, total]);

    const changeCurrentPage = (page) => {
        onChangeCurrentPage?.(page);
    };

    const currentRecords = useMemo(() => {
        if (currentPage === totalPage || totalPage === 0) {
            return total;
        } else {
            return currentPage * pageSize;
        }
    }, [currentPage, pageSize, total, totalPage]);

    return (
        <div className="bg-white-light  flex  justify-end p-[15px]  gap-[5px]">
            <p className="text-sm font-normal text-grey-light">
                {currentRecords} of {total} records
            </p>
            <button
                onClick={() => {
                    changeCurrentPage(currentPage - 1);
                }}
                disabled={currentPage === 1}
            >
                <Image src={SVG.chevron_left_light} />
            </button>

            <button
                onClick={() => {
                    changeCurrentPage(currentPage + 1);
                }}
                disabled={currentPage === totalPage}
            >
                <Image src={SVG.chevron_right_light} />
            </button>
        </div>
    );
}

export default memo(Pagination);
