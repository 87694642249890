import { BoxContent } from 'components/BoxContent';
import { Button } from 'components/Button';
import { CardContent } from 'components/Card';
import GridTable from 'components/GridTable';
import Text from 'components/Text';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { paymentColumn, paymentRow } from './data';
import { InputText, Select } from 'components/Input';

function PaymentsOrganization() {
    const navigate = useNavigate();
    const [blockchainWal, setBlockChainWal] = useState();

    const handleActionTable = (row, action) => {
        switch (action.value) {
            case 'view':
                navigate(routesPath.payment_detail(row.id));
                break;

            default:
                break;
        }
    };
    return (
        <>
            <div className="col">
                <div className="flex justify-between items-center mb-[20px]">
                    <Text size="18" weight="bold" className="pt-[7px]">
                        Payments
                    </Text>
                    <Button className={'px-5  text-xs'} size="medium" variant="outlined">
                        View All
                    </Button>
                </div>
                <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                    <GridTable
                        handle={handleActionTable}
                        className={'w-full table-fixed'}
                        column={paymentColumn}
                        row={paymentRow.slice(0, 5)}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-y-[30px]">
                <div className="col">
                    <BoxContent title={'FIAT Payments'}>
                        <div className="flex flex-wrap gap-5">
                            <CardContent
                                className={'w-[210px]'}
                                title={'Card Payments'}
                                content="85"
                                actionText={'View'}
                                onAction={() => navigate(routesPath.card_payments)}
                            />
                            <CardContent
                                className={'w-[210px]'}
                                title={'ACH Payments'}
                                content="24"
                                actionText={'View'}
                            />
                        </div>
                    </BoxContent>
                </div>

                <div className="col">
                    <BoxContent title={'Crypto Payments'}>
                        <div className={'flex gap-5'}>
                            <CardContent
                                className={'w-[210px]'}
                                title={'Bitcoin Payments'}
                                content="125"
                                actionText={'View'}
                                onAction={() => navigate(routesPath.blockchain_payments)}
                            />
                        </div>
                    </BoxContent>
                </div>
                <div className="col max-w-[317px] ">
                    <BoxContent title={'Blockchain Wallets'}>
                        <div className={'flex flex-col gap-[30px]'}>
                            <Select
                                placeholder="Ethereum Wallet"
                                options={[
                                    {
                                        value: 'ethereum',
                                        label: 'Ethereum Wallet',
                                    },
                                    {
                                        value: 'block',
                                        label: 'Block',
                                    },
                                ]}
                                value={blockchainWal}
                                setValueFormik={setBlockChainWal}
                                classNameText="flex-1"
                                propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                            />

                            <InputText value="0x332sAcd........33d22" readOnly />
                        </div>
                    </BoxContent>
                </div>
            </div>
        </>
    );
}

export default PaymentsOrganization;
