import { ReactComponent as Star } from 'assets/images/star.svg';
import { ReactComponent as Smile } from 'assets/images/smile_icon.svg';
import { ReactComponent as Send } from 'assets/images/send_icon.svg';
import { ReactComponent as Link } from 'assets/images/link_icon.svg';
import { ReactComponent as Close } from 'assets/images/close_icon.svg';
import { ReactComponent as Trash } from 'assets/images/trash_icon.svg';
import { ReactComponent as AccountBalance } from 'assets/images/account_balance.svg';
import { ReactComponent as CreditCard } from 'assets/images/credit_card.svg';
import { ReactComponent as Users } from 'assets/images/users.svg';
import { ReactComponent as Building } from 'assets/images/building.svg';
import { ReactComponent as Brand } from 'assets/images/brand.svg';
import { ReactComponent as Market } from 'assets/images/market.svg';
import { ReactComponent as Minus } from 'assets/images/minus.svg';
import { ReactComponent as Plus } from 'assets/images/plus_light.svg';
import { ReactComponent as ChevronDown } from 'assets/images/chevron_down.svg';
import { ReactComponent as InfoOutline } from 'assets/images/info_outlined.svg';
import { ReactComponent as Agency } from 'assets/images/agency.svg';
import { ReactComponent as Government } from 'assets/images/government.svg';
import { ReactComponent as Finance } from 'assets/images/finance.svg';
import { ReactComponent as Healthcare } from 'assets/images/healthcare.svg';
import { ReactComponent as Retail } from 'assets/images/retail.svg';
import { ReactComponent as Blockchain } from 'assets/images/blockchain.svg';
import { ReactComponent as Travel } from 'assets/images/travel.svg';
import { ReactComponent as Report } from 'assets/images/report_icon.svg';
import { ReactComponent as PlusSquare } from 'assets/images/plus_square_icon.svg';
import { ReactComponent as ChartLine } from 'assets/images/chart_line_icon.svg';
import { ReactComponent as Wallet } from 'assets/images/wallet.svg';
import { ReactComponent as PlusRounded } from 'assets/images/plus_rounded.svg';
import { ReactComponent as AiCanvaTool } from 'assets/images/ai_canva_tool.svg';

const icons = {
    star: Star,
    smile: Smile,
    send: Send,
    link: Link,
    close: Close,
    trash: Trash,
    accountBalance: AccountBalance,
    creditCard: CreditCard,
    users: Users,
    building: Building,
    brand: Brand,
    market: Market,
    plus: Plus,
    minus: Minus,
    chevronDown: ChevronDown,
    infoOutline: InfoOutline,
    agency: Agency,
    finance: Finance,
    government: Government,
    healthcare: Healthcare,
    retail: Retail,
    blockchain: Blockchain,
    travel: Travel,
    report: Report,
    chartLine: ChartLine,
    plusSquare: PlusSquare,
    plusRounded: PlusRounded,
    wallet: Wallet,
    aiCanva: AiCanvaTool,
};

function Svg({ name, sizeIcon, ...props }) {
    //child:"className" style for path
    let Icon = icons[name];
    return <Icon {...props} />;
}

export default Svg;
