import Box from 'components/Box';
import { Button } from 'components/Button';
import MediaCostLineChart from 'components/Charts/MediaCostChart/MediaCostLineChart';
import { CalculatorsModule } from 'components/GlobalModules';
import { Modal } from 'components/Modal';
import FormInputText from 'components/modules/form/FormInputText';
import FormSelect from 'components/modules/form/FormSelect';
import FormTextArea from 'components/modules/form/FormTextArea';
import ProgressCircle from 'components/ProgressCircle/ProgressCircle';
import { Svg } from 'components/Svg';
import Text from 'components/Text';
import { SubTitle } from 'components/Title';
import { Form, Formik } from 'formik';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

const timeZoneOptions = [
    {
        value: 'est',
        label: 'EST',
    },
    {
        value: 'gmt',
        label: 'GMT+7',
    },
];
function Plan() {
    const progress = 75;
    const size = 120;
    const sizeClass = '[120px]';
    const strokeWidth = 10;
    const circleOneStroke = '#f2f4f6';
    const circleTwoStroke = '#3E85F4';

    const [openModal, setOpenModal] = useState({
        isOpen: false,
        type: '',
    });

    const [isEditDataCost, setIsEditDataCost] = useState(true);

    const navigate = useNavigate();

    return (
        <>
            <Formik
                initialValues={{
                    media_cost: 1.32,
                    data_cost: 1.31,
                }}
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                    }, 0);
                }}
            >
                {(props) => (
                    <Form>
                        <div className="flex flex-col gap-y-[30px] mb-[111px]">
                            <div>
                                <SubTitle className="md:mt-0 py-[14px] mb-5">Details</SubTitle>

                                <div className="flex flex-col gap-5">
                                    <FormInputText placeholder="Name" name="name" label="Name" />

                                    <div className="flex flex-col justify-start w-full gap-5 xs:flex-row md:flex-col xl:flex-row">
                                        <FormSelect
                                            placeholder="Time Zone"
                                            propsDropdown={{ classNameBox: 'flex-1' }}
                                            placeholderTop
                                            name="time_zone"
                                            options={timeZoneOptions}
                                        />

                                        <FormInputText
                                            classNameBox="flex-1"
                                            placeholder="Start Date"
                                            name="start_date"
                                        />
                                        <FormInputText classNameBox="flex-1" placeholder="End Date" name="end_Date" />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <SubTitle className="md:mt-0 py-[14px] mb-5">Audience</SubTitle>

                                <div className="flex">
                                    <FormSelect
                                        className={'rounded-[15px] rounded-r-none'}
                                        placeholder="Select Audience"
                                        propsDropdown={{
                                            classNameBox: 'flex-1',
                                        }}
                                        placeholderTop
                                        name="audience"
                                        options={timeZoneOptions}
                                    />

                                    <Button
                                        onClick={() => {
                                            navigate(routesPath.audience_add);
                                        }}
                                        color="secondary"
                                        className="w-fit p-[15px]  rounded-l-none"
                                        shadow="outer"
                                    >
                                        <Text>New</Text>
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <SubTitle className="md:mt-0 py-[14px] mb-5">Creatives</SubTitle>

                                <div className="flex">
                                    <FormSelect
                                        className={'rounded-[15px] rounded-r-none'}
                                        placeholder="Select Creatives"
                                        propsDropdown={{
                                            classNameBox: 'flex-1',
                                        }}
                                        placeholderTop
                                        name="audience"
                                        options={timeZoneOptions}
                                    />
                                    <Button
                                        onClick={() => {
                                            navigate(routesPath.creative_add);
                                        }}
                                        color="secondary"
                                        className="w-fit p-[15px] rounded-l-none"
                                        shadow="outer"
                                    >
                                        <Text>New</Text>
                                    </Button>
                                </div>
                            </div>
                            <div>
                                <SubTitle className="md:mt-0 py-[14px] mb-5">Budget</SubTitle>

                                <div className="flex flex-col justify-start w-full gap-5 sm:flex-row lg:flex-col xl:flex-row">
                                    <FormSelect
                                        placeholder="Select Currency"
                                        propsDropdown={{
                                            classNameBox: 'flex-1',
                                        }}
                                        placeholderTop
                                        name="currency"
                                        options={timeZoneOptions}
                                    />
                                    <FormInputText classNameBox="flex-1" placeholder="Amount" name="amount" />
                                    <FormInputText
                                        PlaceHolder={
                                            <div className="flex items-center gap-[5px]">
                                                <Text color="secondary">Daily Spend Cap</Text>
                                                <Svg name="infoOutline" className="w-[15px] h-[15px]" />
                                            </div>
                                        }
                                        classNameBox="flex-1"
                                        placeholder="Budget"
                                        name="budget"
                                    />
                                </div>

                                <div className="flex flex-col sm:max-lg:flex-row xl:flex-row  justify-end gap-2.5 mt-5">
                                    <Text className="text-right" size="12">
                                        Looking to improve campaign budget? Try our optimization calculators
                                    </Text>
                                    <div className="flex justify-end gap-2.5 flex-wrap">
                                        <Text
                                            underline
                                            size="12"
                                            component="button"
                                            type="button"
                                            onClick={() =>
                                                setOpenModal({
                                                    isOpen: true,
                                                    type: 'cpm',
                                                })
                                            }
                                        >
                                            CPM Calculator
                                        </Text>
                                        <Text
                                            underline
                                            size="12"
                                            component="button"
                                            type="button"
                                            onClick={() =>
                                                setOpenModal({
                                                    isOpen: true,
                                                    type: 'cpc',
                                                })
                                            }
                                        >
                                            CPC Calculator
                                        </Text>
                                        <Text
                                            underline
                                            size="12"
                                            component="button"
                                            type="button"
                                            onClick={() =>
                                                setOpenModal({
                                                    isOpen: true,
                                                    type: 'roi',
                                                })
                                            }
                                        >
                                            ROI Calculator
                                        </Text>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <SubTitle className="md:mt-0 py-[14px] mb-5">Pricing</SubTitle>

                                <div className="flex flex-col justify-start w-full gap-[30px] sm:flex-row lg:flex-col xl:flex-row">
                                    <div className="flex-1 flex flex-col justify-center items-center gap-[15px]">
                                        <div className="flex w-full flex-row gap-[30px] items-center">
                                            <FormInputText
                                                type="number"
                                                step="0.01"
                                                className="w-full"
                                                mark="$"
                                                name="media_cost"
                                                label="Media"
                                            />
                                            <div className="rounded-full shadow-outer">
                                                <ProgressCircle
                                                    progress={progress}
                                                    size={size}
                                                    sizeClass={sizeClass}
                                                    strokeWidth={strokeWidth}
                                                    circleOneStroke={circleOneStroke}
                                                    circleColor={circleTwoStroke}
                                                    ContentValue={
                                                        <div className="flex flex-col items-center justify-center aspect-square gap-[5px]">
                                                            <div className="flex items-center px-[7px]">
                                                                <Text component="p" size="10">
                                                                    Media Cost
                                                                </Text>
                                                                <Svg name="infoOutline" className="w-[12px] h-[12px]" />
                                                            </div>
                                                            <Text size="20" weight="semibold">
                                                                ${props.values.media_cost}
                                                            </Text>
                                                            <Text component="p" size="10">
                                                                {progress}% IPM
                                                            </Text>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="flex w-full flex-row gap-[30px] items-center">
                                            <div className="relative w-full">
                                                <FormInputText
                                                    className="w-full"
                                                    mark="$"
                                                    name="data_cost"
                                                    type="number"
                                                    step="0.01"
                                                    label="Data Cost"
                                                    disabled={isEditDataCost}
                                                />

                                                <Button
                                                    color="secondary"
                                                    className={'absolute top-0 right-0 w-1/2 rounded-l-none'}
                                                    onClick={() => setIsEditDataCost(!isEditDataCost)}
                                                    disabled={!props.values.data_cost}
                                                >
                                                    {isEditDataCost ? 'Edit' : 'Save'}
                                                </Button>
                                            </div>
                                            <div className="rounded-full shadow-outer">
                                                <ProgressCircle
                                                    className={'!bg-white-light'}
                                                    progress={progress}
                                                    size={size}
                                                    sizeClass={sizeClass}
                                                    strokeWidth={strokeWidth}
                                                    circleOneStroke={'#f4f6f8'}
                                                    circleColor={'#f4f6f8'}
                                                    ContentValue={
                                                        <div className="flex  flex-col items-center justify-center aspect-square gap-[5px]">
                                                            <div className="flex items-center px-[7px]">
                                                                <Text component="p" size="10">
                                                                    Data Cost
                                                                </Text>
                                                                <Svg name="infoOutline" className="w-[12px] h-[12px]" />
                                                            </div>
                                                            <Text size="20" weight="semibold">
                                                                ${props.values.data_cost}
                                                            </Text>
                                                            <Text component="p" size="10">
                                                                2.3M Avails
                                                            </Text>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-1">
                                        <Box
                                            shadow="default"
                                            variant="outlined"
                                            className="w-full h-full bg-white-light p-[15px]"
                                        >
                                            <div>
                                                <MediaCostLineChart />
                                            </div>
                                        </Box>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <SubTitle className="md:mt-0 py-[14px] mb-5">Tracking</SubTitle>

                                <div className="flex flex-col gap-5">
                                    <div className="flex flex-col justify-start w-full gap-5 md:flex-row">
                                        <FormSelect
                                            placeholder="Click Token"
                                            propsDropdown={{
                                                classNameBox: 'flex-1',
                                            }}
                                            placeholderTop
                                            name="click_token"
                                            options={timeZoneOptions}
                                        />
                                        <FormSelect
                                            placeholder="Conversion"
                                            propsDropdown={{
                                                classNameBox: 'flex-1',
                                            }}
                                            placeholderTop
                                            name="conversion"
                                            options={timeZoneOptions}
                                        />
                                        <FormSelect
                                            placeholder="Without Consent"
                                            propsDropdown={{
                                                classNameBox: 'flex-1',
                                            }}
                                            placeholderTop
                                            name="without_consent"
                                            options={timeZoneOptions}
                                        />
                                    </div>

                                    <FormTextArea
                                        className={'h-[186px]'}
                                        iconInfo
                                        label={'Conversion pixel'}
                                        value={`!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTa`}
                                        copy
                                    />

                                    <FormTextArea
                                        className={'h-[186px]'}
                                        iconInfo
                                        label={'Landing page view pixel'}
                                        value={` !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTa`}
                                        copy
                                    />
                                </div>
                            </div>

                            <div>
                                <SubTitle className="md:mt-0 py-[14px] mb-5">Additional</SubTitle>

                                <div className="flex flex-col gap-5">
                                    <FormInputText placeholder="Campaign Info" name="campaign_info" />
                                </div>
                            </div>

                            <div className="flex flex-row">
                                <Button
                                    type="submit"
                                    className={'mr-2.5 w-full max-w-[260px]'}
                                    color="secondary"
                                    size="medium"
                                >
                                    Save Draft
                                </Button>
                                <Button className={'w-full max-w-[260px]'} size="medium" disabled>
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            <Modal
                title={'Calculators'}
                bodyClass={'w-full max-w-[382px] pb-[30px]'}
                isOpen={openModal.isOpen}
                onClose={() => setOpenModal({ isOpen: false, type: '' })}
            >
                <CalculatorsModule defaultCalculator={openModal.type} />
            </Modal>
        </>
    );
}

export default memo(Plan);
