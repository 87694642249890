import { useNavigateSearch } from 'hooks';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { routesPath } from 'routes/routes';
function Register() {
    const navigateSearch = useNavigateSearch();

    const { pathname } = useLocation();
    useEffect(() => {
        if (pathname === routesPath.register) {
            navigateSearch(routesPath.register + routesPath.primary_user, { step: 1 });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="flex px-[20px] justify-center items-center py-[120px] min-h-[100vh]  bg-blue-eae">
            <div className="max-w-[400px] w-full">
                <Outlet />
            </div>
        </div>
    );
}

export default Register;
