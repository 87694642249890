import clsx from 'clsx';
import Text from 'components/Text/Text';
import React from 'react';

function Row({ label, content, classLabel, classContent }) {
    return (
        <div className="flex flex-row justify-between h-[25px] items-center">
            <Text className={clsx(classLabel)} size="12">
                {label}
            </Text>
            <Text className={clsx(classContent)} size="12">
                {content}
            </Text>
        </div>
    );
}

export default Row;
