import { SVG } from 'assets/imagePath';
import { Button } from 'components/Button';
import Image from 'components/Image';
import { InputText } from 'components/Input';
import { useFormik } from 'formik';

function VerifyAccount() {
    const { values, handleChange, handleSubmit } = useFormik({
        initialValues: {
            confirmation_code: '',
        },
        onSubmit: (values) => {
            console.log(values);
        },
    });

    return (
        <>
            <div className="text-center font-bold text-[24px] leading-[29px] text-dark-nav mb-[30px]">
                Verify Account
            </div>
            <div className="text-center  text-[14px] leading-[21px] text-dark-nav mb-[20px]">
                Please check your email for the code or verification link sent from an adNucleus.com email address.
            </div>

            <form onSubmit={handleSubmit}>
                <div className="mb-[20px]">
                    <InputText
                        placeholder="Confirmation Code"
                        rounded
                        name="confirmation_code"
                        onChange={handleChange}
                        value={values.confirmation_code}
                    />
                </div>

                <Button
                    className="flex justify-center mx-auto w-[260px] py-[15px] px-5 mb-[30px] shadow-outer"
                    type="submit"
                >
                    Verify
                </Button>
            </form>
            <div className="text-center text-[14px] leading-[21px] text-dark-nav mb-[20px] flex gap-[7px] justify-center">
                Didn’t receive? <Image src={SVG.info_outlined} />
                <u>Resend</u>
            </div>
        </>
    );
}

export default VerifyAccount;
