import { SVG } from 'assets/imagePath';
import GridTable from 'components/GridTable';
import TableHead from 'components/GridTable/TableHead';
import Image from 'components/Image';
import Tabs from 'components/Tabs';
import { audiencesColumn, fakeRowAudiences } from 'pages/Dashboard/data';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';
// import { campaignColumn, campaignRow } from './data';

const audiencesList = [
    { label: 'All', value: 'all' },

    { label: 'Archived', value: 'archived' },
];

const Audiences = () => {
    const [tabsVal, setTabsVal] = useState(audiencesList[0]);
    const navigate = useNavigate();
    const handleActionTable = (action, row) => {
        switch (action.value) {
            case 'view':
                navigate(routesPath.audience_detail(row.id));
                break;

            default:
                break;
        }
    };
    return (
        <div className="p-2.5 md:p-[30px]">
            <div className="font-extrabold text-2xl text-blue-dark mb-[30px] py-[10.5px]">
                <h2>Audiences</h2>
            </div>

            <div className="flex flex-wrap justify-between mb-[30px] gap-y-2.5 ">
                <Tabs
                    onChange={(tab) => {
                        setTabsVal(tab);
                    }}
                    tabList={audiencesList}
                    value={tabsVal.value}
                />
            </div>

            <TableHead
                title={tabsVal.label}
                actionButton={{ label: ' Add Audience', onClick: () => navigate(routesPath.audience_add) }}
            />

            <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                <GridTable
                    className={'w-full table-fixed'}
                    column={audiencesColumn}
                    row={fakeRowAudiences}
                    handle={(action, row) => handleActionTable(row, action)}
                />

                <div className="bg-white-light  flex  justify-end p-[15px]  gap-[5px]">
                    <p className="text-sm font-normal text-grey-light">2 of 2 records</p>
                    <Image src={SVG.chevron_left_light} />
                    <Image src={SVG.chevron_right_light} />
                </div>
            </div>
        </div>
    );
};

export default Audiences;
