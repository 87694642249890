import { IMAGE } from 'assets/imagePath';

export const PARTNERS = [
    {
        id: 'partner_audiencerate',
        img: IMAGE.partner_audiencerate,
    },
    {
        id: 'partner_audigent',
        img: IMAGE.partner_audigent,
    },
    {
        id: 'partner_justtag',
        img: IMAGE.partner_justtag,
    },
    {
        id: 'partner_liveramp',
        img: IMAGE.partner_liveramp,
    },
    {
        id: 'partner_lotame',
        img: IMAGE.partner_lotame,
    },
    {
        id: 'partner_navegg',
        img: IMAGE.partner_navegg,
    },
    {
        id: 'partner_nxteck',
        img: IMAGE.partner_nxteck,
    },
    {
        id: 'partner_zeotap',
        img: IMAGE.partner_zeotap,
    },
    {
        id: 'partner_adex',
        img: IMAGE.partner_adex,
    },

    {
        id: 'partner_weborama',
        img: IMAGE.partner_weborama,
    },
    {
        id: 'partner_sidata',
        img: IMAGE.partner_sidata,
    },
    {
        id: 'partner_osdatasolutions',
        img: IMAGE.partner_osdatasolutions,
    },
];
