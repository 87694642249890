import { AnalyticReview } from 'components/Analytics';
import { doughnutStyle } from 'components/Charts/DoughnutChart/config';
import DoughnutChart from 'components/Charts/DoughnutChart/Doughnut';
import DoughnutLegend from 'components/Charts/DoughnutChart/DoughnutLegend';
import { fakeColumnCPM, fakeColumnCtr, fakeColumnImpression, fakeRowLegend } from 'pages/Dashboard/data';

function CompaniesAnalytics() {
    return (
        <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 md:grid-cols-2">
            <AnalyticReview
                className={'flex-1'}
                title={'Impressions'}
                Chart={<DoughnutChart />}
                LegendComponent={
                    <DoughnutLegend
                        headerLegend={fakeColumnImpression}
                        dataLegend={fakeRowLegend}
                        configData={doughnutStyle}
                    />
                }
            />

            <AnalyticReview
                className={'flex-1'}
                title={'CTR'}
                Chart={<DoughnutChart />}
                LegendComponent={
                    <DoughnutLegend
                        headerLegend={fakeColumnCtr}
                        dataLegend={fakeRowLegend}
                        configData={doughnutStyle}
                    />
                }
            />

            <AnalyticReview
                className={'flex-1'}
                title={'CPM & Total Cost'}
                Chart={<DoughnutChart />}
                LegendComponent={
                    <DoughnutLegend
                        headerLegend={fakeColumnCPM}
                        dataLegend={fakeRowLegend}
                        configData={doughnutStyle}
                    />
                }
            />
        </div>
    );
}

export default CompaniesAnalytics;
