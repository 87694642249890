import { SVG } from 'assets/imagePath';
import Box from 'components/Box';
import { Button } from 'components/Button';
import Image from 'components/Image';
import Checkbox from 'components/Input/Checkbox';
import UploadImage from 'components/Input/UploadImage';
import Text from 'components/Text';
import React, { useState } from 'react';

function UploadSignature() {
    return (
        <div className="flex flex-col gap-[30px] items-center ">
            <Box shadow="default" className={'max-w-[347px] w-full  h-[150px] p-[15px] bg-white-light'}>
                <UploadImage>
                    <div className="flex flex-col gap-[5px] items-center">
                        <Image className={'max-w-[32px] h-auto object-contain'} src={SVG.upload} />
                        <Text>Upload Image</Text>
                    </div>
                </UploadImage>
            </Box>
            <div className="max-w-[260px] w-full flex justify-end">
                <Checkbox Label={<Text>Save for future use</Text>} />
            </div>

            <Button className="max-w-[260px] w-full">Sign</Button>
        </div>
    );
}

export default UploadSignature;
