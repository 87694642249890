import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Dropdown from 'components/Dropdown';
import Image from 'components/Image';
import Text from 'components/Text';

const SelectList = ({ selectList, setValue, onChange, onCloseDropdown, name }) => {
    const handleOnChange = (select) => {
        setValue && setValue(name, select.value);
        onChange && onChange(select.value);
        onCloseDropdown();
    };
    return (
        <ul className="flex flex-col z-50 divide-y-[1px] divide-blue-cfd">
            {selectList.map((menuItem) => {
                return (
                    <li
                        className="flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer"
                        onClick={() => (menuItem?.onClickCustom ? menuItem?.onClickCustom() : handleOnChange(menuItem))}
                        key={menuItem.value}
                    >
                        <div className="flex flex-row items-center gap-[5px] flex-1">
                            <span className="text-sm text-blue-dark whitespace-nowrap">{menuItem.label}</span>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export const Select = ({
    name,
    options,
    placeholder = 'Select Value',
    className,
    classNameText,
    value,
    setValue,
    placeholderTop = false,
    propsDropdown,
    CustomList,
    error,
    helper,
    setValueFormik,
    ...props
}) => {
    const currentOption = options.find((option) => option.value === value);
    return (
        <>
            <Dropdown
                options={({ close }) =>
                    CustomList ? (
                        <CustomList onCloseDropdown={close} />
                    ) : (
                        <SelectList
                            selectList={options}
                            setValue={setValue}
                            onChange={setValueFormik}
                            onCloseDropdown={close}
                            name={name}
                        />
                    )
                }
                {...propsDropdown}
            >
                <div
                    className={clsx(
                        'shadow-default  rounded-[10px] w-full border-none p-[15px] flex items-center gap-[5px]',
                        value ? ' bg-white' : 'bg-white-light',
                        className,
                    )}
                >
                    {/* <div className="flex items-center gap-[5px]"> */}
                    <Text
                        color={currentOption?.label ? 'primary' : 'secondary'}
                        className={clsx('flex-1', classNameText)}
                    >
                        {currentOption?.label || placeholder}
                    </Text>
                    <div>
                        <Image src={SVG.chevron_down} alt="icon-select" />
                    </div>

                    {placeholderTop && currentOption?.label && (
                        <div
                            className={clsx(
                                'absolute top-[-10px] left-[15px] rounded-[30px] h-[15px] text-[10px] leading-[10px] py-[5px] px-[10px]  shadow-default text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                                value ? 'bg-white' : 'bg-white-light',
                            )}
                        >
                            {placeholder}
                        </div>
                    )}

                    {helper && (
                        <div
                            className={clsx(
                                'absolute bottom-[10px] left-[15px] rounded-[30px] h-[20px] text-[10px] leading-[10px] py-[5px] px-[10px] bg-white-light text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                                error ? 'text-error' : '',
                            )}
                        >
                            {helper}
                        </div>
                    )}

                    {/* </div> */}
                </div>
            </Dropdown>
            {/* <input className="" value={value} name={name} {...props} /> */}
        </>
    );
};
