export const chatContents = [
    {
        avatar: '',
        message:
            'Senectus non lobortis nisl facilisis faucibus vitae ullamcorper. Feugiat id faucibus mi elit molestie nulla quisque. Feugiat nunc sit risus gravida feugiat ut aliquet molestie.',
        timestamp: '',
        isYou: false,
        time: '10:00 AM',
    },
    {
        avatar: '',
        message:
            'Senectus non lobortis nisl facilisis faucibus vitae ullamcorper. Feugiat id faucibus mi elit molestie nulla quisque. Feugiat nunc sit risus gravida feugiat ut aliquet molestie.',
        timestamp: '',
        isYou: true,
        time: '3:00 PM',
    },
];
