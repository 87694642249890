import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Box from 'components/Box';
import { Button } from 'components/Button';
import Dropdown, { DropdownMenu } from 'components/Dropdown';
import Image from 'components/Image';
import { InputText, Select } from 'components/Input';
import Text from 'components/Text';
import React, { useState } from 'react';

function TypeSignature() {
    const [formats, setFormats] = useState({
        italic: true,
        bold: true,
        font: 'font-inter',
        label: '',
    });

    const [sign, setSign] = useState('John Wood');

    const handleChangeFormat = (value) => {
        setFormats((prev) => ({
            ...prev,
            ...value,
        }));
    };
    return (
        <div className="flex flex-col items-center gap-5 type__signatures">
            <div className="flex flex-col xs:flex-row gap-2.5 w-full">
                <div className="xs:flex-1 h-[50px] ">
                    <Dropdown
                        clientParent={{ height: 500 - 60 }}
                        classNameBox="h-full"
                        options={({ close }) => (
                            <DropdownMenu
                                onChange={(item) => {
                                    handleChangeFormat({ label: item?.label, font: item?.value });
                                    close();
                                }}
                                currentValue={formats?.font}
                                menu={[
                                    { label: 'Inter', value: 'font-inter' },
                                    { label: 'Mono', value: 'font-mono' },
                                    { label: 'Serif', value: 'font-serif' },
                                ]}
                            />
                        )}
                    >
                        <Box
                            className={'w-full  bg-white-light px-[20px] h-full  flex justify-between items-center'}
                            variant="contained"
                        >
                            <Text>{formats?.label || 'Select Font'}</Text>
                            <Image src={SVG.chevron_down} alt="icon-select" />
                        </Box>
                    </Dropdown>
                </div>
                <div className="justify-center flex flex-row gap-2.5">
                    <Button
                        className={clsx('w-[50px]', formats?.italic ? '!text-primary' : '')}
                        variant="outlined"
                        onClick={() => handleChangeFormat({ italic: !formats.italic })}
                    >
                        I
                    </Button>
                    <Button
                        className={clsx('w-[50px]', formats?.bold ? '!text-primary' : '')}
                        variant="outlined"
                        onClick={() => handleChangeFormat({ bold: !formats.bold })}
                    >
                        B
                    </Button>
                </div>
            </div>

            <InputText classNameBox={'w-full'} value={sign} onChange={(e) => setSign(e.target.value)} />

            <Box
                className={clsx(
                    'bg-white-light min-h-[100px] justify-center items-center w-full flex text-xl',
                    formats?.italic ? 'italic' : '',
                    formats?.bold ? 'font-bold' : '',
                    formats.font,
                )}
            >
                {sign}
            </Box>

            <Button className={'w-full max-w-[260px]'}>Sign</Button>
        </div>
    );
}

export default TypeSignature;
