import clsx from 'clsx';
import Text from 'components/Text';

function SubTitle({ children, className, ...props }) {
    return (
        <Text className={clsx('!text-base md:!text-lg leading-[22px] ', className)} weight="bold" {...props}>
            {children}
        </Text>
    );
}

export default SubTitle;
