import { CompaniesAgency } from 'components/AccountConfig/Companies';
import EarningAgency from 'components/AccountConfig/Earnings/EarningAgency';
import { NotificationSubCo } from 'components/AccountConfig/Notifications';
import { PaymentsSubCo } from 'components/AccountConfig/Payments';
import { ProfileAgency } from 'components/AccountConfig/Profile';
import SettingsAgency from 'components/AccountConfig/Settings/SettingsAgency';
import { StatusSubCo } from 'components/AccountConfig/Status';
import { SupportSubCo } from 'components/AccountConfig/Support';
import { UsersSubCo } from 'components/AccountConfig/Users';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import { APP_ROLE } from 'constants/auth';
import { useAuth } from 'hooks';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { isPermissionView } from 'utils';

const accountTabs = [
    { label: 'Profile', value: 'profile' },
    { label: 'Settings', value: 'settings' },
    {
        label: 'Notifications',
        value: 'notifications',
    },

    { label: 'Payments', value: 'payments' },
    { label: 'Resources', value: 'resources' },
    { label: 'Support', value: 'support' },
    { label: 'Status', value: 'status' },
    { label: 'Users', value: 'users' },
    { label: 'Companies', value: 'companies' },
    { label: 'Earnings', value: 'earnings' },
    // { label: 'Agencies', value: 'agencies',  },
    // { label: 'Leads', value: 'leads',  },
    // { label: 'QA/QC', value: 'qaQc',  },
];

function AccountConfigAgency() {
    const queryString = window.location.search;

    const navigate = useNavigate();
    const urlParams = new URLSearchParams(queryString);
    const defaultConfig = urlParams.get('config');

    const defaultTabs = accountTabs.find((item) => item.value === defaultConfig);
    const [searchParams, setSearchParams] = useSearchParams({
        config: defaultTabs?.value,
    });

    const locationState = useLocation()?.state;

    // const defaultTabs = accountTabs.find((item) => item.value === !!locationState?.config);

    const [tabsVal, setTabsVal] = useState(defaultTabs || accountTabs[0]);

    const renderContent = () => {
        switch (tabsVal.value) {
            case 'profile':
                return <ProfileAgency />;
            case 'settings':
                return <SettingsAgency />;
            case 'notifications':
                return <NotificationSubCo />;
            case 'payments':
                return <PaymentsSubCo />;
            case 'support':
                return <SupportSubCo />;
            case 'status':
                return <StatusSubCo />;
            case 'users':
                return <UsersSubCo />;
            case 'companies':
                return <CompaniesAgency />;
            case 'earnings':
                return <EarningAgency />;
            default:
                break;
        }
    };

    const { Ag, SubCo, Org } = APP_ROLE;

    if (!isPermissionView([Ag, Org])) {
        navigate(routesPath.dashboard);
    }
    useEffect(() => {
        if (!defaultTabs) {
            setSearchParams({ config: tabsVal.value });
        }
    }, []);

    return (
        <div className="p-2.5 md:p-[30px] flex flex-col gap-[30px]">
            <Text className="py-[10.5px]" size="24" weight="bold">
                Agency Account
            </Text>
            <Tabs
                className={'absolute'}
                onChange={(tab) => {
                    setTabsVal(tab);
                    setSearchParams({
                        config: tab.value,
                    });
                }}
                tabList={accountTabs}
                value={tabsVal.value}
            />

            {renderContent()}
        </div>
    );
}

export default AccountConfigAgency;
