import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import { Line } from 'react-chartjs-2';
import { currencyFormat } from 'utils';
import { customTooltips } from 'utils/chartjs';
import { mediaData, labels } from './fakeData';

export const defaultStyleData = {
    fill: false,
    lineTension: 0.3,
    borderCapStyle: 'butt',
    borderWidth: 1,
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointRadius: 0,
    pointStyle: 'circle',
    pointBackgroundColor: 'transparent',
    pointHoverBorderWidth: 3,
};

const fakeData = {
    labels,
    datasets: [
        {
            ...defaultStyleData,
            label: 'Media Cost',
            backgroundColor: '#0E65F1',
            borderColor: '#0E65F1',
            data: mediaData,
        },
    ],
};

const CustomTooltip = ({ datasets }) => {
    return /*html*/ `<div style="background: white; padding: 5px; border-radius: 5px; font-size: 10px;box-shadow: -2px 3px 5px 0px rgba(66, 80, 109, 0.10);">
            <p>${datasets?.dataset?.label}:${' '} 
            <span style="font-weight: bold; color: red">${currencyFormat({
                num: datasets.raw,
                fixed: 2,
                positionCurrency: 'left',
                currencySymbols: '$',
            })}</span> | Avails: <span style="font-weight: bold;">12.5M</span>  </p>
        </div>`;
};

const exampleOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
        intersect: false,
    },
    plugins: {
        tooltip: {
            yAlign: 'bottom',
            displayColors: false,
            backgroundColor: 'white',
            titleAlign: 'center',
            titleFont: {
                color: 'black',
            },
            titleColor: 'black',
            titleMarginBottom: 0,
            enabled: false,
            position: 'nearest',
            caretPadding: 10,
            external: (context) =>
                customTooltips(context, ({ dataFromCurrentElement }) =>
                    CustomTooltip({ datasets: dataFromCurrentElement }),
                ),
        },
    },
    scales: {
        y: {
            grid: {
                display: false,
            },
            beginAtZero: true,
            type: 'linear',

            suggestedMin: 0,
            suggestedMax: 10,
            ticks: {
                callback: function (value, index, ticks) {
                    return currencyFormat({ num: value, fixed: 2, currencySymbols: '$', positionCurrency: 'left' });
                },
                stepSize: 5,
                font: {
                    size: 10,
                },
                color: '#1D2533',
                padding: 0,
            },
        },
        x: {
            grid: {
                display: true,
            },
        },
    },
};
function MediaCostLineChart({ data = fakeData, customTitle }) {
    return (
        <div className="flex flex-col w-[99.9%] h-full max-h-[170px]  gap-[5px] mb-5 xs:mb-0">
            {customTitle ? (
                customTitle
            ) : (
                <div className="flex xs:flex-row flex-col gap-2.5 justify-between">
                    <Text weight="bold" size="12">
                        Media CPM per impressions (in %)
                    </Text>

                    <div className="flex gap-[5px] items-center">
                        <div className="w-[10px] h-[10px] bg-primary rounded-full" />
                        <Text component="p" size="12">
                            Media Cost
                        </Text>
                        <Svg name="infoOutline" />
                    </div>
                </div>
            )}
            <>
                <Line data={data} options={exampleOptions} />
            </>
        </div>
    );
}

export default MediaCostLineChart;
