import { Range, Select } from 'components/Input';
import { useField } from 'formik';
import React from 'react';

function FormRange(props) {
    const [field, meta, helpers] = useField(props);

    const { setValue: setValueFormik } = helpers;
    return <Range helper={meta.error} error={meta.error} setValueFormik={setValueFormik} {...field} {...props} />;
}

export default FormRange;
