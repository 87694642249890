import { Checkbox, RadioSquare, Select } from 'components/Input';
import { useField } from 'formik';
import React from 'react';

function FormRadioSquare(props) {
    const [field, meta, helpers] = useField(props);

    const { setValue: setValueFormik } = helpers;
    return <RadioSquare helper={meta.error} error={meta.error} setValueFormik={setValueFormik} {...field} {...props} />;
}

export default FormRadioSquare;
