import { useState } from 'react';

import { Button } from 'components/Button';
import { Svg } from 'components/Svg';
import Tabs from 'components/Tabs/Tabs';
import Text from 'components/Text/Text';
import { usePaymentModule } from 'context/ui/PaymentModuleContext';
import { BalanceMethod, PaymentHistory } from './Payment';
import PaymentDetail from './Payment/PaymentDetail';
import PaymentMethod from './Payment/PaymentMethod';
import { useViewport } from 'context/ViewportContext';
import Dropdown from 'components/Dropdown/Dropdown';
import { DropdownMenu } from 'components/Dropdown';
import Image from 'components/Image/Image';
import { SVG } from 'assets/imagePath';
import { BREAKPOINT } from 'constants/breakpoints';

function PaymentModule({ paymentDetail }) {
    const paymentTabs = [
        { label: 'Payment', value: 'payment' },
        { label: 'Balance', value: 'balance' },
        { label: 'Payment history', value: 'history' },
    ];

    const { step, tab, setTab } = usePaymentModule();

    const onGetMethod = (tab) => {
        setTab(tab);
    };

    const renderMethodContainer = () => {
        switch (tab.value) {
            case 'payment':
                return <PaymentMethod />;
            case 'balance': {
                return <BalanceMethod />;
            }
            case 'history':
                return <PaymentHistory />;

            default:
        }
    };
    const { width } = useViewport();

    return (
        <div className="flex flex-col gap-5 mt-5">
            <div className="flex flex-col items-center justify-between gap-5 sm:flex-row">
                {width >= BREAKPOINT.sm ? (
                    <Tabs onChange={onGetMethod} tabList={paymentTabs} value={tab.value} />
                ) : (
                    <Dropdown
                        options={({ close }) => (
                            <DropdownMenu
                                menu={paymentTabs}
                                onChange={(menuValue) => {
                                    onGetMethod(menuValue);
                                    close();
                                }}
                            />
                        )}
                        classNameBox={'w-full'}
                    >
                        <div className="rounded-[10px] shadow-raise border-2 border-blue-ebe bg-blue-ebe flex flex-row justify-between w-full items-center gap-2.5 h-[50px] px-[15px]">
                            <Text>{tab.label}</Text>
                            <Image src={SVG.chevron_down} />
                        </div>
                    </Dropdown>
                )}

                <Button className="max-sm:w-full" variant="outlined">
                    <div className="flex flex-row items-center gap-2.5">
                        <Svg className="child:fill-black" name="plus" />
                        <Text>Top Up Balance</Text>
                    </div>
                </Button>
            </div>

            {(tab.value !== 'history' || step === 'history') && step !== 'completed' && (
                <PaymentDetail detailType={tab.value} row={paymentDetail} />
            )}

            {renderMethodContainer()}
        </div>
    );
}

export default PaymentModule;
