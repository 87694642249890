import Text from 'components/Text';
import React from 'react';

function DeactivateAccount() {
    return (
        <div className="box">
            <Text className="pt-7  mb-5" size="18" weight="bold">
                Deactivate Account
            </Text>
            <div className="flex flex-col gap-2.5">
                <Text component="p" size="14">
                    Would you like to deactivate account and withdraw consent to Terms & Conditions?
                </Text>
                <Text component="p" size="14">
                    This account contains:
                </Text>
                <Text component="span" size="14">
                    <Text component="span" size="14" weight="bold">
                        134{' '}
                    </Text>
                    Campaign
                </Text>
                <Text component="span" size="14">
                    <Text component="span" size="14" weight="bold">
                        32{' '}
                    </Text>
                    Audiences
                </Text>
                <Text component="span" size="14">
                    <Text component="span" size="14" weight="bold">
                        243{' '}
                    </Text>
                    Creatives
                </Text>
                <Text component="p" size="14">
                    Deleting your account will remove all the content associated with it. <br />
                    Would you like to transfer ownership of content above before deleting?
                </Text>
                <Text component="p" size="14">
                    Would you like to deactivate account and withdraw consent to Terms & Conditions?
                </Text>

                <div className="flex flex-col xs:flex-row gap-5">
                    <Text component="a" className="underline underline-offset-[3px]  cursor-pointer">
                        Yes, Transfer ownership
                    </Text>
                    <Text
                        component="a"
                        className="underline underline-offset-[3px]  cursor-pointer"
                        onClick={() => console.log('object')}
                    >
                        No, just delete my account
                    </Text>
                </div>
            </div>
        </div>
    );
}

export default DeactivateAccount;
