import { BoxContent } from 'components/BoxContent';
import { InputText, Select } from 'components/Input';
import Text from 'components/Text';
import { useFormik } from 'formik';
import { useState } from 'react';
import CreateForm from './CreateForm';
import UploadAvatar from './UploadAvatar';
const countryOptions = [
    {
        value: 'personal',
        label: 'Personal',
    },
    {
        value: 'business',
        label: 'Business',
    },
];
function ProfileAgency({ agencySuggest = 'Magic Media (234242)' }) {
    const [isPopulateFields, setIsPopulateFields] = useState(false);
    const [image, setImage] = useState('');

    const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            console.log(values);
            // navigateSearch(pathname, { step: '2' });
        },
        // validationSchema: createAccountSchema,
    });

    return (
        <div className="max-w-[820px]">
            {!isPopulateFields && (
                <BoxContent className={'mb-[30px]'} title={'Profile'}>
                    <Text>Would you like to use {agencySuggest} company details for the agency?</Text>
                    <Text component="button" underline onClick={() => setIsPopulateFields(true)}>
                        Populate fields details
                    </Text>
                    <CreateForm createFormSuggest={agencySuggest} />
                </BoxContent>
            )}
            <Text className="mb-5 pt-7" size="18" weight="bold">
                Profile
            </Text>

            <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-y-[20px]">
                    <div className="flex flex-start items-center gap-[20px]">
                        <InputText
                            onChange={handleChange}
                            value={values.agency_name}
                            error={errors.agency_name}
                            classNameBox={'flex-1'}
                            name="agency_name"
                            placeholder={'Agency Name'}
                            rounded
                        />

                        <UploadAvatar image={image} setImage={setImage} label={'Change Profile Image'} />
                    </div>

                    <div className="flex flex-col sm:flex-row flex-start items-center gap-[20px]">
                        <Select
                            placeholder="Country"
                            options={countryOptions}
                            setValue={setFieldValue}
                            value={values.country}
                            classNameText="flex-1"
                            name="country"
                            propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                        />
                        <Select
                            placeholder="City"
                            options={countryOptions}
                            setValue={setFieldValue}
                            value={values.city}
                            classNameText="flex-1"
                            propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                            name="city"
                        />
                        <InputText
                            onChange={handleChange}
                            value={values.zip}
                            error={errors.zip}
                            classNameBox="flex-1"
                            name="zip"
                            placeholder={'ZIP'}
                            rounded
                        />
                    </div>
                    <div>
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.agency_address}
                            error={errors.agency_address}
                            name="agency_address"
                            placeholder={'Agency Address'}
                            rounded
                        />
                    </div>

                    <div className="flex flex-col sm:flex-row flex-start items-center gap-[20px]">
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.agency_email_address}
                            error={errors.agency_email_address}
                            name="agency_email_address"
                            placeholder={'Agency Email Address'}
                            rounded
                        />
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.agency_phone_number}
                            error={errors.agency_phone_number}
                            name="agency_phone_number"
                            placeholder={'Agency Phone Number'}
                            rounded
                        />
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.agency_website}
                            error={errors.agency_website}
                            name="agency_website"
                            placeholder={'Agency Website'}
                            rounded
                        />
                    </div>

                    <InputText
                        classNameBox="flex-1"
                        onChange={handleChange}
                        value={values.agency_registration_number}
                        error={errors.agency_registration_number}
                        name="agency_ein_number"
                        placeholder={'Agency EIN Number'}
                        rounded
                    />

                    <Select
                        placeholder="Primary contact"
                        options={countryOptions}
                        setValue={setFieldValue}
                        value={values.primary_contact}
                        classNameText="flex-1"
                        propsDropdown={{ classNameBox: 'flex-1' }}
                        name="primary_contact"
                    />

                    <InputText
                        onChange={handleChange}
                        value={values.bio}
                        error={errors.bio}
                        classNameBox="flex-1"
                        name="bio"
                        placeholder={'Bio'}
                        rounded
                    />
                </div>
            </form>
        </div>
    );
}

export default ProfileAgency;
