import { ArcElement, Chart as ChartJS } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { doughnutStyle } from './config';

ChartJS.register(ArcElement);

function DoughnutChart({ data = [75, 120, 58, 63, 12] }) {
    const dataConfig = {
        datasets: [
            {
                label: '# of Votes',
                data: data,
                ...doughnutStyle,
            },
        ],
    };
    return (
        <Doughnut
            updateMode="resize"
            className="mx-auto max-w-[150px] max-h-[150px] shadow-outer rounded-full"
            data={dataConfig}
        />
    );
}

export default DoughnutChart;
