import React from 'react';
import { SubTitle } from 'components/Title';
import { CardContent } from 'components/Card';

function CampaignBlock() {
    return (
        <div className="flex flex-col gap-5">
            <SubTitle>Campaign</SubTitle>

            <div className="grid grid-cols-1 xxs:grid-cols-2 grid-flow-row gap-5 lg:grid-cols-4">
                <CardContent className={'flex-1 min-w-max'} title={'Audience'} content="#2323" actionText={'View'} />
                <CardContent className={'flex-1 min-w-max'} title={'Creative'} content="#2323" actionText={'View'} />

                <CardContent className={'flex-1 min-w-max'} title={'Created'} content="22 Feb" actionText={'View'} />

                <CardContent
                    className={'flex-1 min-w-max'}
                    title={'Last Updated'}
                    content="22 Feb"
                    actionText={'View'}
                />
            </div>
        </div>
    );
}

export default CampaignBlock;
