import { SVG } from 'assets/imagePath';

export const offerItems = [
    {
        icon: SVG.agency,
        label: 'Agencies',
    },
    {
        icon: SVG.finance,
        label: 'Finance',
    },
    {
        icon: SVG.government,
        label: 'Government',
    },
    {
        icon: SVG.healthcare,
        label: 'Healthcare',
    },
    {
        icon: SVG.retail,
        label: 'Retail',
    },
    {
        icon: SVG.blockchain,
        label: 'Blockchain',
    },
    {
        icon: SVG.travel,
        label: 'Travel',
    },
];
