import { IMAGE, SVG } from "assets/imagePath";

export const libCol = [
    {
        field: 'id',
        headerName: 'Id',
    },
    {
        field: 'preview',
        headerName: 'Preview',
    },

    {
        field: 'format',
        headerName: 'Format',
    },
    {
        field: 'size',
        headerName: 'Size',
    },
    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row) => (
            <button className="flex justify-center items-center p-0 md:p-[15px]">
                <img className="mx-auto object-contain cursor-pointer" src={SVG.more_vert} alt="more" />
            </button>
        ),
    },
];

export const libRow = [
    {
        id: "1234",
        preview: IMAGE.lib_image,
        name: "Name",
        format: "Format",
        size: "35 Kb"
    },
     {
        id: "1234",
        preview: IMAGE.lib_image,
        name: "Name",
        format: "Format",
        size: "35 Kb"
    }, {
        id: "1234",
        preview: IMAGE.lib_image,
        name: "Name",
        format: "Format",
        size: "35 Kb"
    },
    
]