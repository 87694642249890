import clsx from 'clsx';
import Box from 'components/Box/Box';
import Text from 'components/Text/Text';
import { useRef } from 'react';

const ToolTip = ({ children, tooltip, parentId = '', className }) => {
    const tooltipRef = useRef(null);
    const container = useRef(null);

    const parenRef = document.getElementById(parentId);

    return (
        <div
            ref={container}
            onMouseEnter={({ clientX, clientY }) => {
                if (!tooltipRef.current || !container.current) return;

                const { offsetWidth: widthTooltip, offsetHeight: heightTooltip } = tooltipRef.current;

                if (parenRef) {
                    const { left: parentX, top: parentY } = parenRef.getBoundingClientRect();
                    const {
                        offsetHeight: heightParent,
                        offsetWidth: widthParent,
                        style: { paddingLeft: plParent, paddingRight: prParent, padding },
                    } = parenRef;

                    const clientYWithParent = clientY - parentY;
                    const clientXWithParent = clientX - parentX;

                    if (clientYWithParent + heightTooltip + 40 >= heightParent) {
                        //Check overflow bottom
                        //PaddingY
                        tooltipRef.current.style.top = -20 + 'px';
                        // tooltipRef.current.style.top = 0 + 'px';
                    }

                    if (clientXWithParent + widthTooltip + plParent + prParent >= widthParent) {
                        //Check overflow bottom
                        //PaddingY
                        tooltipRef.current.style.left = -widthTooltip + 'px';
                        // tooltipRef.current.style.top = 0 + 'px';
                    }
                } else {
                    //Tooltip  in window
                    const { left, top } = container.current.getBoundingClientRect();
                    tooltipRef.current.style.left = clientX - left + 'px';
                }
                // const { left, top } = container.current.getBoundingClientRect();
                // tooltipRef.current.style.left = clientX - left + 'px';
            }}
            className={clsx('relative inline-block w-fit max-w-full  group', className)}
        >
            {children}
            {tooltip ? (
                <Box
                    shadow="light"
                    variant="outlined"
                    ref={tooltipRef}
                    className="absolute z-50 invisible p-1 mt-2 text-white transition bg-white rounded opacity-0 w-fit group-hover:visible group-hover:opacity-100 whitespace-nowrap"
                >
                    <Text size="12"> {tooltip}</Text>
                </Box>
            ) : null}
        </div>
    );
};

export default ToolTip;
