export const doughnutStyle = {
    backgroundColor: [
        'rgba(14, 101, 241, 1)',
        'rgba(14, 101, 241, 0.8)',
        'rgba(14, 101, 241, 0.6)',
        'rgba(14, 101, 241, 0.4)',
        'rgba(14, 101, 241, 0.2)',
    ],
    borderColor: '#F4F6F8',
    borderWidth: 3,
    cutout: '30%',
};
