import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Chip from 'components/Chip/Chip';
import { Circle } from 'components/Circle';
import Dropdown from 'components/Dropdown';
import Image from 'components/Image';
import { Svg } from 'components/Svg';
import Text from 'components/Text';
import useDebounce from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';

const SelectList = ({ options, selected, onChange, onCloseDropdown }) => {
    return (
        <ul className="flex flex-col z-50 divide-y-[1px] divide-blue-cfd">
            {options.map((menuItem) => {
                const active = selected.some((item) => item?.value === menuItem.value);
                return (
                    <li
                        className={clsx(
                            'flex flex-row  items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer',
                            active ? 'bg-blue-ebe' : '',
                        )}
                        onClick={() => {
                            onChange(menuItem);
                        }}
                    >
                        <div className="flex flex-row items-center gap-[5px] flex-1">
                            <span className="text-sm text-blue-dark whitespace-nowrap">{menuItem.label}</span>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

const ChipValue = ({ icon, select, onRemove, className }) => {
    return (
        <Chip className={clsx('!p-[2.5px]', className)} color="white">
            <div className="flex flex-row gap-[5px] items-center">
                {icon && (
                    <Circle className={'bg-success'}>
                        <Svg name="plus" className="child:fill-white" />
                    </Circle>
                )}
                <Text className="whitespace-nowrap" size="12">
                    {select.label}
                </Text>
                <Svg
                    name="close"
                    className="child:fill-black max-w-[15px] cursor-pointer"
                    onClick={(e) => {
                        e.stopPropagation();
                        onRemove();
                    }}
                />
            </div>
        </Chip>
    );
};

function SelectMulti({
    className,
    placeholder,
    helper,
    error,
    options,
    classNameText,
    setValueFormik,
    restDropdown,
    chipProps,
    renderSelected,
    renderSelectList,
}) {
    const [selected, setSelected] = useState([]);

    const removeSelected = (menuItem) => {
        let selectedUpdate = [...selected];
        selectedUpdate = selectedUpdate.filter((item) => item.value !== menuItem.value);
        setSelected(selectedUpdate);
    };

    const handleOnChange = (menuItem) => {
        let selectedUpdate = [...selected];
        const isSelected = selectedUpdate.some((item) => item.value === menuItem.value);
        if (isSelected) {
            removeSelected(menuItem);
        } else {
            selectedUpdate.push(menuItem);
            setSelected(selectedUpdate);
        }
    };

    const debounceVal = useDebounce(selected);

    useEffect(() => {
        setValueFormik && setValueFormik(debounceVal);
    }, [debounceVal]);

    return (
        <Dropdown
            options={({ close }) =>
                renderSelectList ? (
                    renderSelectList({ close, setSelected, selected })
                ) : (
                    <SelectList
                        selected={selected}
                        onChange={handleOnChange}
                        onCloseDropdown={close}
                        options={options}
                    />
                )
            }
            {...restDropdown}
        >
            <div
                className={clsx(
                    'shadow-default mt-2.5  rounded-[10px] w-full border-none px-[15px] py-2.5 h-[50px] flex items-center gap-[5px]',
                    selected.length === 0 ? 'bg-white-light' : 'bg-white',
                    className,
                )}
            >
                {selected.length !== 0 && (
                    <div className="absolute top-[3px] left-[15px] rounded-[30px]  text-[10px] leading-[15px] px-[10px] bg-white shadow-default text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]">
                        {placeholder}
                    </div>
                )}
                <div className={clsx('flex-1 w-full', classNameText)}>
                    {(selected.length !== 0 && (
                        <div className="flex flex-row gap-2.5 w-[99%] flex-wrap overflow-auto">
                            {selected.map((select) => {
                                if (renderSelected) {
                                    return renderSelected(select, removeSelected(select));
                                } else {
                                    return (
                                        <ChipValue
                                            key={select.value}
                                            select={select}
                                            onRemove={() => removeSelected(select)}
                                            {...chipProps}
                                        />
                                    );
                                }
                            })}
                        </div>
                    )) || <Text>{placeholder}</Text>}
                </div>
                <div>
                    <Image src={SVG.chevron_down} alt="icon-select" />
                </div>

                {helper && (
                    <div
                        className={clsx(
                            'absolute bottom-[10px] left-[15px] rounded-[30px] h-[20px] text-[10px] leading-[10px] py-[5px] px-[10px] bg-white-light text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                            error ? 'text-error' : '',
                        )}
                    >
                        {helper}
                    </div>
                )}

                {/* </div> */}
            </div>
        </Dropdown>
    );
}

export default SelectMulti;
