import { IMAGE } from 'assets/imagePath';
import clsx from 'clsx';
import { Button } from 'components/Button';
import Image from 'components/Image/Image';
import Text from 'components/Text/Text';
import { useFormikContext } from 'formik';
import { setContrast } from 'utils';
import Holder from '../Holder';

function Leaderboard({ className }) {
    const { values } = useFormikContext();

    const { background, logo, title, subTitle, callAction, base, accent } = values;
    console.log('🚀 ~ file: Leaderboard.jsx:14 ~ Leaderboard ~ values:', values);
    const { isBrightness } = setContrast(base);

    const { isBrightness: isBrightnessAccent } = setContrast(accent);

    return !background?.img?.preview ? (
        <Holder
            className={clsx('flex flex-col gap-[5px] w-full', className)}
            title={'Leaderboard'}
            ratio={{ x: 729, y: 90 }}
        />
    ) : (
        <>
            <div className={clsx('flex flex-col gap-[5px]', className)}>
                <Text>Leaderboard (729x90)</Text>
                <div
                    style={{ aspectRatio: 729 / 90 }}
                    className={clsx('overflow-hidden relative w-full max-h-max bg-white')}
                >
                    <Image className={'object-cover object-center w-[729px] h-[90px]'} src={background?.img?.preview} />

                    <div
                        style={{
                            backgroundColor: base || '#0E65F1',
                        }}
                        className="absolute max-w-[71%] w-full h-full clip polygon c-75 top-0 left-0"
                    >
                        <div className="flex flex-row items-center gap-5 p-[10px]">
                            <div className="min-w-[70px] h-[70px]">
                                <Image
                                    src={logo?.img?.preview || IMAGE.adcanvas_logo_default}
                                    className={'object-contain rounded-full w-[70px] h-[70px]'}
                                />
                            </div>

                            <div className={clsx('flex flex-col gap-[5px] pr-10')}>
                                <Text
                                    style={{ fontSize: '16px' }}
                                    className={clsx(isBrightness ? 'text-black' : 'text-white', title.font)}
                                    weight="bold"
                                >
                                    {title?.text || ''}
                                </Text>
                                <Text
                                    style={{
                                        fontSize: '12px',
                                    }}
                                    className={clsx(isBrightness ? 'text-black' : 'text-white', subTitle.font)}
                                >
                                    {subTitle?.text || ''}
                                </Text>
                            </div>
                        </div>
                    </div>

                    <Button
                        style={{
                            background: accent || '#E39115',
                            fontSize: `${callAction?.size}px`,
                        }}
                        size="none"
                        className={clsx(
                            '!shadow-none absolute right-[15px] bottom-[15px] uppercase rounded-full py-[5px] !px-2.5 font-bold',
                            callAction.font,
                            isBrightnessAccent ? '!text-black' : '!text-white',
                        )}
                    >
                        {callAction?.text || 'Action'}
                    </Button>
                </div>
            </div>
        </>
    );
}

export default Leaderboard;
