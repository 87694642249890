import { SVG } from 'assets/imagePath';
import { InputText } from 'components/Input';
import useDebounce from 'hooks/useDebounce';
import { useEffect, useState } from 'react';

function SearchBox({ onChange, ...rest }) {
    const [searchValue, setSearchValue] = useState('');

    const debounceVal = useDebounce(searchValue);
    const handleSearch = (e) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        onChange?.(debounceVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounceVal]);

    return (
        <InputText
            className={'shadow-inner rounded-[10px] !w-full md:w-60'}
            icon={SVG.search}
            placeholder="Search"
            value={searchValue}
            onChange={handleSearch}
            {...rest}
        />
    );
}

export default SearchBox;
