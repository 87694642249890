import { BoxLabelOutline } from 'components/BoxLabel';
import { Button } from 'components/Button';
import ReviewBlock from 'components/Campaign/Order/ReviewBlock';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import FormCheckboxSquare from 'components/modules/form/FormCheckboxSquare';
import FormInputText from 'components/modules/form/FormInputText';
import FormRadioSquare from 'components/modules/form/FormRadioSquare';
import FormSelect from 'components/modules/form/FormSelect';
import FormUploadVideo from 'components/modules/form/FormUploadVideo';
import { formatBytes, getTypeFile } from 'utils';

const attachment_methods = [{ label: 'Upload', value: 'upload' }];
const video_types = [{ label: 'In-Stream', value: 'in_stream' }];
function MediaVideoDetail({ formValues, data }) {
    const { type, width, height, size } = formValues?.attachment_image || {};
    return (
        <div className="flex flex-col gap-[30px]">
            <div className="flex flex-col w-full h-full gap-5">
                <SubTitle className={'mt-7'}>Attachment</SubTitle>
                <FormSelect options={attachment_methods} name="attachment_method" placeholder="Method" placeholderTop />
                <FormUploadVideo
                    label="Attachment"
                    name="attachment_image"
                    className="h-[320px]"
                    helper={
                        formValues?.attachment_image &&
                        `Size: ${width}x${height}, Format:  ${getTypeFile(type)}, Size: ${formatBytes(size)}`
                    }
                />
            </div>
            <div className="flex flex-col gap-5">
                <div className="flex justify-between gap-5 h-[50px]">
                    <SubTitle className="self-end">Attributes</SubTitle>
                    <Button variant="outlined">Clear all</Button>
                </div>
                <FormSelect options={video_types} name="video_type" placeholder="Video Type" placeholderTop />

                <Text size="16" weight="bold" className="mt-[21px]">
                    Video targeting settings
                </Text>

                <BoxLabelOutline className={'p-[30px] !justify-start'} label={'In-Stream Ad Break type'}>
                    <div className="grid flex-row flex-wrap gap-5 xxs:grid-cols-2 xs:flex">
                        <div className="py-2.5 pl-[15px]">
                            <FormRadioSquare
                                defaultChecked
                                value={'pre_roll'}
                                name="in_stream_ad"
                                Label={<Text>PreRoll</Text>}
                            />
                        </div>
                        <div className="py-2.5 pl-[15px]">
                            <FormRadioSquare value={'mid_roll'} name="in_stream_ad" Label={<Text>MidRoll</Text>} />
                        </div>
                        <div className="py-2.5 pl-[15px]">
                            <FormRadioSquare value={'post_roll'} name="in_stream_ad" Label={<Text>PostRoll</Text>} />
                        </div>
                    </div>
                </BoxLabelOutline>
                <BoxLabelOutline className={'p-[30px] !justify-start'} label={'Player size'}>
                    <div className="grid flex-row flex-wrap gap-5 xxs:grid-cols-2 xs:flex">
                        <div className="py-2.5 pl-[15px]">
                            <FormRadioSquare
                                defaultChecked
                                value={'no_size'}
                                name="player_size"
                                Label={<Text>No Size</Text>}
                            />
                        </div>
                        <div className="py-2.5 pl-[15px]">
                            <FormRadioSquare value={'small'} name="player_size" Label={<Text>Small</Text>} />
                        </div>
                        <div className="py-2.5 pl-[15px]">
                            <FormRadioSquare value={'large'} name="player_size" Label={<Text>Large</Text>} />
                        </div>
                        <div className="py-2.5 pl-[15px]">
                            <FormRadioSquare
                                value={'extra_large'}
                                name="player_size"
                                Label={<Text>Extra Large</Text>}
                            />
                        </div>
                    </div>
                </BoxLabelOutline>
                <BoxLabelOutline className={'p-[30px] !justify-start'} label={'Min player size'}>
                    <div className="flex flex-col w-full gap-5 xxs:flex-row">
                        <FormInputText
                            classNameBox="flex-1 w-full"
                            name="min_width_player"
                            placeholder="Width px"
                            label="Width (px)"
                        />
                        <FormInputText
                            classNameBox="flex-1 w-full"
                            name="min_height_player"
                            placeholder="Height px"
                            label="Height (px)"
                        />
                    </div>
                </BoxLabelOutline>
                <BoxLabelOutline className={'p-[30px] !justify-start'} label={'API Compliance'}>
                    <div className="py-2.5 pl-[15px]">
                        <FormCheckboxSquare value="vpaid" name="vpaid" Label={<Text>VPAID</Text>} />
                    </div>
                </BoxLabelOutline>

                <div className="flex flex-col gap-5 max-w-[694px]">
                    <Text size="16" weight="bold" className="mt-[21px]">
                        Advanced format
                    </Text>

                    <Text>
                        Selecting an advanced ad format will narrow your deal reach specifically to inventory where the
                        ad is configured with the behavior, Shape and size or the selected ad format. Click on View
                        example to access the AdNucleus Format gallery.
                    </Text>

                    <Button className={'max-w-[191px] w-full text-xs'} variant="outlined">
                        Select advanced format(s)
                    </Button>
                </div>
            </div>

            <div className="max-w-[900px]">
                <ReviewBlock reviewStatus={data?.review} />
            </div>
        </div>
    );
}

export default MediaVideoDetail;
