import { Button } from 'components/Button';
import { SubTitle } from 'components/Title';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import EducationPost from './EducationPost';

function EducationTopic({ topic,  posts, isPublic }) {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col gap-5">
            <div className="flex xs:justify-between justify-center   items-center h-[50px]">
                <SubTitle className={'mt-[7px]'}>{topic}</SubTitle>
                <Button
                    className={'hidden xs:block'}
                    variant="outlined"
                    onClick={() =>
                        navigate(
                            isPublic
                                ? routesPath.public_topic_detail(topic || '123')
                                : routesPath.topic_detail(topic || '123'),
                        )
                    }
                >
                    View all
                </Button>
            </div>

            <div className="grid grid-flow-row grid-cols-1 gap-5 xs:grid-cols-2 md:grid-cols-5 lg:grid-cols-3 xl:grid-cols-5">
                {Array.isArray(posts) &&
                    posts
                        .slice(0, 5)
                        .map((post, idx) => <EducationPost isPublic={isPublic} key={post?.id || idx} post={post} />)}
            </div>
            <Button
                className={'xs:hidden block'}
                variant="outlined"
                onClick={() =>
                    navigate(
                        isPublic
                            ? routesPath.public_topic_detail(topic || '123')
                            : routesPath.topic_detail(topic || '123'),
                    )
                }
            >
                View all
            </Button>
        </div>
    );
}

export default EducationTopic;
