import clsx from 'clsx';
import Text from 'components/Text/Text';
import React from 'react';

function DropdownMenu({ menu, onChange, currentValue, labelField = 'label', className }) {
    return (
        <ul className={clsx('flex flex-col z-50 w-full divide-y-[1px] divide-blue-cfd', className)}>
            {menu.map((menuItem, idx) => {
                return (
                    !menuItem?.isHide && (
                        <li
                            key={menuItem?.id || idx}
                            className={clsx(
                                'flex flex-row  w-full items-center px-[20px] py-[11.5px] hover:bg-blue-ebe cursor-pointer',
                                menuItem?.value === currentValue ? 'bg-blue-ebe' : '',
                            )}
                            onClick={() => onChange(menuItem)}
                        >
                            <div className="flex flex-row items-center gap-[5px] w-full flex-1">
                                <Text title={menuItem[labelField]} component="a">
                                    {menuItem[labelField]}
                                </Text>
                            </div>
                        </li>
                    )
                );
            })}
        </ul>
    );
}

export default DropdownMenu;
