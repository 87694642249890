import { BoxLabelOutline } from 'components/BoxLabel';
import BoxLabel from 'components/BoxLabel/BoxLabel';
import { CardSelect } from 'components/Card';
import { Svg } from 'components/Svg';
import { SubTitle } from 'components/Title';
import { useState } from 'react';
import ACHPayment from './ACHPayment';
import CreditCardPayment from './CreditCardPayment';
import { usePaymentModule } from 'context/ui/PaymentModuleContext';

function FIATMethod() {
    const [fiatMethod, setFiatMethod] = useState('');
    const { setStep } = usePaymentModule();

    const handleOnChange = (method) => {
        setFiatMethod(method);
        setStep(method);
    };

    // const renderFiatMethod = () => {
    //     switch (fiatMethod) {
    //         case 'card':
    //             return <CreditCardPayment />;
    //         case 'ach':
    //             return <ACHPayment />;
    //         default:
    //             return <></>;
    //     }
    // };
    return (
        <div className="balance_method py-2.5">
            <SubTitle className="mb-5 mt-7">FIAT Payment</SubTitle>

            <BoxLabelOutline label="Select payment method">
                <CardSelect
                    active={fiatMethod === 'card'}
                    title={<Svg name="creditCard" sizeIcon={'48'} />}
                    subTitle="Card"
                    onClick={() => handleOnChange('card')}
                />
                <CardSelect
                    active={fiatMethod === 'ach'}
                    title={<Svg name="accountBalance" sizeIcon={'48'} />}
                    subTitle="ACH"
                    onClick={() => handleOnChange('ach')}
                />
            </BoxLabelOutline>

            {/* {renderFiatMethod()} */}
        </div>
    );
}

export default FIATMethod;
