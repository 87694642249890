import Advertising from 'components/Public/Industries/Advertising';
import { EducationTopic } from 'components/Education';
import { POSTS } from 'components/Education/data';
import { Offer } from 'components/Public/Home';
import { offerItems } from 'components/Public/Home/data';
import { Introduce, Tailored } from 'components/Public/Industries';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function Industries() {
    const location = useLocation();
    const industryLabel = location?.state?.industryLabel;
    const [offer, setOffer] = useState(industryLabel || offerItems[0].label);

    useEffect(() => {
        if (industryLabel) setOffer(industryLabel);
    }, [location]);

    return (
        <div className="flex flex-col">
            <Introduce offer={offer} />
            <Offer defaultActive={offer} onChange={setOffer} />
            <Tailored offer={offer} />
            <div className="bg-blue-eae py-[60px] px-5">
                <div className="mx-auto max-w-[1080px]">
                    <EducationTopic topic={'Recent Articles'} posts={POSTS} isPublic />
                </div>
            </div>
            <Advertising />
        </div>
    );
}

export default Industries;
