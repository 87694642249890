import { SVG } from 'assets/imagePath';
import Box from 'components/Box/Box';
import { Button } from 'components/Button';
import Row from 'components/GridTable/Row';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import LocationItem from './LocationItem';
import { DetailBox2 } from 'components/DetailBox';
import SegmentItem from './SegmentItem';

function SelectedSide({
    type,
    title,
    selectedArr,
    importBtn,
    onRemoveSelect,
    onHoverRemove,
    onChangeGeoLocation,
    onSave,
}) {
    return (
        <div className="flex flex-col w-full h-full gap-5 selected_segments">
            <SubTitle>{title}</SubTitle>

            <Box
                variant="contained"
                className="flex flex-col items-stretch justify-between flex-shrink-0 w-full h-[356px] gap-5 p-5 overflow-auto border-blue-cfd bg-white-light"
            >
                <div className="flex flex-col overflow-scroll flex-1 h-full gap-[10px]">
                    {Array.isArray(selectedArr) &&
                        selectedArr.map((selected) =>
                            type === 'SEGMENT' ? (
                                <SegmentItem item={selected} />
                            ) : (
                                <LocationItem
                                    inputProps={{ onChange: (e) => onChangeGeoLocation(e, selected.id) }}
                                    onRemove={() => onRemoveSelect(selected.id)}
                                    place={selected}
                                    onHoverRemove={onHoverRemove}
                                    {...selected}
                                />
                            ),
                        )}
                </div>
                <div className="flex items-center gap-2.5 w-full h-[100px] md:h-[50px]">
                    {/* Scrool */}
                    <Button
                        styleIcon={'w-[17px] max-w-[17px] mr-2.5'}
                        classNameText={'md:!justify-start'}
                        className={'w-full'}
                        variant="outlined"
                        startIcon={SVG.upload}
                        onClick={importBtn?.onClick}
                    >
                        <Text className="!text-sm">{importBtn?.label}</Text>
                    </Button>
                    <Button
                        styleIcon={'w-[17px] max-w-[17px]'}
                        className={'w-[50px]'}
                        variant="outlined"
                        startIcon={SVG.trash_icon}
                        onClick={() => {
                            onRemoveSelect();
                        }}
                    ></Button>
                </div>
            </Box>

            <DetailBox2
                title="Summary"
                column={[
                    { field: 'segments', headerName: 'Segments' },
                    { field: 'avails', headerName: 'Avails' },
                    { field: 'price', headerName: 'Price' },
                ]}
                row={{ segments: '2', avails: '29M', price: '$5.54' }}
            />

            <Button onClick={onSave}>Save Segments</Button>
        </div>
    );
}

export default SelectedSide;
