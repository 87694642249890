import { fetchRelativeLocation } from './maps';

export const requestGPSPermission = (onSuccess, onError) => {
    return navigator.geolocation.getCurrentPosition(
        function (position) {
            // Success: User granted permission, and we have the location data
            let lat = position.coords.latitude;
            let lng = position.coords.longitude;

            onSuccess({
                lat,
                lng,
            });
        },
        async function (error) {
            // Error handling in case user denies permission or there's an issue
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    try {
                        //Try get relative Location by third library
                        const res = await fetchRelativeLocation();
                        const location = res.loc.split(',');
                        onSuccess({ lat: parseFloat(location[0]), lng: parseFloat(location[1]) });
                    } catch (error) {
                        console.log('User denied the request for Geolocation.');
                    }
                    break;
                case error.POSITION_UNAVAILABLE:
                    console.log('Location information is unavailable.');
                    break;
                case error.TIMEOUT:
                    console.log('The request to get user location timed out.');
                    break;
                case error.UNKNOWN_ERROR:
                    console.log('An unknown error occurred.');
                    break;

                default:
                    return;
            }

            onError(error);
        },
    );
};
