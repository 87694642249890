import React, { forwardRef, useId, useRef, useState } from 'react';

function InputColor({ children, onChange, onChangeComplete }, ref) {
    const id = useId();

    const [color, setColor] = useState('#000');

    const handleOnChange = (color) => {
        setColor(color);
        if (!onChangeComplete) {
            onChange?.(color);
        }
    };

    const inputRef = useRef(null);

    return (
        <label className="relative" htmlFor={id}>
            {children}
            <input
                onChange={(e) => handleOnChange(e.target.value)}
                onBlur={() => {
                    onChangeComplete?.(color);
                }}
                pattern="#[a-f0-9]{6}"
                className="absolute top-0 opacity-0"
                ref={inputRef}
                type="color"
                id={id}
            />
        </label>
    );
}

export default forwardRef(InputColor);
