import { IMAGE, SVG } from 'assets/imagePath';
import Image from 'components/Image';
import Text from 'components/Text';

const OurPlatform = () => {
    return (
        <section className="w-full container mx-auto pt-[30px] px-[20px] xs:pt-[100px]">
            <div className="flex justify-center items-center gap-2.5 md:gap-[60px] xl:gap-[140px] flex-col-reverse xl:flex-row-reverse mx-[12px]">
                <div className="left">
                    <div className="font-bold text-lg sm:text-xl  leading-[30px] text-dark-nav font-sen">
                        Easily create and optimize <br className="hidden xl:block" /> campaigns with our platform
                    </div>
                    <Text className="xl:max-w-[465px] mt-[20px] text-xs sm:text-sm leading-[21px] text-gray">
                        Deliver exceptional campaigns that offer the most engaging customer experience with AdNucleus.
                        Our platform is built on the three core pillars of programmatic advertising—planning, executing,
                        and analyzing. With an open and flexible DSP, media teams can be more productive and drive the
                        best results for their clients.
                    </Text>
                    <div className="item">
                        <div className="flex items-center gap-[15px] mt-[30px]">
                            <Image
                                src={SVG.strategy_tool}
                                className="p-[5px] h-[37px] w-[37px] rounded-[50px] bg-white shadow-outer "
                            />

                            <div className="font-bold text-sm sm:text-base leading-[24px] text-dark-nav">
                                Cross-Channel Targeting Tool
                            </div>
                        </div>
                        <Text className="xl:max-w-[465px] mt-[20px] text-xs sm:text-sm leading-[21px] text-gray">
                            Leverage our in-depth creative consultation services to align your creative assets with your
                            campaign's key performance indicators (KPIs), and optimize your ad creation based on the
                            specific platform capabilities.
                        </Text>
                    </div>

                    <div className="item">
                        <div className="flex items-center gap-[15px] mt-[30px]">
                            <Image
                                src={SVG.audience}
                                className="p-[5px] h-[37px] w-[37px] rounded-[50px] bg-white shadow-outer "
                            />

                            <div className="font-bold text-sm sm:text-base leading-[24px] text-dark-nav">
                                Inventory Marketplace
                            </div>
                        </div>
                        <Text className="xl:max-w-[465px] mt-[20px] text-xs sm:text-sm text-gray">
                            Gain access to high-quality, scaled supply across all channels where your audiences consume
                            media.
                        </Text>
                    </div>

                    <div className="item">
                        <div className="flex items-center gap-[15px] mt-[30px]">
                            <Image
                                src={SVG.marketplace}
                                className="p-[5px] h-[37px] w-[37px] rounded-[50px] bg-white shadow-outer "
                            />

                            <div className="text-sm font-bold sm:text-base text-dark-nav">Strategy Tools </div>
                        </div>
                        <Text className="max-w-[465px] mt-[20px] text-xs sm:text-sm leading-[21px] text-gray">
                            Save time by executing multiple tactics with a single campaign using our suite of strategy
                            tools, which include bid factoring and creative tactic capabilities.
                        </Text>
                    </div>
                </div>
                <div className="right h-auto max-w-[600px]">
                    <Image src={IMAGE.home_2} />
                </div>
            </div>
        </section>
    );
};

export default OurPlatform;
