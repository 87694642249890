import { Select } from 'components/Input';
import { useField } from 'formik';
import React from 'react';

function FormSelect(props) {
    const [field, meta, helpers] = useField(props);

    const { setValue: setValueFormik } = helpers;
    return <Select helper={meta.error} error={meta.error} setValueFormik={setValueFormik} {...field} {...props} />;
}

export default FormSelect;
