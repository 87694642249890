import { BoxContent } from 'components/BoxContent';
import { Button } from 'components/Button';
import FormInputPassword from 'components/modules/form/FormInputPassword';
import Text from 'components/Text';
import { Form, Formik } from 'formik';
import React from 'react';

function ChangePassword() {
    return (
        <BoxContent title={'Password'}>
            <Formik
                initialValues={{}}
                onSubmit={(data) => {
                    console.log(data);
                }}
            >
                <Form>
                    <div className="grid grid-cols-1 xs:grid-cols-3 md:grid-cols-[1fr_1fr_1fr_82px] gap-5">
                        <FormInputPassword placeholder="Current Password" name="current_password" />
                        <FormInputPassword placeholder="New Password" name="new_password" />
                        <FormInputPassword placeholder="Current New Password" name="repeat_password" />
                        <Button className={'xs:max-md:col-start-3 col-start-auto'} color="secondary" type="submit">
                            Charge
                        </Button>
                    </div>
                </Form>
            </Formik>

            <div className="flex flex-col xxs:flex-row gap-2.5 mt-[30px]">
                <Text>Can’t remember current password?</Text>
                <Text underline>Reset current password?</Text>
            </div>
        </BoxContent>
    );
}

export default ChangePassword;
