import Chip from 'components/Chip/Chip';
import AdCanvaModule from 'components/GlobalModules/AdCanvaModule';
import { Modal } from 'components/Modal';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import React, { useMemo, useState } from 'react';
import { IconButton } from 'components/Button';
import { SVG } from 'assets/imagePath';

const AdCanvaContext = React.createContext();

const HeaderModal = ({ template }) => {
    const { step, setStep, closeModalAdCanva } = useAdCanva();

    const styleChip = useMemo(() => {
        switch (template.status) {
            case 'draft':
                return {
                    color: 'white',
                    label: 'Draft',
                };

            case 'in_progress':
                return {
                    color: 'info',
                    label: 'In Progress',
                };
            case 'review':
                return {
                    color: 'warning',
                    label: 'Review',
                };
            case 'approve':
                return {
                    color: 'success',
                    label: 'Approve',
                };

            default:
                return {
                    color: 'white',
                    label: 'Draft',
                };
        }
    }, [template.status]);

    return step === 1 ? (
        <div className="flex items-center justify-between">
            <Text size="24" weight="bold">
                {template?.title}
            </Text>
            <button type="button" onClick={closeModalAdCanva}>
                <Svg className="child:fill-black w-[30px] h-[30px]" name="close" />
            </button>
        </div>
    ) : (
        <div>
            <div className="flex flex-row gap-5">
                <IconButton
                    classNameImage={'w-5 h-5'}
                    className="w-[40px] md:w-[50px] h-[40px] md:h-[50px]"
                    source={SVG.chevron_left}
                    onClick={step === 1 ? undefined : () => setStep(step - 1)}
                />
                <div className="flex flex-1 items-center gap-5 justify-center md:justify-start">
                    <Text size="24" weight="bold">
                        {template?.title}
                    </Text>
                    <Text className="hidden md:block" size="24" weight="bold" color="secondary">
                        #{template?.id}
                    </Text>
                    <Chip className="hidden md:block font-normal" color={styleChip.color}>
                        {styleChip.label}
                    </Chip>
                </div>
                <button type="button" onClick={closeModalAdCanva}>
                    <Svg className="child:fill-black w-[30px] h-[30px]" name="close" />
                </button>
            </div>
            <div className="flex md:hidden items-center justify-center gap-[20px] mt-[10px]">
                <Text size="24" weight="bold" color="secondary">
                    #{template?.id}
                </Text>
                <Chip className="font-normal" color={styleChip.color}>
                    {styleChip.label}
                </Chip>
            </div>
        </div>
    );
};
const AdCanvaProvider = ({ children }) => {
    // This is the exact same logic that we previously had in our hook

    const [step, setStep] = useState(1);

    const [template, setTemplate] = useState();

    const [open, setOpen] = useState(false);

    const showModalAdCanva = () => {
        setOpen(true);
    };

    const closeModalAdCanva = () => {
        setOpen(false);
        setStep(1);
        setTemplate();
    };

    /* Now we are dealing with a context instead of a Hook, so instead
     of returning the width and height we store the values in the
     value of the Provider */

    const Header = () => {
        return <div className="text-lg text-black">2</div>;
    };

    return (
        <AdCanvaContext.Provider value={{ step, setStep, template, setTemplate, showModalAdCanva, closeModalAdCanva }}>
            <Modal
                bodyClass={'max-w-[1155px] w-full pb-[30px] z-[100]'}
                className="z-[100]"
                renderHeader={
                    () => <HeaderModal template={{ title: 'AdCanva', ...template }} />
                    // <div className="flex justify-between">
                    //     <div className="flex flex-row gap-5">
                    //         {step !== 1 && (
                    //             <IconButton
                    //                 classNameImage={'w-5 h-5'}
                    //                 className="w-[50px] h-50px"
                    //                 source={SVG.chevron_left}
                    //                 onClick={step === 1 ? undefined : () => setStep(step - 1)}
                    //             />
                    //         )}
                    //         <TitleModal template={{ title: 'AdCanva', ...template }} />
                    //     </div>
                    //     <button type="button" onClick={closeModalAdCanva}>
                    //         <Svg className="child:fill-black w-[30px] h-[30px]" name="close" />
                    //     </button>
                    // </div>
                }
                // title={<TitleModal template={{ title: 'AdCanva' }} />}
                isOpen={open}
                onBack={step === 1 ? undefined : () => setStep(step - 1)}
                onClose={closeModalAdCanva}
            >
                <AdCanvaModule open={open} onClose={closeModalAdCanva} />
            </Modal>

            {/* Just wrapper */}
            {children}
        </AdCanvaContext.Provider>
    );
};

const useAdCanva = () => {
    /* We can use the "useContext" Hook to acccess a context from within
     another Hook, remember, Hooks are composable! */
    const { step, setStep, ...rest } = React.useContext(AdCanvaContext);
    return { step, setStep, ...rest };
};

export { useAdCanva };

export default AdCanvaProvider;
