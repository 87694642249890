import { SVG } from 'assets/imagePath';
import { Button } from 'components/Button';
import GridTable from 'components/GridTable';
import Image from 'components/Image';
import { SubTitle } from 'components/Title';
import { libCol, libRow } from './data';
import { useAdCanva } from 'context/AdCanvaContext';

function LibraryModule({ onCreateCanvas }) {
    const { showModalAdCanva } = useAdCanva();

    return (
        <div className="mt-5">
            <div className="flex justify-start  md:justify-between md:flex-row flex-col  gap-2.5 mb-5  md:items-center">
                <SubTitle className="md:self-end md:mt-0">Media</SubTitle>
                <div className="flex gap-2.5 md:gap-5">
                    <Button
                        className={'text-xs h-[50px]'}
                        size="none"
                        variant="outlined"
                        onClick={() => {
                            showModalAdCanva();
                            onCreateCanvas?.();
                        }}
                    >
                        Create Using Canvas
                    </Button>
                    <Button className={'text-xs h-[50px]'} size="none" variant="outlined">
                        Upload
                    </Button>
                </div>
            </div>
            <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                <GridTable className={'w-full table-fixed'} column={libCol} row={libRow} />

                <div className="bg-white-light  flex  justify-end p-[15px]  gap-[5px]">
                    <p className="text-sm font-normal text-grey-light">2 of 2 records</p>
                    <Image src={SVG.chevron_left_light} />
                    <Image src={SVG.chevron_right_light} />
                </div>
            </div>
        </div>
    );
}

export default LibraryModule;
