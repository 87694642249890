import { IMAGE, SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Image from 'components/Image';
import { Svg } from 'components/Svg';
import React from 'react';

function SavedSignatureItem({ active = false, signatureImg = IMAGE.signature_default }) {
    return (
        <div
            className={clsx(
                'p-2.5 flex flex-col gap-2.5 rounded-[10px] max-h-full h-full ring-1 ring-blue-eae',
                active ? 'bg-white' : 'bg-transparent',
            )}
        >
            <div className="flex flex-row  justify-between">
                {active && <Image className={'max-w-[17px] w-full object-contain'} src={SVG.checked_circle_primary} />}
                <div className="ml-auto">
                    <Image className={'max-w-[17px] w-full h-auto object-contain'} src={SVG.info_outlined} />
                </div>
            </div>
            <Image className={'max-w-[95px] w-full h-auto mx-auto object-contain'} src={signatureImg} />

            <Image className={'max-w-[17px] w-full object-contain cursor-pointer'} src={SVG.trash_icon} />
        </div>
    );
}

export default SavedSignatureItem;
