import { SVG } from 'assets/imagePath';
import Box from 'components/Box';
import { BoxContent } from 'components/BoxContent';
import Image from 'components/Image';
import Text from 'components/Text';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function UpgradeCompany() {
    const navigate = useNavigate();
    return (
        <BoxContent title={'Upgrade'}>
            <Box
                className="h-[130px] max-w-[226px] w-full px-[15px] flex justify-center items-center cursor-pointer"
                shadow="outer"
                variant="outlined"
                onClick={() => navigate(routesPath.upgrade_company_plan)}
            >
                <div className="flex gap-[5px] items-center">
                    <Text>Upgrade to Company Plan</Text>
                    <Image className={'max-w-[17px]'} src={SVG.info_outlined} />
                </div>
            </Box>
        </BoxContent>
    );
}

export default UpgradeCompany;
