import clsx from 'clsx';
import { Svg } from 'components/Svg';
import Text from 'components/Text';
import { memo, useEffect } from 'react';

function Modal({ title = 'Modal', bodyClass, isOpen, onClose, onBack, renderHeader, children, modalBoxId }) {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('overflow-hidden');
        }
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, [isOpen]);

    const handleClose = () => {
        onClose();
    };

    const modalClasses = `fixed  inset-0 z-50 !rounded-[15px] overflow-auto  ${isOpen ? 'visible' : 'invisible'}`;

    const overlayClasses = `fixed w-full h-full inset-0 z-20 bg-black transition-all opacity-50 ${
        isOpen ? 'opacity-100' : 'opacity-50'
    }`;

    const contentClasses = `absolute z-50 top-[50%]  transition-all 
    ease-in-out left-[50%] translate-x-[-50%] translate-y-[-50%] w-full  h-auto  p-5  max-h-[95%] ${
        isOpen ? 'scale-100' : 'scale-0'
    }`;

    return (
        <div className={clsx('w-full ', modalClasses)}>
            <div className={overlayClasses} onClick={handleClose}></div>

            <div className={contentClasses}>
                <div
                    className={clsx(
                        'w-full  py-[15px]  px-[30px] pb-[30px] mx-auto rounded-[15px] bg-blue-eae  overflow-auto h-full  shadow-check-box',
                        bodyClass,
                    )}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    id={modalBoxId}
                >
                    {renderHeader ? (
                        renderHeader()
                    ) : (
                        <div className="flex justify-between items-center h-[50px]">
                            {onBack && (
                                <button className="rotate-90" type="button" onClick={onBack}>
                                    <Svg className="child:fill-black w-[30px] h-[30px]" name="chevronDown" />
                                </button>
                            )}
                            <Text component="div" size="24" weight="bold">
                                {title}
                            </Text>

                            <button type="button" onClick={onClose}>
                                <Svg className="child:fill-black w-[30px] h-[30px]" name="close" />
                            </button>
                        </div>
                    )}

                    {isOpen && <div className="body">{children}</div>}
                </div>
            </div>
        </div>
    );
}

export default memo(Modal);
