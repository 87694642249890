import { SVG } from 'assets/imagePath';
import { Button } from 'components/Button';
import Image from 'components/Image';
import { InputPassword, InputText } from 'components/Input';
import Text from 'components/Text/Text';
import { fakeUser } from 'constants/auth';
import { AUTH_KEY } from 'constants/localStorageKey';
import { REGEX } from 'constants/regex';
import { useFormik } from 'formik';
import { useAuth } from 'hooks';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import * as Yup from 'yup';

const loginSchema = Yup.object({
    email: Yup.string()
        .trim()
        .required('Email is required')
        .email('Email is invalid')
        .matches(REGEX.blankSpace, 'This field cannot contain  blankspaces'),
    password: Yup.string()
        .trim()
        .required('Password is required')
        .matches(REGEX.blankSpace, 'This field cannot contain  blankspaces'),

    confirm_password: Yup.string().trim(),
});

function Login() {
    const { actSetAuth } = useAuth();
    const [err, setErr] = useState('');

    const navigate = useNavigate();

    const onLogin = (values) => {
        //Call API check Login
        const user = fakeUser.find((item) => item?.email === values.email && item?.password === values.password);
        if (user) {
            // delete user.password;
            localStorage.setItem(AUTH_KEY, JSON.stringify({ is_login: true, user }));
            actSetAuth({ is_login: true, user });
            navigate(0);
        } else {
            setErr('Email or password not exist');
        }
    };

    const { values, handleChange, handleSubmit, errors } = useFormik({
        initialValues: {
            email: null,
            password: null,
        },
        onSubmit: (values) => {
            onLogin(values);
        },
        validationSchema: loginSchema,
    });

    const { pathname } = useLocation();

    return (
        <div className="flex justify-center items-center py-[120px] px-[10px] min-h-[100vh]  bg-blue-eae">
            <div className="w-full max-w-[400px]">
                <div className="w-full text-center">
                    <Image
                        onClick={() => navigate('/')}
                        src={pathname.includes(routesPath.agency_sub_company_user) ? SVG.logo_agency : SVG.logo}
                        className="w-full cursor-pointer  max-w-[200px] mx-auto mb-5"
                    />
                </div>

                <Text weight="bold" className="mb-5 text-center text-error">
                    {err}
                </Text>

                <form className="" onSubmit={handleSubmit}>
                    <InputText
                        className="mb-[20px]"
                        placeholder="Email"
                        rounded
                        name="email"
                        onChange={handleChange}
                        value={values.email}
                        error={errors.email}
                        helper={errors.email}
                    />
                    <InputPassword
                        className="mb-[20px]"
                        placeholder="Password"
                        rounded
                        name="password"
                        onChange={handleChange}
                        value={values.password}
                        error={!!errors.password}
                        helper={errors.password}
                    />

                    <Button
                        type="submit"
                        className="mx-auto flex  justify-center w-full max-w-[260px] py-[15px] px-5 mb-[20px] shadow-outer"
                    >
                        Login
                    </Button>
                </form>

                <div className="text-center   text-[14px] leading-[17px] text-dark-nav mb-[20px]">
                    Don’t have an account?{' '}
                    <span onClick={() => navigate(routesPath.change_email)} className="underline cursor-pointer">
                        Sign Up!
                    </span>
                </div>

                <div
                    onClick={() => navigate(routesPath.reset_password)}
                    className="text-center cursor-pointer  text-[14px] leading-[17px] text-dark-nav  mt-[20px] underline"
                >
                    Forgot Password?
                </div>
            </div>
        </div>
    );
}

export default Login;
