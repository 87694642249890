import Tabs from 'components/Tabs/Tabs';
import React, { useState } from 'react';
import MarkupCalculator from './Calculators/MarkupCalculator';
import CPCCalculator from './Calculators/CPCCalculator';
import CPMCalculator from './Calculators/CPMCalculator';
import ROICalculator from './Calculators/ROICalculator';

const calculators = [
    {
        label: 'Markup',
        value: 'markup',
    },
    {
        label: 'CPM',
        value: 'cpm',
    },
    {
        label: 'CPC',
        value: 'cpc',
    },
    {
        label: 'ROI',
        value: 'roi',
    },
];

function CalculatorsModule({ defaultCalculator }) {
    const defaultCal = calculators.find((item) => item.value === defaultCalculator);
    const [tab, setTab] = useState(defaultCal || calculators[0]);
    return (
        <div className="flex flex-col gap-5 mt-5">
            <Tabs
                onChange={(tab) => {
                    setTab(tab);
                }}
                className={'w-full !max-w-full'}
                tabList={calculators}
                value={tab.value}
            />

            {tab.value === 'markup' && <MarkupCalculator />}
            {tab.value === 'cpc' && <CPCCalculator />}
            {tab.value === 'cpm' && <CPMCalculator />}
            {tab.value === 'roi' && <ROICalculator />}
        </div>
    );
}

export default CalculatorsModule;
