import CategoryTable from 'components/AccountConfig/Education/CategoryTable';
import { HeaderNavigate } from 'components/HeaderNavigate';

function CategoriesManagement() {
    return (
        <div className="p-2.5 md:p-[30px] flex flex-col gap-[30px]">
            <HeaderNavigate detailInfo={{ detailName: 'Categories' }} />
            <CategoryTable />
        </div>
    );
}

export default CategoriesManagement;
