import { SVG } from 'assets/imagePath';
import { BoxLabelOutline } from 'components/BoxLabel';
import { CardSelect } from 'components/Card';
import Image from 'components/Image';
import Text from 'components/Text';
import { SubTitle } from 'components/Title';
import { usePaymentModule } from 'context/ui/PaymentModuleContext';
import { useState } from 'react';

function CryptoMethod() {
    const [cryptoMethod, setCryptoMethod] = useState('');

    const { setStep } = usePaymentModule();

    const handleOnChange = (method) => {
        setStep(method);
    };

    return (
        <div className="balance_method py-2.5">
            <SubTitle className="mb-5 mt-7">Web3 Payment</SubTitle>

            <BoxLabelOutline label="Select Blockchain Wallet">
                <div className="grid justify-center grid-cols-1 gap-5 sm:grid-cols-5">
                    <div className="flex justify-center col-span-1">
                        <CardSelect
                            active={cryptoMethod === 'bitcoin'}
                            title={<Image src={SVG.bitcoin} className="object-contain w-12 h-12" />}
                            subTitle="Bitcoin"
                            onClick={() => handleOnChange('bitcoin')}
                        />
                    </div>
                    <div className="flex items-center justify-center">
                        <Text size="12">or Connect Wallet</Text>
                    </div>

                    <div className="grid grid-cols-1 col-span-1 gap-5 xxs:grid-cols-2 sm:grid-cols-3 sm:col-span-3">
                        <CardSelect
                            active={cryptoMethod === 'metamask'}
                            title={<Image src={SVG.metamask} className="object-contain w-12 h-12" />}
                            subTitle="Metamask"
                            onClick={() => handleOnChange('metamask')}
                        />
                        <CardSelect
                            active={cryptoMethod === 'coinbase'}
                            title={<Image src={SVG.coinbase} className="object-contain w-12 h-12" />}
                            subTitle="Coinbase"
                            onClick={() => handleOnChange('coinbase')}
                        />
                        <CardSelect
                            active={cryptoMethod === 'ledger'}
                            title={<Image src={SVG.ledger} className="object-contain w-12 h-12" />}
                            subTitle="Ledger"
                            onClick={() => handleOnChange('ledger')}
                        />
                    </div>
                </div>
            </BoxLabelOutline>
        </div>
    );
}

export default CryptoMethod;
