import clsx from 'clsx';
import Text from 'components/Text';
import React, { useEffect } from 'react';
const colors = require('tailwindcss/colors');
//setValueFormik  get value for formik
//onChange props get value for {Parent Component}

function Range({
    color = 'primary',
    setValueFormik,
    onCustomChange,
    defaultValue = 50,
    minValue = 0,
    maxValue = 100,
    name,
    customValueForm,
    className,
    ...props
}) {
    const [value, setValue] = React.useState(defaultValue);
    const handleChangeValue = (e) => {
        setValue(e.target.value);
        onCustomChange && onCustomChange(e.target.value);
    };

    useEffect(() => {
        //Debounce value
        const timeOut = setTimeout(() => {
            let resultValue = value;
            if (customValueForm) {
                resultValue = customValueForm(value);
            }
            setValueFormik?.(resultValue);
        }, 500);
        return () => {
            clearTimeout(timeOut);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const colorProgress = {
        primary: {
            bgColor: '#0E65F1',
            dotColor: 'progress-primary',
        },
        error: {
            bgColor: '#E32416',
            dotColor: 'progress-error',
        },
    };
    const percentage = ((value - minValue) / (maxValue - minValue)) * 100;
    return (
        <div className={clsx('flex flex-col items-center gap-x-2.5', className)}>
            <div className="relative w-full">
                <output
                    for={name}
                    className="tooltip_arrow   absolute -top-[125%]  bg-white-light p-[5px] rounded-[5px] z-20 text-[10px] text-blue-dark"
                    style={{ left: `calc(${percentage}% - ${percentage * (25 / 100)}px)` }}
                >
                    {value}%
                </output>

                <input
                    className={`py-[4px] progress w-full shadow-default rounded-full ${colorProgress[color].dotColor}`}
                    style={{
                        background: `linear-gradient(to right, ${colorProgress[color].bgColor} 0%, ${colorProgress[color].bgColor} ${percentage}%, #f4f6f8 ${percentage}%, #f4f6f8 100%)`,
                    }}
                    {...props}
                    name={name}
                    value={value}
                    onChange={handleChangeValue}
                    type={'range'}
                    min={minValue}
                    max={maxValue}
                />
            </div>

            <div className="flex justify-between w-full ">
                <Text size={12}>{minValue}%</Text>
                <Text size={12}>{maxValue}%</Text>
            </div>
        </div>
    );
}

export { Range };
