import clsx from 'clsx';
import Text from 'components/Text';

function BoxContent({ title, className, children }) {
    return (
        <div className={clsx('box__content', className)}>
            {title && (
                <Text className="py-[14px]  mb-5 !text-lg" weight="bold">
                    {title || 'TITLE'}
                </Text>
            )}

            {children}
        </div>
    );
}

export default BoxContent;
