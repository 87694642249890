import { useFormikContext } from 'formik';
import { memo, useEffect } from 'react';
let resetPresForm = {};
const FormObserver = ({ getValues }) => {
    const { values, resetForm } = useFormikContext();
    resetPresForm = resetForm;
    useEffect(() => {
        getValues(values);
    }, [values]);

    return null;
};

export { resetPresForm };

export default memo(FormObserver);
