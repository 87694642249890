import { BoxLabel } from 'components/BoxLabel';
import { Button } from 'components/Button';
import FormInputText from 'components/modules/form/FormInputText';
import FormSelect from 'components/modules/form/FormSelect';
import { Form } from 'formik';
import React from 'react';

const segmentTypes = [
    {
        value: 'life_style',
        label: 'LifeStyle',
    },
    {
        value: 'gmt',
        label: 'GMT+7',
    },
];
function SegmentForm({ isUpdate }) {
    return (
        <Form>
            <BoxLabel shadow="none" variant="outlined" className={'p-[30px] my-5'} label="Details">
                <div className="grid grid-rows-3 gap-5">
                    <div className="flex flex-row gap-5">
                        <FormSelect
                            placeholder="Segment Type"
                            propsDropdown={{ classNameBox: 'flex-1' }}
                            placeholderTop
                            name="segment_type"
                            options={segmentTypes}
                        />

                        <FormInputText label="Deal ID" classNameBox="flex-1" placeholder="Deal ID" name="deal_id" />
                        <FormInputText classNameBox="flex-1" placeholder="Data Cost" name="data_cost" />

                        <FormInputText
                            classNameBox="flex-1"
                            placeholder="Available Impressions"
                            name="available_impression"
                        />
                    </div>

                    <div className="flex flex-row gap-5">
                        <FormInputText classNameBox="flex-[2.8]" placeholder="Description" name="description" />

                        <FormInputText classNameBox="flex-[1]" placeholder="Deal Name" name="deal_name" />
                    </div>
                    <div className="flex flex-row gap-5">
                        <div className="flex-[2.8] flex flex-row gap-5">
                            <FormInputText classNameBox="flex-1" placeholder="Vendor" name="vendor" />

                            <FormInputText classNameBox="flex-1" placeholder="Vendor Category" name="vendor_category" />
                        </div>
                        <FormInputText
                            classNameBox="flex-1"
                            placeholder="Sub-category (optional)"
                            name="sub_category"
                        />
                    </div>
                </div>
            </BoxLabel>

            <Button type="submit" className={'max-w-[260px] w-full'}>
                {isUpdate ? 'Update' : 'Add'}
            </Button>
        </Form>
    );
}

export default SegmentForm;
