import { InputText, Select } from 'components/Input';
import Text from 'components/Text';
import { useFormik } from 'formik';
import { useState } from 'react';
import CreateForm from './CreateForm';
import UploadAvatar from './UploadAvatar';
const countryOptions = [
    {
        value: 'personal',
        label: 'Personal',
    },
    {
        value: 'business',
        label: 'Business',
    },
];
function ProfileSubCo() {
    const [image, setImage] = useState('');
    const { values, handleChange, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: {
            // email: '',
            // first_name: '',
            // last_name: '',
            // street_address: '',
            // suite_or_apartment: '',
            // city: '',
            // zip_code: '',
            // accept_term: false,
            // company_name: '',
            // business_street_address: '',
            // business_suite_or_apartment: '',
            // business_city: '',
            // business_zip_code: '',
        },
        onSubmit: (values) => {
            console.log(values);
            // navigateSearch(pathname, { step: '2' });
        },
        // validationSchema: createAccountSchema,
    });

    return (
        <div className="max-w-[820px]">
            <div className="mb-[30px]">
                <CreateForm />
            </div>

            <Text className="mb-5 pt-7" size="18" weight="bold">
                Profile
            </Text>

            <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-y-[20px]">
                    <div className="flex flex-start items-center gap-[20px]">
                        <InputText
                            onChange={handleChange}
                            value={values.company_name}
                            error={errors.company_name}
                            classNameBox={'flex-1'}
                            name="company_name"
                            placeholder={'Company Name'}
                            rounded
                        />

                        <UploadAvatar image={image} setImage={setImage} label={'Change Profile Image'} />
                    </div>

                    <div className="flex flex-col sm:flex-row flex-start items-center gap-[20px]">
                        <Select
                            placeholder="Country"
                            options={countryOptions}
                            setValue={setFieldValue}
                            value={values.country}
                            classNameText="flex-1"
                            name="country"
                            propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                        />
                        <Select
                            placeholder="City"
                            options={countryOptions}
                            setValue={setFieldValue}
                            value={values.city}
                            classNameText="flex-1"
                            propsDropdown={{ classNameBox: 'flex-1 w-full' }}
                            name="city"
                        />
                        <InputText
                            onChange={handleChange}
                            value={values.zip}
                            error={errors.zip}
                            classNameBox="flex-1"
                            name="zip"
                            placeholder={'ZIP'}
                            rounded
                        />
                    </div>
                    <div>
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.company_address}
                            error={errors.company_address}
                            name="company_address"
                            placeholder={'Company Address'}
                            rounded
                        />
                    </div>

                    <div className="flex flex-col sm:flex-row  flex-start items-center gap-[20px]">
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.company_email_address}
                            error={errors.company_email_address}
                            name="company_email_address"
                            placeholder={'Company Email Address'}
                            rounded
                        />
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.company_phone_number}
                            error={errors.company_phone_number}
                            name="company_phone_number"
                            placeholder={'Company Phone Number'}
                            rounded
                        />
                        <InputText
                            classNameBox="flex-1"
                            onChange={handleChange}
                            value={values.company_website}
                            error={errors.company_website}
                            name="company_website"
                            placeholder={'Company Website'}
                            rounded
                        />
                    </div>

                    <InputText
                        classNameBox="flex-1"
                        onChange={handleChange}
                        value={values.company_registration_number}
                        error={errors.company_registration_number}
                        name="company_registration_number"
                        placeholder={'Company Registration Number '}
                        rounded
                    />

                    <Select
                        placeholder="Primary contact"
                        options={countryOptions}
                        setValue={setFieldValue}
                        value={values.primary_contact}
                        classNameText="flex-1"
                        propsDropdown={{ classNameBox: 'flex-1' }}
                        name="primary_contact"
                    />

                    <InputText
                        onChange={handleChange}
                        value={values.bio}
                        error={errors.bio}
                        classNameBox="flex-1"
                        name="bio"
                        placeholder={'Bio'}
                        rounded
                    />
                </div>
            </form>
        </div>
    );
}

export default ProfileSubCo;
