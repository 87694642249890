import { Button } from 'components/Button';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import { APP_ROLE } from 'constants/auth';
import { useAdCanva } from 'context/AdCanvaContext';
import { useAuth } from 'hooks';
import React from 'react';

function DesignReview() {
    const { setStep, closeModalAdCanva } = useAdCanva();

    const { currentRole } = useAuth();

    // const { currentRole } = useAuth();

    // console.log(currentRole);

    const handleApproveDesign = () => {
        //Request role (user)

        //Logic call api approve

        // Close modal when call api success
        closeModalAdCanva();
    };

    const handleSubmitDesign = () => {
        //Designer role (organization user)
        //Logic call api submit
        // Close modal when call api success
        closeModalAdCanva();
    };
    return (
        <div className="flex flex-col gap-5">
            <SubTitle>{currentRole === APP_ROLE.Org ? 'Submit Creatives' : 'Review'}</SubTitle>

            <Text size="14">
                Lacinia eget in eget molestie aliquam egestas proin. At diam tincidunt pellentesque orci. Tellus lorem
                nunc ornare fames lectus. Morbi pulvinar bibendum sed enim enim turpis sit aliquam. Proin nibh cursus
                massa elementum.
            </Text>

            <div className="flex flex-row flex-1 w-full gap-5">
                <Button className={'w-full'} color="secondary" onClick={() => setStep((prev) => prev - 1)}>
                    Back
                </Button>
                {currentRole === APP_ROLE.Org ? (
                    <Button className={'w-full'} onClick={handleSubmitDesign}>
                        Submit
                    </Button>
                ) : (
                    <Button className={'w-full'} onClick={handleApproveDesign}>
                        Approve
                    </Button>
                )}
            </div>
        </div>
    );
}

export default DesignReview;
