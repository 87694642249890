import { SVG } from 'assets/imagePath';
import { AnalyticReview } from 'components/Analytics';
import { BarLineLegend } from 'components/Charts';
import { BarChart } from 'components/Charts/BarChart';
import DoughnutChart from 'components/Charts/DoughnutChart/Doughnut';
import DoughnutLegend from 'components/Charts/DoughnutChart/DoughnutLegend';
import { doughnutStyle } from 'components/Charts/DoughnutChart/config';
import GridTable from 'components/GridTable';
import TableHead from 'components/GridTable/TableHead';
import Image from 'components/Image';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import { fakeColumnCtr, fakeColumnImpression, fakeRowLegend } from 'pages/Dashboard/data';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';
import { isPermissionView } from 'utils';
import { campaignColumn, campaignRow } from './data';
import FormFilterCampaign from 'components/FilterTools/forms/FormFilterCampaign';
import { Pagination } from 'components/Pagination';
import { getFakeRowsByPage } from 'components/Pagination/func';
import moment from 'moment';

const campaignList = [
    { label: 'All', value: 'all' },
    { label: 'Draft', value: 'draft' },
    { label: 'Paused', value: 'paused' },
    { label: 'Embed', value: 'embed' },
    { label: 'Active', value: 'active' },
    { label: 'Archived', value: 'archived' },
];

const LegendOverallPerformance = () => {
    return (
        <div className="flex flex-row  items-center justify-center  gap-2.5">
            <div className="flex items-center">
                <div className="h-[9px] w-[9px] rounded-full opacity-[0.25] bg-primary mr-[6.5px]" />
                <Text size="10">Ad Spend</Text>
            </div>
        </div>
    );
};

const Campaign = () => {
    const [tabsVal, setTabsVal] = useState(campaignList[0]);

    const [dataCampaign, setDataCampaign] = useState(campaignRow);

    const [currentDataRender, setCurrentDataRender] = useState([]);

    const navigate = useNavigate();

    const handleActionTable = (action, row) => {
        switch (action.value) {
            case 'view':
                navigate(routesPath.campaign_detail(row.id));

                break;

            default:
                break;
        }
    };

    const [searchValue, setSearchValue] = useState('');
    const [filters, setFilters] = useState({});

    const [pagination, setPagination] = useState({
        total: campaignRow.length,
        limit: 10,
        currentPage: 1,
    });

    useEffect(() => {
        const data = getFakeRowsByPage(dataCampaign, pagination.limit, pagination.currentPage);
        setCurrentDataRender(data);
    }, [pagination.currentPage, pagination.limit, dataCampaign]);

    useEffect(() => {
        let filterArr = [...campaignRow];

        //Filter Tab

        if (tabsVal.value !== 'all') {
            filterArr = filterArr.filter((item) => item.state === tabsVal.value);
        }
        //Search

        if (searchValue) {
            filterArr = filterArr.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()));
        }

        //Budged
        if (filters.budged) {
            filterArr = filterArr.filter(
                (item) => filters.budged[0] <= item.budget && item.budget <= filters.budged[1],
            );
        }

        //Date

        console.log(filters);

        if (filters?.rangeDate) {
            const { rangeDate } = filters;
            if (rangeDate[0] && rangeDate[1]) {
                console.log('between');

                filterArr = filterArr.filter(
                    (item) =>
                        moment(item.start_date).unix() >= moment(rangeDate[0]).unix() &&
                        moment(item.end_date).unix() <= moment(rangeDate[1]).unix(),
                );
            } else if (rangeDate[0]) {
                console.log(moment(rangeDate[0]).unix());
                console.log(moment('Jan 24, 2023').unix() >= moment(rangeDate[0]).unix());
                console.log('start');
                filterArr = filterArr.filter((item) => moment(item.start_date).unix() >= moment(rangeDate[0]).unix());

                console.log(filterArr);
            } else if (rangeDate[1]) {
                console.log('end');

                filterArr = filterArr.filter((item) => moment(item.end_date).unix() <= moment(rangeDate[1]).unix());
            } else {
                filterArr = [];
            }
        }

        setPagination((prev) => ({
            ...prev,
            currentPage: 1,
            total: filterArr.length,
        }));
        setDataCampaign(filterArr);
    }, [tabsVal, searchValue, filters.budged, filters?.rangeDate]);

    return (
        <div className="p-2.5 md:p-[30px]">
            <div className="font-extrabold text-2xl text-blue-dark mb-[30px] py-[10.5px]">
                <h2>Campaigns</h2>
            </div>

            {isPermissionView(['Ag']) && (
                <div className="flex flex-col mb-[30px]">
                    <div className="flex items-center justify-between mb-5">
                        <Text className="self-end !text-[18px]" weight="bold">
                            Analytics
                        </Text>
                    </div>
                    <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 md:grid-cols-2">
                        <AnalyticReview
                            className={'w-full'}
                            title={'Ad Spend'}
                            CustomReview={
                                <div className="flex flex-col w-full h-[99%]   gap-[5px] ">
                                    <BarLineLegend
                                        legendYRight={
                                            <div>
                                                <LegendOverallPerformance />
                                            </div>
                                        }
                                        legendYLeft={'Ad Spend, $'}
                                    />
                                    <div className="w-full h-full">
                                        <BarChart />
                                    </div>
                                </div>
                            }
                        />
                        <AnalyticReview
                            className={'flex-1'}
                            title={'Impressions'}
                            Chart={<DoughnutChart />}
                            LegendComponent={
                                <DoughnutLegend
                                    headerLegend={fakeColumnImpression}
                                    dataLegend={fakeRowLegend}
                                    configData={doughnutStyle}
                                />
                            }
                        />

                        <AnalyticReview
                            className={'flex-1'}
                            title={'CTR'}
                            Chart={<DoughnutChart />}
                            LegendComponent={
                                <DoughnutLegend
                                    headerLegend={fakeColumnCtr}
                                    dataLegend={fakeRowLegend}
                                    configData={doughnutStyle}
                                />
                            }
                        />
                    </div>
                </div>
            )}

            <div className="flex flex-wrap justify-between mb-[30px] gap-y-2.5 py-[5px]">
                <Tabs
                    onChange={(tab) => {
                        setTabsVal(tab);
                    }}
                    tabList={campaignList}
                    value={tabsVal.value}
                />
            </div>

            <TableHead
                title={tabsVal.label}
                tools={{
                    search: {
                        onChange: (value) => {
                            setSearchValue(value);
                        },
                    },
                    filter: {
                        FilterForm: <FormFilterCampaign />,
                        onApply: (data) => {
                            setFilters(data);
                        },
                        onReset: (data) => {
                            setFilters(data);
                        },
                        defaultValues: { ...filters },
                    },
                }}
                actionButton={{ label: 'Add Campaign', onClick: () => navigate(routesPath.campaign_services(312)) }}
            />

            <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                <GridTable
                    className={'w-full table-fixed'}
                    column={campaignColumn}
                    row={currentDataRender}
                    handle={(row, action) => handleActionTable(action, row)}
                />

                <Pagination
                    onChangeCurrentPage={(page) => setPagination((prev) => ({ ...prev, currentPage: page }))}
                    currentPage={pagination.currentPage}
                    total={pagination.total}
                />
            </div>
        </div>
    );
};

export default Campaign;
