import Box from 'components/Box/Box';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import React from 'react';

function CreateNewRequest({ ...props }) {
    return (
        <Box className="flex flex-col items-center justify-center gap-5 p-5 cursor-pointer" {...props}>
            <Svg className="max-w-[160px] aspect-square h-auto w-full" name="plusRounded" />

            <div className="flex flex-col items-center">
                <Text weight="bold">Create New</Text>
                <Text ellipsis>Create a new creatives request</Text>
            </div>
        </Box>
    );
}

export default CreateNewRequest;
