import { Button } from 'components/Button';
import Text from 'components/Text/Text';
import FormInputText from 'components/modules/form/FormInputText';
import FormTextArea from 'components/modules/form/FormTextArea';
import { Form, useFormikContext } from 'formik';
import { useState } from 'react';

function GenerateText({ type }) {
    const { setValues, values, setFieldValue, handleSubmit } = useFormikContext();

    function fakeGenerate() {
        return new Promise((resolve, reject) => {
            const img = `https://picsum.photos/200?random=${Math.floor(Math.random() * 101)}`;
            setTimeout(() => {
                resolve({
                    desc: 'Logo for roofing',
                    img,
                });
            }, 500);
        });
    }

    const handleAutoGenerate = async () => {
        try {
            const res = await fakeGenerate();
            setValues({
                logoDesc: res.desc,
                imgLogo: {
                    preview: res.img,
                },
            });
            setIsGenerated(true);
        } catch (error) {}
    };

    const [isGenerated, setIsGenerated] = useState(false);
    const [loading, setLoading] = useState(false);

    const generateImage = async () => {
        setLoading(true);

        try {
            const response = await fetch('https://api-inference.huggingface.co/models/prompthero/openjourney', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer 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`,
                },
                body: JSON.stringify({ inputs: values.logoDesc }),
            });

            const blob = await response.blob();
            const image = URL.createObjectURL(blob);
            setValues({
                logoDesc: values.logoDesc,
                imgLogo: {
                    preview: image,
                },
            });
            //   setImageFile(new File([blob], 'art.png', { type: 'image/png' }));
            setLoading(false);
            setIsGenerated(true);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <Form className="flex flex-col gap-5">
            <Text size="14">Please describe the text you would like to see on ad.</Text>

            <FormTextArea className="h-[150px]" name="textDesc" placeholder="Description" label="Description" />
            <FormInputText name="generatedTitle" placeholder="Generated Title" label="Generated Title" />

            <FormInputText name="generatedSubTitle" placeholder="Generated Subtitle" label="Generated Title" />
            <FormInputText
                name="generatedCallAction"
                placeholder="Generated Call to Action"
                label="Generated Call to Action"
            />

            {isGenerated ? (
                <div className="flex flex-row gap-5">
                    <Button
                        disabled={loading}
                        color="secondary"
                        onClick={generateImage}
                        className={'flex-1'}
                        type="button"
                    >
                        Regenerate
                    </Button>
                    <Button disabled={loading} onClick={handleSubmit} className={'flex-1'} type="button">
                        Use
                    </Button>
                </div>
            ) : (
                <div className="flex flex-row gap-5">
                    <Button disabled={loading} onClick={generateImage} className={'flex-1'} type="button">
                        Generate
                    </Button>
                </div>
            )}
        </Form>
    );
}

export default GenerateText;
