import { useAdCanva } from 'context/AdCanvaContext';
import { Form, Formik } from 'formik';
import CanvaChooseSize from '../../CanvaChooseSize';
import CanvaSaved from '../../CanvaSaved';
import CanvaUpdateBg from '../../CanvaUpdateBg';
import CanvaUpdateColors from '../../CanvaUpdateColors';
import CanvaUpdateContent from '../../CanvaUpdateContent';
import SelectTemplate from '../../SelectTemplate';
import { TemplateLayout } from '../../layouts';

function Templates() {
    const { step, setStep, template, setTemplate } = useAdCanva();

    return (
        <Formik
            initialValues={{
                callAction: {
                    text: 'Learn more',
                    size: '12',
                    font: 'font-inter',
                },
                subTitle: {
                    text: 'For every big goal you have in life, the best way to tackle it is to create smaller goals that you can get started on right away',
                    size: '12',
                    font: 'font-inter',
                },
                title: {
                    text: 'You are one click away from success!',
                    size: '16',
                    font: 'font-inter',
                },
                base: '#0E65F1',
                accent: '#E39115',
            }}
            onSubmit={(data) => {
                console.log(data);
            }}
        >
            <Form>
                {step === 1 && <SelectTemplate title="Choose Template" subTitle="Placeholder" />}

                {step === 2 && (
                    <TemplateLayout title={'Update Images'} subTitle={'Placeholder'}>
                        <CanvaChooseSize />
                    </TemplateLayout>
                )}

                {step === 3 && (
                    <TemplateLayout title={'Update Images'} subTitle={'Placeholder'}>
                        <CanvaUpdateBg />
                    </TemplateLayout>
                )}
                {step === 4 && (
                    <TemplateLayout title={'Update Text'} subTitle={'Placeholder'}>
                        <CanvaUpdateContent />
                    </TemplateLayout>
                )}
                {step === 5 && (
                    <TemplateLayout title={'Update Text'} subTitle={'Placeholder'}>
                        <CanvaUpdateColors />
                    </TemplateLayout>
                )}
                {step === 6 && (
                    <TemplateLayout title={'Save Creatives Set'} subTitle={'Placeholder'}>
                        <CanvaSaved />
                    </TemplateLayout>
                )}
            </Form>
        </Formik>
    );
}

export default Templates;
