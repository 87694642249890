import { SVG } from 'assets/imagePath';
import { CardRangeView } from 'components/Card';
import GridTable from 'components/GridTable';
import TableHead from 'components/GridTable/TableHead';
import Image from 'components/Image';
import Text from 'components/Text/Text';
import { paymentColumn, paymentRow } from './data';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function PaymentsSubCo() {
    const navigate = useNavigate();
    const handleActionTable = (row, action) => {
        switch (action.value) {
            case 'view':
                navigate(routesPath.payment_detail(row.id));
                break;

            default:
                break;
        }
    };
    return (
        <div className="flex flex-col gap-[30px]">
            <div className="balance">
                <Text className="!text-[18px] flex-1 mb-5" weight="bold">
                    Balance
                </Text>

                <CardRangeView
                    className={'w-full min-w-max max-w-[396px]'}
                    title="Remaining Balance"
                    actionText={'Top up'}
                    propsRange={{ from: '$0.00', to: '$0.00', value: 0, className: '!bg-blue-cfd' }}
                    propsTextReview={{ label: '$0.00' || '$0.00', className: 'text-error' }}
                />
            </div>

            <div className="table__payments">
                <TableHead title={'Payments'} />
                <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                    <GridTable
                        handle={handleActionTable}
                        className={'w-full table-fixed'}
                        column={paymentColumn}
                        row={paymentRow}
                    />

                    <div className="bg-white-light  flex  justify-end p-[15px]  gap-[5px]">
                        <p className="text-sm font-normal text-grey-light">2 of 2 records</p>
                        <Image src={SVG.chevron_left_light} />
                        <Image src={SVG.chevron_right_light} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentsSubCo;
