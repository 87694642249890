import { BoxContent } from 'components/BoxContent';
import {
    AgreementBlock,
    AuthorBlock,
    CampaignBlock,
    FundBlock,
    ServiceBlock,
    SignBlock,
} from 'components/Campaign/Order';
import ReviewBlock from 'components/Campaign/Order/ReviewBlock';
import { ChatBox, ChatInput } from 'components/ChatBox';
import { chatContents } from 'components/ChatBox/fakeData';

import { Form, Formik } from 'formik';
import { memo } from 'react';

function Order() {
    const signed = false;
    return (
        <Formik
            initialValues={{}}
            onSubmit={(values, actions) => {
                setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    actions.setSubmitting(false);
                }, 0);
            }}
        >
            <Form>
                <div className="flex flex-col gap-y-[30px]">
                    <CampaignBlock />
                    <AuthorBlock />
                    <ServiceBlock />
                    <AgreementBlock />

                    {signed ? (
                        <>
                            <FundBlock
                                hasRemainingFund
                                propsRange={{ from: '$0.00', to: '$475.00', value: 75, colorProgress: 'bg-success' }}
                            />
                            <ReviewBlock reviewStatus={'pending'} />
                        </>
                    ) : (
                        <SignBlock />
                    )}

                    <BoxContent title={'Comments'} className=" max-w-[820px]">
                        <div className="flex flex-col gap-5">
                            <ChatBox chatContents={chatContents} />
                            <ChatInput placeholder="Message" />
                        </div>
                    </BoxContent>
                </div>
            </Form>
        </Formik>
    );
}

export default memo(Order);
