import { SVG } from 'assets/imagePath';
import GridTable from 'components/GridTable';
import TableHead from 'components/GridTable/TableHead';
import Image from 'components/Image';
import { companiesColumn, companiesRow } from 'pages/Dashboard/data';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function CompaniesTable() {
    const navigate = useNavigate();
    const handleActionTable = (row, action) => {
        switch (action.value) {
            case 'view':
                navigate(routesPath.company_detail(row.id));
                break;

            default:
                break;
        }
    };
    return (
        <>
            <TableHead
                title={'Companies'}
                actionButton={{ label: 'Add Company', onClick: () => navigate(routesPath.add_company) }}
            />
            <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                <GridTable
                    className={'w-full table-fixed'}
                    column={companiesColumn}
                    row={companiesRow}
                    handle={(row, action) => handleActionTable(row, action)}
                />

                <div className="bg-white-light flex justify-end p-[15px]  gap-[5px]">
                    <p className="text-sm font-normal text-grey-light">2 of 2 records</p>
                    <Image src={SVG.chevron_left_light} />
                    <Image src={SVG.chevron_right_light} />
                </div>
            </div>
        </>
    );
}

export default CompaniesTable;
