import { AnalyticReview } from 'components/Analytics';
import { CardContent } from 'components/Card';
import { BarLineChart, BarLineLegend } from 'components/Charts';

import Text from 'components/Text';
import { SubTitle } from 'components/Title';

const LegendOverallPerformance = () => {
    return (
        <div className="flex flex-row  items-center justify-center  gap-2.5">
            <div className="flex items-center ">
                <div className="h-[1.5px] w-[9px] bg-primary mr-[6.5px] rounded-[3px]" />
                <Text size="10">Impressions</Text>
            </div>
            <div className="flex items-center">
                <div className="h-[1.5px] w-[9px] bg-primary opacity-[0.35] mr-[6.5px] rounded-[3px]" />
                <Text size="10">Clicks</Text>
            </div>
            <div className="flex items-center">
                <div className="h-[9px] w-[9px] rounded-full opacity-[0.25] bg-primary mr-[6.5px]" />
                <Text size="10">CTR</Text>
            </div>
        </div>
    );
};

function OverallPerformance({ is }) {
    return (
        <div className="flex flex-col gap-5">
            <SubTitle className={'pt-7'}>Overall Preformance</SubTitle>

            <div className="grid grid-flow-row grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-4">
                <CardContent className={'flex-1 '} title={'Impressions'} content="145,176" actionText={'View'} />
                <CardContent className={'flex-1 '} title={'Clicks'} content="780" actionText={'View'} />

                <CardContent className={'flex-1 '} title={'CTR'} content="0.54%" actionText={'View'} />

                <CardContent className={'flex-1 '} title={'Total Cost'} content="$871.07" actionText={'View'} />
            </div>
            <div className="flex w-full">
                <AnalyticReview
                    className={'w-full'}
                    title={'Overall Preformance'}
                    CustomReview={
                        <div className="flex flex-col w-[99.9%] h-full max-h-[170px]  gap-[5px] mb-5 xs:mb-0">
                            <BarLineLegend
                                legend={
                                    <div className="hidden xs:block">
                                        <LegendOverallPerformance />
                                    </div>
                                }
                                legendYLeft={'Impressions, Clicks'}
                                legendYRight="CTR"
                            />
                            <div className="flex-1">
                                <BarLineChart />
                            </div>
                            <div className="block px-5 xs:hidden">
                                <LegendOverallPerformance />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
}

export default OverallPerformance;
