import { SVG } from 'assets/imagePath';
import { BoxLabelOutline } from 'components/BoxLabel';
import { CardSelect } from 'components/Card';
import Image from 'components/Image/Image';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import { usePaymentModule } from 'context/ui/PaymentModuleContext';
import React, { useState } from 'react';

function StepSelect() {
    const [paymentMethod, setPaymentMethod] = useState('balance');
    const handleOnChange = (method) => {
        setPaymentMethod(method);
    };

    const { step, setStep } = usePaymentModule();

    return (
        <BoxLabelOutline label="Select payment method">
            {/* <div className="grid justify-center grid-cols-1 gap-5 sm:grid-cols-6"> */}
            <div className="flex flex-col w-full gap-5 min-[840px]:flex-row items-center min-[840px]:justify-start">
                <div className="flex justify-center w-full col-span-2 max-w-[168px] flex-[1.5]">
                    <CardSelect
                        className={'w-full overflow-unset'}
                        active={paymentMethod === 'balance'}
                        title={<Svg name="wallet" className="object-contain w-12 h-12" />}
                        subTitle={
                            <div className="flex flex-col items-center justify-center">
                                <p>Balance</p>
                                <p>($3245.00, €3245.00)</p>
                            </div>
                        }
                        onClick={() => handleOnChange('balance')}
                    />
                </div>

                <div className="flex items-center justify-center ">
                    <Text size="12">or</Text>
                </div>

                <div className="grid flex-row justify-center flex-1 w-full grid-cols-2 gap-5 justify-items-center sm:flex">
                    <CardSelect
                        className={'overflow-visible'}
                        active={paymentMethod === 'card'}
                        title={<Svg name="creditCard" sizeIcon={'48'} />}
                        subTitle="Card"
                        onClick={() => handleOnChange('card')}
                        action
                    />

                    <CardSelect
                        className={'overflow-visible'}
                        active={paymentMethod === 'bitcoin'}
                        title={<Image src={SVG.bitcoin} className="object-contain w-12 h-12" />}
                        subTitle="Bitcoin"
                        onClick={() => handleOnChange('bitcoin')}
                        action
                    />
                    <CardSelect
                        className={'overflow-visible'}
                        active={paymentMethod === 'ach'}
                        title={<Svg name="accountBalance" sizeIcon={'48'} />}
                        subTitle="ACH"
                        onClick={() => handleOnChange('ach')}
                        action
                    />
                    <CardSelect
                        className={'overflow-visible'}
                        active={paymentMethod === 'new'}
                        title={<Svg name="plus" className="w-12 h-12 child:fill-black" />}
                        subTitle="New"
                        onClick={() => {
                            handleOnChange('new');
                            setStep('add_method');
                        }}
                    />
                </div>
            </div>
        </BoxLabelOutline>
    );
}

export default StepSelect;
