import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-quill/dist/quill.snow.css';
import 'react-phone-input-2/lib/style.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GlobalContextProvider } from 'context/GlobalContext';
import ViewportProvider from 'context/ViewportContext';
import AuthContextProvider from 'context/AuthContext';
import PaymentProvider from 'context/ui/PaymentModuleContext';
import AdCanvaProvider from 'context/AdCanvaContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <GlobalContextProvider>
                <AuthContextProvider>
                    <ViewportProvider>
                        <PaymentProvider>
                            <AdCanvaProvider>
                                <App />
                            </AdCanvaProvider>
                        </PaymentProvider>
                    </ViewportProvider>
                </AuthContextProvider>
            </GlobalContextProvider>
        </BrowserRouter>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
