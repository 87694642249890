import { AUTH_KEY } from 'constants/localStorageKey';
import useLocalStorage from 'hooks/useLocalStorage';
import React, { createContext, useReducer } from 'react';

export const initAuth = {
    user: null,
    is_login: false,
};

const actions = {
    SET_AUTH: 'SET_AUTH',
};

const authReducer = (state, { type, payload }) => {
    switch (type) {
        case actions.SET_AUTH:
            return {
                ...state,
                auth: {
                    ...state?.auth,
                    user: payload?.user,
                    is_login: payload?.is_login,
                },
            };

        default:
            return state;
    }
};
export const AuthContext = createContext();

function AuthContextProvider({ children }) {
    const [auth, setAuth] = useLocalStorage(AUTH_KEY, initAuth);
    const [state, dispatch] = useReducer(authReducer, auth || initAuth);
    const value = {
        auth: { ...state },
        actSetAuth: (newAuth) => {
            setAuth(newAuth);
            dispatch({ type: actions.SET_AUTH, payload: { ...newAuth } });
        },
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
