import { SVG } from 'assets/imagePath';
import GridTable from 'components/GridTable';
import TableHead from 'components/GridTable/TableHead';
import Image from 'components/Image';
import { Modal } from 'components/Modal';
import { Formik } from 'formik';
import { SegmentDetail, SegmentForm } from './components';
import { dealsColumn, dealsRow } from './data';
import { useState } from 'react';
import ActionsButton from 'components/GridTable/ActionsButton';
import ToolTip from 'components/Tooltip/Tooltip';
import Text from 'components/Text/Text';
import { Svg } from 'components/Svg';
import ViewSegment from './components/ViewSegment';

function DealsTable({}) {
    const [openModal, setOpenModal] = useState({
        isOpen: false,
        isUpdate: false,
        data: {},
        step: 1,
        type: '',
    });

    const openEdit = (data) => {
        const fakeData = {
            segment_type: 'life_style',
            deal_id: 'LifeStyle',
            data_cost: '100$',
        };
        setOpenModal({
            isOpen: true,
            isUpdate: true,
            data: fakeData,
            step: 1,
            type: 'edit',
        });
    };

    const openViewDetail = (data) => {
        const fakeData = {
            segment_type: 'life_style',
            data_cost: '100$',
            ...data,
        };
        setOpenModal({
            isOpen: true,
            isUpdate: true,
            data: fakeData,
            step: 1,
            type: 'view',
        });
    };

    const onChangeAction = (action, data) => {
        if (action.value === 'edit') {
            openEdit(data);
        }
        if (action.value === 'view') {
            openViewDetail(data);
        }
    };

    const dealsColumn = [
        {
            field: 'id',
            headerName: 'Deal ID',
        },

        {
            field: 'createdAt',
            headerName: 'Created at',
        },
        {
            field: 'creative_sizes',
            headerName: 'Creative Sizes',
            renderCell: (row) => {
                return (
                    <ToolTip
                        tooltip={
                            <ol className="p-2.5 list-disc list-inside marker:!text-center">
                                {row?.creative_sizes.map((item, idx) => (
                                    <li key={idx} className="">
                                        {item}
                                    </li>
                                ))}
                            </ol>
                        }
                    >
                        <div className="p-0 md:p-[15px] flex flex-row max-md:justify-end items-center gap-[5px]">
                            <Text>{row?.creative_sizes?.length}</Text>
                            <Svg name="infoOutline" className="w-[15px] h-[15px]" />
                        </div>
                    </ToolTip>
                );
            },
        },
        {
            field: 'audience_sizes',
            headerName: 'Audience Segments',
            renderCell: (row) => {
                return (
                    <ToolTip tooltip={'hello world'}>
                        <div className="p-0 md:p-[15px] flex flex-row  max-md:justify-end items-center gap-[5px]">
                            <Text>7</Text>
                            <Svg name="infoOutline" className="w-[15px] h-[15px]" />
                        </div>
                    </ToolTip>
                );
            },
        },
        {
            field: 'semantic_sizes',
            headerName: 'Semantic Sizes',
            renderCell: (row) => {
                return (
                    <ToolTip parentId="dealTable" tooltip={'hello world'}>
                        <div className="p-[0px] md:p-[15px] flex flex-row max-md:justify-end items-center gap-[5px]">
                            <Text>7</Text>
                            <Svg name="infoOutline" className="w-[15px] h-[15px]" />
                        </div>
                    </ToolTip>
                );
            },
        },

        {
            field: 'data_cost',

            headerName: 'Data Cost',
        },
        {
            field: 'media_cost',

            headerName: 'Media Cost',
        },
        {
            field: 'currency',

            headerName: 'Currency',
        },
        {
            field: 'country',

            headerName: 'Country',
        },

        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (row) => {
                return (
                    <ActionsButton
                        options={[
                            {
                                label: 'View',
                                value: 'view',
                            },
                            {
                                label: 'Edit',
                                value: 'edit',
                            },
                        ]}
                        onChange={(valueAction) => onChangeAction(valueAction, row)}
                    />
                );
            },
        },
    ];

    return (
        <>
            <div>
                <TableHead title={'Segments Targeted'} />

                <div id="" className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                    <GridTable
                        tableId={'dealTable'}
                        className={'w-full table-fixed'}
                        column={dealsColumn}
                        row={dealsRow}
                    />

                    <div className="bg-white-light flex justify-end p-[15px]  gap-[5px]">
                        <p className="text-sm font-normal text-grey-light">2 of 2 records</p>
                        <Image src={SVG.chevron_left_light} />
                        <Image src={SVG.chevron_right_light} />
                    </div>
                </div>
            </div>

            <Modal
                bodyClass={openModal.step === 1 ? 'max-w-[1022px]' : 'max-w-[800px]'}
                isOpen={openModal.isOpen}
                onClose={() =>
                    setOpenModal({
                        isOpen: false,
                        isUpdate: false,
                        data: {
                            deal_id: '',
                        },
                        step: 1,
                    })
                }
                title={
                    <div className="flex flex-row gap-[5px]">
                        <Text size="24" weight="bold">
                            Deal
                        </Text>
                        <Text color="secondary" size="24" weight="bold">
                            #{openModal?.data.id}
                        </Text>
                    </div>
                }
                modalBoxId={'dealTable'}
            >
                {openModal.type === 'view' && <ViewSegment dealData={openModal.data} />}
                {openModal.type === 'edit' && (
                    <>
                        {openModal.step === 1 && (
                            <Formik
                                enableReinitialize
                                initialValues={openModal.data}
                                onSubmit={(values, actions) => {
                                    setOpenModal({
                                        isOpen: true,
                                        isUpdate: false,
                                        data: values,
                                        step: 2,
                                    });
                                }}
                            >
                                <SegmentForm isUpdate={openModal.isUpdate} />
                            </Formik>
                        )}

                        {openModal.step === 2 && (
                            <SegmentDetail
                                detail={openModal.data}
                                onEdit={() =>
                                    setOpenModal({
                                        isOpen: true,
                                        isUpdate: true,
                                        data: openModal.data,
                                        step: 1,
                                    })
                                }
                            />
                        )}
                    </>
                )}
            </Modal>
        </>
    );
}

export default DealsTable;
