import Chip from 'components/Chip/Chip';
import ActionsButton from 'components/GridTable/ActionsButton';

export const ticketsColumn = [
    {
        field: 'id',

        headerName: 'ID',
        // selectHead: {
        //     renderContentSelect: () => {
        //         return (
        //             <ul className="p-2.5">
        //                 <li>Id</li>
        //             </ul>
        //         );
        //     },
        // },
    },

    {
        field: 'status',
        headerName: 'Status',

        renderCell: (row) => {
            return (
                <Chip className={'ml-[15px]'} color="white">
                    {row?.status}
                </Chip>
            );
        },
    },
    {
        field: 'author',

        headerName: 'Author',
    },

    {
        field: 'actions',

        headerName: 'Actions',
        renderCell: (row, handle) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return (
                <ActionsButton
                    onChange={(val) => {
                        handle(row, val);
                    }}
                />
            );
        },
    },
];

export const ticketRow = [
    {
        id: 324,

        status: 'In Queue',
        author: 'John Wood',
    },
    {
        id: 324,

        status: 'In Queue',
        author: 'John Wood',
    },
    {
        id: 324,

        status: 'In Queue',
        author: 'John Wood',
    },
    {
        id: 324,

        status: 'In Queue',
        author: 'John Wood',
    },
    {
        id: 324,

        status: 'In Queue',
        author: 'John Wood',
    },
    {
        id: 324,

        status: 'In Queue',
        author: 'John Wood',
    },
    {
        id: 324,

        status: 'In Queue',
        author: 'John Wood',
    },
    {
        id: 324,

        status: 'In Queue',
        author: 'John Wood',
    },
    {
        id: 324,

        status: 'In Queue',
        author: 'John Wood',
    },
    {
        id: 324,

        status: 'In Queue',
        author: 'John Wood',
    },
    {
        id: 324,

        status: 'In Queue',
        author: 'John Wood',
    },
];
