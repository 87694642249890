import clsx from 'clsx';
import Box from 'components/Box';
import Text from 'components/Text';
import { memo, useEffect, useRef, useState } from 'react';

function Tabs({ value, tabList, onChange, className, classNameBox }) {
    const defaultActive = tabList.find((item) => item?.value === value);

    const [active, setActive] = useState(defaultActive.value);

    const handleChange = (e, newValue) => {
        onChange(newValue);
    };
    const refBox = useRef();

    useEffect(() => {
        const activeValue = tabList.find((item) => item?.value === value);
        setActive(activeValue.value);
    }, [value]);

    return (
        <div className={clsx('relative grid h-[70px]', classNameBox)}>
            <Box
                ref={refBox}
                className={clsx('flex w-full max-w-min items-center  p-2.5 overflow-x-auto no-scrollbar', className)}
                shadow="outer"
                variant="outlined"
            >
                {tabList.map((tab, idx) => (
                    <Box
                        className={clsx(
                            'h-[40px] sm:h-[50px] px-[15px] w-full  flex-1   mx-auto cursor-pointer flex justify-center items-center',
                            tab.disable ? 'pointer-events-none opacity-25' : '',
                        )}
                        shadow={active === tab.value && 'inner'}
                        variant={active === tab.value || 'none'}
                        onClick={(e) => {
                            if (tab.disable) {
                                return;
                            }
                            setActive(tab.value);
                            handleChange(e, tab);
                        }}
                        key={tab?.value}
                    >
                        <Text className="text-center text-blue-dark whitespace-nowrap">
                            {tab?.label}
                            {tab?.sub && (
                                <Text component="span" className=" text-grey-light ml-[5px]">
                                    ({tab?.sub})
                                </Text>
                            )}
                        </Text>
                    </Box>
                ))}
            </Box>
        </div>
    );
}

export default memo(Tabs);
