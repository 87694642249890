import clsx from 'clsx';
import Text from 'components/Text';
import { memo, useId, useState } from 'react';

function Switch({ label, propsInput, onChange, ...rest }) {
    const switchId = useId();

    const [checked, setChecked] = useState(false);

    const handleChangeChecked = (e) => {
        const checkedStatus = e.target.checked;

        setChecked(checkedStatus);
        onChange && onChange(checkedStatus);
    };
    return (
        <>
            <label className="select-none w-fit flex flex-row items-center gap-2.5 cursor-pointer" htmlFor={switchId}>
                <div className="p-[5px] flex flex-row rounded-[10px] bg-white-light shadow-default w-fit">
                    <div
                        className={clsx(
                            'w-10 h-10 flex justify-center items-center text-xs  rounded-[10px]',
                            !checked ? 'bg-gray text-white shadow-chip' : '',
                        )}
                    >
                        OFF
                    </div>
                    <div
                        className={clsx(
                            'w-10 h-10 flex  justify-center items-center text-xs rounded-[10px]',
                            checked ? 'bg-primary text-white' : '',
                        )}
                    >
                        ON
                    </div>
                </div>
                {label && (
                    <Text className="text-xs xxs:text-sm" size="14">
                        {label}
                    </Text>
                )}
            </label>
            <input onChange={handleChangeChecked} hidden type="checkbox" id={switchId} {...propsInput} />
        </>
    );
}

export default memo(Switch);
