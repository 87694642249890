import clsx from 'clsx';
import { CardContent } from 'components/Card';
import { Circle } from 'components/Circle';
import { Svg } from 'components/Svg';
import { SubTitle } from 'components/Title';
import React from 'react';

function UpgradePlanItem({ active, cardProps, className, benefits }) {
    const {className:classNameCard, ...cardPropsRest } = cardProps
    return (
        <div className={clsx('flex flex-col gap-5', className)}>
            <CardContent
                className={clsx(classNameCard,active ? 'ring-[3px] ring-primary' : '')}
                {...cardPropsRest}
            />

            <div className="flex flex-col gap-2.5">
                {Array.isArray(benefits) &&
                    benefits.map((benefit, idx) => (
                        <div key={idx} className="flex gap-[15px] items-center">
                            <Circle className={'bg-blue-eae p-5'} shadow>
                                <Circle className={'bg-primary'}>{benefit?.icon || <Svg name={benefit.svg} />}</Circle>
                            </Circle>

                            <SubTitle>{benefit.title}</SubTitle>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default UpgradePlanItem;
