export const weightClass = (weight) => {
    switch (weight) {
        case 'normal':
            return 'font-normal';
        case 'medium':
            return 'font-medium';
        case 'semibold':
            return 'font-semibold';
        case 'bold':
            return 'font-bold';
        case 'extrabold':
            return 'font-extrabold';
        default:
            return 'font-normal';
    }
};

export const sizeClass = (size) => {
    switch (size) {
        case '10':
            return 'text-xxs';
        case '12':
            return 'text-xxs md:text-xs';
        case '14':
            return 'text-xs md:text-sm';
        case '16':
            return 'text-sm md:text-base';
        case '18':
            return 'text-base md:text-lg';
        case '20':
            return 'text-lg md:text-xl';
        case '24':
            return 'text-xl md:text-2xl';
        case 'custom':
            return '';
        default:
            return '';
    }
};

export const colorClass = (color) => {
    switch (color) {
        case 'primary':
            return 'text-blue-dark';
        case 'secondary':
            return 'text-grey-light';

        default:
            return 'text-blue-dark';
    }
};

export const familyStyle = (family) => {
    switch (family) {
        case 'inter':
            return '';
        case 'sens':
            return 'font-sen';

        default:
            return 'text-blue-dark';
    }
};
