import { SVG } from 'assets/imagePath';
import Box from 'components/Box/Box';
import { Button } from 'components/Button';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import React, { memo } from 'react';

const OrderSummary = memo(() => {
    return (
        <div className="">
            <div className="py-[15px]">
                <h3 className="text-base font-bold text-left text-blue-dark">Order Summary</h3>
            </div>
            <div className="p-[15px] bg-white-light rounded-[15px] shadow-default">
                <div className="flex flex-col">
                    <div className="flex flex-col ">
                        <h2 className="font-semibold text-xs pt-[15px]">Order Summary</h2>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">ID</p>
                            <p className="text-xs font-normal">321</p>
                        </div>
                    </div>

                    <div className="flex flex-col ">
                        <h2 className="font-semibold text-xs pt-[10px]">Campaign</h2>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Name</p>
                            <p className="text-xs font-normal">Campaign 1</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">State</p>
                            <p className="text-xs font-normal">Draft</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">ID</p>
                            <p className="text-xs font-normal">312</p>
                        </div>

                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Audience</p>
                            <p className="text-xs font-normal">Audience 1</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Creative</p>
                            <p className="text-xs font-normal">Creatives 1</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Created</p>
                            <p className="text-xs font-normal">02/22/2023</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Last Updated</p>
                            <p className="text-xs font-normal">02/22/2023</p>
                        </div>
                    </div>

                    <div className="flex flex-col ">
                        <h2 className="font-semibold text-xs pt-[10px]">Author</h2>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Author</p>
                            <p className="text-xs font-normal">John Wood</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Company</p>
                            <p className="text-xs font-normal">Audi</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Agency</p>
                            <p className="text-xs font-normal">Top Agency</p>
                        </div>
                    </div>

                    <div className="flex flex-col ">
                        <h2 className="font-semibold text-xs pt-[10px]">Service</h2>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Data Cost</p>
                            <p className="text-xs font-normal">$1.32</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Media Cost</p>
                            <p className="text-xs font-normal">$1.32</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Campaign Budget</p>
                            <p className="text-xs font-normal">$475.00</p>
                        </div>

                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Start Date</p>
                            <p className="text-xs font-normal">02/22/2023</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">End Date</p>
                            <p className="text-xs font-normal">02/22/2023</p>
                        </div>
                    </div>

                    <div className="flex flex-col ">
                        <h2 className="font-semibold text-xs pt-[10px]">Agreement</h2>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Agreement</p>
                            <p className="text-xs font-normal">Signed</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Terms</p>
                            <p className="text-xs font-normal">Checked</p>
                        </div>
                    </div>

                    <div className="flex flex-col ">
                        <h2 className="font-semibold text-xs pt-[10px]">Funds</h2>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Remaining funds</p>
                            <p className="text-xs font-normal">$475.00</p>
                        </div>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Total Spent</p>
                            <p className="text-xs font-normal">$475.00</p>
                        </div>
                    </div>

                    <div className="flex flex-col ">
                        <h2 className="font-semibold text-xs pt-[10px]">Review</h2>
                        <div className="flex justify-between py-[5px]">
                            <p className="text-xs font-normal">Status</p>
                            <p className="text-xs font-normal">Rejected</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-[15px]">
                <h3 className="text-base font-bold text-left text-blue-dark">Balance</h3>
            </div>

            <Box>
                <div className="flex flex-col p-[15px]">
                    <div className="flex justify-between py-[5px] gap-5">
                        <p className="text-xs font-normal">Available Balance</p>
                        <p className="text-xs font-normal">$6,325</p>
                    </div>
                    <div className="flex justify-between py-[5px] gap-5">
                        <p className="text-xs font-normal">Recent Top Up</p>
                        <p className="text-xs font-normal">$225</p>
                    </div>
                </div>
            </Box>

            <Button className={'w-full mt-5'} variant="outlined">
                <div className="flex flex-row gap-[5px] items-center">
                    <Svg name={'plus'} className="child:fill-black" />
                    <Text>Add Funds</Text>
                </div>
            </Button>
        </div>
    );
});

export default OrderSummary;
