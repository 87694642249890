import { EducationTopic } from 'components/Education';
import PublicEducationPostDetail from 'components/Education/PublicEducationPostDetail';
import { POSTS } from 'components/Education/data';
import { useEffect, useState } from 'react';

function PublicPostDetail() {
    const [post, setPost] = useState();
    useEffect(() => {
        //call api
        const fakePost = {
            id: 1,
            title: 'Post about topic',
            category: 'Category',
            relatedPosts: POSTS.slice(0, 3),
        };
        //call Related Posts
        setTimeout(() => {
            setPost(fakePost);
        }, 500);
    }, []);

    return (
        <div className="flex h-full p-5 md:p-[30px] ">
            <div className="flex flex-col gap-[30px] flex-1 max-w-[1200px] mx-auto">
                <PublicEducationPostDetail post={post} />
                <div className="flex flex-col items-center gap-5 max-w-[1080px] mx-auto max-xs:px-[40px]">
                    <EducationTopic topic={'Recent Articles'} posts={post?.relatedPosts} isPublic />
                </div>
            </div>
        </div>
    );
}

export default PublicPostDetail;
