import { useFormikContext } from 'formik';
import ColorPicker from './ColorPicker';
import { generateRandomColor } from 'utils';
import { useState } from 'react';
import GenerativeButton from './GenerativeButton';
import Text from 'components/Text';
import { SubTitle } from 'components/Title';

function CanvaUpdateColors() {
    const {
        values: { base, accent },
        setFieldValue,
    } = useFormikContext();

    const [rColor, setRColor] = useState('');

    const handleGenerateColor = () => {
        setRColor(generateRandomColor());
    };

    return (
        <div className="flex flex-col gap-5">
            <SubTitle>Choose Colors</SubTitle>
            <GenerativeButton
                className={'justify-center'}
                onClick={handleGenerateColor}
                label="Try Our Generative Color Palletes"
            />
            <ColorPicker
                onChangeColor={(color) => {
                    setFieldValue('base', color);
                }}
                defaultPicker={base}
                label={'Base'}
                name="base"
                externalColor={rColor}
            />
            <ColorPicker
                onChangeColor={(color) => {
                    setFieldValue('accent', color);
                }}
                defaultPicker={accent}
                label={'Accent'}
                name="accent"
                externalColor={rColor}
            />
        </div>
    );
}

export default CanvaUpdateColors;
