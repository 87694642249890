import { SVG } from 'assets/imagePath';
import GridTable from 'components/GridTable';
import TableHead from 'components/GridTable/TableHead';
import Image from 'components/Image';
import { earningColumn, earningRow } from './data';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function EarningsTable() {
    const navigate = useNavigate();
    const handleActionTable = (row, action) => {
        switch (action.value) {
            case 'view':
                navigate(routesPath.earning_detail(row.id));

                break;

            default:
                break;
        }
    };
    return (
        <>
            <TableHead title={'Earnings'} actionButton={{ label: 'Balance' }} />
            <div className="flex flex-col rounded-[10px] border border-blue-cfd overflow-hidden">
                <GridTable
                    handle={handleActionTable}
                    className={'w-full table-fixed'}
                    column={earningColumn}
                    row={earningRow}
                />

                <div className="bg-white-light flex justify-end p-[15px]  gap-[5px]">
                    <p className="text-sm font-normal text-grey-light">2 of 2 records</p>
                    <Image src={SVG.chevron_left_light} />
                    <Image src={SVG.chevron_right_light} />
                </div>
            </div>
        </>
    );
}

export default EarningsTable;
