import { Button } from 'components/Button';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import FormInputText from 'components/modules/form/FormInputText';
import { Form, Formik } from 'formik';
import React from 'react';

function ResetVerifyPw({ setStep }) {
    return (
        <div className="flex flex-col items-center gap-5">
            <Text className="mb-5 text-center" weight="bold" size="24">
                Verify Password Reset
            </Text>

            <Text className="text-center">
                Please check your email for the code or verification link sent from an adNucleus.com email address.
            </Text>

            {/* <Text weight="bold" className="mb-5 text-center text-error">
                    {err}
                </Text> */}

            <Formik
                className=""
                initialValues={{}}
                onSubmit={(values, actions) => {
                    setStep(3);
                    setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                    }, 0);
                }}
            >
                <Form className="w-full">
                    <FormInputText
                        className="mb-[20px] w-full"
                        placeholder="Confirmation code"
                        rounded
                        name="verify_code"
                    />

                    <Button
                        type="submit"
                        className="mx-auto flex  justify-center w-full max-w-[260px] py-[15px] px-5 mb-[20px] shadow-outer"
                    >
                        Verify
                    </Button>
                </Form>
            </Formik>

            <div className="flex flex-start gap-[5px] items-center">
                <Text>Don't received?</Text>
                <Svg name="infoOutline" />
                <Text underline>Resend</Text>
            </div>
        </div>
    );
}

export default ResetVerifyPw;
