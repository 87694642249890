import React, { useEffect, useState } from 'react';

function useDebounce(value, delay = 500, customizeFunc = undefined) {
    const [result, setResult] = useState(value);
    useEffect(() => {
        let id = setTimeout(() => {
            setResult(value);
            customizeFunc?.();
        }, delay);

        return () => {
            clearTimeout(id);
        };
    }, [value]);

    return result;
}

export default useDebounce;
