import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { colorClass, familyStyle, sizeClass, weightClass } from './Text.styles';

function Text(
    {
        component = 'p',
        weight = 'normal',
        size = '14',
        color = 'primary',
        family = 'inter', // 'inter' | 'sens'
        className,
        children,
        underline = false,
        ellipsis = false,
        ...props
    },
    ref,
) {
    let Component = component;

    return (
        <Component
            ref={ref}
            className={clsx(
                '',
                weightClass(weight),
                colorClass(color),
                sizeClass(size),
                familyStyle(family),
                underline ? 'underline underline-offset-[3px] cursor-pointer' : '',
                ellipsis ? 'overflow-hidden text-ellipsis whitespace-nowrap' : '',
                className,
            )}
            {...props}
        >
            {children}
        </Component>
    );
}

export default forwardRef(Text);
