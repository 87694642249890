import { NotificationSubCo } from 'components/AccountConfig/Notifications';
import { PaymentsSubCo } from 'components/AccountConfig/Payments';
import { ProfileSubCo } from 'components/AccountConfig/Profile';
import { SettingsSubCo } from 'components/AccountConfig/Settings';
import { StatusSubCo } from 'components/AccountConfig/Status';
import { SupportSubCo } from 'components/AccountConfig/Support';
import { UsersSubCo } from 'components/AccountConfig/Users';
import Tabs from 'components/Tabs';
import Text from 'components/Text';
import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const accountTabs = [
    { label: 'Profile', value: 'profile' },
    { label: 'Settings', value: 'settings' },
    {
        label: 'Notifications',
        value: 'notifications',
    },

    { label: 'Payments', value: 'payments' },
    { label: 'Resources', value: 'resources' },
    { label: 'Support', value: 'support' },
    { label: 'Status', value: 'status' },
    { label: 'Users', value: 'users' },
    // { label: 'Companies', value: 'companies', },
    // { label: 'Earnings', value: 'earnings', },
    // { label: 'Agencies', value: 'agencies', },
    // { label: 'Leads', value: 'leads', },
    // { label: 'QA/QC', value: 'qaQc', },
];

function AccountConfigSubCompany() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const defaultConfig = urlParams.get('config');

    const defaultTabs = accountTabs.find((item) => item.value === defaultConfig);
    const [searchParams, setSearchParams] = useSearchParams({
        config: defaultTabs?.value,
    });

    const locationState = useLocation()?.state;

    // const defaultTabs = accountTabs.find((item) => item.value === !!locationState?.config);

    const [tabsVal, setTabsVal] = useState(defaultTabs || accountTabs[0]);

    const renderContent = () => {
        switch (tabsVal.value) {
            case 'profile':
                return <ProfileSubCo />;
            case 'settings':
                return <SettingsSubCo />;
            case 'notifications':
                return <NotificationSubCo />;
            case 'payments':
                return <PaymentsSubCo />;
            case 'support':
                return <SupportSubCo />;

            case 'status':
                return <StatusSubCo />;
            case 'users':
                return <UsersSubCo />;
            default:
                break;
        }
    };

    useEffect(() => {
        if (!defaultTabs) {
            setSearchParams({ config: tabsVal.value });
        }
    }, []);

    return (
        <div className="p-2.5 md:p-[30px] flex flex-col gap-[30px]">
            <Text className="py-[10.5px]" size="24" weight="bold">
                {'Company account'}
            </Text>
            <Tabs
                onChange={(tab) => {
                    setTabsVal(tab);
                    setSearchParams({
                        config: tab.value,
                    });
                }}
                tabList={accountTabs}
                value={tabsVal.value}
            />

            {renderContent()}
        </div>
    );
}

export default AccountConfigSubCompany;
