import { SVG } from 'assets/imagePath';
import { DropdownMenu } from 'components/Dropdown';
import Dropdown from 'components/Dropdown/Dropdown';
import React from 'react';

function ActionsButton({
    options = [
        { label: 'View', value: 'view' },

        { label: 'Edit', value: 'edit' },
        { label: 'Delete', value: 'delete' },
    ],
    onChange = (valueAction) => {},
}) {
    return (
        <Dropdown
            className={'right-0 max-h-[150px] overflow-auto'}
            options={({ close }) => (
                <DropdownMenu
                    menu={options}
                    onChange={(menuItem) => {
                        onChange(menuItem);
                        close();
                    }}
                />
            )}
        >
            <button
                type="button"
                className="flex max-md:ml-auto  justify-end md:justify-start items-center p-0 md:p-[15px]"
            >
                <img className="object-contain mx-auto cursor-pointer" src={SVG.more_vert} alt="more" />
            </button>
        </Dropdown>
    );
}

export default ActionsButton;
