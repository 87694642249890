import { SVG } from 'assets/imagePath';
import Box from 'components/Box/Box';
import { BoxLabel } from 'components/BoxLabel';
import { CardContent } from 'components/Card';
import {
    defaultStyleData,
    mediaData,
    labels,
    baseMediaCostData,
    orgMediaCostData,
} from 'components/Charts/MediaCostChart';
import MediaCostLineChart from 'components/Charts/MediaCostChart/MediaCostLineChart';
import { HeaderNavigate } from 'components/HeaderNavigate';
import Image from 'components/Image/Image';
import { InputText } from 'components/Input';
import ProgressCircle from 'components/ProgressCircle/ProgressCircle';
import Text from 'components/Text/Text';
import { SubTitle } from 'components/Title';
import FormInputMarkup from 'components/modules/form/FormInputMarkup';
import FormInputText from 'components/modules/form/FormInputText';
import FormRange from 'components/modules/form/FormRange';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

function Pricing() {
    const progress = 50;
    const size = 120;
    const sizeClass = '[120px]';
    const strokeWidth = 10;
    const circleOneStroke = '#f2f4f6';
    const circleTwoStroke = '#3E85F4';
    const circleErr = '#E32416';
    const circleWarning = '#E39115';

    const pricingSchema = Yup.object({
        base_media_cost: Yup.number().required('Required, please enter a valid Base Media Cost'),
        agency_media_cost_markup: Yup.string().required('Required, please enter a valid markup'),
    });
    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <div className="flex flex-col gap-[30px]">
                <HeaderNavigate detailInfo={{ detailName: 'Pricing' }} />
            </div>

            <div className="flex flex-col gap-5 mt-[30px]">
                <SubTitle className="py-[14px]">Subscriptions</SubTitle>

                <div className="flex flex-row gap-5">
                    <CardContent
                        className={'max-w-[186px] w-full'}
                        title={'Agency subscribers'}
                        content={'7'}
                        actionText="View"
                        // onAction={() => navigate(routesPath.deals)}
                    />
                    <CardContent
                        className={'max-w-[186px] w-full'}
                        title={'Company subscribers'}
                        content={'54'}
                        actionText="View"
                        // onAction={() => navigate(routesPath.deals)}
                    />
                    <CardContent
                        className={'max-w-[186px] w-full'}
                        title={'Sub Company clients'}
                        content={'21'}
                        actionText="View"
                        // onAction={() => navigate(routesPath.deals)}
                    />
                </div>

                <InputText readOnly mark="$" className={'pl-[29px]'} value="179" label="Company Pricing" />
                <InputText readOnly mark="$" className={'pl-[29px]'} value="279" label="Agency Pricing" />
            </div>

            <div className="flex flex-col gap-5 mt-[30px]">
                <SubTitle className="py-[14px]">Ad pricing</SubTitle>

                <Formik
                    initialValues={{
                        base_media_cost: '0.50',
                        agency_media_cost_markup: '10%',
                        retail_media_cost_markup: '10%',
                    }}
                    validationSchema={pricingSchema}
                    validateOnChange
                    onSubmit={(data) => {
                        console.log(data);
                    }}
                >
                    {(props) => {
                        return (
                            <Form className="flex flex-col gap-5">
                                <FormInputText
                                    label="Base Media Cost"
                                    type="number"
                                    step="any"
                                    name="base_media_cost"
                                    mark="$"
                                />
                                <div className="flex flex-row gap-5">
                                    <FormInputMarkup
                                        label="Agency Media Cost Markup"
                                        name="agency_media_cost_markup"
                                        type={'number'}
                                        max="100"
                                    />
                                    <FormInputMarkup
                                        label="Retail Media Cost Markup"
                                        name="retail_media_cost_markup"
                                        max="100"
                                    />
                                </div>
                                <div className="flex flex-row gap-[30px]">
                                    <Box
                                        shadow="default"
                                        variant="outlined"
                                        className="w-full h-[220px]  flex-1 bg-white-light p-[15px]"
                                    >
                                        <div>
                                            <MediaCostLineChart
                                                customTitle={
                                                    <div className="flex flex-1 xs:flex-row flex-col gap-2.5 justify-between">
                                                        <Text weight="bold" size="12">
                                                            Media CPM per impressions (in %)
                                                        </Text>

                                                        <div className="flex flex-row gap-5">
                                                            <div className="flex gap-[5px] items-center">
                                                                <div className="w-[10px] h-[10px] bg-error rounded-full" />
                                                                <Text component="p" size="12">
                                                                    Base Media Cost
                                                                </Text>
                                                                <Image src={SVG.info_light} />
                                                            </div>
                                                            <div className="flex gap-[5px] items-center">
                                                                <div className="w-[10px] h-[10px] bg-primary rounded-full" />
                                                                <Text component="p" size="12">
                                                                    Media Cost
                                                                </Text>
                                                                <Image src={SVG.info_light} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                data={{
                                                    labels,
                                                    datasets: [
                                                        {
                                                            ...defaultStyleData,
                                                            label: 'Media Cost',
                                                            backgroundColor: '#0E65F1',
                                                            borderColor: '#0E65F1',
                                                            data: mediaData,
                                                        },
                                                        {
                                                            ...defaultStyleData,
                                                            label: 'Base Media Cost',
                                                            backgroundColor: circleErr,
                                                            borderColor: circleErr,
                                                            data: baseMediaCostData,
                                                        },
                                                        {
                                                            ...defaultStyleData,
                                                            // label: 'OrgMedia Cost',
                                                            backgroundColor: circleWarning,
                                                            borderColor: circleWarning,
                                                            data: orgMediaCostData,
                                                        },
                                                    ],
                                                }}
                                            />
                                        </div>
                                    </Box>
                                    <div className="flex flex-1 flex-col items-center gap-[15px]">
                                        <BoxLabel
                                            variant="outlined"
                                            shadow="none"
                                            label="Media cost"
                                            classNameLabel={'left-[40px] border-none rounded-none'}
                                            className={'max-w-[450px] w-full'}
                                        >
                                            <div className="px-[30px] pt-[30px] pb-5">
                                                <FormRange
                                                    color="error"
                                                    name="retail_media_cost_markup"
                                                    defaultValue={10}
                                                    customValueForm={(value) => `${value}%`}
                                                />
                                            </div>
                                        </BoxLabel>

                                        <div className="flex flex-row gap-[30px] ">
                                            <div className="rounded-full shadow-outer">
                                                <ProgressCircle
                                                    progress={progress}
                                                    size={size}
                                                    sizeClass={sizeClass}
                                                    strokeWidth={strokeWidth}
                                                    circleOneStroke={circleOneStroke}
                                                    circleColor={circleErr}
                                                    ContentValue={
                                                        <div className="flex flex-col items-center justify-center aspect-square gap-[5px]">
                                                            <div className="flex items-center px-[7px]">
                                                                <Text className="text-center" component="p" size="10">
                                                                    Organization <br /> Media Cost
                                                                </Text>
                                                            </div>
                                                            <Text size="20" weight="semibold">
                                                                ${props.values.base_media_cost}
                                                            </Text>
                                                        </div>
                                                    }
                                                />
                                            </div>

                                            <div className="rounded-full shadow-outer">
                                                <ProgressCircle
                                                    progress={progress}
                                                    size={size}
                                                    sizeClass={sizeClass}
                                                    strokeWidth={strokeWidth}
                                                    circleOneStroke={circleOneStroke}
                                                    circleColor={circleWarning}
                                                    ContentValue={
                                                        <div className="flex flex-col items-center justify-center aspect-square gap-[5px]">
                                                            <div className="flex items-center px-[7px]">
                                                                <Text className="text-center" component="p" size="10">
                                                                    Agency <br /> Media Cost
                                                                </Text>
                                                            </div>
                                                            <Text size="20" weight="semibold">
                                                                $0.55
                                                            </Text>
                                                            <Text component="p" size="10">
                                                                {props.values.agency_media_cost_markup} Markup
                                                            </Text>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            <div className="rounded-full shadow-outer">
                                                <ProgressCircle
                                                    progress={progress}
                                                    size={size}
                                                    sizeClass={sizeClass}
                                                    strokeWidth={strokeWidth}
                                                    circleOneStroke={circleOneStroke}
                                                    circleColor={circleTwoStroke}
                                                    ContentValue={
                                                        <div className="flex flex-col items-center justify-center aspect-square gap-[5px]">
                                                            <div className="flex items-center px-[7px]">
                                                                <Text className="text-center" component="p" size="10">
                                                                    Retail <br /> Media Cost
                                                                </Text>
                                                            </div>
                                                            <Text size="20" weight="semibold">
                                                                $0.65
                                                            </Text>
                                                            <Text component="p" size="10">
                                                                {props.values.retail_media_cost_markup} Markup
                                                            </Text>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}

export default Pricing;
