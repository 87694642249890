import Chip from 'components/Chip/Chip';
import Image from 'components/Image/Image';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import { useNavigate } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function EducationPost({ post, isPublic = false }) {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col overflow-hidden">
            <div className="relative  rounded-[20px]  aspect-[4/3] overflow-hidden">
                <Image src={post.image} className={'object-cover object-center w-full'} />

                <Chip size="small" className={'absolute !bottom-[10px] left-[10px] !px-2 !py-1'} color="white">
                    <Text size="10" color="secondary">
                        {post.category}
                    </Text>
                </Chip>
            </div>

            <div className="p-[15px] pt-5">
                <div
                    className="cursor-pointer flex gap-[5px] items-center"
                    onClick={() =>
                        navigate(isPublic ? routesPath.public_post_detail(post?.id) : routesPath.post_detail(post?.id))
                    }
                >
                    <Text size="12" weight="bold" ellipsis>
                        {post.post}
                    </Text>

                    <Svg name="chevronDown" className="-rotate-90" />
                </div>
            </div>
        </div>
    );
}

export default EducationPost;
