import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import FormInputText from 'components/modules/form/FormInputText';
import FormSelect from 'components/modules/form/FormSelect';
import FormObserver, { resetPresForm } from 'components/modules/FormikObserver/FormObserver';
import { Portal } from 'components/Portal';
import Text from 'components/Text';
import { Form, Formik, getIn, useFormik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import AddBank from './Withdraw/AddBank';

function WithdrawModule({}) {
    const [isOpen, setIsOpen] = useState(false);

    const [valuesForm, setValuesForm] = useState({}); //initvalue
    // getIn(values, name)
    const bankAccounts = [
        { label: 'Default Bank Account', value: 'default' },
        {
            label: 'Add  a Bank Account',
            value: 'add_bank',
            onClickCustom: () => {
                setIsOpen(true);
            },
        },
    ];

    useEffect(() => {
        return () => {
            resetPresForm();
        };
    }, []);

    return (
        <>
            <div className="mt-2.5 flex flex-col w-full gap-5">
                <Text component="span" size="14">
                    Available amount{' '}
                    <Text size="14" className="text-success" weight="bold" component="span">
                        $12,430
                    </Text>
                </Text>

                <Formik
                    initialValues={{}}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            alert(JSON.stringify(values, null, 2));
                            actions.setSubmitting(false);
                        }, 1000);
                    }}
                >
                    <Form className="flex flex-col gap-5">
                        {bankAccounts.length === 0 ? (
                            <Button
                                variant="outlined"
                                className={'child:justify-start'}
                                onClick={() => setIsOpen(true)}
                            >
                                Add a Bank Account
                            </Button>
                        ) : (
                            <div className="flex flex-col gap-2.5 justify-end">
                                <FormSelect
                                    placeholder="Select Bank Account"
                                    propsDropdown={{ classNameBox: 'flex-1' }}
                                    placeholderTop
                                    name="bank_account"
                                    options={bankAccounts}
                                />
                                {valuesForm?.bank_account && (
                                    <Text
                                        className="text-right w-fit ml-auto"
                                        component="a"
                                        underline
                                        onClick={() => resetPresForm()}
                                    >
                                        Edit Selected Bank Details
                                    </Text>
                                )}
                            </div>
                        )}
                        <FormInputText name="amount" placeholder="Choose amount" />
                        <FormObserver getValues={setValuesForm} />
                        <Button type="submit" className={'w-full'}>
                            Withdraw
                        </Button>
                    </Form>
                </Formik>
            </div>
            {isOpen && (
                <Portal>
                    <Modal
                        bodyClass={'max-w-[800px] w-full pb-[30px] z-[100]'}
                        className="z-[100]"
                        title="Add Bank Account"
                        isOpen={isOpen}
                        onClose={() => setIsOpen(false)}
                    >
                        <AddBank />
                    </Modal>
                </Portal>
            )}
        </>
    );
}

export default WithdrawModule;
