import { resourceColumn, resourceRow } from 'components/AccountConfig/Education/data';
import { DetailBox } from 'components/DetailBox';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { usersColumn, usersRow } from 'pages/Dashboard/data';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function PostReview() {
    // const navigate = useNavigate();

    const { postId } = useParams();

    const rowDetail = resourceRow.find((item) => {
        return item?.id == postId;
    });

    const [row, setRow] = useState(rowDetail || usersRow[0]);

    useEffect(() => {
        //Fetch Row Earning Detail
        // setRow(rowDetail)
    }, []);

    return (
        <div className="p-2.5 md:p-[30px] w-full">
            <div className="flex flex-col gap-[30px]">
                <HeaderNavigate detailInfo={{ detailName: 'Post', detailId: postId }} />

                <div className="max-w-[400px]">
                    <DetailBox
                        title="Post"
                        column={resourceColumn.filter((item) => item?.field !== 'actions')}
                        row={row}
                    />
                </div>
            </div>
        </div>
    );
}

export default PostReview;
