import { BoxContent } from 'components/BoxContent';
import Text from 'components/Text';
import React from 'react';

function Signatures() {
    return (
        <BoxContent title="Signatures">
            <div className="flex flex-row gap-2.5 mt-[30px]">
                <Text>You have 2 saved signatures</Text>
                <Text underline>Edit</Text>
            </div>
        </BoxContent>
    );
}

export default Signatures;
