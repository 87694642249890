import Box from 'components/Box/Box';
import { BoxLabelOutline } from 'components/BoxLabel';
import { Button } from 'components/Button';
import Image from 'components/Image/Image';
import { Modal } from 'components/Modal';
import { Portal } from 'components/Portal';
import Text from 'components/Text/Text';
import { useAdCanva } from 'context/AdCanvaContext';
import { Formik, useFormikContext } from 'formik';
import { useState } from 'react';
import GenerateImage from './GenerateImageForm';
import GenerateLogo from './GenerateLogoForm';
import GenerativeButton from './GenerativeButton';

import { MdCheck, MdClose, MdOutlineEdit } from 'react-icons/md';

import { InputText } from 'components/Input';
import { SubTitle } from 'components/Title';
import FormUpload from 'components/modules/form/FormUpload';

function CanvaUpdateBg() {
    const { setStep } = useAdCanva();
    const onNext = () => {
        setStep(3);
    };

    const [openModal, setOpenModal] = useState({
        type: '',
        open: false,
        data: {},
    });

    const handleCloseModal = () => {
        setOpenModal((prev) => ({
            ...prev,
            open: false,
        }));
    };
    const handleOpenModal = (type) => {
        setOpenModal((prev) => ({
            ...prev,
            open: true,
            type,
        }));
    };

    const { setValues, values, setFieldValue } = useFormikContext();

    const [descUpdate, setDescUpdate] = useState({
        isUpdateLogo: false,
        isUpdateImage: false,
        updateDescLogo: '',
        updateDescImg: '',
    });

    return (
        <>
            <div className="flex flex-col items-center gap-5">
                <div className="flex w-full  flex-1 flex-col gap-[5px]">
                    <SubTitle>Choose Image</SubTitle>
                </div>

                <GenerativeButton
                    label="Try Our Generative Logo"
                    onClick={() => {
                        handleOpenModal('logo');
                    }}
                />
                <BoxLabelOutline className={'flex-col flex !p-5 w-full'} label={'Logo'}>
                    {!values.logo?.img ? (
                        <div className="flex w-full gap-5">
                            <Button className="flex-1" variant="outlined">
                                Library
                            </Button>
                            <FormUpload
                                keepBlob
                                className="!py-0 !bg-inherit !shadow-none !flex-1"
                                name="logo.img"
                                renderWrapper={() => (
                                    <Text className="w-full h-full" variant="outlined">
                                        Upload
                                    </Text>
                                )}
                            />
                        </div>
                    ) : (
                        <Box className="p-[5px] w-full bg-white">
                            <div className="flex w-full flex-row items-center gap-[5px] bg-white">
                                <Image
                                    className={'h-[32px] w-[32px] object-cover rounded-lg'}
                                    src={values.logo?.img?.preview}
                                />

                                {descUpdate.isUpdateLogo ? (
                                    <InputText
                                        defaultValue={values.logo?.desc}
                                        onChange={(e) => {
                                            setDescUpdate((prev) => ({
                                                ...prev,
                                                updateDescLogo: e.target.value,
                                            }));
                                        }}
                                        className={'w-full !bg-transparent py-0 !px-[2.5px] shadow-none !text-[12px]'}
                                    />
                                ) : (
                                    <Text component="div" className="!leading-[12px] flex-1 w-full h-full" size="12">
                                        {values.logo?.desc}
                                    </Text>
                                )}
                                <div className="w-[15px] h-[15px]">
                                    {descUpdate.isUpdateLogo ? (
                                        <MdCheck
                                            className="cursor-pointer "
                                            size={15}
                                            onClick={() => {
                                                setValues({
                                                    ...values,
                                                    logo: {
                                                        img: values.logo.img,
                                                        desc: descUpdate.updateDescLogo,
                                                    },
                                                });

                                                setDescUpdate((prev) => ({
                                                    ...prev,
                                                    isUpdateLogo: false,
                                                    // updateDescLogo: '',
                                                }));
                                            }}
                                        />
                                    ) : (
                                        <MdOutlineEdit
                                            className="cursor-pointer"
                                            size={15}
                                            onClick={() => {
                                                setDescUpdate((prev) => ({
                                                    ...prev,
                                                    isUpdateLogo: true,
                                                }));
                                            }}
                                        />
                                    )}
                                </div>
                                <div className="w-[15px] h-[15px]">
                                    <MdClose
                                        className="cursor-pointer"
                                        size={15}
                                        onClick={() => {
                                            if (descUpdate.isUpdateLogo) {
                                                setDescUpdate((prev) => ({
                                                    ...prev,
                                                    isUpdateLogo: false,
                                                    updateDescLogo: values.logo.desc,
                                                }));
                                            } else {
                                                //Clear blob image
                                                URL.revokeObjectURL(values.logo?.img?.preview);
                                                setValues({
                                                    ...values,
                                                    logo: undefined,
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </Box>
                    )}
                </BoxLabelOutline>

                <GenerativeButton
                    label="Try Our Generative Image"
                    onClick={() => {
                        handleOpenModal('img');
                    }}
                />
                <BoxLabelOutline className={'flex-col flex !p-5 w-full'} label={'Image'}>
                    {!values.background?.img ? (
                        <div className="flex w-full gap-5">
                            <Button className="flex-1" variant="outlined">
                                Library
                            </Button>
                            <FormUpload
                                keepBlob
                                className="!py-0 !bg-inherit !shadow-none !flex-1"
                                name="background.img"
                                renderWrapper={() => (
                                    <Text className="w-full h-full" variant="outlined">
                                        Upload
                                    </Text>
                                )}
                            />
                        </div>
                    ) : (
                        <Box className="p-[5px] w-full flex flex-row items-center gap-[5px] bg-white">
                            <Image
                                className={'h-[32px] w-[32px] object-cover rounded-lg'}
                                src={values.background?.img?.preview}
                            />

                            {descUpdate.isUpdateImage ? (
                                <InputText
                                    defaultValue={values.background?.desc}
                                    onChange={(e) => {
                                        setDescUpdate((prev) => ({
                                            ...prev,
                                            updateDescImg: e.target.value,
                                        }));
                                    }}
                                    className={'w-full !bg-transparent py-0 !px-[2.5px] shadow-none !text-[12px]'}
                                />
                            ) : (
                                <Text component="div" className="!leading-[12px] flex-1 w-full h-full" size="12">
                                    {values.background?.desc}
                                </Text>
                            )}

                            <div className="w-[15px] h-[15px]">
                                {descUpdate.isUpdateImage ? (
                                    <MdCheck
                                        className="cursor-pointer "
                                        size={15}
                                        onClick={() => {
                                            setValues({
                                                ...values,
                                                background: {
                                                    img: values.background.img,
                                                    desc: descUpdate.updateDescImg,
                                                },
                                            });

                                            setDescUpdate((prev) => ({
                                                ...prev,
                                                isUpdateImage: false,
                                                // updateDescLogo: '',
                                            }));
                                        }}
                                    />
                                ) : (
                                    <MdOutlineEdit
                                        className="cursor-pointer"
                                        size={15}
                                        onClick={() => {
                                            setDescUpdate((prev) => ({
                                                ...prev,
                                                isUpdateImage: true,
                                            }));
                                        }}
                                    />
                                )}
                            </div>
                            <div className="w-[15px] h-[15px]">
                                <MdClose
                                    className="cursor-pointer"
                                    size={15}
                                    onClick={() => {
                                        if (descUpdate.isUpdateImage) {
                                            setDescUpdate((prev) => ({
                                                ...prev,
                                                isUpdateImage: false,
                                                updateDescImg: values.background.desc,
                                            }));
                                        } else {
                                            //Clear blob image
                                            URL.revokeObjectURL(values.background?.img?.preview);
                                            setValues({
                                                ...values,
                                                background: undefined,
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </Box>
                    )}
                </BoxLabelOutline>
            </div>

            <Portal>
                <Modal
                    bodyClass="max-w-[580px] w-full"
                    isOpen={openModal.type === 'img' && openModal.open}
                    onClose={handleCloseModal}
                    title="Generate Image"
                >
                    <Formik
                        initialValues={{
                            generateDesc: '',
                            generateImg: '',
                        }}
                        onSubmit={(data, actions) => {
                            setValues({
                                ...values,
                                background: {
                                    desc: data?.generateDesc,
                                    img: data?.generateImg,
                                },
                            });
                            handleCloseModal();
                        }}
                    >
                        <GenerateImage />
                    </Formik>
                </Modal>
            </Portal>

            <Portal>
                <Modal
                    bodyClass="max-w-[580px] w-full"
                    isOpen={openModal.type === 'logo' && openModal.open}
                    onClose={handleCloseModal}
                    title="Generate Logo"
                >
                    <Formik
                        initialValues={{
                            logoDesc: '',
                            imgLogo: '',
                        }}
                        onSubmit={(data, actions) => {
                            setValues({
                                ...values,
                                logo: {
                                    desc: data.logoDesc,
                                    img: data.imgLogo,
                                },
                            });
                            handleCloseModal();
                        }}
                    >
                        <GenerateLogo />
                    </Formik>
                </Modal>
            </Portal>
        </>
    );
}

export default CanvaUpdateBg;
