import { AudienceForm } from 'components/Audiences';
import AudienceSummary from 'components/Audiences/AudienceSummary';
import { Button } from 'components/Button';
import { HeaderNavigate } from 'components/HeaderNavigate';
import { Form, Formik } from 'formik';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function AudienceService() {
    const { audienceId } = useParams();

    const formikRef = useRef(null);

    return (
        <div className="flex h-full">
            <div className="flex-1 p-5 md:p-[30px]">
                <HeaderNavigate
                    navigateTo={routesPath.audiences}
                    detailInfo={{ detailName: 'Audience', detailId: audienceId, chipLabel: 'Draft' }}
                    chipProps={{ color: 'white' }}
                    ActionArea={
                        <div>
                            <Button
                                onClick={() => {
                                    formikRef.current?.submitForm();
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    }
                />
                <Formik
                    innerRef={formikRef}
                    initialValues={{
                        targeting_method: 'social',
                        type: 'location',
                    }}
                    onSubmit={(values) => {
                        alert(JSON.stringify(values));
                    }}
                >
                    {({ values }) => (
                        <Form>
                            <AudienceForm targetMethodVal={values?.targeting_method} />
                        </Form>
                    )}
                </Formik>
            </div>

            <div className="lg:block hidden px-5 py-[30px] w-[280px] bg-blue-ebe border-l border-blue-cfd h-full">
                <AudienceSummary />
            </div>
        </div>
    );
}

export default AudienceService;
