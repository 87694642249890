import ActionsButton from 'components/GridTable/ActionsButton';

export const dealsRow = [
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
    {
        id: 5765067,
        created_at: '2023-03-12 03:51 PM',
        creative_sizes: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        audience_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],
        semantic_segments: ['300x250', '300x600', '728x90', '320x50', '160x600', '320x100', '300x300'],

        data_cost: '6.857',
        media_cost: '1.24',
        currency: 'USD',
        country: 'USA',
    },
];
