import { SVG } from 'assets/imagePath';
import { IconButton } from 'components/Button';
import { EducationPost } from 'components/Education';
import { POSTS } from 'components/Education/data';
import { InputText } from 'components/Input';
import { SubTitle } from 'components/Title';
import { useEffect, useState } from 'react';

function TopicDetail() {
    const [topic, setTopic] = useState();

    useEffect(() => {
        //call api
        const fakeTopic = {
            id: 1,
            title: 'External',
            posts: POSTS,
        };

        setTimeout(() => {
            setTopic(fakeTopic);
        }, 500);
    }, []);

    return (
        <div className="p-2.5 md:p-[30px]">
            <div className="font-bold text-2xl text-blue-dark mb-[30px] py-[10.5px]">
                <h2>{topic?.title}</h2>
            </div>
            <div className="flex flex-col gap-5">
                <div className="flex items-center justify-between gap-5">
                    <div className="h-[40px] flex items-end">
                        <SubTitle>External</SubTitle>
                    </div>
                    <div className="flex justify-start gap-5 md:items-center">
                        <IconButton
                            variant="outlined"
                            className="!w-[50px] h-[50px]  !shadow-none !p-[17.5px]"
                            source={SVG.filter}
                            onClick={() => {}}
                        />
                        <InputText
                            className={'shadow-inner rounded-[10px] !w-full md:w-60'}
                            icon={SVG.search}
                            placeholder="Search"
                        />
                    </div>
                </div>

                <div className="grid grid-flow-row grid-cols-2 gap-5 xs:grid-cols-3 md:grid-cols-5 lg:grid-cols-3 xl:grid-cols-5">
                    {Array.isArray(topic?.posts) &&
                        topic?.posts.map((post, idx) => <EducationPost key={post?.id || idx} post={post} />)}
                </div>
            </div>
        </div>
    );
}

export default TopicDetail;
