import { SVG } from 'assets/imagePath';
import clsx from 'clsx';
import Box from 'components/Box/Box';
import Image from 'components/Image/Image';
import { Svg } from 'components/Svg';
import Text from 'components/Text/Text';
import UploadImage from './UploadImage';
import { useState } from 'react';
import UploadVideo from './UploadVideo';

function InputUploadVideo({ label, className, helper, error, defaultVideo, ...props }) {
    const [video, setVideo] = useState({
        preview: defaultVideo,
    });
    return (
        <Box shadow="default" className={clsx('bg-white-light p-5   relative', className)}>
            {label && (
                <Text
                    className={clsx(
                        'z-1 px-[10px]  absolute bg-inherit z-0  rounded-[30px] shadow-default-top bg-white-light left-[15px] -top-[5px]  text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                    )}
                    size="10"
                >
                    {label}
                </Text>
            )}
            <UploadVideo
                video={video}
                className={'aspect-video '}
                setVideo={setVideo}
                renderRemoveButton={({ remove }) => (
                    <Svg
                        className="absolute cursor-pointer -bottom-[10px] -right-[10px]"
                        name="trash"
                        onClick={remove}
                    />
                )}
                {...props}
            >
                <Image src={SVG.upload} />
            </UploadVideo>
            {helper && (
                <Text
                    className={clsx(
                        'px-[10px] absolute bg-inherit z-0  rounded-[30px] shadow-default-bottom  bg-white-light left-[15px] -bottom-[5px] text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                        error ? 'text-error' : '',
                    )}
                    size="10"
                >
                    {helper}
                </Text>
            )}
        </Box>
    );
}

export default InputUploadVideo;
