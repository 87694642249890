import { SVG } from 'assets/imagePath';
import { Button, IconButton } from 'components/Button';
import { FilterButton, SearchBox } from 'components/FilterTools';
import { InputText } from 'components/Input';
import Text from 'components/Text';
import React from 'react';

function TableHead({ title, actionButton, tools }) {
    return (
        <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-[20px] gap-5">
            <Text className="!text-[18px] flex-1 " weight="bold">
                {title}
            </Text>
            <div className="flex justify-start gap-5 md:items-center">
                <FilterButton {...tools?.filter} />

                <SearchBox {...tools?.search} />
                {actionButton && (
                    <Button size="medium" className={'px-5'} onClick={() => actionButton.onClick()}>
                        {actionButton.label}
                    </Button>
                )}
            </div>
        </div>
    );
}

export default TableHead;
