import { AddCompany, CompanyDetail } from 'components/AccountConfig/Companies';
import { AddUser, UserDetail } from 'components/AccountConfig/Users';
import GgMap from 'components/Map/GgMap';
import { AUTH_KEY } from 'constants/localStorageKey';
import { useAuth } from 'hooks';
import useLocalStorage from 'hooks/useLocalStorage';
import { DashboardLayout } from 'layouts/DashboardLayout';
import NotMatch from 'layouts/NotMatch';
import { ProtectedLayout } from 'layouts/ProtectedLayout';
import { PublicLayout } from 'layouts/PublicLayout';
import {
    AccountConfigAgency,
    AccountConfigCompany,
    AccountConfigSingleUser,
    AccountConfigSubCompany,
} from 'pages/AccountConfig';
import AccountConfigOrganization from 'pages/AccountConfig/AccountConfigOrganization';
import AgencyDetail from 'pages/AccountConfig/Agencies/AgencyDetail';
import EarningDetail from 'pages/AccountConfig/Earning/EarningDetail';
import AddLeads from 'pages/AccountConfig/Leads/AddLeads';
import EditLead from 'pages/AccountConfig/Leads/EditLeads';
import LeadReview from 'pages/AccountConfig/Leads/LeadReview';
import { PaymentDetail } from 'pages/AccountConfig/Payments';
import BlockChainPayments from 'pages/AccountConfig/Payments/BlockChainPayments';
import CardPayments from 'pages/AccountConfig/Payments/CardPayments';
import { CreateCompanyForm } from 'pages/AccountConfig/Profile';
import { CategoriesManagement, EditPost, PostManagement } from 'pages/AccountConfig/Resources';
import AddPost from 'pages/AccountConfig/Resources/AddPost';
import { Balance, Branding, Deals, Embeds, Markup, Pricing, UpgradeCompanyPlan } from 'pages/AccountConfig/Settings';
import { AudienceDetail, AudienceService } from 'pages/Audiences';
import Audiences from 'pages/Audiences/Audiences';
import { ChangeEmail, ResetPassword } from 'pages/Auth';
import Login from 'pages/Auth/Login';
import Campaign from 'pages/Campaign';
import CampaignDetail from 'pages/Campaign/CampaignDetail/CampaignDetail';
import CampaignService from 'pages/Campaign/CampaignService';
import { CreativeDetail, CreativeService } from 'pages/Creatives';
import Creatives from 'pages/Creatives/Creatives';
import Dashboard from 'pages/Dashboard';
import { Education, PostReview, TopicDetail } from 'pages/Education';
import PostDetail from 'pages/Education/PostDetail';
import PublicEducation from 'pages/Education/PublicEducation';
import PublicPostDetail from 'pages/Education/PublicPostDetail';
import Home from 'pages/Home';
import Industries from 'pages/Industries/Industries';
import { MediaDisplayDetail, MediaNativeDetail, MediaVideoDetail } from 'pages/Media';
import MediaLayout from 'pages/Media/MediaLayout';
import { Register } from 'pages/Register';
import RegisterAgencySubCompanyUser from 'pages/Register/RegisterAgencySubCompanyUser';
import RegisterCompanyOrganizationUser from 'pages/Register/RegisterCompanyOrganizationUser';
import RegisterPrimaryUser from 'pages/Register/RegisterPrimaryUser';
import { TicketComment, TicketDetail } from 'pages/Ticket';
import { Route, Routes } from 'react-router-dom';
import { routesPath } from 'routes/routes';

function App() {
    const [auth, setAuth] = useLocalStorage(AUTH_KEY);

    return (
        <Routes>
            {auth?.is_login ? (
                <Route element={<ProtectedLayout />}>
                    <Route element={<DashboardLayout />}>
                        <Route path={routesPath.dashboard} element={<Dashboard />} />
                        <Route path={routesPath.campaign} element={<Campaign />} />
                        <Route path={routesPath.audiences} element={<Audiences />} />
                        <Route path={routesPath.creative} element={<Creatives />} />
                        <Route path={routesPath.education} element={<Education />} />
                        <Route path={routesPath.account_single_user} element={<AccountConfigSingleUser />} />
                        <Route path={routesPath.account_subCo} element={<AccountConfigSubCompany />} />
                        <Route path={routesPath.account_company} element={<AccountConfigCompany />} />
                        <Route path={routesPath.account_agency} element={<AccountConfigAgency />} />
                        <Route path={routesPath.account_organization} element={<AccountConfigOrganization />} />
                        <Route path={routesPath.test_map} element={<GgMap />} />

                        {/* Settings */}
                        <Route path={routesPath.markup} element={<Markup />} />
                        <Route path={routesPath.embed} element={<Embeds />} />
                        <Route path={routesPath.balance} element={<Balance />} />
                        <Route path={routesPath.branding} element={<Branding />} />
                        <Route path={routesPath.deals} element={<Deals />} />
                        <Route path={routesPath.pricing} element={<Pricing />} />

                        <Route path={routesPath.upgrade_company_plan} element={<UpgradeCompanyPlan />} />
                        {/* Payment */}
                        <Route path={routesPath.blockchain_payments} element={<BlockChainPayments />} />
                        <Route path={routesPath.card_payments} element={<CardPayments />} />

                        {/* Users */}
                        <Route path={routesPath.add_user} element={<AddUser />} />
                        <Route path={routesPath.user_detail()} element={<UserDetail />} />
                        {/* Agency */}
                        <Route path={routesPath.agency_detail()} element={<AgencyDetail />} />

                        {/* Company */}
                        <Route path={routesPath.add_company} element={<AddCompany />} />
                        <Route path={routesPath.company_detail()} element={<CompanyDetail />} />

                        {/* Education */}
                        <Route path={routesPath.posts_management} element={<PostManagement />} />
                        <Route path={routesPath.categories_management} element={<CategoriesManagement />} />

                        <Route path={routesPath.add_post} element={<AddPost />} />
                        <Route path={routesPath.edit_post()} element={<EditPost />} />

                        <Route path={routesPath.add_lead} element={<AddLeads />} />
                        <Route path={routesPath.lead_review()} element={<LeadReview />} />
                        <Route path={routesPath.qaqc_review()} element={<LeadReview />} />

                        <Route path={routesPath.edit_lead()} element={<EditLead />} />

                        <Route path={routesPath.campaign_detail()} element={<CampaignDetail />} />

                        <Route path={routesPath.campaign_services()} element={<CampaignService />} />
                        <Route path={routesPath.audience_detail()} element={<AudienceDetail />} />

                        <Route path={routesPath.audience_edit()} element={<AudienceService />} />
                        <Route path={routesPath.audience_add} element={<AudienceService />} />

                        <Route path={routesPath.creative_detail()} element={<CreativeDetail />} />
                        <Route path={routesPath.creative_edit()} element={<CreativeService />} />
                        <Route path={routesPath.creative_add} element={<CreativeService />} />

                        <Route path={routesPath.ticket_detail()} element={<TicketDetail />} />
                        <Route path={routesPath.ticket_comment()} element={<TicketComment />} />

                        <Route
                            path={routesPath.media_display_detail()}
                            element={<MediaLayout MediaForm={MediaDisplayDetail} />}
                        />
                        <Route
                            path={routesPath.media_video_detail()}
                            element={<MediaLayout MediaForm={MediaVideoDetail} />}
                        />
                        <Route
                            path={routesPath.media_native_detail()}
                            element={<MediaLayout MediaForm={MediaNativeDetail} />}
                        />

                        <Route path={routesPath.earning_detail()} element={<EarningDetail />} />
                        <Route path={routesPath.payment_detail()} element={<PaymentDetail />} />
                        <Route path={routesPath.topic_detail()} element={<TopicDetail />} />
                        <Route path={routesPath.post_detail()} element={<PostDetail />} />
                        <Route path={routesPath.post_review()} element={<PostReview />} />
                    </Route>
                    <Route element={<Register />}>
                        <Route path={routesPath.create_company_form} element={<CreateCompanyForm />} />
                    </Route>
                </Route>
            ) : (
                <>
                    <Route path="/" element={<PublicLayout />}>
                        <Route path={routesPath.home} element={<Home />} />
                        <Route path={routesPath.industries} element={<Industries />} />

                        <Route path={routesPath.public_education} element={<PublicEducation />} />
                        <Route path={routesPath.public_post_detail()} element={<PublicPostDetail />} />
                    </Route>

                    <Route path={routesPath.register} element={<Register />}>
                        <Route path={routesPath.register + routesPath.primary_user} element={<RegisterPrimaryUser />} />
                        <Route
                            path={routesPath.register + routesPath.company_organization_user}
                            element={<RegisterCompanyOrganizationUser />}
                        />
                        <Route
                            path={routesPath.register + routesPath.agency_sub_company_user}
                            element={<RegisterAgencySubCompanyUser />}
                        />
                    </Route>

                    <Route path={routesPath.login} element={<Login />}>
                        <Route path={routesPath.login + routesPath.primary_user} />
                        <Route path={routesPath.login + routesPath.company_organization_user} />
                        <Route path={routesPath.login + routesPath.agency_sub_company_user} />
                    </Route>

                    <Route path={routesPath.reset_password} element={<ResetPassword />} />
                    <Route path={routesPath.change_email} element={<ChangeEmail />} />
                </>
            )}

            <Route path={routesPath.not_match} element={<NotMatch />} />
        </Routes>
    );
}

export default App;
