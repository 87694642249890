import clsx from 'clsx';
import Image from 'components/Image';
import Text from 'components/Text';
import { forwardRef, useRef, useState } from 'react';
import { sizes } from './styles/input.styles';

export const InputText = forwardRef(
    (
        {
            icon,
            mark,
            size = 'medium',
            rounded = true,
            className,
            placeholder,
            PlaceHolder,
            value,
            // onChange,
            disabled,
            error,
            classNameBox,
            label,
            helper,
            ...props
        },
        ref,
    ) => {
        const inputRef = useRef(null);
        const [isFocus, setIsFocus] = useState(false);

        return (
            <div ref={ref} className={clsx('relative w-full h-fit  block', disabled ? 'opacity-50' : '', classNameBox)}>
                {icon && (
                    <span
                        onClick={() => inputRef.current?.focus()}
                        className="absolute inset-y-0 left-0 flex items-center pl-[15px]"
                    >
                        <Image src={icon} />
                    </span>
                )}
                {mark && (
                    <span
                        onClick={() => inputRef.current?.focus()}
                        className="absolute inset-y-0 left-0 flex items-center pl-[15px] text-xs"
                    >
                        {mark}
                    </span>
                )}

                {label && !!String(value) ? (
                    <Text
                        className={clsx(
                            'z-100 px-[10px]  absolute bg-inherit z-0  rounded-[30px] shadow-default-top left-[15px] -top-[8px]  text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                            value ? 'bg-white' : 'bg-white-light',
                        )}
                        size="10"
                    >
                        {label}
                    </Text>
                ) : null}

                <input
                    ref={inputRef}
                    className={clsx(
                        'placeholder:text-grey-light placeholder:text-sm block  w-full  shadow-default focus:outline-none text-sm',

                        sizes[size],
                        icon ? 'pl-9' : '',
                        mark ? 'pl-7' : '',

                        value ? 'bg-white' : 'bg-white-light',
                        rounded ? 'rounded-[10px]' : '',

                        className,
                    )}
                    type="text"
                    placeholder={PlaceHolder ? undefined : placeholder}
                    value={value}
                    disabled={disabled}
                    {...props}
                    onBlur={() => {
                        setIsFocus(false);
                    }}
                    onFocus={() => setIsFocus(true)}
                />

                {helper && (
                    <Text
                        className={clsx(
                            'px-[10px] absolute bg-inherit z-0  rounded-[30px] shadow-default-bottom  bg-white left-[15px] -bottom-[5px] text-ellipsis overflow-hidden whitespace-nowrap max-w-[calc(100%-30px)]',
                            error ? 'text-error' : '',
                        )}
                        size="10"
                    >
                        {helper}
                    </Text>
                )}

                {isFocus ||
                    (!value && (
                        <span
                            onClick={() => {
                                inputRef.current.focus();
                            }}
                            className="cursor-text absolute inset-y-0 left-0 flex items-center pl-[20px] select-none"
                        >
                            {PlaceHolder}
                        </span>
                    ))}
            </div>
        );
    },
);
