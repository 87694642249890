import { IMAGE } from 'assets/imagePath';
import Avatar from 'components/Avatar/Avatar';
import Text from 'components/Text';
import React from 'react';

// messageInfo = {
//     avatar,
//     message,
//     time
// }
function ChatMessage({ isYou, messageInfo }) {
    return (
        <div className={`flex w-full gap-2.5 ${isYou ? 'flex-row' : 'flex-row-reverse'}`}>
            <div
                className={`p-2.5 flex-1 shadow-check-box rounded-[5px] ${
                    isYou ? 'bg-white-light' : 'bg-blue-dark text-white'
                }`}
            >
                <p className="text-xs ">{messageInfo?.message}</p>
            </div>

            <div className="flex flex-col self-end items-center gap-[5px]">
                <Avatar className={'max-w-[24px] max-h-[24px]'} src={messageInfo?.avatar || IMAGE.avatar} />
                <Text size="12" className="time__chat">
                    {messageInfo?.time}
                </Text>
            </div>
        </div>
    );
}

export default ChatMessage;
