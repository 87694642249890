import { memo, useLayoutEffect, useRef, useState } from 'react';
import { InputText } from './InputText';
import './styles/Input.css';
function RangeSlide({ defaultValue, min = 0, max = 5000, onChange, gap = 500, ...props }) {
    const [minValue, setMinValue] = useState(props?.value?.[0] || min);

    const [maxValue, setMaxValue] = useState(props.value?.[1] || max);

    const handleChangeInputMin = (e) => {
        const minVal = +e.target.value;

        if (minVal < 0) {
            setMinValue(0);
            return;
        }
        if (maxValue - minVal < gap) {
            return;
        }
        setMinValue(e.target.value);
    };

    const handleChangeInputMax = (e) => {
        const maxVal = +e.target.value;

        if (maxVal < 0) {
            setMinValue(0);
            return;
        }
        if (maxVal - gap < minValue) {
            return;
        }
        setMaxValue(e.target.value);
    };

    const progressRef = useRef(null);

    const handleChangeRange = (e, rangeType) => {
        const currentVal = parseInt(e.target.value);

        if (rangeType === 'min') {
            if (maxValue - currentVal < gap) {
                //Current Value = current min
                if (maxValue >= max) {
                    setMaxValue(max);
                    setMinValue(max - gap);
                    return;
                }
                setMaxValue(currentVal + gap);

                setMinValue(currentVal);
            } else {
                setMinValue(currentVal);
            }
        } else {
            if (currentVal - gap < minValue) {
                if (minValue <= min) {
                    setMaxValue(min + gap);
                    setMinValue(min);
                    return;
                }
                setMinValue(currentVal - gap);
                setMaxValue(currentVal);
            }
            setMaxValue(currentVal);
        }
    };

    useLayoutEffect(() => {
        if (progressRef.current) {
            //Style
            progressRef.current.style.left = (minValue * 100) / max + '%';
            progressRef.current.style.right = 100 - (maxValue * 100) / max + '%';
        }

        onChange && onChange?.([minValue, maxValue]);
    }, [minValue, maxValue]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between gap-5">
                <InputText
                    size="small"
                    placeholder="Min"
                    label="Min"
                    rounded
                    classNameBox="w-full"
                    className="w-full"
                    type="number"
                    // onChange={handleChangeInputMin}
                    value={minValue}
                    disabled
                />

                <div> - </div>

                <InputText
                    placeholder="Max"
                    label="Max"
                    rounded
                    size="small"
                    type="number"
                    className="appearance-none"
                    // onChange={handleChangeInputMax}
                    value={maxValue}
                    disabled
                />
            </div>

            <div className="relative double__slider h-[5px] rounded-lg bg-[#ddd] outline-none top-[1px] m-2.5">
                <div ref={progressRef} className="absolute h-full rounded-lg left-[25%] right-[25%]  bg-primary"></div>
                <input
                    className="absolute appearance-none bg-transparent outline-none pointer-events-none top-[-5px] left-0 w-[101%]"
                    type="range"
                    value={minValue}
                    min={min}
                    max={max}
                    onChange={(e) => handleChangeRange(e, 'min')}
                />
                <input
                    className="absolute appearance-none bg-transparent outline-none pointer-events-none  top-[-5px] left-0 w-[101%]"
                    type="range"
                    value={maxValue}
                    onChange={handleChangeRange}
                    min={min}
                    max={max}
                />
            </div>
        </div>
    );
}

export default memo(RangeSlide);
