import { SVG } from 'assets/imagePath';
import Image from 'components/Image';
import Text from 'components/Text';
import React from 'react';

function FindAudiences() {
    return (
        <section className="2xl:h-[203px] w-full mx-auto  py-[30px] px-[20px]  xs:p-[50px] bg-dark-nav">
            <div className="container mx-auto flex flex-col  2xl:flex-row-reverse items-center gap-[30px] justify-between">
                <div>
                    <div className="text-center 2xl:text-left 2xl:w-[312px] mb-[10px] text-white text-lg xs:text-xl font-bold leading-[30px] font-sen">
                        Find your perfect audience
                    </div>
                    <div className="text-center 2xl:text-left text-blue-cfd 2xl:max-w-[407px] text-xs xs:text-sm leading-[21px]">
                        Whether you bring your own first-party data or use our third-party data, we will help you
                        identify, reach, and engage your audiences across all supply channels
                    </div>
                </div>
                <div className="flex gap-y-5 gap-x-[10px] justify-center flex-wrap xl:flex-nowrap">
                    <div className="w-[120px] h-[80px] text-center">
                        <Image src={SVG.native} className="mx-auto w-10 h-10" />
                        <Text className="text-xs xs:text-sm leading-[21px] text-white xs:mt-2.5 mt-[5px]">Native</Text>
                    </div>
                    <div className="w-[120px] h-[80px] text-center">
                        <Image src={SVG.display} className="mx-auto w-10 h-10" />
                        <Text className="text-xs xs:text-sm leading-[21px] text-white xs:mt-2.5 mt-[5px]">Display</Text>
                    </div>
                    <div className="w-[120px] h-[80px] text-center">
                        <Image src={SVG.video} className="mx-auto w-10 h-10" />
                        <Text className="text-xs xs:text-sm leading-[21px] text-white xs:mt-2.5 mt-[5px]">Video</Text>
                    </div>
                    <div className="w-[120px] h-[80px] text-center">
                        <Image src={SVG.audio} className="mx-auto w-10 h-10" />
                        <Text className="text-xs xs:text-sm leading-[21px] text-white xs:mt-2.5 mt-[5px]">Audio</Text>
                    </div>
                    <div className="w-[120px] h-[80px]  text-center">
                        <Image src={SVG.tv} className="mx-auto w-10 h-10" />
                        <Text className="text-xs xs:text-sm leading-[21px] text-white xs:mt-2.5 mt-[5px]">Tv</Text>
                    </div>
                    <div className="w-[120px] h-[80px] text-center">
                        <Image src={SVG.game} className="mx-auto w-10 h-10" />
                        <Text className="text-xs xs:text-sm leading-[21px] text-white xs:mt-2.5 mt-[5px]">In-Game</Text>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FindAudiences;
