import { InputText } from 'components/Input';
import Text from 'components/Text';
import React from 'react';

function CPCCalculator() {
    return (
        <div className="flex flex-col mt-5 gap-5">
            <Text>CPC is Cost Per Click, also known as PPC (Pay Per Click).</Text>

            <InputText placeholder={'Cost'} />
            <InputText placeholder={'Clicks'} />
            <InputText placeholder={'CPM'} />
        </div>
    );
}

export default CPCCalculator;
