const NewKind = () => {
    return (
        <section className="w-full mx-auto px-5 py-[30px] xs:py-[60px] bg-white ">
            <div className="text-center text-4xl sm:text-5xl tracking-tight sm:tracking-tighter  font-bold pb-[10px] sm:pb-[20px] text-dark-nav font-sen">
                Amplify Your Reach
            </div>
            <div className="text-center mx-auto text-sm md:text-base leading-6  text-grey-light max-w-[640px]">
                Establish your brand as a market leader – act now and leverage the cutting-edge capabilities of
                AdNucleus’ innovative DSP to launch strategic advertising campaigns that will outshine your competition
                and fuel your business growth.
            </div>
        </section>
    );
};

export default NewKind;
