import { SVG } from 'assets/imagePath';
import Box from 'components/Box';
import { Button } from 'components/Button';
import { SignatureModule } from 'components/GlobalModules';
import Checkbox from 'components/Input/Checkbox';
import { Modal } from 'components/Modal';
import Text from 'components/Text';
import React, { useState } from 'react';

function SignBlock() {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <div className="flex flex-col gap-5">
                <Box className="bg-white-light p-[15px] max-h-[260px] overflow-auto">
                    <Text size="14">
                        Enim cras non sit consectetur posuere. Malesuada non duis vel suspendisse porttitor ultricies
                        egestas justo convallis. Euismod faucibus dui fames non. A nam proin blandit ac vitae integer
                        ornare neque. Enim imperdiet magnis ut orci amet. <br /> Hac tristique sapien fringilla egestas
                        tellus ut in interdum. Augue vitae quisque viverra massa eget. Purus arcu sed facilisi at id
                        urna lacus in ut. Vulputate etiam malesuada integer sed. Adipiscing condimentum ipsum ultrices
                        sodales dolor tellus. Aliquam bibendum faucibus nulla libero. Maecenas ultrices tincidunt id
                        diam nulla ut sit amet ultricies. Enim neque pellentesque augue aliquet convallis et velit. Dui
                        fermentum vulputate tristique pretium at accumsan etiam elementum semper. Non consectetur eget
                        porttitor aenean gravida parturient. Arcu sed cursus volutpat placerat tempus velit ultrices
                        ultricies a. Rhoncus suspendisse egestas molestie pellentesque urna ullamcorper aliquam aliquam
                        tellus. Enim mi augue adipiscing quis egestas eget. Aenean habitant tellus nisl at sagittis
                        dolor in. Nibh vulputate auctor quam habitasse dictum cras diam.
                    </Text>
                </Box>
                <div className="flex flex-col gap-2.5 md:grid  md:grid-cols-3 items-center">
                    <div className="col-start-2">
                        <Checkbox />
                    </div>
                    <div className="col-start-3">
                        <Button
                            onClick={() => setIsOpen(true)}
                            className={'w-full'}
                            color="primary"
                            startIcon={SVG.signature_icon}
                        >
                            Add Signature
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                bodyClass={'max-w-[500px] pb-[30px]'}
                title="Add Signature"
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <SignatureModule />
            </Modal>
        </>
    );
}

export default SignBlock;
