import { Button } from 'components/Button';
import { useAdCanva } from 'context/AdCanvaContext';
import CanvaDrawer from '../CanvaDrawer';

function TemplateLayout({ children }) {
    const { setStep, step } = useAdCanva();

    const onNext = () => {
        setStep((prev) => prev + 1);
    };
    return (
        <div className="flex flex-col gap-5 mt-5">
            <div className="flex flex-col-reverse gap-5 md:flex-row">
                <div className="flex-1">
                    {children}

                    <div className="flex flex-row w-full gap-5 mt-5">
                        <Button
                            onClick={(prev) => setStep(step - 1)}
                            className={'flex-1'}
                            color="secondary"
                            variant="contained"
                        >
                            Back
                        </Button>

                        <Button className={'flex-1'} onClick={onNext}>
                            Continue
                        </Button>
                    </div>
                </div>

                <div className="flex-[3] overflow-hidden">
                    <CanvaDrawer />
                </div>
            </div>
        </div>
    );
}

export default TemplateLayout;
